import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import users from './modules/users'
import infomation from './modules/infomation'
import commons from './apis/commons'
import administrators from './apis/administrators'
import menu from './apis/menu'
import roles from './apis/roles'
import businesses from './apis/businesses'
import notices from './apis/Operation/notices'
import campaign from './apis/campaign'
import tags from './apis/Influences/tags'
import campaignManage from './apis/campaignManage'
import ir from './apis/Homepage/ir'
import influences from './apis/Influences/influences'
import channels from './apis/Influences/channels'
import costs from './apis/Influences/costs'
import billingGroups from './apis/billingGroups'
import faqs from './apis/Operation/faqs'
import qnas from './apis/Operation/qnas'
import messages from './apis/Operation/messages'
import adQnas from './apis/Homepage/adQnas'
import productionQnas from './apis/Homepage/productionQnas'
import agencyQnas from './apis/Homepage/agencyQnas'
import terms from './apis/Operation/terms'
import uploads from './apis/uploads'
import banners from './apis/Operation/banners'
import posts from './apis/posts'
import personalDashboard from './apis/personalDashboard'
import showHosts from './apis/showHost'
import kozelDashboard from './apis/kozelDashboard'
import businessesUser from './apis/businessesUser'
import services from './apis/services'
import channelGuide from './apis/channelGuide'
import stylemate from './apis/stylemate'
import alimtalk from './apis/Operation/alimtalk'

export default createStore({
  debug: true,
  namespaced: true,
  state: {
    sideMenuOpened: true,
    listUrl: localStorage.getItem('listUrl')
  },
  getters: {
    getListUrl: state => state.listUrl
  },
  mutations: {
    setSideOpened (state, payload) {
      state.sideMenuOpened = payload
    },
    setListUrl (state, payload) {
      localStorage.setItem('listUrl', payload)
      state.listUrl = payload
    }
  },
  actions: {
    changeSideOpened ({ commit }, state) {
      commit('setSideOpened', state)
    },
    setListUrl ({ commit }, state) {
      commit('setListUrl', state)
    }
  },
  modules: {
    auth,
    users,
    infomation,
    uploads,
    commons,
    menu,
    businesses,
    billingGroups,
    campaign,
    campaignManage,
    stylemate,
    administrators,
    roles,
    channels,
    influences,
    tags,
    costs,
    banners,
    faqs,
    messages,
    notices,
    qnas,
    terms,
    ir,
    adQnas,
    productionQnas,
    agencyQnas,
    posts,
    personalDashboard,
    showHosts,
    kozelDashboard,
    businessesUser,
    services,
    channelGuide,
    alimtalk
  },
  plugins: [
    createPersistedState({
      paths: []
    })
    // createLogger()
  ]
})
