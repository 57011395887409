import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { useAxios } from '@vueuse/integrations/useAxios'

export const useMbcaCampaignsStore = defineStore('mbcaCampaigns', ({
  state: () => ({
    campaignList: [],
    campaignDetail: {},
    campaignHistoryList: [],
    materialList: [],
    materialDetail: {},
    materialHistoryList: [],
    materialUnSelectRows: [],
    coverData: {},
    remoteSecondGoogleCategory: []
  }),
  actions: {
    setCoverLoading ({ show, text }) {
      this.coverData = {
        show,
        text
      }
    },
    async getList (params) {
      const config = {
        method: 'GET',
        url: '/_p/biz/bca-campaigns',
        params: {
          ...params
        }
      }
      const result = await useAxios(config.url, config, axios)
      this.campaignList = result
    },
    async campaignDownload ({ params }) {
      const config = {
        method: 'GET',
        responseType: 'blob',
        url: '/_p/biz/bca-campaigns/download',
        params: {
          ...params
        }
      }
      const { response } = await useAxios(config.url, config, axios)
      return response.value
    },
    async getDetail ({ bcaCampaignId }) {
      const config = {
        method: 'GET',
        url: `/_p/biz/bca-campaigns/${bcaCampaignId}`
      }
      const { response } = await useAxios(config.url, config, axios)
      this.campaignDetail = response
    },
    async setMultipleCampaignStatus (data) {
      const config = {
        method: 'PATCH',
        url: '/_p/biz/bca-campaigns/process-detail-status',
        data
      }

      return await useAxios(config.url, config, axios)
    },
    async setCampaignStatus ({ data }) {
      const config = {
        method: 'PATCH',
        url: '/_p/biz/bca-campaigns/process-detail-status',
        data
      }

      return await useAxios(config.url, config, axios)
    },
    async getCampaignHistory ({ bcaCampaignId, data }) {
      const config = {
        method: 'GET',
        url: `/_p/biz/bca-campaigns/${bcaCampaignId}/examination-histories`,
        data
      }

      const result = await useAxios(config.url, config, axios)
      this.campaignHistoryList = result
    },
    async getMaterialList ({ bcaCampaignId, params }) {
      const config = {
        method: 'GET',
        url: `/_p/biz/bca-campaigns/${bcaCampaignId}/bca-ad-creatives`,
        params: {
          ...params,
          perPage: 9999
        }
      }

      const result = await useAxios(config.url, config, axios)
      this.materialList = result
    },
    async getMaterialDetail ({ bcaCampaignId, bcaAdCreativeId }) {
      const config = {
        method: 'GET',
        url: `/_p/biz/bca-campaigns/${bcaCampaignId}/bca-ad-creatives/${bcaAdCreativeId}`
      }

      const result = await useAxios(config.url, config, axios)
      this.materialDetail = result.response.value
    },
    async getMaterialHistoryList ({ bcaCampaignId, bcaAdCreativeId, params }) {
      const config = {
        method: 'GET',
        url: `/_p/biz/bca-campaigns/${bcaCampaignId}/bca-ad-creatives/${bcaAdCreativeId}/examination-histories`,
        params: {
          ...params
        }
      }

      const result = await useAxios(config.url, config, axios)
      this.materialHistoryList = result
    },
    async setMaterialStatus ({ bcaCampaignId, data }) {
      const config = {
        method: 'PATCH',
        url: `/_p/biz/bca-campaigns/${bcaCampaignId}/bca-ad-creatives/creative-status`,
        data
      }

      return await useAxios(config.url, config, axios)
    },
    async setCampaignSetting ({ bcaCampaignId, data }) {
      const config = {
        method: 'PATCH',
        url: `/_p/biz/bca-campaigns/${bcaCampaignId}/setting`,
        data
      }
      const result = await useAxios(config.url, config, axios)
      return result
    },
    setMaterialUnSelectRows (row) {
      this.materialUnSelectRows = row
    },
    async getAdCreativeList ({ params }) {
      const config = {
        method: 'GET',
        url: '/_p/biz/bca-ad-creatives',
        params
      }
      return await useAxios(config.url, config, axios)
    },
    async getGoogleAdsCategory (params) {
      const config = {
        method: 'GET',
        url: '/_p/biz/google-ads-category',
        params: {
          order: 'name',
          sort: 'ascend',
          ...params
        }
      }
      return await useAxios(config.url, config, axios)
    },
    async addGoogleAdsCategory ({ data }) {
      const config = {
        method: 'POST',
        url: '/_p/biz/google-ads-category',
        data
      }

      return await useAxios(config.url, config, axios)
    },
    async getGoogleCategory (params) {
      const config = {
        method: 'GET',
        url: '/_p/biz/google/category',
        params
      }

      const { response } = await useAxios(config.url, config, axios)
      if (config.params.depth === 2) {
        this.remoteSecondGoogleCategory = response.value?.results.map(item => item.topicConstant)
      }
      return response.value?.results.map(item => item.topicConstant)
    },
    async saveManageGoogleCategory ({ data, isCreate, googleAdsCategoryId }) {
      console.log({ isCreate })
      const config = {
        method: isCreate ? 'POST' : 'PUT',
        url: isCreate ? '/_p/biz/google-ads-category' : `/_p/biz/google-ads-category/${googleAdsCategoryId}`,
        data
      }
      try {
        await useAxios(config.url, config, axios)
      } catch (error) {
        console.log(error)
      }
    },
    async deleteManageGoogle ({ googleAdsCategoryId }) {
      const config = {
        method: 'DELETE',
        url: `/_p/biz/google-ads-category/${googleAdsCategoryId}`
      }

      await useAxios(config.url, config, axios)
    }
  }
}))
