import { defineAsyncComponent } from 'vue'
import { MESSAGES } from '@/constants/messages'
import { modal } from 'vxe-table'

const confirmModal = (option) => {
  modal.confirm({
    showHeader: !!option.title,
    status: 'warning',
    confirmButtonText: option.okText || MESSAGES.COMMON.BUTTON.OK,
    cancelButtonText: option.cancelText || MESSAGES.COMMON.BUTTON.CANCEL,
    title: option?.title,
    slots: {
      default: () => {
        const htmlContent = defineAsyncComponent(() => import('@/components/layout/HtmlContent.vue'))

        return [
          <htmlContent content={option?.content} />
        ]
      }
    }
  }).then((type) => {
    if (type === 'confirm') {
      if (option?.onOk) {
        option?.onOk()
      }
    } else {
      if (option?.onCancel) {
        option?.onCancel()
      }
    }
  })
}

const infoModal = (option) => {
  modal.alert({
    showHeader: !!option.title,
    confirmButtonText: option.okText || MESSAGES.COMMON.BUTTON.OK,
    status: 'info',
    title: option?.title,
    content: option?.content
  }).then((type) => {
    if (type === 'confirm') {
      if (option?.onOk) {
        option?.onOk()
      }
    }
  })
}

const messageModal = (option) => {
  modal.message(option).then(r => {})
}

export {
  confirmModal,
  infoModal,
  messageModal
}
