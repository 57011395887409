import axios from '@/plugins/axios'
import {
  SET_MESSAGE_TEMPLATE_LIST,
  SET_MESSAGE_RESULT_LIST,
  SET_MESSAGE_SEND_WAITING_LIST,
  SET_MESSAGE_GROUP_STAT
} from '@/store/mutation_types'

const state = {
  messageListModel: {},
  messageResultListModel: {},
  messageSendListWaitingModel: {},
  messageGroupsStat: {},
  messageTemplateListModel: {}
}

const getters = {
  sendWaitingData: state => state.messageSendListWaitingModel,
  sendResultData: state => state.messageResultListModel,
  messageTemplateData: state => state.messageTemplateListModel,
  messageGroupsStat: state => state.messageGroupsStat
}

const actions = {
  async sendMessage ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: `/_p/message-groups/${data.bodyType}`,
      data: {
        ...data
      }
    }
    const res = await axios(config)
    return res
  },
  /* 발송 대기 조회 */
  async getSendWaitingList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/message-groups',
      params: {
        perPage: 10,
        page: 1,
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_MESSAGE_SEND_WAITING_LIST, response)
    })
  },
  async cancelMessageItem ({ commit }, { id }) {
    const setting = {
      method: 'PATCH',
      url: `/_p/message-groups/${id}/cancel`
    }
    const result = await axios(setting)
    return result
  },
  async setMessageItemSendTime ({ commit }, { id, data }) {
    const setting = {
      method: 'PATCH',
      url: `/_p/message-groups/${id}/scheduled`,
      data: { ...data }
    }
    const result = await axios(setting)
    return result
  },
  async getMessageItemStat ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/message-groups/${id}/stat`
    }
    await axios(config).then((response) => {
      commit(SET_MESSAGE_GROUP_STAT, response)
    })
  },
  /* 발송 결과 조회 */
  async getSendResultList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/messages',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_MESSAGE_RESULT_LIST, response)
    })
  },
  async resendMessage ({ commit }, { data }) {
    const setting = {
      method: 'PATCH',
      url: '/_p/messages/re-send',
      data
    }
    const result = await axios(setting)
    return result
  },
  /* 템플릿 조회 */
  async getMessageTemplateList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/message-templates',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_MESSAGE_TEMPLATE_LIST, response)
    })
  },
  async saveTemplateItem ({ commit }, { id, data }) {
    const urls = id ? `/${id}` : ''
    const config = {
      method: id ? 'PUT' : 'POST',
      url: `/_p/message-templates${urls}`,
      params: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async deleteTemplateItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/message-templates/${id}`
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_MESSAGE_TEMPLATE_LIST]: (state, payload) => {
    state.messageTemplateListModel = payload
  },
  [SET_MESSAGE_RESULT_LIST]: (state, payload) => {
    state.messageResultListModel = payload
  },
  [SET_MESSAGE_SEND_WAITING_LIST]: (state, payload) => {
    state.messageSendListWaitingModel = payload
  },
  [SET_MESSAGE_GROUP_STAT]: (state, payload) => {
    state.messageGroupsStat = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
