import axios from '@/plugins/axios'
import {
  SET_CHANNEL_GUIDE_LIST,
  SET_CHANNEL_GUIDE_DETAIL
} from '@/store/mutation_types'

const state = {
  channelGuide: {},
  channelGuideDetail: {}
}
const getters = {
  channelGuideList: state => state.channelGuide,
  channelGuideDetail: state => state.channelGuideDetail
}
const actions = {
  async getChannelGuideList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/guides',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_CHANNEL_GUIDE_LIST, response)
    })
  },
  async getChannelGuideDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/guides/${id}`
    }

    await axios(config).then(response => {
      commit(SET_CHANNEL_GUIDE_DETAIL, response)
    })
  },
  async createChannelGuideItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/guides',
      data
    }
    const result = await axios(config)
    return result
  },
  async saveChannelGuideItem ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/guides/${id}`,
      data
    }
    const result = await axios(setting)
    return result
  },
  async deleteChannelGuideItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/guides/${id}`
    }
    const result = await axios(setting)
    return result
  }
}
const mutations = {
  [SET_CHANNEL_GUIDE_LIST]: (state, payload) => {
    state.channelGuide = payload
  },
  [SET_CHANNEL_GUIDE_DETAIL]: (state, payload) => {
    state.channelGuideDetail = payload
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
