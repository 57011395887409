export const vxeKr = {
  form: {
    folding: '확장검색',
    unfolding: '확장검색'
  },
  input: {
    date: {
      m1: '01월',
      m2: '02월',
      m3: '03월',
      m4: '04월',
      m5: '05월',
      m6: '06월',
      m7: '07월',
      m8: '08월',
      m9: '09월',
      m10: '10월',
      m11: '11월',
      m12: '12월',
      quarterLabel: '{0}',
      monthLabel: '{0}',
      dayLabel: '{0}년 {1}',
      labelFormat: {
        date: 'yyyy-MM-dd',
        time: 'HH:mm:ss',
        datetime: 'yyyy-MM-dd HH:mm:ss',
        week: '[Week] WW, yyyy',
        month: 'yyyy-MM',
        quarter: '[Quarter] q, yyyy',
        year: 'yyyy'
      },
      weeks: {
        w: 'Week',
        w0: '일',
        w1: '월',
        w2: '화',
        w3: '수',
        w4: '목',
        w5: '금',
        w6: '토'
      },
      months: {
        m0: '01월',
        m1: '02월',
        m2: '03월',
        m3: '04월',
        m4: '05월',
        m5: '06월',
        m6: '07월',
        m7: '08월',
        m8: '09월',
        m9: '10월',
        m10: '11월',
        m11: '12월'
      },
      quarters: {
        q1: 'First quarter',
        q2: 'Second quarter',
        q3: 'Third quarter',
        q4: 'Fourth quarter'
      }
    }
  }
}
