import axios from '@/plugins/axios'

const state = {}

const getters = {}

const actions = {
  async getSignedUrl ({ commit }, { params }) {
    const config = {
      method: 'GET',
      url: '/uploads/pre-signed-url',
      params: {
        ...params
      }
    }

    const res = await axios(config)
    return res
  },

  async uploadSignedUrl ({ commit }, { url, file }) {
    const readFile = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => { resolve(reader.result) })
        reader.readAsArrayBuffer(file)
      })
    }

    const data = await readFile(file)
    await fetch(url, { method: 'PUT', body: data })

    // const config = {
    //   method: 'PUT',
    //   url: url,
    //   headers: { 'Content-Type': 'application/octet-stream' },
    //   data: data
    // }
    //
    // delete axios.defaults.headers.common.Authorization
    //
    // await axios(config).then(response => {
    //   axios.defaults.headers.common.Authorization = `Bearer ${authInfo.token}`
    //   return response
    // })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
