import axios from '@/plugins/axios'
import {
  SET_TRACKING_ERROR_LIST,
  SET_TRACKING_ALARM_LIST
} from '@/store/mutation_types'

const state = {
  postsTrackingErrorModel: {},
  postsTrackingAlarmModel: {}
}

const getters = {
  postsTrackingErrorData: state => state.postsTrackingErrorModel,
  postsTrackingAlarmData: state => state.postsTrackingAlarmModel
}

const actions = {
  async getTrackingErrorList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/posts/tracking-error',
      params: {
        perPage: 10,
        page: 1,
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_TRACKING_ERROR_LIST, response)
    })
  },
  async getTrackingAlarmList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/posts/tracking-alarm',
      params: {
        perPage: 10,
        page: 1,
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_TRACKING_ALARM_LIST, response)
    })
  },
  async setPostingDays ({ commit }, { id, days }) {
    const config = {
      method: 'PATCH',
      url: `/_p/posts/${id}/posting-days`,
      data: {
        postingDays: days
      }
    }

    await axios(config)
  }
}

const mutations = {
  [SET_TRACKING_ERROR_LIST]: (state, payload) => {
    state.postsTrackingErrorModel = payload
  },
  [SET_TRACKING_ALARM_LIST]: (state, payload) => {
    state.postsTrackingAlarmModel = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
