import axios from '@/plugins/axios'
import {
  SET_STYLE_MATE_BRAND_LIST,
  SET_STYLE_MATE_BRAND_DETAIL,
  SET_STYLE_MATE_PRODUCT_LIST,
  SET_STYLE_MATE_PRODUCT_DETAIL,
  SET_STYLE_MATE_FRONT_MANAGE_LIST,
  SET_STYLE_MATE_POST_LIST,
  SET_STYLE_MATE_APPROVE_LIST,
  SET_STYLE_MATE_LIKE_LIST
} from '@/store/mutation_types'

const state = {
  brand: {
    list: {},
    detail: {}
  },
  product: {
    list: {},
    detail: {}
  },
  approve: {
    list: {}
  },
  front: {
    product: {},
    brand: {},
    post: {}
  },
  post: {},
  like: {}
}

const getters = {
  brandListData: state => state.brand.list,
  brandDetail: state => state.brand.detail,
  productListData: state => state.product.list,
  productDetail: state => state.product.detail,
  frontProductListData: state => state.front.product,
  frontBrandListData: state => state.front.brand,
  frontPostListData: state => state.front.post,
  postListData: state => state.post,
  approveListData: state => state.approve.list,
  likeListData: state => state.like
}

const actions = {
  async getStyleMateBrandList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/stylemates/brands',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_STYLE_MATE_BRAND_LIST, response)
    })
  },
  async getStyleMateBrandDetail ({ commit }, id) {
    const config = {
      method: 'GET',
      url: `/_p/stylemates/brands/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_STYLE_MATE_BRAND_DETAIL, response)
    })
  },
  async createStyleMateBrandItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/stylemates/brands',
      data
    }
    const result = await axios(config)
    commit(SET_STYLE_MATE_BRAND_DETAIL, result)
    return result
  },
  async saveStyleMateBrandItem ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/stylemates/brands/${id}`,
      data
    }
    const result = await axios(setting)
    commit(SET_STYLE_MATE_BRAND_DETAIL, result)
    return result
  },
  async getStyleMateProductList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/stylemates/products',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_STYLE_MATE_PRODUCT_LIST, response)
    })
  },
  async getStyleMateProductDetail ({ commit }, id) {
    const config = {
      method: 'GET',
      url: `/_p/stylemates/products/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_STYLE_MATE_PRODUCT_DETAIL, response)
    })
  },
  async createStyleMateProductItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/stylemates/products',
      data
    }
    const result = await axios(config)
    commit(SET_STYLE_MATE_PRODUCT_DETAIL, result)
    return result
  },
  async saveStyleMateProductItem ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/stylemates/products/${id}`,
      data
    }
    const result = await axios(setting)
    commit(SET_STYLE_MATE_PRODUCT_DETAIL, result)
    return result
  },
  async setStyleMateBrandDisplay ({ commit }, data) {
    const setting = {
      method: 'PATCH',
      url: '/_p/stylemates/brands/is-display',
      data
    }
    const result = await axios(setting)
    return result
  },
  async getStyleMateFrontManageList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/stylemates/front-manages',
      params: {
        ...params,
        perPage: 9999
      }
    }
    await axios(config).then((response) => {
      commit(SET_STYLE_MATE_FRONT_MANAGE_LIST, {
        type: params.type,
        payload: response
      })
    })
  },
  async createStyleMateFrontManageItem ({ commit }, data) {
    const config = {
      method: 'POST',
      url: '/_p/stylemates/front-manages',
      data
    }
    const result = await axios(config)
    return result
  },
  async delStyleMateFrontManageItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/stylemates/front-manages/${id}`
    }
    const result = await axios(setting)
    return result
  },
  async setStyleMateFrontManageOrdering ({ commit }, data) {
    const config = {
      method: 'PATCH',
      url: '/_p/stylemates/front-manages/ordering',
      data
    }
    const result = await axios(config)
    return result
  },
  async getStyleMatePostList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/stylemates/posts',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_STYLE_MATE_POST_LIST, response)
    })
  },
  // 승인요청
  async getStyleMateApproveList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/stylemates/stylemate-approve',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_STYLE_MATE_APPROVE_LIST, response)
    })
  },
  async setStyleMateApproveStatus ({ commit }, { id, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/stylemates/channels/${id}/stylemate-approve`,
      data
    }
    const result = await axios(config)
    return result
  },
  async setStyleMateForcingApproveStatus ({ commit }, { id, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/stylemates/channels/${id}/force-stylemate-approve`,
      data: {
        stylemateStatus: 'approve'
      }
    }
    const result = await axios(config)
    return result
  },
  async getStyleMateLikeList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/stylemates/influence-likes',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_STYLE_MATE_LIKE_LIST, response)
    })
  },
  async productMultipleUpload ({ commit }, file) {
    const config = {
      method: 'POST',
      url: '/_p/stylemates/products/upload',
      data: file
    }
    console.log(config)
    return await axios(config)
  },
  async setProductDisplay ({ commit }, { params, productId }) {
    const config = {
      method: 'PATCH',
      url: `/_p/stylemates/products/${productId}/is-display`,
      params
    }
    return await axios(config)
  },
  async deleteProduct ({ commit }, productId) {
    const config = {
      method: 'DELETE',
      url: `/_p/stylemates/products/${productId}`
    }
    return await axios(config)
  },
  async imageThumbnailUpload ({ commit }, { params, productId }) {
    const config = {
      method: 'POST',
      url: `/_p/stylemates/products/${productId}/image-thumbnail-path`,
      params
    }
    return await axios(config)
  },
  async producImageUpload ({ commit }, { data, productId }) {
    const config = {
      method: 'POST',
      url: `/_p/stylemates/products/${productId}/product-image-files`,
      data
    }
    return await axios(config)
  }
}

const mutations = {
  [SET_STYLE_MATE_BRAND_LIST]: (state, payload) => {
    state.brand.list = payload
  },
  [SET_STYLE_MATE_BRAND_DETAIL]: (state, payload) => {
    state.brand.detail = payload
  },
  [SET_STYLE_MATE_PRODUCT_LIST]: (state, payload) => {
    state.product.list = payload
  },
  [SET_STYLE_MATE_PRODUCT_DETAIL]: (state, payload) => {
    state.product.detail = payload
  },
  [SET_STYLE_MATE_FRONT_MANAGE_LIST]: (state, { type, payload }) => {
    state.front[type] = payload
  },
  [SET_STYLE_MATE_POST_LIST]: (state, payload) => {
    state.post = payload
  },
  [SET_STYLE_MATE_APPROVE_LIST]: (state, payload) => {
    state.approve.list = payload
  },
  [SET_STYLE_MATE_LIKE_LIST]: (state, payload) => {
    state.like = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
