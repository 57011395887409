import { PAGE, PAGE_TITLE } from '@@routes'

export default {
  path: '/homepage',
  name: PAGE.HOMEPAGE,
  redirect: '/homepage/ir',
  component: () => import(/* webpackChunkName: "homepage" */'@page/Homepage/Index.vue'),
  meta: {
    title: PAGE_TITLE.HOMEPAGE,
    serviceName: PAGE.HOMEPAGE,
    useIcon: 'ri-computer-line'
  },
  children: [
    {
      path: 'ir',
      name: PAGE.HOMEPAGE_IR,
      redirect: '/homepage/ir/list',
      component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Index.vue'),
      meta: {
        title: PAGE_TITLE.HOMEPAGE_IR,
        menuName: PAGE.HOMEPAGE_IR,
        roleKeys: { menu: 'homepage', subMenu: 'iRManage' }
      },
      children: [
        {
          path: 'list/:page?',
          name: PAGE.HOMEPAGE_IR_LIST,
          component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Ir/List.vue')
        },
        {
          path: 'form/:id?',
          name: PAGE.HOMEPAGE_IR_FORM,
          component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Ir/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.HOMEPAGE_IR_FORM
          }
        }
      ]
    },
    {
      path: 'recommend',
      name: PAGE.HOMEPAGE_RECOMMEND,
      redirect: '/homepage/recommend/advertise',
      component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/Index.vue'),
      meta: {
        title: PAGE_TITLE.HOMEPAGE_RECOMMEND,
        menuName: PAGE.HOMEPAGE_RECOMMEND,
        tabName: PAGE.HOMEPAGE_RECOMMEND,
        roleKeys: { menu: 'homepage', subMenu: 'inquireAdvertisement' }
      },
      children: [
        {
          path: 'advertise',
          name: PAGE.HOMEPAGE_ADVERTISE_RECOMMEND,
          redirect: '/homepage/recommend/advertise/list',
          component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.HOMEPAGE_ADVERTISE_RECOMMEND
          },
          children: [
            {
              path: 'list',
              name: PAGE.HOMEPAGE_ADVERTISE_RECOMMEND_LIST,
              component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/Advertisement/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.HOMEPAGE_ADVERTISE_RECOMMEND_ANSWER,
              component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/Advertisement/Answer.vue'),
              meta: {
                pageTitle: PAGE_TITLE.HOMEPAGE_ADVERTISE_RECOMMEND_ANSWER
              }
            }
          ]
        },
        {
          path: 'production',
          name: PAGE.HOMEPAGE_PRODUCTION_RECOMMEND,
          redirect: '/homepage/recommend/production/list',
          component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.HOMEPAGE_PRODUCTION_RECOMMEND
          },
          children: [
            {
              path: 'list',
              name: PAGE.HOMEPAGE_PRODUCTION_RECOMMEND_LIST,
              component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/Production/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.HOMEPAGE_PRODUCTION_RECOMMEND_ANSWER,
              component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/Production/Answer.vue'),
              meta: {
                pageTitle: PAGE_TITLE.HOMEPAGE_PRODUCTION_RECOMMEND_ANSWER
              }
            }
          ]
        },
        {
          path: 'agency',
          name: PAGE.HOMEPAGE_AGENCY_RECOMMEND,
          redirect: '/homepage/recommend/agency/list',
          component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.HOMEPAGE_AGENCY_RECOMMEND
          },
          children: [
            {
              path: 'list',
              name: PAGE.HOMEPAGE_AGENCY_RECOMMEND_LIST,
              component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/Agency/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.HOMEPAGE_AGENCY_RECOMMEND_ANSWER,
              component: () => import(/* webpackChunkName: "homepage" */ '@page/Homepage/Recommend/Agency/Answer.vue'),
              meta: {
                pageTitle: PAGE_TITLE.HOMEPAGE_AGENCY_RECOMMEND_ANSWER
              }
            }
          ]
        }
      ]
    }
  ]
}
