import axios from '@/plugins/axios'
import { SET_NOTICES_LIST, SET_NOTICES_DETAIL } from '@/store/mutation_types'

const state = {
  noticeModel: {},
  noticeDetail: {}
}

const getters = {
  noticeListData: state => state.noticeModel,
  noticeDetail: state => state.noticeDetail
}

const actions = {
  async getNoticeList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/notices',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_NOTICES_LIST, response)
    })
  },
  async getNoticeItemDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/notices/${id}`
    }

    await axios(config).then(response => {
      commit(SET_NOTICES_DETAIL, response)
    })
  },
  async createNoticeItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/notices',
      data
    }
    const result = await axios(config)
    return result
  },
  async saveNoticeItem ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/notices/${id}`,
      data
    }
    const result = await axios(setting)
    return result
  },
  async deleteNoticeItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/notices/${id}`
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_NOTICES_LIST]: (state, payload) => {
    state.noticeModel = payload
  },
  [SET_NOTICES_DETAIL]: (state, payload) => {
    state.noticeDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
