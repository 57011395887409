import axios from '@/plugins/axios'
import {
  SET_ALIMTALK_TEMPLATE_LIST,
  SET_ALIMTALK_TEMPLATE_DETAIL,
  SET_ALIMTALK_TEMPLATE_LIST_TEST
  // SET_ALIMTALK_RESULT_LIST,
  // SET_ALIMTALK_SEND_WAITING_LIST
} from '@/store/mutation_types'

const state = {
  alimtalkTemplateListTest: {},
  alimtalkTemplateListModel: {},
  alimtalkTemplateListDetail: {}

}

const getters = {
  alimtalkTemplateDataTest: state => state.alimtalkTemplateListTest,
  alimtalkTemplateData: state => state.alimtalkTemplateListModel,
  alimtalkTemplateDetail: state => state.alimtalkTemplateListDetail
}

const actions = {
  // 발송
  async sendAlimtalk ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/message-groups/alimtalk',
      data: {
        ...data
      }
    }
    const res = await axios(config)
    return res
  },
  // 템플릿
  async getTemplateList ({ commit }, data) {
    const config = {
      method: 'GET',
      url: '/_p/message-template-alimtalks/list',
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async getTemplateDetail ({ commit }, { code }) {
    const config = {
      method: 'GET',
      url: `/_p/message-template-alimtalks/${code}`
    }
    await axios(config).then((response) => {
      commit(SET_ALIMTALK_TEMPLATE_LIST_TEST, response)
    })
  },
  async getAlimtalkTemplateList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/message-template-alimtalks',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_ALIMTALK_TEMPLATE_LIST, response)
    })
  },
  async getAlimtalkTemplateDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/message-template-alimtalks/${id}`
    }

    await axios(config).then(response => {
      commit(SET_ALIMTALK_TEMPLATE_DETAIL, response)
    })
  },
  async createAlimtalkTemplateItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/message-template-alimtalks',
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async saveAlimtalkTemplateItem ({ commit }, { id, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/message-template-alimtalks/${id}`,
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async deleteAlimtalkTemplateItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/message-template-alimtalks/${id}`
    }
    const result = await axios(setting)
    return result
  }
}
const mutations = {
  [SET_ALIMTALK_TEMPLATE_LIST]: (state, payload) => {
    state.alimtalkTemplateListModel = payload
  },
  [SET_ALIMTALK_TEMPLATE_LIST_TEST]: (state, payload) => {
    state.alimtalkTemplateListTest = payload
  },
  [SET_ALIMTALK_TEMPLATE_DETAIL]: (state, payload) => {
    state.alimtalkTemplateListDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
