<template>
  <teleport :to="props.teleport">
    <vue-final-modal
      v-slot="{ params, close }"
      v-bind="$attrs"
      :ssr="false"
      :lock-scroll="props.teleport === 'body'"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="modal__title">
        {{ props.title }}
        <slot name="title" />
        <button
          v-if="!props.nonIconClose"
          class="modal__close"
          @click="modalActions.cancel(close)"
        >
          <i class="ri-close-line" />
        </button>
      </div>
      <div
        class="modal__content"
        :style="{width: `${props.width}px`}"
      >
        <slot :params="params" />
      </div>
      <div
        v-if="!props.nonConfirm || !props.nonCancel"
        class="modal__action"
      >
        <vxe-button
          v-if="!props.nonConfirm"
          status="primary"
          :loading="props.loading"
          :disabled="props.disabledConfirm"
          @click="modalActions.confirm(close)"
        >
          {{ props.confirmText }}
        </vxe-button>
        <slot name="addButtons" />
        <vxe-button
          v-if="!props.nonCancel"
          @click="modalActions.cancel(close)"
        >
          {{ props.cancelText }}
        </vxe-button>
      </div>
    </vue-final-modal>
  </teleport>
</template>

<script setup>
import { VueFinalModal } from 'vue-final-modal'

const props = defineProps({
  teleport: {
    type: String,
    default: 'body'
  },
  title: {
    type: String,
    default: null
  },
  width: {
    type: Number,
    default: 320
  },
  nonIconClose: {
    type: Boolean,
    default: false
  },
  confirmText: {
    type: String,
    default: '확인'
  },
  nonConfirm: {
    type: Boolean,
    default: false
  },
  cancelText: {
    type: String,
    default: '취소'
  },
  nonCancel: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  disabledConfirm: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['confirm', 'cancel'])

const modalActions = {
  confirm: (close) => {
    emit('confirm', close)
  },
  cancel: (close) => {
    close()
    emit('cancel')
  }
}
</script>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 96%;
  padding: 0;
  margin: 1rem;
  background: #fff;
  border-radius: 0;
}

.modal__title {
  position: relative;
  width: 100%;
  padding: 12px 20px;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid $gray-light1;
}

.modal__close {
  position: absolute;
  top: 7px;
  right: 10px;

  i {
    font-size: 24px;
  }
}

.modal__content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;

  .no-scroll & {
    overflow-y: visible;
  }
}

.modal__action {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 24px 10px;
  border-top: 1px solid $gray-light1;
}
</style>
