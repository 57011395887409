import { PAGE, PAGE_TITLE, PAGE_TAB } from '@@routes'

export default {
  path: '/product',
  name: PAGE.PRODUCT,
  redirect: '/product/manage',
  component: () => import(/* webpackChunkName: "productManage" */ '@page/Product/Index.vue'),
  meta: {
    title: PAGE_TITLE.PRODUCT,
    serviceName: PAGE.PRODUCT,
    useIcon: 'ri-product-hunt-line',
    roleKeys: { menu: 'business', subMenu: 'businessOperator' }
  },
  children: [
    {
      path: 'manage',
      name: PAGE.PRODUCT_MANAGE,
      redirect: '/product/manage/list',
      component: () => import(/* webpackChunkName: "productManage" */ '@page/Product/Manage/Index.vue'),
      meta: {
        title: PAGE_TITLE.PRODUCT_MANAGE,
        menuName: PAGE.PRODUCT_MANAGE
      },
      children: [
        {
          path: 'list',
          name: PAGE.PRODUCT_MANAGE_LIST,
          component: () => import(/* webpackChunkName: "productManage" */ '@page/Product/Manage/List.vue'),
          meta: {
            title: PAGE_TITLE.PRODUCT_MANAGE_LIST,
            menuName: PAGE.PRODUCT_MANAGE_LIST
          }
        },
        {
          path: 'form',
          name: PAGE.PRODUCT_MANAGE_FORM,
          redirect: '/product/manage/form/info',
          component: () => import(/* webpackChunkName: "productManage" */ '@page/Product/Manage/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.PRODUCT_MANAGE_FORM,
            tabName: PAGE.PRODUCT_MANAGE_FORM
          },
          children: [
            {
              path: 'info/:id?',
              name: PAGE.PRODUCT_MANAGE_FORM_INFO,
              component: () => import(/* webpackChunkName: "productManage" */ '@page/Product/Manage/FormInfo.vue'),
              meta: {
                title: PAGE_TAB.PRODUCT_MANAGE_FORM_INFO
              }
            }
            // {
            //   path: 'content/:id?',
            //   name: PAGE.PRODUCT_MANAGE_FORM_CONTENT,
            //   component: () => import(/* webpackChunkName: "productManage" */ '@page/ProductManage/FormContent.vue'),
            //   meta: {
            //     title: PAGE_TAB.PRODUCT_MANAGE_FORM_CONTENT
            //   }
            // }
          ]
        }
      ]
    },
    {
      path: 'order',
      name: PAGE.PRODUCT_ORDER,
      redirect: '/product/order/list',
      component: () => import(/* webpackChunkName: "productManage" */ '@page/Product/Order/Index.vue'),
      meta: {
        title: PAGE_TITLE.PRODUCT_ORDER,
        menuName: PAGE.PRODUCT_ORDER
      },
      children: [
        {
          path: 'list',
          name: PAGE.PRODUCT_ORDER_LIST,
          component: () => import(/* webpackChunkName: "productManage" */ '@page/Product/Order/List.vue'),
          meta: {
            title: PAGE_TITLE.PRODUCT_ORDER_LIST,
            menuName: PAGE.PRODUCT_ORDER_LIST
          }
        },
        {
          path: 'manage/:id?',
          name: PAGE.PRODUCT_ORDER_MANAGE,
          component: () => import(/* webpackChunkName: "productManage" */ '@page/Product/Order/Manage.vue'),
          meta: {
            pageTitle: PAGE_TITLE.PRODUCT_ORDER_MANAGE,
            tabName: PAGE.PRODUCT_ORDER_MANAGE
          }
        }
      ]
    },
    {
      path: 'creator',
      name: PAGE.PRODUCT_CREATOR,
      component: () => import(/* webpackChunkName: "productManage" */ '@page/Product/Creator/Index.vue'),
      meta: {
        title: PAGE_TITLE.PRODUCT_CREATOR,
        menuName: PAGE.PRODUCT_CREATOR
      }
    }
  ]
}
