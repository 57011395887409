export const MESSAGES = {
  COMMON: {
    BUTTON: {
      OK: '확인',
      CANCEL: '취소',
      MODIFY: '수정',
      DELETE: '삭제',
      SEND: '발송',
      RESEND: '재발송',
      END: '마감',
      SAVE: '저장'
    },
    NO_ROLE: {
      title: '권한이 없습니다.',
      description: '관리자에게 문의 바랍니다.'
    },
    COMPARE_DATE: {
      description: '종료일은 시작일 이후로 입력 가능합니다.'
    },
    BLACKLIST: {
      description: '블랙리스트 상태를 수정하시겠습니까?'
    },
    MEMO: {
      IS_CONTENT: {
        description: '등록을 취소하고, 리스트로 이동하시겠습니까?'
      }
    }
  },
  SERVICE: {
    LOGIN_MANAGE: {
      DELETE: {
        description: '해당 서비스를 삭제하시겠습니까?'
      },
      MODIFY: {
        CONFIRM: {
          description: '서비스를 수정하시겠습니까?'
        }
      }
    }
  },
  PASSWORD: {
    EMAIL_RESEND: {
      title: '비밀번호 재설정 메일이 발송되었습니다.',
      description: '이메일을 수신하지 못한 경우, 스팸메일함을 확인해 주세요.'
    },
    CHANGE: {
      title: '비밀번호 변경이 완료되었습니다.',
      description: '새로운 비밀번호로 로그인하세요.'
    },
    USER: {
      MODIFY: {
        description: '비밀번호를 수정하겠습니까?'
      },
      SUCCESS: {
        description: '비밀번호 변경이 완료되었습니다.'
      }
    }
  },
  JOIN: {
    VALIDATE: {
      title: '입력 형식이 올바르지 않습니다.',
      description: '비밀번호는 영문자, 숫자, 특수문자의 조합으로 입력해 주세요.'
    }
  },
  OPERATOR: {
    SEARCH_VALIDATE: {
      description: '콤마( , )를 입력하여 검색할 수 없습니다.'
    },
    INVITE: {
      CONFIRM: {
        description: '운영자로 추가하시겠습니까?'
      },
      VALIDATE: {
        title: '운영자를 초대할 수 없습니다.',
        description: '이름 / 이메일 주소 / 역할을 입력 혹은 선택해 주세요.'
      },
      OVERLAP_INVITE: {
        title: '운영자를 초대할 수 없습니다.',
        description: '이미 초대된 운영자입니다.'
      },
      OVERLAP_JOIN: {
        title: '운영자를 초대할 수 없습니다.',
        description: '이미 가입한 운영자입니다.'
      }
    },
    DETAIL: {
      DELETE: {
        description: '해당 운영자를 삭제하시겠습니까?'
      },
      MODIFY: {
        description: '해당 운영자 정보를 수정하시겠습니까?'
      }
    },
    NOTUSER: {
      CONFIRM: {
        title: '비즈니스 회원을 등록하시겠습니까?'
      }
    }
  },
  ROLE: {
    CREATE: {
      IS_CREATE: {
        description: '역할을 등록하시겠습니까?'
      },
      VALIDATE: {
        NAME: {
          description: '역할명 입력 후 등록해 주세요.'
        },
        STATUS: {
          description: '상태를 선택해주세요'
        }
      }
    },
    DELETE: {
      description: '해당 역할을 삭제하시겠습니까?'
    },
    HAS_OPERATOR: {
      title: '해당 역할을 가진 운영자가 존재하여 삭제할 수 없습니다.',
      description: '운영자 관리에서 운영자 제거 후 다시 시도해 주세요.'
    },
    OVERLAP_NAME: {
      title: '역할을 등록할 수 없습니다.',
      description: '역할명이 이미 존재합니다. 역할명을 변경해 주세요.'
    },
    MODIFY: {
      description: '해당 역할을 수정하시겠습니까?'
    },
    OPERATOR: {
      DELETE: {
        description: '선택한 인원을 제거하시겠습니까?'
      },
      CHANGE_ROLE: {
        description: '이미 소속된 역할이 있는 운영자가 있습니다. 역할을 변경하시겠습니까?'
      }
    }
  },
  BUSINESSES: {
    CANCEL: {
      description: '등록을 취소하고, 리스트로 이동하시겠습니까?'
    },
    CREATE: {
      IS_CREATE: {
        description: '비즈니스 등록 이후 접근 가능합니다.'
      },
      CONFIRM: {
        description: '비즈니스를 등록하시겠습니까?'
      },
      OVERLAP_NAME: {
        title: '비즈니스를 등록할 수 없습니다.',
        description: '비즈니스명이 이미 존재합니다. 비즈니스명을 변경해 주세요.'
      }
    },
    DELETE: {
      description: '해당 비즈니스를 삭제하시겠습니까?'
    },
    MODIFY: {
      CONFIRM: {
        description: '비즈니스를 수정하시겠습니까?'
      },
      OVERLAP_NAME: {
        title: '비즈니스를 수정 할 수 없습니다.',
        description: '비즈니스명이 이미 존재합니다. 비즈니스명을 변경해 주세요.'
      },
      VALIDATE: {
        title: '비즈니스를 수정할 수 없습니다.',
        description: '필수 항목을 형식에 맞게 입력해 주세요.'
      },
      CANCEL: {
        description: '등록을 취소하고, 리스트로 이동하시겠습니까?'
      },
      BANK: {
        title: '계좌번호 수정이 필요합니다.(은행명 확인)'
      }
    },
    MANAGERS: {
      CONFIRM: {
        description: '담당자를 추가하시겠습니까?'
      },
      VALIDATE: {
        title: '담당자를 추가할 수 없습니다.',
        description: '필수 항목을 형식에 맞게 입력해 주세요.'
      },
      DELETE: {
        description: '해당 비즈니스 담당자를 제거하시겠습니까?'
      }
    },
    ACCOUNT: {
      SUCCESS: {
        description: '계좌가 정상적으로 등록되었습니다.'
      }
    },
    OPERATOR: {
      CONFIRM: {
        description: '비즈니스 회원을 등록하시겠습니까?'
      },
      CHANGE: {
        description: '기존 소유자는 관리자 권한으로 변경됩니다. 소유자를 변경하시겠습니까?'
      },
      DELETE: {
        description: '해당 멤버를 삭제하시겠습니까?'
      },
      MODIFY: {
        description: '기본 정보를 수정하시겠습니까?'
      }
    },
    ROLE: {
      CONFIRM: {
        description: '권한을 변경하시겠습니까?'
      },
      CHANGE: {
        description: '기존 소유자는 관리자 권한으로 변경됩니다. 소유자를 변경하시겠습니까?'
      }
    },
    AGENCY: {
      STATUS: {
        description: '에이전시 상태를 변경 하시겠습니까?'
      },
      MODIFY: {
        CONFIRM: {
          description: '에이전시를 수정하시겠습니까?'
        },
        OVERLAP_NAME: {
          title: '에이전시를 수정 할 수 없습니다.',
          description: '에이전시명이 이미 존재합니다. 에이전시명을 변경해 주세요.'
        },
        VALIDATE: {
          title: '에이전시를 수정할 수 없습니다.',
          description: '필수 항목을 형식에 맞게 입력해 주세요.'
        }
      },
      CREATE: {
        IS_CREATE: {
          description: '에이전시 등록 이후 접근 가능합니다.'
        },
        CONFIRM: {
          description: '에이전시를 등록하시겠습니까?'
        },
        OVERLAP_NAME: {
          title: '에이전시를 등록할 수 없습니다.',
          description: '에이전시명이 이미 존재합니다. 에이전시명을 변경해 주세요.'
        }
      },
      DELETE: {
        description: '해당 에이전시를 삭제하시겠습니까?'
      }
    },
    COMPANY: {
      DELETE: {
        description: '출처사를 삭제하시겠습니까?'
      },
      MODIFY: {
        description: '출처사를 수정하시겠습니까?'
      }
    },
    CONTACTPOINT: {
      DELETE: {
        description: '해당 컨택 포인트를 삭제하시겠습니까?'
      }
    },
    FILE: {
      DELETE: {
        description: '해당 파일을 삭제하시겠습니까?'
      }
    }
  },
  CAMPAIGNS: {
    LOAD: {
      CONFIRM: {
        title: '선택한 캠페인을 불러오시겠습니까?',
        description: '선택한 캠페인으로 기존 입력 내용이 모두 변경됩니다.'
      },
      NO_SELECT: '불러올 캠페인을 선택해주세요.'
    },
    DELETE: '해당 캠페인을 삭제하시겠습니까?',
    SAVE: {
      SUCCESS: '캠페인이 성공적으로 저장되었습니다.',
      FAIL: {
        title: '캠페인을 저장할 수 없습니다.',
        description: '필수 항목을 형식에 맞게 입력(선택)해 주세요.'
      },
      STEP_ESSENTIAL: {
        MODIFY: '필수정보를 수정하시겠습니까?',
        SUCCESS: '필수정보가 저장되었습니다.',
        FAIL: '필수정보 저장 실패. 지속 발생 시 플랫폼본부 문의 요망'
      },
      STEP_BASIC: {
        LOAD_SAVE: '불러온 캠페인의 전체 정보(필수정보 제외)가 저장됩니다.',
        MODIFY: '기본정보를 수정하시겠습니까?',
        BASE: {
          SUCCESS: '기본정보가 저장되었습니다.',
          FAIL: '기본정보 저장 실패. 지속 발생 시 플랫폼본부 문의 요망'
        },
        INTRO: {
          SUCCESS: '캠페인소개 정보가 저장되었습니다.',
          FAIL: '캠페인소개 정보 저장 실패. 지속 발생 시 플랫폼본부 문의 요망'
        },
        SCHEDULE: {
          SUCCESS: '일정관리 정보가 저장되었습니다.',
          FAIL: '일정관리 정보 저장 실패. 지속 발생 시 플랫폼본부 문의 요망'
        },
        BOOKING: {
          NO_SELECT: '중복지원불가 캠페인을 선택해주세요.',
          SUCCESS: '모집설정 정보가 저장되었습니다.',
          FAIL: '모집설정 정보 저장 실패. 지속 발생 시 플랫폼본부 문의 요망'
        }
      },
      STEP_MISSION: {
        MODIFY: '미션설정 정보를 수정하시겠습니까?',
        SUCCESS: '미션설정 정보가 저장되었습니다.',
        FAIL: '미션설정 정보 저장 실패. 지속 발생 시 플랫폼본부 문의 요망'
      },
      STEP_ETC: {
        MODIFY: '리워드/계약서 정보를 수정하시겠습니까?',
        REWARD: {
          SUCCESS: '리워드 정보가 저장되었습니다.',
          FAIL: '리워드 정보 저장 실패. 지속 발생 시 플랫폼본부 문의 요망'
        },
        CONTRACT: {
          SUCCESS: '계약서 정보가 저장되었습니다.',
          FAIL: '계약서 정보 저장 실패. 지속 발생 시 플랫폼본부 문의 요망'
        }
      }
    },
    MOVE: {
      description: '입력 중인 내용을 저장하지 않고, 다음 단계로 이동하시겠습니까?'
    },
    CREATE: {
      COPY_LINK: {
        description: '클립보드에 확인링크가 복사되었습니다.'
      },
      CANCEL: {
        description: '등록을 취소하고, 리스트로 이동하시겠습니까?'
      },
      IS_CREATE: {
        description: '캠페인을 등록하시겠습니까?'
      },
      VALIDATE: {
        title: '캠페인을 등록할 수 없습니다.',
        description: '필수 항목을 형식에 맞게 입력(선택)해 주세요.'
      },
      REFLECTION: {
        description: '해당 캠페인을 반영하시겠습니까?'
      }
    },
    BASIC: {
      CATEGORY: {
        description: '카테고리는 최대 2개까지 설정 가능합니다.'
      },
      CUSTOM_SCHEDULE: {
        description: '커스텀 일정을 제거하시겠습니까?'
      },
      SUBSCRIBERS: {
        description: '최대 구독자수는 최소 구독자수 이하로 입력하실 수 없습니다.'
      },
      QUESTIONS: {
        description: '추가 질문은 최대 3개까지 추가할 수 있습니다.'
      }
    },
    CUSTOM_SCHEDULE: {
      MAX: {
        description: '커스텀 일정은 최대 3개 까지 추가 가능합니다.'
      }
    },
    EXCLUSIVE: {
      description: '선택한 캠페인을 중복지원 불가 캠페인으로 설정하시겠습니까?'
    },
    CONDITION: {
      AGE: {
        description: '최대 나이는 최소 나이 이하로 입력하실 수 없습니다.'
      },
      CONFIRM: {
        description: '해당 내용으로 지원 가능 조건을 설정하시겠습니까?'
      },
      VALIDATE: {
        title: '지원 가능 조건을 설정할 수 없습니다.',
        VALIDATE: '조건을 형식에 맞게 입력 후 설정 버튼을 눌러주세요.'
      }
    },
    QUESTION: {
      MIN: {
        description: '선택할 답변 내용이 2개 이상 있어야 추가 가능합니다.'
      },
      MAX: {
        description: '답변 내용 개수는 최대 선택 수 이상으로 입력되어야 합니다.'
      },
      VALIDATE: {
        title: '추가 질문을 추가할 수 없습니다.',
        description: '입력하지 않은 항목을 확인해 주세요.'
      }
    },
    MISSION: {
      HASHTAG: {
        description: '필수 삽입 해시태그는 최대 10개 까지 입력 가능합니다.'
      },
      ACCOUNT_TAG: {
        description: '필수 삽입 계정태그는 최대 10개 까지 입력 가능합니다.'
      }
    },
    REWARD: {
      description: '최대 팔로워 수는 최소 팔로워 수 이하로 입력하실 수 없습니다.'
    }
  },
  CAMPAIGNS_MANAGE: {
    CONFIRM_POINT: {
      CONFIRM: {
        description: '포인트를 변경하시겠습니까?'
      },
      VALIDATE: {
        title: '지급 포인트를 변경할 수 없습니다.',
        description: '미션 진행 중이거나 완료된 참여자입니다.'
      }
    },
    DELIVERY: {
      UPLOAD: {
        description: '송장번호를 업로드 하시겠습니까?'
      },
      NUMBER: {
        description: '<strong>송장번호를 수정 하시겠습니까?</strong><br/>송장번호가 등록되면 참여자의 마이페이지의 ‘배송전’버튼이 활성화됩니다. 이후 실제 배송이 시작되면 [배송시작] 알림톡이 발송됩니다.'
      }
    },
    COMPLETE: {
      SUCCESS: {
        description: '해당 캠페인을 종료 캠페인으로 변경하시겠습니까?'
      },
      CANCEL: {
        title: '캠페인 취소',
        description: '캠페인 취소하면 진행중인 캠페인이 종료되고, 종료 캠페인으로 이동합니다. 취소하시겠습니까?'
      },
      FAIL: {
        title: '캠페인을 저장할 수 없습니다.',
        description: '종료 또는 지급 완료되지 않은 포스트(영상)이 있습니다.'
      }
    },
    CATEGORY: {
      MAX: {
        description: '카테고리는 최대 2개까지 설정 가능합니다.'
      }
    },
    DELETE_MANAGER: {
      description: '해당 비즈니스 담당자를 제거하시겠습니까?'
    },
    BOOKINGS: {
      BOOKING_TO_JOINS: {
        description: '선택한 채널을 참여자로 선정하시겠습니까?'
      }
    },
    JOINS: {
      DELETE: {
        CONFIRM: {
          description: '선택한 참여자를 제거하시겠습니까?'
        },
        FAIL: {
          title: '참여자를 제거할 수 없습니다.',
          description: '미션 진행 중이거나 완료된 참여자입니다.'
        }
      },
      ANNOUNCE: {
        description: '현재 캠페인의 참여자를 발표 처리하시겠습니까?'
      },
      ADD: {
        description: '해당 채널을 참여자로 추가하시겠습니까?'
      }
    },
    PROCESS_MANAGE: {
      STATE: {
        description: '관리 상태를 변경하시겠습니까?'
      },
      POST: {
        CONFIRM: {
          description: '포스트를 연결하시겠습니까?'
        },
        VALIDATE: {
          title: '포스트 연결을 수정할 수 없습니다.',
          description: '필수 항목을 형식에 맞게 입력(선택)해 주세요.'
        }
      },
      EMPTY_JOIN_COUNT_POST: {
        title: '캠페인 상태 변경',
        description: '참여자가 없어서 포스트 등록기간으로 변경할 수 없습니다.'
      }
    }
  },
  INFLUENCER: {
    MODIFY: '해당 인플루언서를 수정하시겠습니까?',
    CHANNEL: {
      DELETE: '해당 채널을 삭제하시겠습니까?'
    }
  },
  CHANNEL: {
    CAMPAIGN: {
      NO_SELECT: '참여할 캠페인을 선택해주세요.'
    }
  },
  TAGS: {
    CONFIRM: {
      description: '신규 태그를 추가하시겠습니까?'
    },
    OVERLAP: {
      title: '태그를 추가할 수 없습니다.',
      description: '이미 존재하는 태그입니다.'
    },
    MODIFY: {
      description: '해당 태그를 수정하시겠습니까?'
    }
  },
  BILLINGS: {
    CREATE: {
      CONFIRM: '비정기 정산을 등록하시겠습니까?'
    },
    OVERLAP_NAME: {
      title: '정산 이름을 수정할 수 없습니다.',
      description: '동일한 이름으로 수정할 수 없습니다.'
    },
    PROCESS_STATUS: {
      CONFIRM: '정산을 확인 완료 처리하시겠습니까?',
      FAIL: {
        title: '정산을 확인 완료 처리할 수 없습니다.',
        description: '선택 내역 중 신청 중인 정산이 포함되어 있습니다.'
      }
    },
    DEPOSIT_CLOSING: {
      CONFIRM: {
        title: '정산 마감',
        description: '현재 정산을 마감하겠습니까?'
      }
    },
    IRREGULAR: {
      DELETE: {
        description: '해당 정산을 삭제하시겠습니까?'
      },
      ADD_INFLUENCER: {
        description: '해당 인플루언서를 정산 내역에 추가하시겠습니까?'
      },
      DELETE_INFLUENCER: {
        description: '해당 인플루언서를 정산 내역에서 삭제하시겠습니까?'
      }
    }
  },
  MESSAGE: {
    RESET: {
      description: '작성 중인 내용이 있습니다. 초기화하시겠습니까?'
    },
    SEND: {
      CONFIRM: {
        description: '메시지를 발송하시겠습니까?'
      },
      FAIL: {
        description: '메시지를 발송할 수 없습니다.'
      },
      SUCCESS: {
        title: '메시지를 발송했습니다.'
      },
      CANCEL: {
        description: '해당 메시지 발송을 취소하시겠습니까?'
      }
    },
    RESEND: {
      description: '해당 메시지를 재발송하시겠습니까?'
    },
    TEMPLATE: {
      MODIFY: {
        description: '해당 템플릿을 수정하시겠습니까?'
      },
      DELETE: {
        description: '해당 템플릿을 삭제하시겠습니까?'
      }
    }
  },
  OPERATION: {
    NOTICE: {
      DELETE: {
        description: '해당 공지사항을 삭제하시겠습니까?'
      },
      CREATE: {
        description: '공지사항을 등록하시겠습니까?'
      },
      MODIFY: {
        description: '공지사항을 수정하시겠습니까?'
      }
    },
    BANNER: {
      DISPLAY: {
        description: '해당 배너를 숨김 처리 하시겠습니까?'
      },
      ORDER: {
        description: '변경된 순서를 적용하시겠습니까?'
      },
      MODIFY: {
        description: '배너를 수정하시겠습니까?'
      },
      DELETE: {
        description: '배너를 삭제하시겠습니까?'
      }
    },
    FAQ: {
      ORDER: {
        description: '변경된 순서를 적용하시겠습니까?'
      },
      DETAIL: {
        DELETE: '해당 FAQ를 삭제하시겠습니까?',
        CREATE: {
          CONFIRM: {
            description: 'FAQ를 등록하시겠습니까?'
          },
          FAIL: {
            title: 'FAQ를 등록할 수 없습니다.',
            description: '필수 항목을 형식에 맞게 입력(선택)해 주세요.'
          }
        },
        MODIFY: {
          CONFIRM: {
            description: 'FAQ를 수정하시겠습니까?'
          },
          FAIL: {
            title: 'FAQ를 수정할 수 없습니다.',
            description: '필수 항목을 형식에 맞게 입력(선택)해 주세요.'
          }
        }
      }
    },
    QNA: {
      ANSWER: {
        description: '해당 내용으로 답변 처리하시겠습니까?'
      }
    },
    TERMS: {
      DELETE: {
        description: '해당 약관을 삭제하시겠습니까?'
      },
      CREATE: {
        description: '약관을 등록하시겠습니까?'
      },
      MODIFY: {
        description: '약관을 수정하시겠습니까?'
      }
    },
    CHANNEL_GUIDE: {
      DELETE: {
        description: '해당 가이드를 삭제하시겠습니까?'
      },
      CREATE: {
        description: '가이드를 등록하시겠습니까?'
      },
      MODIFY: {
        description: '가이드를 수정하시겠습니까?'
      }
    }
  },
  HOMEPAGE: {
    IR: {
      DELETE: {
        description: '해당 IR을 삭제하시겠습니까?'
      },
      CREATE: {
        description: 'IR을 등록하시겠습니까?'
      },
      MODIFY: {
        description: 'IR을 수정하시겠습니까?'
      }
    },
    RECOMMEND: {
      DELETE: {
        description: '해당 광고문의을 삭제하시겠습니까?'
      },
      ANSWER: {
        description: '답변 처리하시겠습니까?'
      }
    }
  },
  STYLEMATE: {
    FRONT_MANAGE: {
      DELETE: '삭제하시겠습니까?'
    }
  },
  PRODUCTS: {
    MODIFY: '해당 상품을 수정하시겠습니까?',
    DELETE: '해당 상품을 삭제하시겠습니까?'
  },
  MBCA: {
    CAMPAIGN: {
      EXAMINATION: {
        title: '캠페인 검수 승인',
        description: `해당 캠페인의 검수 승인을 진행 하시겠습니까?
        (승인 후에는 캠페인 광고 종료 혹은 소재의 개별 광고 중단만 진행 가능합니다.)
        `
      }
    }
  }
}
