export default [
  {
    path: 'etc/statistics',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "statistics" */ '@page/Etc/Dashboard.vue'),
    meta: {
      title: '모니터링 통계',
      serviceName: 'Dashboard',
      roleKeys: { menu: 'stats', subMenu: 'statsReport' },
      useIcon: 'ri-line-chart-line'
    }
  },
  {
    path: 'etc/kozel',
    name: 'KozelDashboard',
    component: () => import(/* webpackChunkName: "kozel" */ '@page/Etc/Kozel.vue'),
    meta: {
      title: 'Kozel 대시보드',
      serviceName: 'KozelDashboard',
      roleKeys: { menu: 'kozel', subMenu: 'kozelDashboard' },
      useIcon: 'ri-goblet-fill'
    }
  },
  {
    path: 'etc/kozelAccounts',
    name: 'KozelAccounts',
    component: () => import(/* webpackChunkName: "kozel" */ '@page/Etc/KozelAccounts.vue'),
    meta: {
      title: 'Kozel 크롤계정목록',
      serviceName: 'KozelAccounts',
      roleKeys: { menu: 'kozel', subMenu: 'kozelAccountManage' },
      useIcon: 'ri-account-pin-box-line'
    }
  }
]
