import axios from '@/plugins/axios'
import {
  SET_MEMO_LIST,
  SET_INFLUENCER_LIST,
  SET_INFLUENCER_DETAIL,
  SET_INFLUENCER_SEARCH,
  SET_INFLUENCE_POINT,
  SET_INFLUENCE_POINT_BEFORE,
  SET_INFLUENCE_WITHDRAWALS,
  SET_INFLUENCE_WITHDRAWAL_REASONS,
  SET_INFLUENCE_COST_LIST,
  SET_INFLUENCE_POST_REVIEW_LIST,
  SET_INFLUENCE_DOWNLOAD_HISTORY
} from '@/store/mutation_types'
import cryptoString from '@/utils/cryptoString'

const state = {
  memoModel: [],
  influencerModel: {},
  influencerDetail: {},
  influencerSearch: {},
  secedeUserModel: {},
  secedeReasons: [],
  pointModel: {},
  previousPointModel: {},
  influencerCostModel: {},
  postReviewModel: {},
  influenceDownloadHistory: {}
}

const getters = {
  memoListData: state => state.memoModel,
  isManaged: state => state.influencerDetail.isManaged,
  influencerData: state => state.influencerModel,
  secedeUserListData: state => state.secedeUserModel,
  /* 등록 및 상세 */
  influencerDetailForm: state => {
    const data = state.influencerDetail
    const channel = data.channel.map(item => {
      return {
        id: item.id,
        link: item.channelLink,
        type: item.type,
        category: item.managedCategory ? item.managedCategory : [],
        info: {
          userId: item.channelUserId,
          userName: item.channelUserName
        }
      }
    })

    if (data.bankAccount?.ownerNumber) data.bankAccount.ownerNumber = cryptoString.decrypt(data.bankAccount.ownerNumber)

    return {
      channel,
      channelChecked: channel.length > 0 ? true : null,
      name: data.managedUserName,
      email: data.managedUserEmail,
      tel: data.managedUserTel,
      bankAccount: data.bankAccount?.bankName ? data.bankAccount : null,
      additionalInformation: {
        addressLocale: data.addressLocale,
        addressZipcode: data.addressZipcode,
        address1: data.address1,
        address2: data.address2,
        gender: data.gender,
        birthDate: data.birthDate,
        marital: data.marital,
        children: data.children ? data.children : [],
        hasPet: data.hasPet,
        hasCar: Boolean(data.hasCar)
      },
      influenceCreator: {},
      affiliatedCompany: data.affiliatedCompany ? data.affiliatedCompany : {},
      influencePersonalContactPoint: data.influencePersonalContactPoint ? data.influencePersonalContactPoint : {}
    }
  },
  influencerDetail: state => {
    const data = state.influencerDetail
    if (data.bankAccount?.ownerNumber) {
      data.bankAccount.ownerNumber = cryptoString.decrypt(data.bankAccount.ownerNumber)
    }
    return data
  },
  influencerSearch: state => state.influencerSearch,
  influencerChannel: state => state.influencerDetail.channel,
  /* point */
  pointData: state => state.pointModel,
  previousPointData: state => state.previousPointModel,
  /* 단가 */
  influencerCostData: state => state.influencerCostModel,
  postReview: state => state.postReviewModel,
  /* 다운로드 히스토리 */
  influenceDownloadHistoryList: state => state.influenceDownloadHistory
}

const actions = {
  async getMemoList ({ commit }, id) {
    const config = {
      method: 'GET',
      url: `/_p/influences/${id}/memos`,
      params: {
        perPage: 9999,
        page: 1
      }
    }
    await axios(config).then((response) => {
      commit(SET_MEMO_LIST, response)
    })
  },
  async saveMemoItem ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/influences/${id}/memos`,
      data: data
    }

    await axios(config)
  },
  async deleteMemoItem ({ commit }, { id, memoId }) {
    const config = {
      method: 'DELETE',
      url: `/_p/influences/${id}/memos/${memoId}`
    }

    await axios(config)
  },

  async setBlackList ({ commit }, { id, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/influences/${id}/black`,
      data: data
    }
    await axios(config)
  },
  async getInfluencesList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/influences',
      params: {
        perPage: 10,
        page: 1,
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCER_LIST, response)
    })
  },
  /* 포인트 */
  async getInfluencePointList ({ commit }, { id, data }) {
    const config = {
      method: 'GET',
      url: `/_p/influences/${id}/points`,
      params: { ...data }
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCE_POINT, response)
    })
  },
  async saveInfluencePointItem ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/influences/${id}/points`,
      data: { ...data }
    }
    const result = await axios(config)
    return result
  },
  async getInfluencePreviousPointList ({ commit }, { id, data }) {
    const config = {
      method: 'GET',
      url: `/_p/influences/${id}/previous-points`,
      params: { ...data }
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCE_POINT_BEFORE, response)
    })
  },
  /* 등록 및 수정 */
  async getInfluenceItem ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/influences/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCER_DETAIL, response)
    })
  },
  async getInfluenceSearch ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/influences/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCER_SEARCH, response)
    })
  },
  async createInfluenceItem ({ commit }, { data }) {
    if (data.bankAccount?.ownerNumber) {
      data.bankAccount.ownerNumber = cryptoString.encrypt(data.bankAccount.ownerNumber)
      delete data.bankAccount.personal
      delete data.bankAccount.corp
    }
    const config = {
      method: 'POST',
      url: '/_p/influences',
      data: data
    }
    const result = await axios(config)
    return result
  },
  async saveInfluenceItem ({ commit }, { id, data }) {
    if (data.bankAccount?.ownerNumber) {
      data.bankAccount.ownerNumber = cryptoString.encrypt(data.bankAccount.ownerNumber)
      delete data.bankAccount.personal
      delete data.bankAccount.corp
    }
    const config = {
      method: 'PUT',
      url: `/_p/influences/${id}`,
      data: {
        addChannel: [],
        deleteChannel: [],
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  /* 탈퇴 */
  async getSecedeUserList ({ state, commit, dispatch }, data) {
    if (!state.secedeReasons.length) await dispatch('getSecedeReasons')
    const config = {
      method: 'GET',
      url: '/_p/user-withdrawals',
      params: {
        ...data
      }
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCE_WITHDRAWALS, response)
    })
  },
  async getSecedeReasons ({ commit }) {
    const config = {
      method: 'GET',
      url: '/commons/withdrawal-reasons'
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCE_WITHDRAWAL_REASONS, response)
    })
  },
  /* 단가 */
  async getCostList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/influences/cost',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCE_COST_LIST, response)
    })
  },
  async getInfluenceCostList ({ commit }, { id, data }) {
    const config = {
      method: 'GET',
      url: `/_p/influences/${id}/costs`,
      params: {
        ...data
      }
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCE_COST_LIST, response)
    })
  },
  async createInfluenceCostItem ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/influences/${id}/costs`,
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async saveInfluenceCostItem ({ commit }, { id, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/influences/${id}/costs/${data.id}`,
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async deleteInfluenceCostItem ({ commit }, { id, data }) {
    const config = {
      method: 'DELETE',
      url: `/_p/influences/${id}/costs/${data.influenceCost}`,
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  /* 기타 */
  async getInfluenceToken ({ commit }, influenceId) {
    const config = {
      method: 'POST',
      url: `/_p/influences/${influenceId}/token`
    }
    axios(config).then((res) => {
      const domain = location.href.includes('localhost') ? 'https://localhost:8080' : import.meta.env.VITE_APP_FRONT_URL
      window.open(`${domain}/influencesLogin/${res.token}?expires_at=${res.expires_at}`, '_blank')
    })
  },
  async uploadNaver ({ commit }, { file }) {
    const config = {
      method: 'POST',
      url: '/_p/influences/upload-naver/download',
      data: file,
      responseType: 'blob'
    }
    const res = await axios(config)
    return res
  },
  /* 다운로드 등 */
  async getInfluenceDownloadHistory ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/influences/download-history',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCE_DOWNLOAD_HISTORY, response)
    })
  },
  async influenceDownload ({ commit }, { params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: '/_p/influences/download',
      params: {
        perPage: 9999,
        ...params
      }
    }
    axios.defaults.timeout = 60 * 1000 * 10
    const res = await axios(config)
    axios.defaults.timeout = 60 * 1000
    return res
  },

  // 인플루언서 포스트 평가 내역
  async getPostReview ({ commit }, { id, params }) {
    const config = {
      method: 'GET',
      url: `/_p/influences/${id}/post-review`,
      params: {
        perPage: 10,
        page: 1,
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_INFLUENCE_POST_REVIEW_LIST, response)
    })
  }
}

const mutations = {
  [SET_MEMO_LIST]: (state, payload) => {
    state.memoModel = payload
  },
  [SET_INFLUENCER_LIST]: (state, payload) => {
    payload.data = payload.data.map(item => {
      delete item.children
      return item
    })
    state.influencerModel = payload
  },
  [SET_INFLUENCER_DETAIL]: (state, payload) => {
    state.influencerDetail = payload
  },
  [SET_INFLUENCER_SEARCH]: (state, payload) => {
    state.influencerSearch = payload
  },
  [SET_INFLUENCE_POINT]: (state, payload) => {
    state.pointModel = payload
  },
  [SET_INFLUENCE_POINT_BEFORE]: (state, payload) => {
    state.previousPointModel = payload
  },
  [SET_INFLUENCE_WITHDRAWALS]: (state, payload) => {
    state.secedeUserModel.data = payload.data.map(item => {
      if (item.reason.length) {
        item.reason[0] = state.secedeReasons.find(reason => String(reason.id) === item.reason[0])?.text
      }
      return item
    })
    state.secedeUserModel.meta = payload.meta
  },
  [SET_INFLUENCE_WITHDRAWAL_REASONS]: (state, payload) => {
    state.secedeReasons = payload
  },
  [SET_INFLUENCE_COST_LIST]: (state, payload) => {
    state.influencerCostModel = payload
  },
  [SET_INFLUENCE_POST_REVIEW_LIST]: (state, payload) => {
    state.postReviewModel = payload
  },
  [SET_INFLUENCE_DOWNLOAD_HISTORY]: (state, payload) => {
    state.influenceDownloadHistory = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
