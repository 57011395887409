import { messageModal } from '@/mixins/confirmModal'

export default async function errorHandler (error) {
  const isBlob = error.request.responseType
  const errorCode = error.response.status || error.status
  let errorData = null
  if (isBlob) {
    const blobError = JSON.parse(await error.response.data.text())
    errorData = blobError.error || blobError.errors[0]
  } else {
    errorData = error.response.data.error || error.response.data?.errors[0]
  }
  const errorMessage = errorData.message

  switch (errorCode) {
    case 401:
      return messageModal({ status: 'warning', content: errorMessage })
    default:
      messageModal({ status: 'warning', content: errorMessage })
      break
  }
}
