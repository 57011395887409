import axios from '@/plugins/axios'
import { SET_ADQNA_LIST, SET_ADQNA_DETAIL } from '@/store/mutation_types'

const state = {
  adQnaModel: {},
  adQnaDetail: {}
}

const getters = {
  adQnaListData: state => state.adQnaModel,
  adQnaDetail: state => state.adQnaDetail
}

const actions = {
  async getAdQnaList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/ad-qnas',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_ADQNA_LIST, response)
    })
  },
  async getAdQnaItemDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/ad-qnas/${id}`
    }
    await axios(config).then(response => {
      commit(SET_ADQNA_DETAIL, response)
    })
  },
  async saveAdQnaItem ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/ad-qnas/${id}/answer`,
      data: {
        ...data
      }
    }
    const result = await axios(setting)
    return result
  },
  async deleteAdQnaItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/ad-qnas/${id}`
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_ADQNA_LIST]: (state, payload) => {
    state.adQnaModel = payload
  },
  [SET_ADQNA_DETAIL]: (state, payload) => {
    state.adQnaDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
