import { PAGE, PAGE_TITLE } from '@@routes'

export default {
  path: '/service',
  name: PAGE.SERVICE,
  redirect: '/service/loginManage',
  component: () => import(/* webpackChunkName: "service" */'@page/Service/Index.vue'),
  meta: {
    title: PAGE_TITLE.SERVICE,
    serviceName: PAGE.SERVICE,
    useIcon: 'ri-chat-heart-line'
  },
  children: [
    {
      path: 'loginManage',
      name: PAGE.SERVICE_LOGIN_MANAGE,
      redirect: '/service/loginManage/list',
      component: () => import(/* webpackChunkName: "service" */ '@page/Service/LoginManage/Index.vue'),
      meta: {
        title: PAGE_TITLE.SERVICE_LOGIN_MANAGE,
        menuName: PAGE.SERVICE_LOGIN_MANAGE,
        roleKeys: { menu: 'serviceManage', subMenu: 'serviceManager' }
      },
      children: [
        {
          path: 'list/:page?',
          name: PAGE.SERVICE_LOGIN_MANAGE_LIST,
          component: () => import(/* webpackChunkName: "service" */ '@page/Service/LoginManage/List.vue')
        },
        {
          path: 'form/:id?',
          name: PAGE.SERVICE_LOGIN_MANAGE_FORM,
          component: () => import(/* webpackChunkName: "service" */ '@page/Service/LoginManage/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.SERVICE_LOGIN_MANAGE_FORM
          }
        }
      ]
    },
    {
      path: 'maintenance',
      name: PAGE.SERVICE_MAINTENANCE_MANAGE,
      component: () => import(/* webpackChunkName: "service" */ '@page/Service/Maintenance/Index.vue'),
      meta: {
        title: PAGE_TITLE.SERVICE_MAINTENANCE_MANAGE,
        menuName: PAGE.SERVICE_MAINTENANCE_MANAGE,
        roleKeys: { menu: 'serviceManage', subMenu: 'serviceManager' }
      }
    }
  ]
}
