import { SET_CHANNEL_INFO, SET_CHANNEL_VISIBLE } from '@/store/mutation_types'
const state = {
  channelInfo: null,
  channelInfoVisible: false
}

const getters = {
  channelInfo: state => state.channelInfo,
  channelInfoVisible: state => state.channelInfoVisible
}

const actions = {
  addChanelInfo: async ({ commit, dispatch, rootGetters }, data) => {
    const config = {
      id: data
    }

    await dispatch('influences/getInfluenceSearch', config, { root: true })
    const influence = rootGetters['influences/influencerSearch']

    commit(SET_CHANNEL_INFO, influence)
  },
  channelInfoToggle: ({ commit }, data) => {
    commit(SET_CHANNEL_VISIBLE, data)
  }
}

const mutations = {
  [SET_CHANNEL_INFO]: (state, payload) => {
    state.channelInfoVisible = true
    state.channelInfo = payload
  },
  [SET_CHANNEL_VISIBLE]: (state, payload) => {
    state.channelInfoVisible = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
