import axios from '@/plugins/axios'
import { SET_KOZELDASHBOARD_SUMMARY_LIST, SET_KOZELDASHBOARD_REPORT_LIST, SET_KOZELDASHBOARD_CRAWLING_STAT_LIST, GET_KOZELACCOUNTS_LIST } from '@/store/mutation_types'

const state = {
  kozelDashboardSummary: [],
  kozelDashboardReport: [],
  kozelDashboardCrawlingStat: [],
  kozelAccountsList: {
    data: []
  }
}

const getters = {
  kozelSummary: state => state.kozelDashboardSummary,
  kozelReport: state => state.kozelDashboardReport,
  kozelCrawlingStat: state => state.kozelDashboardCrawlingStat,
  kozelAccountsList: state => state.kozelAccountsList
}

const actions = {
  async getSummaryList ({ commit }) {
    const config = {
      method: 'GET',
      url: '/_p/kozel-dashboard/summary'
    }
    await axios(config).then((response) => {
      commit(SET_KOZELDASHBOARD_SUMMARY_LIST, response)
    })
  },
  async getReportList ({ commit }) {
    const config = {
      method: 'GET',
      url: '/_p/kozel-dashboard/report'
    }
    await axios(config).then((response) => {
      commit(SET_KOZELDASHBOARD_REPORT_LIST, response)
    })
  },
  async getCrawlingStatList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/kozel-dashboard/crawling-stat',
      params: {
        startDate: '',
        endDate: '',
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_KOZELDASHBOARD_CRAWLING_STAT_LIST, response)
    })
  },
  async getKozelAccoutns ({ commit }) {
    const config = {
      method: 'GET',
      url: '/_p/kozel-account-manages/list'
    }

    await axios(config).then((response) => {
      commit(GET_KOZELACCOUNTS_LIST, response)
    })
  },
  async kozelAccoutnClear ({ commit }, data) {
    const config = {
      method: 'PUT',
      data: data,
      url: '/_p/kozel-account-manages/clear'
    }

    await axios(config)
  }
}

const mutations = {
  [SET_KOZELDASHBOARD_SUMMARY_LIST]: (state, payload) => {
    state.kozelDashboardSummary = payload
  },
  [SET_KOZELDASHBOARD_REPORT_LIST]: (state, payload) => {
    state.kozelDashboardReport = payload
  },
  [SET_KOZELDASHBOARD_CRAWLING_STAT_LIST]: (state, payload) => {
    state.kozelDashboardCrawlingStat = payload
  },
  [GET_KOZELACCOUNTS_LIST]: (state, payload) => {
    state.kozelAccountsList.data = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
