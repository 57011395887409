import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import useTable from '@/plugins/vxeTable'
import customPlugin from '@/plugins/customPlugin'
import { numberFormat, numberTel } from '@/utils/number'
import VueTippy from 'vue-tippy'
import '@/assets/scss/tippy.css'
import '@/assets/scss/main.scss'
import 'remixicon/fonts/remixicon.css'
import { createPinia } from 'pinia'

const app = createApp(App)
app.config.productionTip = false
app.config.devtools = true

app.use(customPlugin)
app.use(useTable)
app.config.globalProperties.$numberFilters = {
  numberFormat,
  numberTel
}

app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'top',
      allowHTML: true
    }
  }
)

app.use(createPinia())
app.use(store).use(router).mount('#app')
