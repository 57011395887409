import { computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { CODE } from '@/constants/codes'
import { MESSAGES } from '@/constants/messages'
import _transform from 'lodash/transform'
import _isObject from 'lodash/isObject'
import _cloneDeep from 'lodash/cloneDeep'

export function useGlobalConfig () {
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const currentRoute = router.currentRoute.value
  const currentRole = computed({
    get: () => store.getters['auth/currentRole'] || {},
    set: value => value
  })
  const reactionQuery = computed(() => router.currentRoute.value.query)
  const reactionParams = computed(() => router.currentRoute.value.params)
  const reactionRouterName = computed(() => router.currentRoute.value.name)
  const isCreate = computed(() => !router.currentRoute.value.params.id)
  const channelType = computed(() => store.getters['campaign/channelType'])
  const goList = (path, filter) => {
    const url = store.getters.getListUrl || path
    if (path && !store.getters.getListUrl?.includes(path)) {
      router.push({ path, query: filter }).then(() => {})
    } else {
      router.push(url).then(() => {})
    }
  }

  const listQuery = async (tableRef, { page, sorts, filters, form, code }, routeChanged = true) => {
    const checkKey = ['bookingCount', 'joinCount', 'completionCount', 'joinCancelCount', 'blackCount', 'point']
    let order = ''
    if (routeChanged) {
      const query = { }
      const queryCheck = (key, value, check) => {
        if (check) {
          query[key] = value
        } else {
          delete query[key]
        }
      }

      queryCheck('page', page.currentPage, page.currentPage > 1)
      queryCheck('pageSize', page.pageSize, page.pageSize !== 10)
      queryCheck('sorts', JSON.stringify(sorts), sorts.length > 0)
      queryCheck('filters', JSON.stringify(filters), filters.length > 0)
      queryCheck('form', JSON.stringify(form), Object.values(form).find(item => ![undefined, null].includes(item)))

      if (code === '_init' && Object.values(route.query).length > 0) {
        Object.assign(query, route.query)
      }

      await router.push({ query: _cloneDeep(query) }).then(() => {})

      if (query.sorts) { Object.assign(tableRef.value.reactData.sortData, JSON.parse(query.sorts)) }
      if (query.filters) { Object.assign(tableRef.value.reactData.filterData, JSON.parse(query.filters)) }
      if (query.form) { Object.assign(tableRef.value.reactData.formData, JSON.parse(query.form)) }
      if (query.page) {
        page.currentPage = parseInt(query.page)
        tableRef.value.reactData.tablePage.currentPage = parseInt(query.page)
      }
      if (query.pageSize) {
        page.pageSize = parseInt(query.pageSize)
        tableRef.value.reactData.tablePage.pageSize = parseInt(query.pageSize)
      }
    }

    const property = sorts.map(item => item.property).join()
    const checkResult = checkKey.some(word => {
      return property.includes(word)
    })
    if (checkResult) {
      order = sorts[0].property.replace('influence.', '')
    } else {
      order = sorts[0]?.property
    }

    return {
      perPage: page.pageSize,
      page: page.currentPage,
      order: order,
      sort: sorts[0]?.order === 'asc' ? 'ascend' : 'descend',
      filters: {}
    }
  }

  const filterDataPickBy = (obj) => {
    const checkKey = ['impression', 'age', 'engagementRate', 'searchDate']
    const omitFromObject = (obj) => {
      return _transform(obj, function (result, val, key) {
        if ((!val && typeof val !== 'boolean' && val !== 0) || val.length === 0) return
        if (_isObject(val)) {
          if (Object.values(val).length > 0) {
            if (checkKey.includes(key)) {
              const keyCount = Object.keys(val).length
              const valueCount = Object.values(val).filter(item => !((!item && typeof item !== 'boolean' && item !== 0) || val.length === 0)).length
              if (keyCount === valueCount) {
                result[key] = omitFromObject(val)
              }
            } else {
              result[key] = omitFromObject(val)
            }
          }
        } else {
          result[key] = val
        }
      })
    }
    return omitFromObject(_cloneDeep(obj))
  }

  const routerLink = ({ url, blank }) => {
    const isFunctionUrl = url instanceof Function ? url() : url
    if (blank) {
      window.open(isFunctionUrl, '_blank')
    } else {
      router.push(isFunctionUrl).catch(() => {})
    }
  }

  const getInfluenceId = (row) => {
    const id = row?.influenceId || row?.influence.id || row?.booking[0].influenceId
    store.dispatch('infomation/addChanelInfo', id)
  }

  watchEffect(() => {
    currentRole.value = store.getters['auth/currentRole']
  })

  return {
    store,
    router,
    routerLink,
    route,
    channelType,
    currentRoute,
    currentRole,
    reactionQuery,
    reactionRouterName,
    reactionParams,
    isCreate,
    CODE,
    goList,
    MESSAGES,
    listQuery,
    filterDataPickBy,
    getInfluenceId
  }
}
