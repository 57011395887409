import CustomButtonGroup from '@/components/layout/CustomButtonGroup.vue'
import CustomInput from '@/components/ui/CustomInput.vue'
import CustomUpload from '@/components/ui/CustomUpload.vue'
import CustomModal from '@/components/ui/CustomModal.vue'
import Multiselect from '@vueform/multiselect'
import TagList from '@/components/ui/TagList.vue'
import TagSelector from '@/components/ui/TagSelector.vue'
import BusinessCategory from '@/components/ui/BusinessCategory.vue'
import TinymceEditor from '@/components/Tinymce.vue'
import ExternalLink from '@/components/ui/ExternalLink.vue'

export default {
  install: (app) => {
    app.component('ExternalLink', ExternalLink)
    app.component('TinymceEditor', TinymceEditor)
    app.component('CustomButtonGroup', CustomButtonGroup)
    app.component('CustomInput', CustomInput)
    app.component('CustomModal', CustomModal)
    app.component('CustomUpload', CustomUpload)
    app.component('Multiselect', Multiselect)
    app.component('TagList', TagList)
    app.component('TagSelector', TagSelector)
    app.component('BusinessCategory', BusinessCategory)

    app.directive('outside-click', {
      beforeMount: (el, binding) => {
        el.outsideClickEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value()
          }
        }
        document.body.addEventListener('click', el.outsideClickEvent, false)
      },
      unmounted: (el) => {
        document.body.removeEventListener('click', el.outsideClickEvent, false)
      }
    })
  }
}
