import axios from '@/plugins/axios'
import {
  SET_BILLING_GROUP,
  SET_BILLINGS,
  SET_ALL_BILLINGS
} from '@/store/mutation_types'

const state = {
  billingGroupsModel: null,
  billingGroupsMeta: null,
  billingsModel: {},
  billingsMeta: null,
  billingAllModel: null,
  pointSummeryDownload: {}
}

const getters = {
  billingGroupData: state => state.billingGroupsModel,
  billingData: state => state.billingsModel,
  billingAllData: state => state.billingAllModel,
  /* before */
  billingGroupList: state => state.billingGroupsModel,
  billingGroupMeta: state => state.billingGroupsMeta,
  billingList: state => state.billingsModel,
  billingMeta: state => state.billingsMeta,
  billingAllList: state => state.billingAllModel,
  billingDownloadPointSummery: state => state.pointSummeryDownload
}

const actions = {
  // 정산 그룹 목록
  async getBillingGroupList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/billing-groups',
      params: {
        filters: {},
        ...params
      }
    }

    await axios(config).then((response) => {
      commit(SET_BILLING_GROUP, response)
    })
  },

  // 정산 그룹
  async getBillingGroupItemDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/billing-groups/${id}`
    }

    const res = await axios(config)
    return res
  },
  async setProcessStatus ({ commit }, { id, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/billing-groups/${id}/process-status`,
      data: data
    }

    await axios(config)
  },
  async setDepositClosingStatus ({ commit }, id) {
    const config = {
      method: 'PATCH',
      url: `/_p/billing-groups/${id}/is-deposit-closing`
    }

    await axios(config)
  },

  // 정산 그룹 내 정산 목록
  async getBillingList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: `/_p/billing-groups/${params.id}/billings`,
      params: {
        ...params
      }
    }

    await axios(config).then((response) => {
      commit(SET_BILLINGS, response)
    })
  },
  async getBillingAllList ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/billing-groups/${id}/billings`,
      params: {
        perPage: 9999,
        page: 1
      }
    }

    await axios(config).then((response) => {
      commit(SET_ALL_BILLINGS, response)
    })
  },
  async downloadBillingList ({ commit }, { id, params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: `/_p/billing-groups/${id}/billing-download`,
      params: {
        perPage: 9999,
        ...params
      }
    }

    const res = await axios(config)
    return res
  },

  // 정산 확인/입금 상태 변경(다중)
  async setBillingItemsConfirmStatus ({ commit }, { billingGroup, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/billing-groups/${billingGroup}/billings/billing-status`,
      data: data
    }

    await axios(config)
  },
  async setBillingItemsDepositStatus ({ commit }, { billingGroup, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/billing-groups/${billingGroup}/billings/deposit-status`,
      data: data
    }

    await axios(config)
  },

  // 정산 확인/입금 상태 변경(only)
  async setBillingItemConfirmStatus ({ commit }, { billingGroup, billing, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/billing-groups/${billingGroup}/billings/${billing}/status`,
      data: data
    }

    await axios(config)
  },
  async setBillingItemDepositStatus ({ commit }, { billingGroup, billing, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/billing-groups/${billingGroup}/billings/${billing}/deposit-status`,
      data: data
    }

    await axios(config)
  },

  // 다운로드
  async setBillingPointDownload ({ commit }, { params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: '/_p/billing-groups/point-summary/download',
      params
    }

    return await axios(config)
  },

  // 비정기
  async createBillingGroupItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/billing-groups',
      params: {
        ...data
      }
    }

    await axios(config)
  },
  async saveBillingGroupItem ({ commit }, { id, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/billing-groups/${id}`,
      data: data
    }

    await axios(config)
  },
  async deleteBillingGroupItem ({ commit }, id) {
    const config = {
      method: 'DELETE',
      url: `/_p/billing-groups/${id}`
    }

    await axios(config)
  },
  async addInfluence ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/billing-groups/${id}/billings`,
      data: data
    }

    await axios(config)
  },
  async deleteInfluence ({ commit }, { id, data }) {
    const config = {
      method: 'DELETE',
      url: `/_p/billing-groups/${id}/billings`,
      data: data
    }

    await axios(config)
  },
  async requestPoint ({ commit }, { billingGroup, billing, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/billing-groups/${billingGroup}/billings/${billing}/request-point`,
      data: data
    }

    await axios(config)
  }
}

const mutations = {
  [SET_BILLING_GROUP]: (state, payload) => {
    state.billingGroupsModel = payload
  },
  [SET_BILLINGS]: (state, payload) => {
    state.billingsModel = payload
  },
  [SET_ALL_BILLINGS]: (state, payload) => {
    state.billingAllModel = payload.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
