import { SET_ROLE_LIST, SET_ROLE_DETAIL, SET_ROLE_MANAGERS } from '@/store/mutation_types'
import { dateStandardFormatList } from '@/utils/date'

import axios from '@/plugins/axios'
// import qs from 'qs'

const state = {
  roleModel: [],
  roleDetail: [],
  roleMangagers: []
}

const getters = {
  roleList: state => state.roleModel,
  roleActiveList () {
    return state.roleModel.data.filter(normal => normal.status === 'active')
  },
  rolePaging () {
    return state.roleModel.meta
  },
  roleDetailList () {
    return state.roleDetail
  },
  roleManagers () {
    return state.roleMangagers
  },
  roleManagersPaging () {
    return state.roleMangagers.meta
  }
}

const actions = {
  async getRoleList ({ commit, dispatch }, params) {
    const config = {
      method: 'GET',
      url: '/_p/roles',
      params: {
        perPage: 10,
        page: 1,
        order: '',
        sort: '',
        filters: {},
        ...params
      }
    }
    const response = await axios(config)
    if (params.detail) {
      for (const index in response.data) {
        const item = response.data[index]
        const roleSet = await dispatch('getRolesManagers', { id: item.id })
        item.operator = roleSet.meta.total
      }
    }
    commit(SET_ROLE_LIST, response)
  },
  async createRoles ({ commit }, params) {
    const config = {
      method: 'POST',
      url: '/_p/roles',
      params: {
        ...params
      }
    }
    const result = await axios(config)
    return result
  },
  async getRoles ({ commit }, id) {
    const config = {
      method: 'GET',
      url: `/_p/roles/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_ROLE_DETAIL, response)
    })
  },
  async saveRoleBasic ({ commit }, { id, params }) {
    const setting = {
      method: 'PUT',
      url: `/_p/roles/${id}`,
      params: {
        ...params
      }
    }
    const result = await axios(setting)
    return result
  },
  async deleteRole ({ commit }, id) {
    const setting = {
      method: 'DELETE',
      url: `/_p/roles/${id}`
    }
    const result = await axios(setting)
    return result
  },
  // 역할 운영자
  async getRolesManagers ({ commit }, { id, params }) {
    const config = {
      method: 'GET',
      url: `/_p/roles/${id}/managers`,
      params: {
        ...params
      }
    }
    const result = await axios(config)
    commit(SET_ROLE_MANAGERS, result)
    return result
  },
  async addRolesManagers ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/roles/${id}/managers`,
      params: data
    }
    const result = axios(config)
    return result
  },
  async deleteRolesManagers ({ commit }, { id, data }) {
    const config = {
      method: 'DELETE',
      url: `/_p/roles/${id}/managers`,
      data: data
    }
    const result = axios(config)
    return result
  }
}

const mutations = {
  [SET_ROLE_LIST]: (state, payload) => {
    payload.data = dateStandardFormatList(payload.data)
    state.roleModel = payload
  },
  [SET_ROLE_DETAIL]: (state, payload) => {
    state.roleDetail = payload
  },
  [SET_ROLE_MANAGERS]: (state, payload) => {
    state.roleMangagers = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
