import VXETable from 'vxe-table'
import XEUtils from 'xe-utils'
import enUS from 'vxe-table/lib/locale/lang/en-US'

import { computed, reactive, nextTick } from 'vue'
import { fetchFromObject } from '@/utils/object'
import { vxeKr } from '@/utils/vxe-lang-kr'
import dayjs from 'dayjs'

export default function useTable (app) {
  /* table */
  VXETable.renderer.add('links', {
    renderDefault (renderOpts, params) {
      const { row, column } = params
      const { events } = renderOpts
      const cellValue = fetchFromObject(row, column.property)
      if (cellValue && events) {
        return [
          <span className="linked" onClick={() => events.click({ row })}>
            { cellValue }
          </span>,
          events.clickBlank ? <i className='ri-external-link-line' onClick={() => events.clickBlank({ row })} /> : ''
        ]
      } else {
        return cellValue || '-'
      }
    }
  })
  /* toolbar */
  VXETable.renderer.add('ToolbarTotal', {
    renderToolbarButton (h, renderOpts) {
      const count = renderOpts.$grid.reactData.tablePage?.total || renderOpts.$grid.reactData.tableData.length
      return [<span style="margin-right: 12px;">{ renderOpts.button.buttonRender?.props?.label || '총'} {count} {renderOpts.button.buttonRender?.props?.suffix || '건'}</span>]
    }
  })
  /* form */
  VXETable.renderer.add('customInput', {
    renderItemContent (renderOpts, params) {
      const props = renderOpts.props || {}
      const { $form, data, property } = params
      // !!!!!events 가 없음
      // const { events = {} } = renderOpts
      const inputEv = () => {
        nextTick(() => {
          $form.updateStatus({ property }, data[property])
        })
      }

      return [
        props.prefixJsx ? props.prefixJsx(params) : '',
        <custom-input
          v-model={ data[property] }
          params={params}
          formatType={ props.formatType }
          showCount={ props.showCount }
          unit={ props.unit }
          width={ props.width }
          options={ props }
          onInput={ inputEv }
        />,
        props.suffixJsx ? props.suffixJsx(params) : ''
      ]
    },
    itemResetMethod ({ data, property }) {
      data[property] = null
    }
  })
  VXETable.renderer.add('customTextarea', {
    renderItemContent (renderOpts, params) {
      const props = renderOpts.props || {}
      const { $form, data, property, item } = params

      return [
        props?.addText
          ? <div className="form-item__add-text">
            { props?.addText }
          </div>
          : null,
        <vxe-textarea
          v-model={data[property]}
          showWordCount={props.showWordCount}
          maxlength={props.maxlength?.value || props.maxlength}
          autosize={props.autosize}
          countMethod={props.countMethod}
          disabled={props.disabled}
          placeholder={`${item.title} 입력해주세요.`}
          onInput={ () => {
            $form.updateStatus({ property }, data[property])
          }}
        />
      ]
    },
    itemResetMethod ({ data, property }) {
      data[property] = null
    }
  })
  VXETable.renderer.add('editor', {
    renderItemContent (renderOpts, params) {
      const props = renderOpts.props || {}
      const { data, property } = params
      // const { events = {} } = renderOpts
      return [
        <tinymce-editor
          v-model={ data[property] }
          body-file={data[props.fileKey || 'bodyFile']}
          disabled={ props.disabled }
          isMedia={props.isMedia}
        />
      ]
    }
  })
  VXETable.renderer.add('imageUpload', {
    renderItemContent (renderOpts, params) {
      const props = renderOpts.props || {}
      const events = renderOpts.events || {}
      const { data, property } = params
      return [
        <custom-upload
          v-model={ data[property] }
          showList={ data[property] }
          disabled={ props.disabled }
          fileSize={ props.fileSize }
          imageSize={ props.imageSize }
          accept={ props.accept }
          content={ props.content }
          onChange={() => events.change({ ...params })}
        />
      ]
    }
  })
  VXETable.renderer.add('inputDatePicker', {
    renderItemContent (renderOpts, params) {
      const props = renderOpts.props || {}
      const { data } = params
      const { events = {} } = renderOpts

      const datePickerCheck = async (e, key, properties, params) => {
        if (key === properties.start) {
          const $parent = document.querySelector(`.${params.item.id}`)
          if (e.$event.type === 'update' && !params.data[properties.end]) {
            const $input = $parent.querySelectorAll('.type--date .vxe-input--inner')
            await e.$input.blur()
            $input[1].click()
          }
        } else if (key === properties.end) {
          if (!params.data[properties.start]) {
            const $parent = document.querySelector(`.${params.item.id}`)
            const $input = $parent.querySelectorAll('.type--date .vxe-input--inner')
            $input[0].click()
            $input[1].blur()
            setTimeout(() => {
              e.$input.reactData.visiblePanel = false
            }, 50)
          }
        }
      }
      return [
        props.prefixJsx ? props.prefixJsx(params) : '',
        <div className={ `date-picker ${props.type} ${props.disabled ? 'is-disabled' : ''}` }>
          <vxe-input
            v-model={data[props.properties.start]}
            type={props.type}
            disabled={ props.disabled }
            placeholder="시작일"
            valueFormat={props.type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'}
            transfer
            onClick={ (e) => datePickerCheck(e, props.properties.start, props.properties, params) }
            onChange={ (e) => datePickerCheck(e, props.properties.start, props.properties, params) }
            onInput={ () => events.input ? events.input(params) : {} }
          />
          <span>~</span>
          <vxe-input
            v-model={data[props.properties.end]}
            type={props.type}
            disabled={ props.disabled }
            disabledMethod={(params) => {
              const { date } = params
              return dayjs(date) < dayjs(data[props.properties.start])
            }}
            placeholder="종료일"
            valueFormat={props.type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'}
            transfer
            onClick={ (e) => datePickerCheck(e, props.properties.end, props.properties, params) }
            onChange={ (e) => datePickerCheck(e, props.properties.end, props.properties, params) }
            onInput={ () => events.input ? events.input(params) : {} }
          />
        </div>,
        props.suffixJsx ? props.suffixJsx(params) : ''
      ]
    },
    itemResetMethod ({ data, property }) {
      data.finishedOn = null
      data.endDate = null
      data.startedOn = null
      data.startDate = null
      data.searchDateType = null
      data.startedAt = null
      data.finishedAt = null
    }
  })
  VXETable.renderer.add('minMax', {
    renderItemContent (renderOpts, params) {
      const props = renderOpts.props || {}
      const { data, property } = params

      const target = reactive({ min: data[property]?.min, max: data[property]?.max })
      const checked = computed(() => !target.min && !target.max)

      const events = {
        input: (e, { $form, data, property }, type, value) => {
          if (!data[property]) data[property] = {}
          data[property][type] = parseInt(value) === 0 ? 0 : parseInt(value) || ''
        },
        button: (e, { data, property }) => {
          data[property] = {}
        }
      }

      return (
        <div className="input-min-max">
          <vxe-button
            content={props.buttonName || '전체'}
            status={checked.value ? 'primary' : 'info'}
            onClick={(e) => events.button(e, params)}
          />
          <vxe-input
            v-model={target.min}
            type='number'
            onInput={(e) => events.input(e, params, 'min', target.min)}
          />
          <span>~</span>
          <vxe-input
            v-model={target.max}
            type='number'
            onInput={(e) => events.input(e, params, 'max', target.max)}
          />
        </div>
      )
    },
    itemResetMethod ({ data, property }) {
      data[property] = {}
    }
  })
  VXETable.renderer.add('multiselect', {
    renderItemContent (renderOpts, params) {
      const props = renderOpts.props || {}
      const events = renderOpts.events || {}
      const { data, property } = params
      return [
        <multiselect
          v-model={ data[property] }
          options={ props.options }
          mode={props.mode}
          max={props.max}
          createTag={props.createTag}
          disabled={ props.disabled || !!props.disabled?.value }
          placeholder={ props.placeholder }
          searchable={ props.searchable }
          valueProp={ props.valueProp }
          object={props.object}
          label={ props.label }
          onSelect={(value, option) => events.select ? events.select({ ...params, value, option }) : {}}
        />
      ]
    }
  })
  VXETable.renderer.add('tagSelector', {
    renderDefault (renderOpts, params) {
      const { row, column } = params
      const { events, props } = renderOpts
      const cellValueTarget = row.channel ? row.channel : row
      const cellProperty = cellValueTarget.isManagedChannel ? 'managedCategory' : (row.channel ? 'category' : column.property)
      const cellValue = fetchFromObject(cellValueTarget, cellProperty)

      return (
        !cellValue.length && !props?.isAdd
          ? '-'
          : <tag-selector
          v-model={cellValue}
          isChoice={props?.isChoice}
          maxCount={props?.maxCount}
          isAdd={props?.isAdd}
          type={props?.type}
          onChange={(tags) => events.change ? events.change({ ...params, tags }) : {}}
        />
      )
    },
    renderItemContent (renderOpts, params) {
      const props = renderOpts.props || {}
      // !!!!!$form 삭제
      const { data, property } = params
      const { events = {} } = renderOpts
      return [
        <tag-selector
          v-model={data[property]}
          isChoice={props.isChoice}
          choiceType={props.choiceType}
          maxCount={props.maxCount}
          isAdd={props.isAdd}
          type={props.type}
          addButtonText={props.addButtonText}
          disabled={props.disabled}
          onChange={ () => events.change ? events.change(params) : {} }
        />
      ]
    },
    itemResetMethod ({ data, property }) {
      data[property] = []
    }
  })
  VXETable.renderer.add('businessCategory', {
    renderItemContent (renderOpts, params) {
      const { data, property } = params
      const props = renderOpts.props || {}
      return [
        <business-category
          v-model:selectedId={data[property]}
          isSearch={props.isSearch}
          type={props.type}
          isTwoDepth={props.isTwoDepth}
        />
      ]
    },
    itemResetMethod ({ data, property }) {
      data[property] = null
    }
  })

  VXETable.setup({
    pager: {
      size: null,
      autoHidden: false,
      perfect: true,
      pageSize: 10,
      pagerCount: 12,
      pageSizes: [5, 10, 15, 20, 50]
    },
    table: {
      showHeader: true,
      keepSource: false,
      delayHover: 250,
      showOverflow: null,
      showHeaderOverflow: null,
      showFooterOverflow: null,
      size: null,
      resizable: false,
      autoResize: false,
      stripe: false,
      border: false,
      round: false,
      emptyText: '데이터가 없습니다.',
      radioConfig: {
        trigger: 'default'
      },
      checkboxConfig: {
        strict: false,
        highlight: false,
        range: false,
        trigger: 'default'
      },
      filterConfig: {
        remote: false,
        filterMethod: null
      },
      expandConfig: {
        trigger: 'default',
        showIcon: true
      },
      treeConfig: {
        children: 'children',
        hasChild: 'hasChild',
        indent: 20,
        showIcon: true
      },
      tooltipConfig: {
        theme: 'dark',
        enterable: false
      },
      sortConfig: {
        remote: true,
        trigger: 'cell'
        // orders: ['ascend', 'descend', null]
      },
      editConfig: {
        trigger: 'click',
        mode: 'row',
        showStatus: true
      },
      customConfig: {
        storage: true
      }
    },
    grid: {
      size: null,
      pagerConfig: {
        perfect: false
      }
    },
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get({
      vxe: {
        ...enUS.vxe,
        ...vxeKr
      }
    }, key), args)
  })
  app.use(VXETable)
}
