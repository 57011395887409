import { PAGE, PAGE_TITLE, PAGE_TAB } from '@@routes'

export default {
  path: '/business',
  name: PAGE.BUSINESS,
  redirect: '/business/user',
  component: () => import(/* webpackChunkName: "business" */'@page/Businesses/Index.vue'),
  meta: {
    title: PAGE_TITLE.BUSINESS,
    serviceName: PAGE.BUSINESS,
    useIcon: 'ri-group-line'
  },
  children: [
    {
      path: 'user',
      name: PAGE.BUSINESS_USER,
      redirect: '/business/user/list',
      component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Index.vue'),
      meta: {
        title: PAGE_TITLE.BUSINESS_USER,
        menuName: PAGE.BUSINESS_USER,
        roleKeys: { menu: 'business', subMenu: 'businessOperator' }
      },
      children: [
        {
          path: 'list',
          name: PAGE.BUSINESS_USER_LIST,
          component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/User/List.vue'),
          meta: {
            pageTitle: PAGE_TITLE.BUSINESS_USER_LIST
          }
        },
        {
          path: 'form/:id(\\d+)?',
          name: PAGE.BUSINESS_USER_FORM,
          component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/User/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.BUSINESS_USER_FORM
          }
        },
        {
          path: 'detail',
          name: PAGE.BUSINESS_USER_DETAIL,
          component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/User/Detail/Detail.vue'),
          redirect: 'detail/business/:id(\\d+)?',
          meta: {
            pageTitle: PAGE_TITLE.BUSINESS_USER_DETAIL,
            tabName: PAGE.BUSINESS_USER_DETAIL
          },
          children: [
            {
              path: 'business/:id(\\d+)?',
              name: PAGE.BUSINESS_USER_DETAIL_BUSINESS,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/User/Detail/DetailBusiness.vue'),
              meta: {
                title: PAGE_TAB.BUSINESS_USER_DETAIL_BUSINESS
              }
            },
            {
              path: 'invite/:id(\\d+)?',
              name: PAGE.BUSINESS_USER_DETAIL_INVITE,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/User/Detail/DetailInvite.vue'),
              meta: {
                title: PAGE_TAB.BUSINESS_USER_DETAIL_INVITE
              }
            },
            // {
            //   path: 'history/:id(\\d+)?',
            //   name: PAGE.BUSINESS_USER_DETAIL_HISTORY,
            //   component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/User/Detail/DetailHistory.vue'),
            //   meta: {
            //     title: PAGE_TAB.BUSINESS_USER_DETAIL_HISTORY
            //   }
            // },
            {
              path: 'qna/:id(\\d+)?',
              name: PAGE.BUSINESS_USER_DETAIL_QNA,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/User/Detail/DetailQna.vue'),
              meta: {
                title: PAGE_TAB.BUSINESS_USER_DETAIL_QNA
              }
            }
          ]
        }
      ]
    },
    {
      path: 'manage',
      name: PAGE.BUSINESS_MANAGE,
      redirect: '/business/manage/list',
      component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Index.vue'),
      meta: {
        title: PAGE_TITLE.BUSINESS_MANAGE,
        menuName: PAGE.BUSINESS_MANAGE,
        roleKeys: { menu: 'business', subMenu: 'businessManager' }
      },
      children: [
        {
          path: 'list',
          name: PAGE.BUSINESS_MANAGE_LIST,
          component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/List.vue')
        },
        {
          path: 'detail',
          name: PAGE.BUSINESS_MANAGE_DETAIL,
          component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/Index.vue'),
          redirect: 'detail/basic',
          meta: {
            pageTitle: PAGE_TITLE.BUSINESS_MANAGE_DETAIL,
            tabName: PAGE.BUSINESS_MANAGE_DETAIL,
            menuName: PAGE.BUSINESS_MANAGE_DETAIL
          },
          children: [
            {
              path: 'basic/:id(\\d+)?',
              name: PAGE.BUSINESS_MANAGE_DETAIL_BASIC,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/DetailBasic.vue'),
              meta: {
                title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_BASIC
              }
            },
            {
              path: 'member/:id(\\d+)',
              name: PAGE.BUSINESS_MANAGE_DETAIL_MEMBER,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/DetailMember.vue'),
              meta: {
                title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_MEMBER,
                countKey: 'member'
              }
            },
            {
              path: 'campaign/:id(\\d+)',
              name: PAGE.BUSINESS_MANAGE_DETAIL_CAMPAIGN,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/DetailCampaign.vue'),
              meta: {
                title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_CAMPAIGN
              }
            },
            // {
            //   path: 'goods/:id(\\d+)?',
            //   name: PAGE.BUSINESS_MANAGE_DETAIL_GOODS,
            //   component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/DetailProduct.vue'),
            //   meta: {
            //     title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_GOODS
            //   }
            // },
            {
              path: 'brand/:id(\\d+)',
              name: PAGE.BUSINESS_MANAGE_DETAIL_BRAND,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/Product/Index.vue'),
              redirect: { name: PAGE.BUSINESS_MANAGE_DETAIL_BRAND_LIST },
              meta: {
                title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_BRAND
              },
              children: [
                {
                  path: 'list',
                  name: PAGE.BUSINESS_MANAGE_DETAIL_BRAND_LIST,
                  component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/DetailBrand.vue'),
                  meta: {
                    title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_BRAND,
                    tabActiveValue: PAGE.BUSINESS_MANAGE_DETAIL_BRAND
                  }
                },
                {
                  path: 'like/:brandId(\\d+)',
                  name: PAGE.BUSINESS_MANAGE_DETAIL_BRAND_LIKE,
                  component: () => import(/* webpackChunkName: "business" */ '@page/Stylemate/LikeManage/Index.vue'),
                  meta: {
                    title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_BRAND,
                    tabActiveValue: PAGE.BUSINESS_MANAGE_DETAIL_BRAND
                  }
                }
              ]
            },
            {
              path: 'product/:id(\\d+)',
              name: PAGE.BUSINESS_MANAGE_DETAIL_PRODUCT,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/Product/Index.vue'),
              redirect: { name: PAGE.BUSINESS_MANAGE_DETAIL_PRODUCT_LIST },
              meta: {
                title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_PRODUCT
              },
              children: [
                {
                  path: 'list',
                  name: PAGE.BUSINESS_MANAGE_DETAIL_PRODUCT_LIST,
                  component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/Product/List.vue'),
                  meta: {
                    title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_PRODUCT
                  }
                },
                {
                  path: 'form/:productId(\\d+)?',
                  name: PAGE.BUSINESS_MANAGE_DETAIL_PRODUCT_FORM,
                  component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/Product/Form.vue'),
                  meta: {
                    title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_PRODUCT,
                    tabActiveValue: PAGE.BUSINESS_MANAGE_DETAIL_PRODUCT
                  }
                },
                {
                  path: 'like/:productId(\\d+)',
                  name: PAGE.BUSINESS_MANAGE_DETAIL_PRODUCT_LIKE,
                  component: () => import(/* webpackChunkName: "business" */ '@page/Stylemate/LikeManage/Index.vue'),
                  meta: {
                    title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_PRODUCT,
                    tabActiveValue: PAGE.BUSINESS_MANAGE_DETAIL_PRODUCT
                  }
                }
              ]
            }
            // {
            //   path: 'history/:id(\\d+)?',
            //   name: PAGE.BUSINESS_MANAGE_DETAIL_HISTORY,
            //   component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Manage/Detail/DetailHistory.vue'),
            //   meta: {
            //     title: PAGE_TAB.BUSINESS_MANAGE_DETAIL_HISTORY
            //   }
            // }
          ]
        }
      ]
    },
    {
      path: 'agency',
      name: PAGE.BUSINESS_AGENCY,
      redirect: '/business/agency/list',
      component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Index.vue'),
      meta: {
        title: PAGE_TITLE.BUSINESS_AGENCY,
        menuName: PAGE.BUSINESS_AGENCY,
        roleKeys: { menu: 'business', subMenu: 'agencyManager' }
      },
      children: [
        {
          path: 'list',
          name: PAGE.BUSINESS_AGENCY_LIST,
          component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Agency/List.vue')
        },
        {
          path: 'detail',
          name: PAGE.BUSINESS_AGENCY_DETAIL,
          component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Agency/Detail/Index.vue'),
          redirect: 'detail/basic',
          meta: {
            pageTitle: PAGE_TITLE.BUSINESS_AGENCY_DETAIL,
            tabName: PAGE.BUSINESS_AGENCY_DETAIL,
            menuName: PAGE.BUSINESS_AGENCY_DETAIL
          },
          children: [
            {
              path: 'basic/:id(\\d+)?',
              name: PAGE.BUSINESS_AGENCY_DETAIL_BASIC,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Agency/Detail/DetailBasic.vue'),
              meta: {
                title: PAGE_TAB.BUSINESS_AGENCY_DETAIL_BASIC
              }
            },
            {
              path: 'business/:id(\\d+)?',
              name: PAGE.BUSINESS_AGENCY_DETAIL_BUSINESS,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Agency/Detail/DetailBusiness.vue'),
              meta: {
                title: PAGE_TAB.BUSINESS_AGENCY_DETAIL_BUSINESS
              }
            },
            // {
            //   path: 'campaign/:id(\\d+)?',
            //   name: PAGE.BUSINESS_MANAGE_AGENCY_CAMPAIGN,
            //   component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Agency/Detail/DetailCampaign.vue'),
            //   meta: {
            //     title: PAGE_TAB.BUSINESS_AGENCY_DETAIL_CAMPAIGN
            //   }
            // },
            {
              path: 'member/:id(\\d+)?',
              name: PAGE.BUSINESS_AGENCY_DETAIL_MEMBER,
              component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Agency/Detail/DetailMember.vue'),
              meta: {
                title: PAGE_TAB.BUSINESS_AGENCY_DETAIL_MEMBER
              }
            }
            // {
            //   path: 'product/:id(\\d+)?',
            //   name: PAGE.BUSINESS_AGENCY_DETAIL_PRODUCT,
            //   component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Agency/Detail/DetailProduct.vue'),
            //   meta: {
            //     title: PAGE_TAB.BUSINESS_AGENCY_DETAIL_PRODUCT
            //   }
            // },
            // {
            //   path: 'history/:id(\\d+)?',
            //   name: PAGE.BUSINESS_AGENCY_DETAIL_HISTORY,
            //   component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Agency/Detail/DetailHistory.vue'),
            //   meta: {
            //     title: PAGE_TAB.BUSINESS_AGENCY_DETAIL_HISTORY
            //   }
            // }
          ]
        }
      ]
    },
    {
      path: 'dataManage',
      name: PAGE.BUSINESS_DATA_MANAGE,
      component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/DataManage/Index.vue'),
      meta: {
        title: PAGE_TITLE.BUSINESS_DATA_MANAGE,
        menuName: PAGE.BUSINESS_DATA_MANAGE,
        roleKeys: { menu: 'business', subMenu: 'businessCategory' }
      }
    },
    {
      path: 'company',
      name: PAGE.BUSINESS_COMPANY,
      redirect: '/business/company/list',
      component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Index.vue'),
      meta: {
        title: PAGE_TITLE.BUSINESS_COMPANY,
        menuName: PAGE.BUSINESS_COMPANY,
        roleKeys: { menu: 'business', subMenu: 'businessManager' }
      },
      children: [
        {
          path: 'list',
          name: PAGE.BUSINESS_COMPANY_LIST,
          component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Company/List.vue'),
          meta: {
            pageTitle: PAGE_TITLE.BUSINESS_COMPANY_LIST
          }
        },
        {
          path: 'form/:id(\\d+)?',
          name: PAGE.BUSINESS_COMPANY_FORM,
          component: () => import(/* webpackChunkName: "business" */ '@page/Businesses/Company/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.BUSINESS_COMPANY_FORM
          }
        }
      ]
    }
  ]
}
