import { PAGE, PAGE_TITLE, PAGE_TAB } from '@@routes'

const manageChildren = (name, type) => {
  return [
    {
      path: 'info',
      name: `${name}${PAGE.CAMPAIGN_MANAGE_INFO}`,
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/ManageInfo.vue'),
      meta: {
        type,
        title: PAGE_TAB.CAMPAIGN_MANAGE_INFO
      }
    },
    {
      path: 'applicant',
      name: `${name}${PAGE.CAMPAIGN_MANAGE_APPLICANT}`,
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/ManageApplicant.vue'),
      meta: {
        type,
        title: PAGE_TAB.CAMPAIGN_MANAGE_APPLICANT,
        countKey: 'bookings'
      }
    },
    {
      path: 'participant',
      name: `${name}${PAGE.CAMPAIGN_MANAGE_PARTICIPANT}`,
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/ManageParticipant.vue'),
      meta: {
        type,
        title: PAGE_TAB.CAMPAIGN_MANAGE_PARTICIPANT,
        countKey: 'joins'
      }
    },
    {
      path: 'progress_manage',
      name: `${name}${PAGE.CAMPAIGN_MANAGE_PROGRESS_MANAGE}`,
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/ManageProgressManage.vue'),
      meta: {
        type,
        title: PAGE_TAB.CAMPAIGN_MANAGE_PROGRESS_MANAGE,
        countKey: 'posts'
      }
    },
    {
      path: 'statistics',
      name: `${name}${PAGE.CAMPAIGN_MANAGE_STATISTICS}`,
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/ManageStatistics.vue'),
      meta: {
        type,
        title: PAGE_TAB.CAMPAIGN_MANAGE_STATISTICS
      }
    }
  ]
}

const formChildren = (name, type) => {
  return [
    {
      path: ':id(\\d+)?',
      name: `${name}${PAGE.CAMPAIGN_FORM_CREATE}`,
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/FormEssential.vue'),
      meta: {
        type,
        title: PAGE_TAB.CAMPAIGN_FROM_CREATE,
        stepKey: 'essential'
      }
    },
    {
      path: 'basic/:id(\\d+)',
      name: `${name}${PAGE.CAMPAIGN_FORM_BASIC}`,
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/FormBasic.vue'),
      meta: {
        type,
        title: PAGE_TAB.CAMPAIGN_FROM_BASIC,
        stepKey: 'basic'
      }
    },
    {
      path: 'mission/:id(\\d+)',
      name: `${name}${PAGE.CAMPAIGN_FORM_MISSION}`,
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/FormMission.vue'),
      meta: {
        type,
        title: PAGE_TAB.CAMPAIGN_FROM_MISSION,
        stepKey: 'mission'
      }
    },
    {
      path: 'etc/:id(\\d+)',
      name: `${name}${PAGE.CAMPAIGN_FORM_REWARD}`,
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/FormEtc.vue'),
      meta: {
        type,
        title: PAGE_TAB.CAMPAIGN_FROM_REWARD,
        stepKey: 'etc'
      }
    }
  ]
}

export default {
  path: '/campaign',
  name: PAGE.CAMPAIGN,
  redirect: '/campaign/ready/list',
  component: () => import(/* webpackChunkName: "campaign" */'@page/Campaign/Index.vue'),
  meta: {
    title: PAGE_TITLE.CAMPAIGN,
    serviceName: PAGE.CAMPAIGN,
    useIcon: 'ri-reserved-line',
    roleKeys: { menu: 'campaign', subMenu: 'campaignManage' }
  },
  children: [
    {
      path: 'self',
      name: PAGE.CAMPAIGN_SELF,
      redirect: '/campaign/self/list',
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Self/Index.vue'),
      meta: {
        title: PAGE_TITLE.CAMPAIGN_SELF,
        menuName: PAGE.CAMPAIGN_SELF
      },
      children: [
        {
          path: 'list/:page?',
          name: PAGE.CAMPAIGN_SELF_LIST,
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Self/List.vue')
        },
        {
          path: 'manage/:id(\\d+)',
          name: PAGE.CAMPAIGN_SELF_MANAGE,
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Self/Manage.vue')
        }
      ]
    },
    {
      path: 'ready',
      name: PAGE.CAMPAIGN_WAITING,
      redirect: '/campaign/ready/list',
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Index.vue'),
      meta: {
        title: PAGE_TITLE.CAMPAIGN_WAITING,
        menuName: PAGE.CAMPAIGN_WAITING
      },
      children: [
        {
          path: 'list/:page?',
          name: PAGE.CAMPAIGN_WAITING_LIST,
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/List.vue'),
          meta: {
            type: 'ready'
          }
        },
        {
          path: 'form',
          name: PAGE.CAMPAIGN_WAITING_FORM,
          redirect: '/campaign/waiting/from',
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.CAMPAIGN_FORM
          },
          children: formChildren(PAGE.CAMPAIGN_WAITING_FORM, 'ready')
        }
      ]
    },
    {
      path: 'progress',
      name: PAGE.CAMPAIGN_PROGRESS,
      redirect: '/campaign/progress/list',
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Index.vue'),
      meta: {
        title: PAGE_TITLE.CAMPAIGN_PROGRESS,
        menuName: PAGE.CAMPAIGN_PROGRESS
      },
      children: [
        {
          path: 'list',
          name: PAGE.CAMPAIGN_PROGRESS_LIST,
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/List.vue'),
          meta: {
            type: 'progress'
          }
        },
        {
          path: 'form',
          name: PAGE.CAMPAIGN_PROGRESS_FORM,
          redirect: '/campaign/progress/from',
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.CAMPAIGN_FORM
          },
          children: formChildren(PAGE.CAMPAIGN_PROGRESS_FORM, 'progress')
        },
        {
          path: 'manage/:id',
          name: PAGE.CAMPAIGN_PROGRESS_MANAGE,
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Manage.vue'),
          meta: {
            pageTitle: PAGE_TITLE.CAMPAIGN_MANAGE,
            tabName: PAGE.CAMPAIGN_PROGRESS_MANAGE
          },
          children: manageChildren(PAGE.CAMPAIGN_PROGRESS_MANAGE, 'progress')
        }
      ]
    },
    {
      path: 'finish',
      name: PAGE.CAMPAIGN_END,
      redirect: '/campaign/finish/list',
      component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Index.vue'),
      meta: {
        title: PAGE_TITLE.CAMPAIGN_END,
        menuName: PAGE.CAMPAIGN_END
      },
      children: [
        {
          path: 'list',
          name: PAGE.CAMPAIGN_END_LIST,
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/List.vue'),
          meta: {
            type: 'finish'
          }
        },
        {
          path: 'form',
          name: PAGE.CAMPAIGN_END_FORM,
          redirect: '/campaign/finish/from',
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Manage.vue'),
          meta: {
            pageTitle: PAGE_TITLE.CAMPAIGN_FORM
          },
          children: formChildren(PAGE.CAMPAIGN_END_FORM, 'finish')
        },
        {
          path: 'manage/:id',
          name: PAGE.CAMPAIGN_END_MANAGE,
          component: () => import(/* webpackChunkName: "campaign" */ '@page/Campaign/Manage.vue'),
          meta: {
            pageTitle: PAGE_TITLE.CAMPAIGN_MANAGE,
            tabName: PAGE.CAMPAIGN_END_MANAGE
          },
          children: manageChildren(PAGE.CAMPAIGN_END_MANAGE, 'finish')
        }
      ]
    }
  ]
}
