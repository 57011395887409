import { PAGE, PAGE_TITLE, PAGE_TAB } from '@@routes'

export default {
  path: '/influences',
  name: PAGE.INFLUENCES,
  redirect: '/influences/list',
  component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Index.vue'),
  meta: {
    title: PAGE_TITLE.INFLUENCES_MANAGE,
    serviceName: PAGE.INFLUENCES,
    useIcon: 'ri-chat-heart-line'
  },
  children: [
    {
      path: '',
      name: PAGE.INFLUENCES_SEARCH,
      redirect: '/influences/list',
      component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Index.vue'),
      meta: {
        title: PAGE_TITLE.INFLUENCES_SEARCH,
        menuName: PAGE.INFLUENCES_SEARCH,
        roleKeys: { menu: 'influence', subMenu: 'searchInfluence' }
      },
      children: [
        {
          path: 'list/:type?',
          name: PAGE.INFLUENCES_LIST,
          component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/List.vue')
        },
        {
          path: 'form/:id?',
          name: PAGE.INFLUENCES_FORM,
          component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.INFLUENCES_FORM
          }
        },
        {
          path: 'detail/:id',
          name: PAGE.INFLUENCES_DETAIL,
          redirect: to => {
            const { params } = to
            if (params.id) {
              return { name: PAGE.INFLUENCES_DETAIL_CHANNEL, params: { id: params.id } }
            }
            return {}
          },
          component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/Detail.vue'),
          meta: {
            pageTitle: PAGE_TITLE.INFLUENCES_DETAIL,
            tabName: PAGE.INFLUENCES_DETAIL
          },
          children: [
            {
              path: 'channel',
              name: PAGE.INFLUENCES_DETAIL_CHANNEL,
              component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/DetailChannel.vue'),
              meta: {
                title: PAGE_TAB.INFLUENCES_DETAIL_CHANNEL
              }
            },
            {
              path: 'campaign',
              name: PAGE.INFLUENCES_DETAIL_CAMPAIGN,
              component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/DetailCampaign.vue'),
              meta: {
                title: PAGE_TAB.INFLUENCES_DETAIL_CAMPAIGN
              }
            },
            {
              path: 'point',
              name: PAGE.INFLUENCES_DETAIL_POINT,
              component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/DetailPoint.vue'),
              meta: {
                title: PAGE_TAB.INFLUENCES_DETAIL_POINT
              }
            },
            {
              path: 'previous_point',
              name: PAGE.INFLUENCES_DETAIL_POINT_BEFORE,
              component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/DetailPointBefore.vue'),
              meta: {
                title: PAGE_TAB.INFLUENCES_DETAIL_POINT_BEFORE
              }
            },
            {
              path: 'inquiry',
              name: PAGE.INFLUENCES_DETAIL_INQUIRY,
              component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/DetailInquiry.vue'),
              meta: {
                title: PAGE_TAB.INFLUENCES_DETAIL_INQUIRY
              }
            },
            {
              path: 'cost',
              name: PAGE.INFLUENCES_DETAIL_COST,
              component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/DetailCost.vue'),
              meta: {
                title: PAGE_TAB.INFLUENCES_DETAIL_COST
              }
            },
            {
              path: 'review',
              name: PAGE.INFLUENCES_DETAIL_REVIEW,
              component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Search/DetailReview.vue'),
              meta: {
                title: PAGE_TAB.INFLUENCES_DETAIL_REVIEW
              }
            }
          ]
        }
      ]
    },
    {
      path: 'channel/:type?',
      name: PAGE.INFLUENCES_CHANNEL,
      component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Channel/Index.vue'),
      meta: {
        title: PAGE_TITLE.INFLUENCES_CHANNEL,
        menuName: PAGE.INFLUENCES_CHANNEL,
        roleKeys: { menu: 'influence', subMenu: 'searchChannel' }
      }
    },
    {
      path: 'tag/:type?',
      name: PAGE.INFLUENCES_TAG_MANAGE,
      component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Tag/Tag.vue'),
      meta: {
        title: PAGE_TITLE.INFLUENCES_TAG_MANAGE,
        menuName: PAGE.INFLUENCES_TAG_MANAGE,
        // roleKeys: { menu: 'influence', subMenu: 'categoryTagManage' }
        roleKeys: { menu: 'arrow', subMenu: 'accessor' }
      }
    },
    {
      path: 'cost',
      name: PAGE.INFLUENCES_COST_SEARCH,
      component: () => import(/* webpackChunkName: "influences" */ '@page/Influences/Cost/Cost.vue'),
      meta: {
        title: PAGE_TITLE.INFLUENCES_COST_SEARCH,
        menuName: PAGE.INFLUENCES_COST_SEARCH,
        roleKeys: { menu: 'influence', subMenu: 'searchInfluenceCost' }
      }
    }
  ]
}
