import axios from '@/plugins/axios'
import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SET_MY_INFO,
  SET_IS_ARROW_ACCESSOR,
  SET_CURRENT_ROLE
} from '@/store/mutation_types'
import { clearAuthCookies } from '@/plugins/authService'

const state = {
  isAuth: false,
  isArrowAccessor: {},
  myInfoStatus: false,
  info: {},
  permissions: {},
  currentRole: {
    view: null,
    modify: null,
    delete: null,
    confirm: null
  }
}

const getters = {
  isAuth: state => state.isAuth,
  info: state => state.info,
  permissions: state => state.permissions,
  currentRole: state => state.currentRole,
  viewPermissionsCheck: state => (keys) => {
    return keys ? state.permissions[keys.menu]?.subMenu[keys.subMenu]?.authorization.view : false
  },
  pageRole: state => (keys) => {
    return keys ? state.permissions[keys.menu]?.subMenu[keys.subMenu]?.authorization : false
  },
  isArrowAccessor: state => state.isArrowAccessor,
  myInfoStatus: state => state.myInfoStatus
}

const actions = {
  async isLogined ({ commit, state }) {
    if (state.info.uid) {
      commit(AUTH_SUCCESS)
    } else {
      commit(AUTH_LOGOUT)
    }
  },
  logout: ({ commit }) => {
    clearAuthCookies()
    commit(AUTH_LOGOUT)
  },
  /* 로그인 후 */
  async isArrowAccessor ({ commit, state, dispatch }) {
    const setting = {
      method: 'GET',
      url: '/_p/is-allow-accessor'
    }
    await axios(setting).then((response) => {
      commit(SET_IS_ARROW_ACCESSOR, response)
    }).catch((err) => {
      if (err.response?.status === 401) {
        dispatch('logout')
      }
    })
  },
  async myInfo ({ commit, dispatch }) {
    const setting = {
      method: 'GET',
      url: '/myInfo'
    }
    await axios(setting).then((response) => {
      commit(SET_MY_INFO, response)
    }).catch((err) => {
      if (err.response?.status === 401) {
        dispatch('logout')
      }
    })
  },
  async currentRole ({ commit }, keys) {
    commit(SET_CURRENT_ROLE, keys)
  }
}

const mutations = {
  [AUTH_SUCCESS]: (state) => {
    state.isAuth = true
  },
  [AUTH_LOGOUT]: (state) => {
    state.isAuth = false
    state.isArrowAccessor = null
    state.myInfoStatus = false
    state.info = {}
    state.permissions = {}
    state.currentRole = {
      view: null,
      modify: null,
      delete: null,
      confirm: null
    }
  },
  [SET_MY_INFO]: (state, payload) => {
    const info = payload

    state.permissions = info.administrator.role.permissions?.menu
    state.info = {
      email: info.email,
      name: info.name,
      uid: info.uid,
      id: info.id,
      status: info.status,
      tel: info.tel,
      role: {
        ...info.administrator.role
      },
      postErrorCount: info.postErrorCount,
      postAlarmCount: info.postAlarmCount,
      channelLostCount: info.channelLostCount,
      selfCampaignCount: {
        total: info.campaignInquiryNotAnsweredCount + info.campaignPostInquiryNotAnsweredCount + info.postCheckingCount,
        campaignInquiryNotAnsweredCount: info.campaignInquiryNotAnsweredCount,
        campaignPostInquiryNotAnsweredCount: info.campaignPostInquiryNotAnsweredCount,
        postCheckingCount: info.postCheckingCount
      }
    }
    state.myInfoStatus = true
  },
  [SET_IS_ARROW_ACCESSOR]: (state, payload) => {
    state.isArrowAccessor = payload
  },
  [SET_CURRENT_ROLE]: (state, payload) => {
    if (state.isArrowAccessor.isAllow) {
      state.permissions.arrow = {
        subMenu: {
          accessor: {
            authorization: { confirm: true, delete: true, modify: true, view: true },
            role: { confirm: false, delete: false, modify: false, view: false },
            ename: 'arrowAccessor'
          }
        }
      }
    }
    state.currentRole = state.permissions[payload.menu]?.subMenu[payload.subMenu]?.authorization
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
