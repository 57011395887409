import axios from '@/plugins/axios'
import { SET_IR_LIST, SET_IR_DETAIL } from '@/store/mutation_types'

const state = {
  irModel: {},
  irDetail: {}
}

const getters = {
  irListData: state => state.irModel,
  irDetail: state => state.irDetail
}

const actions = {
  async getIrList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/irs',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_IR_LIST, response)
    })
  },
  async getIrItemDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/irs/${id}`
    }

    await axios(config).then(response => {
      commit(SET_IR_DETAIL, response)
    })
  },
  async createIrItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/irs',
      params: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async saveIrItem ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/irs/${id}`,
      data: {
        ...data
      }
    }
    const result = await axios(setting)
    return result
  },
  async deleteIrItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/irs/${id}`
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_IR_LIST]: (state, payload) => {
    state.irModel = payload
  },
  [SET_IR_DETAIL]: (state, payload) => {
    state.irDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
