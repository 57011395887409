const PAGE = {
  ERROR404: 'Error404',

  // Auth
  AUTH: 'Auth',
  LOGIN: 'AuthLogin',
  PASSWORD_RESET: 'AuthPasswordReset',
  PASSWORD_CHANGE: 'AuthPasswordChange',
  INVITATION: 'AuthInvitation',
  JOIN: 'AuthJoin',
  JOIN_COMPUTED: 'AuthJoinComputed',

  // 공통
  COMMON: 'Common',
  COMMON_OPERATOR: 'CommonOperatorManage',
  COMMON_OPERATOR_LIST: 'CommonOperatorManageList',
  COMMON_OPERATOR_DETAIL: 'CommonOperatorManageDetail',
  COMMON_OPERATOR_DETAIL_BASIC: 'CommonOperatorManageDetailBasic',
  COMMON_OPERATOR_DETAIL_AUTHORITY: 'CommonOperatorManageDetailAuthority',
  COMMON_ROLE: 'CommonRole',
  COMMON_ROLE_LIST: 'CommonRoleList',
  COMMON_ROLE_FORM: 'CommonRoleForm',
  COMMON_ROLE_FORM_BASIC: 'CommonRoleFormBasic',
  COMMON_ROLE_FORM_AUTHORITY: 'CommonRoleFormAuthority',
  COMMON_ROLE_FORM_OPERATOR_MANAGE: 'CommonRoleFormClient',
  COMMON_NOTICE: 'CommonNotice',
  COMMON_NOTICE_LIST: 'CommonNoticeList',
  // 셀프캠페인 알림
  COMMON_SELF_CAMPAIGN: 'CommonSelfCampaign',
  COMMON_SELF_CAMPAIGN_LIST: 'CommonSelfCampaignList',

  // 로그인 관리
  SERVICE: 'Service',
  SERVICE_LOGIN_MANAGE: 'ServiceLoginManage',
  SERVICE_LOGIN_MANAGE_LIST: 'ServiceLoginManageList',
  SERVICE_LOGIN_MANAGE_FORM: 'ServiceLoginManageForm',
  SERVICE_MAINTENANCE_MANAGE: 'ServiceMaintenanceManage',

  // 비즈니스 관리
  BUSINESS: 'Business',
  BUSINESS_USER: 'BusinesUser',
  BUSINESS_USER_LIST: 'BusinesUserList',
  BUSINESS_USER_FORM: 'BusinesUserForm',
  BUSINESS_USER_DETAIL: 'BusinesUserDetail',
  BUSINESS_USER_DETAIL_BUSINESS: 'BusinesUserDetailBusiness',
  BUSINESS_USER_DETAIL_INVITE: 'BusinesUserDetailInvite',
  BUSINESS_USER_DETAIL_HISTORY: 'BusinesUserDetailHistory',
  BUSINESS_USER_DETAIL_QNA: 'BusinesUserDetailQNA',
  BUSINESS_MANAGE: 'BusinessManage',
  BUSINESS_MANAGE_LIST: 'BusinessManageList',
  BUSINESS_MANAGE_DETAIL: 'BusinessManageDetail',
  BUSINESS_MANAGE_DETAIL_BASIC: 'BusinessManageDetailBasic',
  BUSINESS_MANAGE_DETAIL_CAMPAIGN: 'BusinessManageDetailCampaign',
  BUSINESS_MANAGE_DETAIL_MEMBER: 'BusinessManageDetailMember',
  BUSINESS_MANAGE_DETAIL_GOODS: 'BusinessManageDetailGoods',
  BUSINESS_MANAGE_DETAIL_BRAND: 'BusinessManageDetailBrand',
  BUSINESS_MANAGE_DETAIL_BRAND_LIST: 'BusinessManageDetailBrandList',
  BUSINESS_MANAGE_DETAIL_BRAND_LIKE: 'BusinessManageDetailBrandLike',
  BUSINESS_MANAGE_DETAIL_PRODUCT: 'BusinessManageDetailProduct',
  BUSINESS_MANAGE_DETAIL_PRODUCT_LIST: 'BusinessManageDetailProductList',
  BUSINESS_MANAGE_DETAIL_PRODUCT_FORM: 'BusinessManageDetailProductForm',
  BUSINESS_MANAGE_DETAIL_PRODUCT_LIKE: 'BusinessManageDetailProductLike',
  BUSINESS_MANAGE_DETAIL_HISTORY: 'BusinessManageDetailHistory',
  BUSINESS_DATA_MANAGE: 'BusinessDataManage',
  BUSINESS_COMPANY: 'BusinessCompany',
  BUSINESS_COMPANY_LIST: 'BusinessCompanyList',
  BUSINESS_COMPANY_FORM: 'BusinessCompanyForm',
  BUSINESS_AGENCY: 'BusinessAgency',
  BUSINESS_AGENCY_LIST: 'BusinessAgencyList',
  BUSINESS_AGENCY_DETAIL: 'BusinessAgencyDetail',
  BUSINESS_AGENCY_DETAIL_BASIC: 'BusinessAgencyDetailBasic',
  BUSINESS_AGENCY_DETAIL_BUSINESS: 'BusinessAgencyDetailBusiness',
  BUSINESS_AGENCY_DETAIL_CAMPAIGN: 'BusinessAgencyDetailCampaign',
  BUSINESS_AGENCY_DETAIL_MEMBER: 'BusinessAgencyDetailMember',
  BUSINESS_AGENCY_DETAIL_PRODUCT: 'BusinessAgencyDetailProduct',
  BUSINESS_AGENCY_DETAIL_HISTORY: 'BusinessAgencyDetailHistory',
  BUSINESS_LIST: 'BusinessManageList',
  BUSINESS_FORM: 'BusinessManageForm',
  BUSINESS_FORM_BASIC: 'BusinessManageFormBasic',
  BUSINESS_FORM_CAMPAIGN: 'BusinessManageFormCampaign',
  BUSINESS_FORM_MANAGE: 'BusinessManageFormManage',

  BCA: 'Bca',
  BCA_CAMPAIGN: 'BcaCampaign',
  BCA_CAMPAIGN_READY: 'BcaCampaignReady',
  BCA_CAMPAIGN_READY_LIST: 'BcaCampaignReadyList',
  BCA_CAMPAIGN_READY_DETAIL: 'BcaCampaignReadyDetail',
  BCA_CAMPAIGN_READY_DETAIL_MATERIAL: 'BcaCampaignReadyDetailMaterial',
  BCA_CAMPAIGN_PROGRESS: 'BcaCampaignProgress',
  BCA_CAMPAIGN_PROGRESS_LIST: 'BcaCampaignProgressList',
  BCA_CAMPAIGN_PROGRESS_DETAIL: 'BcaCampaignProgressDetail',
  BCA_CAMPAIGN_PROGRESS_DETAIL_MATERIAL: 'BcaCampaignProgressDetailMaterial',
  BCA_CAMPAIGN_FINISH: 'BcaCampaignFinish',
  BCA_CAMPAIGN_FINISH_LIST: 'BcaCampaignFinishList',
  BCA_CAMPAIGN_FINISH_DETAIL: 'BcaCampaignFinishDetail',
  BCA_CAMPAIGN_FINISH_DETAIL_MATERIAL: 'BcaCampaignFinishDetailMaterial',

  BCA_MEDIA: 'BcaMedia',
  BCA_MEDIA_FACEBOOK: 'BcaMediaFacebook',
  BCA_MEDIA_FACEBOOK_ACCOUNT: 'BcaMediaFacebookAccount',
  BCA_MEDIA_FACEBOOK_ACCOUNT_LIST: 'BcaMediaFacebookAccountList',
  BCA_MEDIA_FACEBOOK_ACCOUNT_FORM: 'BcaMediaFacebookAccountForm',
  BCA_MEDIA_FACEBOOK_EXPOSE: 'BcaMediaFacebookExpose',
  BCA_MEDIA_FACEBOOK_EXPOSE_LIST: 'BcaMediaFacebookExposeList',
  BCA_MEDIA_GOOGLE: 'BcaMediaGoogle',
  BCA_MEDIA_GOOGLE_ACCOUNT: 'BcaMediaGoogleAccount',
  BCA_MEDIA_GOOGLE_ACCOUNT_LIST: 'BcaMediaGoogleAccountList',
  BCA_MEDIA_GOOGLE_ACCOUNT_FORM: 'BcaMediaGoogleAccountForm',
  BCA_MEDIA_GOOGLE_CATEGORY: 'BcaMediaGoogleCategory',
  BCA_MEDIA_GOOGLE_CATEGORY_LIST: 'BcaMediaGoogleCategoryList',
  BCA_MEDIA_MONITORING: 'BcaMediaMonitoring',

  BCA_REPORT: 'BcaReport',
  BCA_REPORT_PERFORMANCE: 'BcaReportPerformance',
  BCA_REPORT_BUSINESS: 'BcaReportBusiness',
  BCA_REPORT_MEDIA: 'BcaReportMedia',
  BCA_REPORT_MATERIAL: 'BcaReportMaterial',
  BCA_REPORT_MBP: 'BcaReportMbp',
  BCA_REPORT_TRACKING: 'BcaReportTracking',
  BCA_REPORT_AD_PERFORMANCE_DATA: 'BcaReportAdPerformanceData',

  // 캠페인
  CAMPAIGN: 'Campaign',
  CAMPAIGN_WAITING: 'CampaignWaiting',
  CAMPAIGN_WAITING_LIST: 'CampaignWaitingList',
  CAMPAIGN_WAITING_FORM: 'CampaignWaitingForm',
  CAMPAIGN_PROGRESS: 'CampaignProgress',
  CAMPAIGN_PROGRESS_LIST: 'CampaignProgressList',
  CAMPAIGN_PROGRESS_FORM: 'CampaignProgressForm',
  CAMPAIGN_PROGRESS_MANAGE: 'CampaignProgressManage',
  CAMPAIGN_END: 'CampaignEnd',
  CAMPAIGN_END_LIST: 'CampaignEndList',
  CAMPAIGN_END_FORM: 'CampaignEndForm',
  CAMPAIGN_END_MANAGE: 'CampaignEndManage',
  CAMPAIGN_List: 'List',
  CAMPAIGN_FORM_CREATE: 'Create',
  CAMPAIGN_FORM_BASIC: 'Basic',
  CAMPAIGN_FORM_MISSION: 'Mission',
  CAMPAIGN_FORM_REWARD: 'Reward',
  CAMPAIGN_MANAGE_INFO: 'Info',
  CAMPAIGN_MANAGE_APPLICANT: 'Applicant',
  CAMPAIGN_MANAGE_PARTICIPANT: 'Participant',
  CAMPAIGN_MANAGE_PROGRESS_MANAGE: 'ProgressManage',
  CAMPAIGN_MANAGE_SURVEY: 'Survey',
  CAMPAIGN_MANAGE_POINT: 'Point',
  CAMPAIGN_MANAGE_STATISTICS: 'Statistics',
  CAMPAIGN_SELF: 'CampaignSelf',
  CAMPAIGN_SELF_LIST: 'CampaignSelfList',
  CAMPAIGN_SELF_MANAGE: 'CampaignSelfManage',

  // 인플루언서
  INFLUENCES: 'Influences',
  INFLUENCES_SEARCH: 'InfluencesSearch',
  INFLUENCES_LIST: 'InfluencesSearchList',
  INFLUENCES_FORM: 'InfluencesSearchForm',
  INFLUENCES_DETAIL: 'InfluencesSearchDetail',
  INFLUENCES_DETAIL_CHANNEL: 'InfluencesSearchDetailChannel',
  INFLUENCES_DETAIL_CAMPAIGN: 'InfluencesSearchDetailCampaign',
  INFLUENCES_DETAIL_POINT: 'InfluencesSearchDetailPoint',
  INFLUENCES_DETAIL_POINT_BEFORE: 'InfluencesSearchDetailPointBefore',
  INFLUENCES_DETAIL_INQUIRY: 'InfluencesSearchDetailInquiry',
  INFLUENCES_DETAIL_COST: 'InfluencesSearchDetailCost',
  INFLUENCES_DETAIL_REVIEW: 'InfluencesSearchDetailReview',
  INFLUENCES_CHANNEL: 'InfluencesChannel',
  INFLUENCES_TAG_MANAGE: 'InfluencesTagManage',
  INFLUENCES_COST_SEARCH: 'InfluencesCostSearch',

  // 정산
  SETTLEMENT: 'Settlement',
  SETTLEMENT_REGULAR: 'SettlementRegular',
  SETTLEMENT_REGULAR_LIST: 'SettlementRegularList',
  SETTLEMENT_REGULAR_MANAGE: 'SettlementRegularManage',
  SETTLEMENT_IRREGULAR: 'SettlementIrregular',
  SETTLEMENT_IRREGULAR_LIST: 'SettlementIrregularList',
  SETTLEMENT_IRREGULAR_MANAGE: 'SettlementIrregularManage',

  // 통계
  STATISTICS: 'Statistics',
  STATISTICS_DASHBOARD: 'StatisticsDashboard',
  STATISTICS_REPORT: 'StatisticsReport',
  STATISTICS_REPORT_LIST: 'StatisticsReportList',
  STATISTICS_REPORT_FORM: 'StatisticsReportForm',

  // 운영
  OPERATION: 'Operation',
  OPERATION_MESSAGE: 'OperationMessage',
  OPERATION_MESSAGE_SEND: 'OperationMessageSend',
  OPERATION_MESSAGE_SEND_LIST: 'OperationMessageSendList',
  OPERATION_MESSAGE_SEND_RESULT_LIST: 'OperationMassageSendResultList',
  OPERATION_MESSAGE_TEMPLATE: 'OperationMassageTemplate',

  OPERATION_ALIMTALK: 'OperationAlimtalk',
  OPERATION_ALIMTALK_TEMPLATE: 'OperationAlimtalkTemplate',
  OPERATION_ALIMTALK_SEND: 'OperationAlimtalkSend',
  OPERATION_ALIMTALK_SEND_LIST: 'OperationAlimtalkSendList',
  OPERATION_ALIMTALK_SEND_RESULT_LIST: 'OperationAlimtalkSendResultList',

  OPERATION_NOTICE: 'OperationNotice',
  OPERATION_MEDIANCE_NOTICE: 'OperationMedianceNotice',
  OPERATION_MEDIANCE_NOTICE_LIST: 'OperationMedianceNoticeList',
  OPERATION_MEDIANCE_NOTICE_FORM: 'OperationMedianceNoticeForm',
  OPERATION_STYLEMATE_NOTICE: 'OperationStylemateNotice',
  OPERATION_STYLEMATE_NOTICE_LIST: 'OperationStylemateNoticeList',
  OPERATION_STYLEMATE_NOTICE_FORM: 'OperationStylemateNoticeForm',
  OPERATION_MBP_NOTICE: 'OperationMbpNotice',
  OPERATION_MBP_NOTICE_LIST: 'OperationMbpNoticeList',
  OPERATION_MBP_NOTICE_FORM: 'OperationMbpNoticeForm',

  OPERATION_FAQ: 'OperationFaq',
  OPERATION_MEDIANCE_FAQ: 'OperationMedianceFaq',
  OPERATION_MEDIANCE_FAQ_LIST: 'OperationMedianceFaqList',
  OPERATION_MEDIANCE_FAQ_FORM: 'OperationMedianceFaqForm',
  OPERATION_STYLEMATE_FAQ: 'OperationStylemateFaq',
  OPERATION_STYLEMATE_FAQ_LIST: 'OperationStylemateFaqList',
  OPERATION_STYLEMATE_FAQ_FORM: 'OperationStylemateFaqForm',
  OPERATION_MBP_FAQ: 'OperationMbpFaq',
  OPERATION_MBP_FAQ_LIST: 'OperationMbpFaqList',
  OPERATION_MBP_FAQ_FORM: 'OperationMbpFaqForm',

  OPERATION_QNAS: 'OperationQnas',
  OPERATION_MEDIANCE_QNAS: 'OperationMedianceQnas',
  OPERATION_MEDIANCE_QNAS_LIST: 'OperationMedianceQnasList',
  OPERATION_MEDIANCE_QNAS_FORM: 'OperationMedianceQnasForm',
  OPERATION_STYLEMATE_QNAS: 'OperationStylemateQnas',
  OPERATION_STYLEMATE_QNAS_LIST: 'OperationStylemateQnasList',
  OPERATION_STYLEMATE_QNAS_FORM: 'OperationStylemateQnasForm',
  OPERATION_MBP_QNAS: 'OperationMbpQnas',
  OPERATION_MBP_QNAS_LIST: 'OperationMbpQnasList',
  OPERATION_MBP_QNAS_FORM: 'OperationMbpQnasForm',

  OPERATION_CHANNEL_GUIDE: 'OperationChannelGuide',
  OPERATION_CHANNEL_GUIDES_NEW: 'OperationChannelGuideNew',
  OPERATION_CHANNEL_GUIDES_NEW_LIST: 'OperationChannelGuideNewList',
  OPERATION_CHANNEL_GUIDES_NEW_FORM: 'OperationChannelGuideNewForm',
  OPERATION_CHANNEL_GUIDES_EXIST: 'OperationChannelGuideExist',
  OPERATION_CHANNEL_GUIDES_EXIST_LIST: 'OperationChannelGuideExistList',
  OPERATION_CHANNEL_GUIDES_EXIST_FORM: 'OperationChannelGuideExistForm',
  OPERATION_TRACKING: 'OperationTracking',
  OPERATION_TRACKING_LIST: 'OperationTrackingList',
  OPERATION_BANNER: 'OperationBanner',
  OPERATION_BANNER_LIST: 'OperationBannerList',
  OPERATION_BANNER_ORDERING: 'OperationBannerOrdering',
  OPERATION_BANNER_FORM: 'OperationBannerForm',
  OPERATION_TERMS: 'OperationTerms',
  OPERATION_TERMS_LIST: 'OperationTermsList',
  OPERATION_TERMS_FORM: 'OperationTermsForm',
  OPERATION_TERMS_SERVICE: 'OperationTermsService',
  OPERATION_TERMS_SERVICE_LIST: 'OperationTermsServiceList',
  OPERATION_TERMS_SERVICE_FORM: 'OperationTermsServiceForm',
  OPERATION_TERMS_POLICY: 'OperationTermsPolicy',
  OPERATION_TERMS_POLICY_LIST: 'OperationTermsPolicyList',
  OPERATION_TERMS_POLICY_FORM: 'OperationTermsPolicyForm',
  OPERATION_TERMS_MARKETING: 'OperationTermsMarketing',
  OPERATION_TERMS_MARKETING_LIST: 'OperationTermsMarketingList',
  OPERATION_TERMS_MARKETING_FORM: 'OperationTermsMarketingForm',
  OPERATION_TERMS_THIRD_PARTY: 'OperationTermsThirdParty',
  OPERATION_TERMS_THIRD_PARTY_LIST: 'OperationTermsThirdPartyList',
  OPERATION_TERMS_THIRD_PARTY_FORM: 'OperationTermsThirdPartyForm',
  OPERATION_TERMS_GUIDELINE: 'OperationTermsGuideline',
  OPERATION_TERMS_GUIDELINE_LIST: 'OperationTermsGuidelineList',
  OPERATION_TERMS_GUIDELINE_FORM: 'OperationTermsGuidelineForm',

  // 홈페이지
  HOMEPAGE: 'Homepage',
  HOMEPAGE_IR: 'HomepageIR',
  HOMEPAGE_IR_LIST: 'HomepageIRList',
  HOMEPAGE_IR_FORM: 'HomepageIRForm',
  HOMEPAGE_RECOMMEND: 'HomepageRecommend',
  HOMEPAGE_ADVERTISE_RECOMMEND: 'HomepageAdvertiseRecommend',
  HOMEPAGE_ADVERTISE_RECOMMEND_LIST: 'HomepageAdvertiseRecommendList',
  HOMEPAGE_ADVERTISE_RECOMMEND_ANSWER: 'HomepageAdvertiseRecommendAnswer',
  HOMEPAGE_PRODUCTION_RECOMMEND: 'HomepageProductionRecommend',
  HOMEPAGE_PRODUCTION_RECOMMEND_LIST: 'HomepageProductionRecommendList',
  HOMEPAGE_PRODUCTION_RECOMMEND_ANSWER: 'HomepageProductionRecommendAnswer',
  HOMEPAGE_AGENCY_RECOMMEND: 'HomepageAgencyRecommend',
  HOMEPAGE_AGENCY_RECOMMEND_LIST: 'HomepageAgencyRecommendList',
  HOMEPAGE_AGENCY_RECOMMEND_ANSWER: 'HomepageAgencyRecommendAnswer',

  // 라이브 캐스트
  LIVE_CAST: 'LiveCast',
  LIVE_CAST_SHOW_HOST: 'LiveCastShowHost',
  LIVE_CAST_SHOW_HOST_LIST: 'LiveCastShowHostList',
  LIVE_CAST_SHOW_HOST_FORM: 'LiveCastShowHostForm',
  LIVE_CAST_SHOW_HOST_DETAIL: 'LiveCastShowHostDetail',
  LIVE_CAST_SHOW_HOST_INFO: 'LiveCastShowHostInfo',
  LIVE_CAST_SHOW_HOST_BROADCAST: 'LiveCastShowHostBroadcast',
  LIVE_CAST_STUDIO: 'LiveCastStudio',

  // 스타일메이트
  STYLEMATE: 'Stylemate',
  STYLEMATE_APPLY_MANAGE: 'StylemateApplyManage',
  STYLEMATE_PRODUCT_MANAGE: 'StylemateProductManage',
  STYLEMATE_PRODUCT_MANAGE_LIST: 'StylemateProductManageList',
  STYLEMATE_PRODUCT_MANAGE_FORM: 'StylemateProductManageForm',
  STYLEMATE_PRODUCT_MANAGE_LIKE: 'StylemateProductManageLike',
  STYLEMATE_BRAND_MANAGE: 'StylemateBrandManage',
  STYLEMATE_BRAND_MANAGE_LIST: 'StylemateBrandManageList',
  STYLEMATE_BRAND_MANAGE_FORM: 'StylemateBrandManageForm',
  STYLEMATE_BRAND_MANAGE_LIKE: 'StylemateBrandManageLike',
  STYLEMATE_FRONT_MANAGE: 'StylemateFrontManage',
  STYLEMATE_FRONT_MANAGE_BANNER: 'StylemateFrontManageBanner',
  STYLEMATE_FRONT_MANAGE_BANNER_LIST: 'StylemateFrontManageBannerList',
  STYLEMATE_FRONT_MANAGE_BANNER_FORM: 'StylemateFrontManageBannerForm',
  STYLEMATE_FRONT_MANAGE_BANNER_ORDERING: 'StylemateFrontManageBannerOrdering',
  STYLEMATE_FRONT_MANAGE_BANNER_ITEM: 'StylemateFrontManageBannerItem',
  STYLEMATE_FRONT_MANAGE_BANNER_ITEM_LIST: 'StylemateFrontManageBannerItemList',
  STYLEMATE_FRONT_MANAGE_BANNER_ITEM_FORM: 'StylemateFrontManageBannerItemForm',
  STYLEMATE_FRONT_MANAGE_BANNER_ITEM_ORDERING: 'StylemateFrontManageBannerItemOrdering',
  STYLEMATE_FRONT_MANAGE_PRODUCT: 'StylemateFrontManageProduct',
  STYLEMATE_FRONT_MANAGE_BRAND: 'StylemateFrontManageBrand',
  STYLEMATE_FRONT_MANAGE_CONTENT: 'StylemateFrontManageContent',
  STYLEMATE_CATEGORY_MANAGE: 'StylemateCategoryManage',

  // 상품
  PRODUCT: 'Product',
  PRODUCT_MANAGE: 'ProductManage',
  PRODUCT_MANAGE_LIST: 'ProductManageList',
  PRODUCT_MANAGE_FORM: 'ProductManageForm',
  PRODUCT_MANAGE_FORM_INFO: 'ProductManageFormInfo',
  PRODUCT_MANAGE_FORM_CONTENT: 'ProductManageFormContent',
  PRODUCT_ORDER: 'ProductOrder',
  PRODUCT_ORDER_LIST: 'ProductOrderList',
  PRODUCT_ORDER_MANAGE: 'ProductOrderManage',
  PRODUCT_CREATOR: 'ProductCreator'
}

export default PAGE
