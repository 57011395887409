// auth
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_CHECKED = 'AUTH_CHECKED'
export const SET_MY_INFO = 'SET_MY_INFO'
export const SET_IS_ARROW_ACCESSOR = 'SET_IS_ARROW_ACCESSOR'
export const SET_CURRENT_ROLE = 'SET_CURRENT_ROLE'

// roles
export const SET_ROLE_LIST = 'SET_ROLE_LIST'
export const SET_ROLE_DETAIL = 'SET_ROLE_DETAIL'
export const SET_ROLE_MANAGERS = 'SET_ROLE_MANAGERS'

// users
export const SET_USER_LIST = 'SET_USER_LIST'
export const SET_USER_NOT_ADMINISTRATOR_LIST = 'SET_USER_NOT_ADMINISTRATOR_LIST'

// menu
export const SET_USER_MENU = 'SET_USER_MENU'
// 메뉴
export const SET_MENU = 'SET_MENU'

// businesses
export const SET_BUSINESSES_LIST = 'SET_BUSINESSES_LIST'
export const SET_BUSINESSES_MANAGE_LIST = 'SET_BUSINESSES_MANAGE_LIST'
export const SET_BUSINESSES_MANAGE_DETAIL = 'SET_BUSINESSES_MANAGE_DETAIL'
export const SET_BUSINESSES_MANAGE_OPERATOR = 'SET_BUSINESSES_MANAGE_OPERATOR'
export const SET_BUSINESSES_MANAGE_BRAND = 'SET_BUSINESSES_MANAGE_BRAND'

// agency
export const SET_BUSINESSES_AGENCY_LIST = 'SET_BUSINESSES_AGENCY_LIST'
export const SET_BUSINESSES_AGENCY_DETAIL = 'SET_BUSINESSES_AGENCY_DETAIL'
export const SET_BUSINESSES_AGENCY_BUSINESS = 'SET_BUSINESSES_AGENCY_BUSINESS'
export const SET_BUSINESSES_AGENCY_OPERATOR = 'SET_BUSINESSES_AGENCY_OPERATOR'

// notices
export const SET_NOTICES_LIST = 'SET_NOTICES_LIST'
export const SET_NOTICES_DETAIL = 'SET_NOTICES_DETAIL'

// campaign
export const SET_CAMPAIGN_PROCESS = 'SET_CAMPAIGN_PROCESS'
export const SET_CAMPAIGN_LIST = 'SET_CAMPAIGN_LIST'
export const SET_CAMPAIGN_LIST_EXCLUSIVE = 'SET_CAMPAIGN_LIST_EXCLUSIVE'
export const SET_CAMPAIGN_DETAIL = 'SET_CAMPAIGN_DETAIL'
export const SET_CAMPAIGN_DETAIL_LOAD = 'SET_CAMPAIGN_DETAIL_LOAD'
export const SET_CAMPAIGN_DETAIL_RESET = 'SET_CAMPAIGN_DETAIL_RESET'
export const SET_CAMPAIGN_FINISH_LIST = 'SET_CAMPAIGN_FINISH_LIST'

// 태그
export const SET_TAG_LIST = 'SET_TAG_LIST'
export const SET_TAG_LIST_ITEM = 'SET_TAG_LIST_ITEM'
export const SET_TAG_TYPE_LIST = 'SET_TAG_TYPE_LIST'

// what's new
export const SET_WHAT_LIST = 'SET_WHAT_LIST'
export const SET_WHAT_DETAIL = 'SET_WHAT_DETAIL'

// campaign Manage
export const SET_BOOKINGS_LIST = 'SET_BOOKINGS_LIST'
export const SET_BOOKING_DETAIL = 'SET_BOOKING_DETAIL'
export const SET_JOINS_LIST = 'SET_JOINS_LIST'
export const SET_PROCESS_DETAIL_STATUS = 'SET_PROCESS_DETAIL_STATUS'
export const SET_POST_LIST = 'SET_POST_LIST'
export const SET_POST_DETAIL = 'SET_POST_DETAIL'

// styleMate
export const SET_STYLE_MATE_BRAND_LIST = 'GET_STYLE_MATE_BRAND_LIST'
export const SET_STYLE_MATE_BRAND_DETAIL = 'GET_STYLE_MATE_BRAND_DETAIL'
export const SET_STYLE_MATE_PRODUCT_LIST = 'GET_STYLE_MATE_PRODUCT_LIST'
export const SET_STYLE_MATE_PRODUCT_DETAIL = 'GET_STYLE_MATE_PRODUCT_DETAIL'
export const SET_STYLE_MATE_FRONT_MANAGE_LIST = 'GET_STYLE_MATE_FRONT_MANAGE_LIST'
export const SET_STYLE_MATE_POST_LIST = 'SET_STYLE_MATE_POST_LIST'
export const SET_STYLE_MATE_APPROVE_LIST = 'SET_STYLE_MATE_APPROVE_LIST'
export const SET_STYLE_MATE_LIKE_LIST = 'SET_STYLE_MATE_LIKE_LIST'

// trends
export const SET_TRENDS_LIST = 'SET_TRENDS_LIST'
export const SET_TRENDS_DETAIL = 'SET_TRENDS_DETAIL'

// ir
export const SET_IR_LIST = 'SET_IR_LIST'
export const SET_IR_DETAIL = 'SET_IR_DETAIL'

// common
export const SET_DELIVERY_COMPANY_LIST = 'SET_DELIVERY_COMPANY_LIST'

// influences
export const SET_MEMO_LIST = 'SET_MEMO_LIST'
export const SET_INFLUENCER_LIST = 'SET_INFLUENCER_LIST'
export const SET_INFLUENCER_DETAIL = 'SET_INFLUENCER_DETAIL'
export const SET_INFLUENCER_SEARCH = 'SET_INFLUENCER_SEARCH'
export const SET_CHANNEL_LIST = 'SET_CHANNEL_LIST'
export const SET_INFLUENCE_POINT = 'SET_INFLUENCE_POINT'
export const SET_INFLUENCE_POINT_BEFORE = 'SET_INFLUENCE_POINT_BEFORE'
export const SET_INFLUENCE_WITHDRAWALS = 'SET_INFLUENCE_WITHDRAWALS'
export const SET_INFLUENCE_WITHDRAWAL_REASONS = 'SET_INFLUENCE_WITHDRAWAL_REASONS'
export const SET_INFLUENCE_COST_LIST = 'SET_INFLUENCE_COST_LIST'
export const SET_INFLUENCE_POST_REVIEW_LIST = 'SET_INFLUENCE_POST_REVIEW_LIST'
export const SET_INFLUENCE_DOWNLOAD_HISTORY = 'SET_INFLUENCE_DOWNLOAD_HISTORY'

// cost
export const SET_COST_LIST = 'SET_COST_LIST'

// billing-group
export const SET_BILLING_GROUP = 'SET_BILLING_GROUP'
export const SET_BILLINGS = 'SET_BILLINGS'
export const SET_ALL_BILLINGS = 'SET_ALL_BILLINGS'
export const SET_ALLAPPROVAL = 'SET_ALLAPPROVAL'

// faq
export const SET_FAQ_LIST = 'SET_FAQ_LIST'
export const SET_FAQ_DETAIL = 'SET_FAQ_DETAIL'

// qna
export const SET_QNA_LIST = 'SET_QNA_LIST'
export const SET_QNA_DETAIL = 'SET_QNA_DETAIL'

// message
export const SET_MESSAGE_TEMPLATE_LIST = 'SET_MESSAGE_TEMPLATE_LIST'
export const SET_MESSAGE_RESULT_LIST = 'SET_MESSAGE_RESULT_LIST'
export const SET_MESSAGE_SEND_WAITING_LIST = 'SET_MESSAGE_SEND_WAITING_LIST'
export const SET_MESSAGE_GROUP_STAT = 'SET_MESSAGE_GROUP_STAT'

// adQna
export const SET_ADQNA_LIST = 'SET_ADQNA_LIST'
export const SET_ADQNA_DETAIL = 'SET_ADQNA_DETAIL'

export const SET_PRODUCTION_QNA_LIST = 'SET_PRODUCTION_QNA_LIST'
export const SET_PRODUCTION_QNA_DETAIL = 'SET_PRODUCTION_QNA_DETAIL'

export const SET_AGENCY_QNA_LIST = 'SET_AGENCY_QNA_LIST'
export const SET_AGENCY_QNA_DETAIL = 'SET_AGENCY_QNA_DETAIL'

// terms
export const SET_TERMS_LIST = 'SET_TERMS_LIST'
export const SET_TERMS_DETAIL = 'SET_TERMS_DETAIL'

// policy
export const SET_POLICY_LIST = 'SET_POLICY_LIST'
export const SET_POLICY_DETAIL = 'SET_POLICY_DETAIL'

// banner
export const SET_BANNER_LIST = 'SET_BANNER_LIST'
export const SET_BANNER_DETAIL = 'SET_BANNER_DETAIL'

// 공통
export const SET_BANK_LIST = 'BANK_LIST'

export const SET_TRACKING_ERROR_LIST = 'SET_TRACKING_ERROR_LIST'
export const SET_TRACKING_ALARM_LIST = 'SET_TRACKING_ALARM_LIST'
export const SET_LOST_CONNECTION_LIST = 'SET_LOST_CONNECTION_LIST'

// personalDashboard
export const SET_PERSONALDASHBOARD_LIST = 'SET_PERSONALDASHBOARD_LIST'

// SHOW host
export const SET_HOST_MEMO_LIST = 'SET_HOST_MEMO_LIST'
export const SET_SHOW_HOST_LIST = 'SET_SHOW_HOST_LIST'
export const SET_SHOW_HOST_DETAIL = 'SET_SHOW_HOST_DETAIL'
export const SET_SHOW_HOST_BROADCAST_LIST = 'SET_SHOW_HOST_BROADCAST_LIST'
export const SET_SHOW_HOST_BROADCAST_DETAIL = 'SET_SHOW_HOST_BROADCAST_DETAIL'

// kozelDashboard
export const SET_KOZELDASHBOARD_SUMMARY_LIST = 'SET_KOZELDASHBOARD_SUMMARY_LIST'
export const SET_KOZELDASHBOARD_REPORT_LIST = 'SET_KOZELDASHBOARD_REPORT_LIST'
export const SET_KOZELDASHBOARD_CRAWLING_STAT_LIST = 'SET_KOZELDASHBOARD_CRAWLING_STAT_LIST'

// kozel accounts
export const GET_KOZELACCOUNTS_LIST = 'KOZELDASHBOARD_ACCOUNTS_LIST'

// businessesUser
export const SET_BUSINESSESUSER_LIST = 'SET_BUSINESSESUSER_LIST'
export const SET_BUSINESSESUSER_DETAIL = 'SET_BUSINESSESUSER_DETAIL'
export const SET_BUSINESSES_OPERATORS_LIST = 'SET_BUSINESSES_OPERATORS_LIST'
export const SET_BUSINESSES_NOT_USER_LIST = 'SET_BUSINESSES_NOT_USER_LIST'

// channelGuide
export const SET_CHANNEL_GUIDE_LIST = 'SET_CHANNEL_GUIDE_LIST'
export const SET_CHANNEL_GUIDE_DETAIL = 'SET_CHANNEL_GUIDE_DETAIL'

// category
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST'

// 채널정보
export const SET_CHANNEL_INFO = 'SET_CHANNEL_INFO'
export const SET_CHANNEL_VISIBLE = 'SET_CHANNEL_VISIBLE'

// 알림톡
export const SET_ALIMTALK_TEMPLATE_LIST_TEST = 'SET_ALIMTALK_TEMPLATE_LIST_TEST'
export const SET_ALIMTALK_TEMPLATE_LIST = 'SET_ALIMTALK_TEMPLATE_LIST'
export const SET_ALIMTALK_TEMPLATE_DETAIL = 'SET_ALIMTALK_TEMPLATE_DETAIL'
export const SET_ALIMTALK_RESULT_LIST = 'SET_ALIMTALK_RESULT_LIST'
export const SET_ALIMTALK_SEND_WAITING_LIST = 'SET_ALIMTALK_SEND_WAITING_LIST'

// 출처사
export const SET_BUSINESSES_COMPANY_LIST = 'SET_BUSINESSES_COMPANY_LIST'
export const SET_BUSINESSES_COMPANY_DETAIL = 'SET_BUSINESSES_COMPANY_DETAIL'
