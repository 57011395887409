import { PAGE, PAGE_TITLE } from '@@routes'

export default {
  path: '/settlement',
  name: PAGE.SETTLEMENT,
  component: () => import(/* webpackChunkName: "settlement" */'@page/Settlement/Index.vue'),
  meta: {
    title: PAGE_TITLE.SETTLEMENT,
    serviceName: PAGE.SETTLEMENT,
    useIcon: 'ri-calculator-line'
  },
  children: [
    {
      path: 'regular',
      name: PAGE.SETTLEMENT_REGULAR,
      redirect: '/settlement/regular/list',
      component: () => import(/* webpackChunkName: "settlement" */ '@page/Settlement/Index.vue'),
      meta: {
        title: PAGE_TITLE.SETTLEMENT_REGULAR,
        menuName: PAGE.SETTLEMENT_REGULAR,
        roleKeys: { menu: 'calculate', subMenu: 'regularCalculate' }
      },
      children: [
        {
          path: 'list',
          name: PAGE.SETTLEMENT_REGULAR_LIST,
          component: () => import(/* webpackChunkName: "settlement" */ '@page/Settlement/List.vue')
        },
        {
          path: 'manage/:id?',
          name: PAGE.SETTLEMENT_REGULAR_MANAGE,
          component: () => import(/* webpackChunkName: "settlement" */ '@page/Settlement/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.SETTLEMENT_REGULAR_MANAGE
          }
        }
      ]
    },
    {
      path: 'irregular',
      name: PAGE.SETTLEMENT_IRREGULAR,
      redirect: '/settlement/irregular/list',
      component: () => import(/* webpackChunkName: "settlement" */ '@page/Settlement/Index.vue'),
      meta: {
        title: PAGE_TITLE.SETTLEMENT_IRREGULAR,
        menuName: PAGE.SETTLEMENT_IRREGULAR,
        roleKeys: { menu: 'calculate', subMenu: 'irregularCalculate' }
      },
      children: [
        {
          path: 'list',
          name: PAGE.SETTLEMENT_IRREGULAR_LIST,
          component: () => import(/* webpackChunkName: "settlement" */ '@page/Settlement/List.vue')
        },
        {
          path: 'manage/:id',
          name: PAGE.SETTLEMENT_IRREGULAR_MANAGE,
          component: () => import(/* webpackChunkName: "settlement" */ '@page/Settlement/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.SETTLEMENT_IRREGULAR_MANAGE
          }
        }
      ]
    }
  ]
}
