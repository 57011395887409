import axios from '@/plugins/axios'
import {
  SET_TERMS_LIST,
  SET_TERMS_DETAIL
} from '@/store/mutation_types'

const state = {
  listModel: {},
  detailModel: {}
}

const getters = {
  listData: state => state.listModel,
  detailData: state => state.detailModel
}

const actions = {
  async getTermsList ({ commit }, { type, params }) {
    const config = {
      method: 'GET',
      url: `/_p/${type}`,
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_TERMS_LIST, response)
    })
  },
  async setTermsDisplayState ({ commit }, { type, terms, data }) {
    const setting = {
      method: 'PATCH',
      url: `/_p/${type}/${terms}/is-display`,
      data: {
        ...data
      }
    }
    await axios(setting)
  },
  async getTermsItemDetail ({ commit }, { type, id }) {
    const config = {
      method: 'GET',
      url: `/_p/${type}/${id}`
    }

    await axios(config).then(response => {
      commit(SET_TERMS_DETAIL, response)
    })
  },
  async createTermsItem ({ commit }, { type, data }) {
    const config = {
      method: 'POST',
      url: `/_p/${type}`,
      data
    }
    const result = await axios(config)
    return result
  },
  async saveTermsItem ({ commit }, { type, id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/${type}/${id}`,
      data
    }
    const result = await axios(setting)
    return result
  },
  async deleteTermsItem ({ commit }, { type, id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/${type}/${id}`
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_TERMS_LIST]: (state, payload) => {
    state.listModel = payload
  },
  [SET_TERMS_DETAIL]: (state, payload) => {
    state.detailModel = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
