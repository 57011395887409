import axios from 'axios'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import { messageModal } from '@/mixins/confirmModal'

const domainType = import.meta.env.VITE_APP_DOMAIN_TYPE ? `_${import.meta.env.VITE_APP_DOMAIN_TYPE}` : ''
const USER_TOKEN = `MEDIANCE_AUTH_TOKEN${domainType}`
const USER_TOKEN_TIME = `MEDIANCE_AUTH_TIME${domainType}`
const USER_TOKEN_REFRESH = `MEDIANCE_AUTH_REFRESH${domainType}`
const IS_APP = 'MEDIANCE_APP'
const DOMAIN = import.meta.env.VITE_APP_DOMAIN || ''

const setAuthInfoCookies = (authInfo) => {
  authInfo.expiresAt = authInfo?.expiresAt?.replace(' ', '+')
  const refreshExpiresAt = authInfo?.refreshExpiresAt?.replace(' ', '+')
  const cookieConfig = {
    path: '/',
    domain: DOMAIN,
    secure: false,
    sameSite: 'Strict'
  }

  if (Cookies.get(USER_TOKEN)) {
    clearAuthCookies()
    if (Cookies.get(USER_TOKEN)) { clearAuthCookies() }
  }

  Cookies.set(USER_TOKEN, authInfo.token, cookieConfig)
  Cookies.set(USER_TOKEN_TIME, authInfo.expiresAt, cookieConfig)
  Cookies.set(USER_TOKEN_REFRESH, authInfo.refreshToken, {
    ...cookieConfig,
    expires: new Date(refreshExpiresAt)
  })

  const isApp = Cookies.get(IS_APP)
  if (isApp) window.Mediance.SetAuthToken(authInfo)
}

const clearAuthCookies = (callback) => {
  const isToken = !!Cookies.get(USER_TOKEN)

  if (callback && isToken) {
    callback()
  }

  const cookieConfig = {
    path: '/',
    domain: DOMAIN,
    secure: false,
    sameSite: 'Strict'
  }

  Cookies.remove(USER_TOKEN, { path: '/' })
  Cookies.remove(USER_TOKEN_TIME, { path: '/' })
  Cookies.remove(USER_TOKEN_REFRESH, { path: '/' })

  Cookies.remove(USER_TOKEN, cookieConfig)
  Cookies.remove(USER_TOKEN_TIME, cookieConfig)
  Cookies.remove(USER_TOKEN_REFRESH, cookieConfig)

  const isApp = Cookies.get(IS_APP)
  if (isApp) window.Mediance.ClearAuthToken()
}

const checkAuthCookies = () => {
  const token = Cookies.get(USER_TOKEN)
  const refreshToken = Cookies.get(USER_TOKEN_REFRESH)

  return !!(token && refreshToken)
}

const refresh = async (config) => {
  let token = Cookies.get(USER_TOKEN)
  const expireAt = Cookies.get(USER_TOKEN_TIME)
  const refreshToken = Cookies.get(USER_TOKEN_REFRESH)

  // 토큰 갱신 서버통신
  if (dayjs(expireAt).diff(dayjs()) <= 0 && refreshToken) {
    const { data } = await axios({
      method: 'GET',
      url: `${import.meta.env.VITE_APP_AUTH_API_URL}/refresh-token-verified`,
      headers: {
        Authorization: `Bearer ${token}`,
        'refresh-token': `Bearer ${refreshToken}`
      }
    })

    token = data.token
    if (token) await setAuthInfoCookies(data)
  }

  if (!token || !refreshToken) {
    delete axios.defaults.headers.common.Authorization
  } else {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

const refreshErrorHandle = () => {
  Cookies.remove(USER_TOKEN_REFRESH)
}

/*
* 필요한 파라미터 정리(로그인, 가입, 탈퇴, 초대)
* 공통: type(join, login): callback, service, type, site(admin service code 다를 때)
* 탈퇴: type(withdraw): email, uid + token
* 초대: type(login): invite, inviteType
*/
const goAuthPage = (type, callbackUrl, options = { isAction: true }) => {
  const query = {
    callback: encodeURIComponent(`${location.protocol}//${location.host}${callbackUrl}`),
    service: options.service || import.meta.env.VITE_APP_AUTH_SERVICE,
    type: options.type || import.meta.env.VITE_APP_AUTH_TYPE
  }

  if (import.meta.env.VITE_APP_AUTH_SITE) query.site = import.meta.env.VITE_APP_AUTH_SITE
  if (options?.email) query.email = options.email
  if (options?.uid) query.uid = options.uid
  if (options?.inviteType) {
    query.inviteType = options?.inviteType
    query.invite = '{token}'
  }
  if (type === 'withdraw') {
    query.token = Cookies.get(USER_TOKEN)
    query.referrer = encodeURIComponent(import.meta.env.VITE_APP_URL)
  }
  const queryParse = Object.entries(query).map(([key, val]) => val ? `${key}=${val}` : '').join('&')

  const url = import.meta.env.VITE_APP_AUTH_URL

  if (options.isAction) {
    location.href = `${url}/${type}?${queryParse}`
  } else {
    const localHostUrl = location.href.includes('localhost') ? 'https://accounts.alloo.cc' : url
    return `${localHostUrl}/${type}?${queryParse}`
  }
}

const goFrontPage = (options = {}) => {
  const callbackDomains = {
    bizcenter: import.meta.env.VITE_APP_BIZ_CENTER_URL,
    mediance: import.meta.env.VITE_APP_FRONT_URL,
    stylemate: import.meta.env.VITE_APP_STYPE_MATE_URL
  }
  const query = {
    callback: encodeURIComponent(options.callback || callbackDomains[options.service]),
    service: options.service === 'stylemate' ? 'mediance' : options.service || import.meta.env.VITE_APP_AUTH_SERVICE,
    type: options.type || import.meta.env.VITE_APP_AUTH_TYPE,
    id: options.id,
    token: Cookies.get(USER_TOKEN)
  }

  const queryParse = Object.entries(query).map(([key, val]) => `${key}=${val}`).join('&')

  const fullUrl = `${import.meta.env.VITE_APP_AUTH_URL}/check?${queryParse}`

  const extensionTarget = document.querySelector('#app')
  const editorExtensionId = extensionTarget.getAttribute('data-extension-id')
  if (editorExtensionId && window.chrome && window.chrome.runtime) {
    // eslint-disable-next-line no-undef
    chrome.runtime.sendMessage(editorExtensionId, { url: fullUrl }, (response) => {
      console.log('response', response.responseMsg)
    })
  } else {
    alert('extension을 실행해주세요.')
    // window.open(`${import.meta.env.VITE_APP_AUTH_URL}/check?${queryParse}`, '_blank')
  }
}

export {
  setAuthInfoCookies,
  clearAuthCookies,
  checkAuthCookies,
  refresh,
  refreshErrorHandle,
  goAuthPage,
  goFrontPage
}
