import dayjs from 'dayjs'

const dateStandardFormatList = (payload) => {
  return payload.map(day => {
    return {
      ...day,
      createdAt: dayjs(day.createdAt).format('YYYY-MM-DD HH:mm')
    }
  })
}
const dateStandardFormat = (payload) => {
  return dayjs(payload).format('YYYY-MM-DD HH:mm')
}
const dateStandardFullFormat = (payload) => {
  return dayjs(payload).format('YYYY-MM-DD HH:mm:00')
}
const dateStandardShortFormat = (payload) => {
  return dayjs(payload).format('YYYY-MM-DD')
}
const dateSimpleFormat = (payload, type = 'eng') => {
  const format = type === 'eng' ? 'YYYY-MM-DD' : 'YYYY년 MM월 DD일'
  return dayjs(payload).format(format)
}
const dateAge = (payload) => {
  return dayjs().format('YYYY') - dayjs(payload).format('YYYY') + 1 || '-'
}

export {
  dateStandardFormatList,
  dateStandardFormat,
  dateStandardFullFormat,
  dateStandardShortFormat,
  dateSimpleFormat,
  dateAge
}
