import * as imageConversion from 'image-conversion'

export default async function imageCompress (target, callback) {
  const targetFiles = []
  for (let index = 0; index < target.length; index++) {
    const res = await imageConversion.compress(target[index], {
      type: target[index].type,
      quality: 0.95
    })
    const blob = res
    const fileExtention = target[index].name.substring(target[index].name.lastIndexOf('.'), target[0].name.length)

    const myFile = new File([blob], `images${index}${fileExtention}`, {
      type: blob.type
    })
    targetFiles.push(myFile)
  }
  await callback(targetFiles)
}
