import { PAGE, PAGE_TITLE, PAGE_TAB } from '@@routes'

export default {
  path: '/stylemate',
  name: PAGE.STYLEMATE,
  redirect: '/stylemate/applyManage',
  component: () => import(/* webpackChunkName: "stylemate" */'@page/Stylemate/Index.vue'),
  meta: {
    title: PAGE_TITLE.STYLEMATE,
    serviceName: PAGE.STYLEMATE,
    useIcon: 'ri-group-line',
    roleKeys: { menu: 'business', subMenu: 'businessManager' }
  },
  children: [
    {
      path: 'applyManage',
      name: PAGE.STYLEMATE_APPLY_MANAGE,
      component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/ApplyManage/Index.vue'),
      meta: {
        title: PAGE_TITLE.STYLEMATE_APPLY_MANAGE,
        menuName: PAGE.STYLEMATE_APPLY_MANAGE
      }
    },
    {
      path: 'productManage',
      name: PAGE.STYLEMATE_PRODUCT_MANAGE,
      redirect: '/stylemate/productManage/list',
      component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/Index.vue'),
      meta: {
        title: PAGE_TITLE.STYLEMATE_PRODUCT_MANAGE,
        menuName: PAGE.STYLEMATE_PRODUCT_MANAGE
      },
      children: [
        {
          path: 'list',
          name: PAGE.STYLEMATE_PRODUCT_MANAGE_LIST,
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/ProductManage/List.vue')
        },
        {
          path: 'form/:id?',
          name: PAGE.STYLEMATE_PRODUCT_MANAGE_FORM,
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/ProductManage/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.STYLEMATE_PRODUCT_MANAGE_FORM
          }
        },
        {
          path: 'like/:id',
          name: PAGE.STYLEMATE_PRODUCT_MANAGE_LIKE,
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/LikeManage/Index.vue'),
          meta: {
            pageTitle: PAGE_TITLE.STYLEMATE_PRODUCT_MANAGE_LIKE
          }
        }
      ]
    },
    {
      path: 'brandManage',
      name: PAGE.STYLEMATE_BRAND_MANAGE,
      redirect: '/stylemate/brandManage/list',
      component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/Index.vue'),
      meta: {
        title: PAGE_TITLE.STYLEMATE_BRAND_MANAGE,
        menuName: PAGE.STYLEMATE_BRAND_MANAGE
      },
      children: [
        {
          path: 'list',
          name: PAGE.STYLEMATE_BRAND_MANAGE_LIST,
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/BrandManage/List.vue')
        },
        {
          path: 'form/:id?',
          name: PAGE.STYLEMATE_BRAND_MANAGE_FORM,
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/BrandManage/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.STYLEMATE_BRAND_MANAGE_FORM
          }
        },
        {
          path: 'like/:id',
          name: PAGE.STYLEMATE_BRAND_MANAGE_LIKE,
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/LikeManage/Index.vue'),
          meta: {
            pageTitle: PAGE_TITLE.STYLEMATE_BRAND_MANAGE_LIKE
          }
        }
      ]
    },
    {
      path: 'frontManage',
      name: PAGE.STYLEMATE_FRONT_MANAGE,
      redirect: '/stylemate/frontManage/banner/home/list',
      component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Index.vue'),
      meta: {
        title: PAGE_TITLE.STYLEMATE_FRONT_MANAGE,
        menuName: PAGE.STYLEMATE_FRONT_MANAGE,
        tabName: PAGE.STYLEMATE_FRONT_MANAGE
      },
      children: [
        {
          path: 'banner/home',
          name: PAGE.STYLEMATE_FRONT_MANAGE_BANNER,
          redirect: '/stylemate/frontManage/banner/home/list',
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/Index.vue'),
          meta: {
            menuName: PAGE_TAB.STYLEMATE_FRONT_MANAGE_BANNER,
            title: PAGE_TAB.STYLEMATE_FRONT_MANAGE_BANNER
          },
          children: [
            {
              path: 'list',
              name: PAGE.STYLEMATE_FRONT_MANAGE_BANNER_LIST,
              component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Banners/List.vue'),
              meta: {
                pageTitle: PAGE_TITLE.STYLEMATE_FRONT_MANAGE_BANNER_LIST,
                tabActiveValue: PAGE.STYLEMATE_FRONT_MANAGE_BANNER
              }
            },
            {
              path: 'ordering',
              name: PAGE.STYLEMATE_FRONT_MANAGE_BANNER_ORDERING,
              component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Banners/Ordering.vue'),
              meta: {
                pageTitle: PAGE_TITLE.STYLEMATE_FRONT_MANAGE_BANNER_ORDERING,
                tabActiveValue: PAGE.STYLEMATE_FRONT_MANAGE_BANNER
              }
            },
            {
              path: 'form/:id?',
              name: PAGE.STYLEMATE_FRONT_MANAGE_BANNER_FORM,
              component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Banners/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.STYLEMATE_FRONT_MANAGE_BANNER_FORM
              }
            }
          ]
        },
        {
          path: 'banner/item',
          name: PAGE.STYLEMATE_FRONT_MANAGE_BANNER_ITEM,
          redirect: '/stylemate/frontManage/banner/item/list',
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/Index.vue'),
          meta: {
            menuName: PAGE_TAB.STYLEMATE_FRONT_MANAGE_BANNER_ITEM,
            title: PAGE_TAB.STYLEMATE_FRONT_MANAGE_BANNER_ITEM
          },
          children: [
            {
              path: 'list',
              name: PAGE.STYLEMATE_FRONT_MANAGE_BANNER_ITEM_LIST,
              component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Banners/List.vue'),
              meta: {
                pageTitle: PAGE_TITLE.STYLEMATE_FRONT_MANAGE_BANNER_ITEM,
                tabActiveValue: PAGE.STYLEMATE_FRONT_MANAGE_BANNER_ITEM
              }
            },
            {
              path: 'ordering',
              name: PAGE.STYLEMATE_FRONT_MANAGE_BANNER_ITEM_ORDERING,
              component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Banners/Ordering.vue'),
              meta: {
                pageTitle: PAGE_TITLE.STYLEMATE_FRONT_MANAGE_BANNER_ITEM_ORDERING,
                tabActiveValue: PAGE.STYLEMATE_FRONT_MANAGE_BANNER_ITEM
              }
            },
            {
              path: 'form/:id?',
              name: PAGE.STYLEMATE_FRONT_MANAGE_BANNER_ITEM_FORM,
              component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Banners/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.STYLEMATE_FRONT_MANAGE_BANNER_ITEM_FORM
              }
            }
          ]
        },
        {
          path: 'product',
          name: PAGE.STYLEMATE_FRONT_MANAGE_PRODUCT,
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Product/Index.vue'),
          meta: {
            pageTitle: PAGE_TITLE.STYLEMATE_FRONT_MANAGE_PRODUCT,
            title: PAGE_TAB.STYLEMATE_FRONT_MANAGE_PRODUCT
          }
        },
        {
          path: 'brand',
          name: PAGE.STYLEMATE_FRONT_MANAGE_BRAND,
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Brand/Index.vue'),
          meta: {
            pageTitle: PAGE_TITLE.STYLEMATE_FRONT_MANAGE_BRAND,
            title: PAGE_TAB.STYLEMATE_FRONT_MANAGE_BRAND
          }
        },
        {
          path: 'content',
          name: PAGE.STYLEMATE_FRONT_MANAGE_CONTENT,
          component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/FrontManage/Content/Index.vue'),
          meta: {
            pageTitle: PAGE_TITLE.STYLEMATE_FRONT_MANAGE_CONTENT,
            title: PAGE_TAB.STYLEMATE_FRONT_MANAGE_CONTENT
          }
        }
      ]
    },
    {
      path: 'categoryManage',
      name: PAGE.STYLEMATE_CATEGORY_MANAGE,
      component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/CategoryManage/Index.vue'),
      meta: {
        title: PAGE_TITLE.STYLEMATE_CATEGORY_MANAGE,
        menuName: PAGE.STYLEMATE_CATEGORY_MANAGE
      }
    }
    // {
    //   path: 'LikeManage',
    //   name: PAGE.STYLEMATE_LIKE_MANAGE,
    //   redirect: '/stylemate/LikeManage/brand',
    //   component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/LikeManage/Index.vue'),
    //   meta: {
    //     title: PAGE_TITLE.STYLEMATE_LIKE_MANAGE,
    //     menuName: PAGE.STYLEMATE_LIKE_MANAGE,
    //     tabName: PAGE.STYLEMATE_LIKE_MANAGE
    //   },
    //   children: [
    //     {
    //       path: 'brand',
    //       name: PAGE.STYLEMATE_LIKE_MANAGE_BRAND,
    //       component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/LikeManage/Brand/Index.vue'),
    //       meta: {
    //         pageTitle: PAGE_TITLE.STYLEMATE_LIKE_MANAGE,
    //         title: PAGE_TAB.STYLEMATE_LIKE_MANAGE_BRAND
    //       }
    //     },
    //     {
    //       path: 'product',
    //       name: PAGE.STYLEMATE_LIKE_MANAGE_PRODUCT,
    //       component: () => import(/* webpackChunkName: "stylemate" */ '@page/Stylemate/LikeManage/Product/Index.vue'),
    //       meta: {
    //         pageTitle: PAGE_TITLE.STYLEMATE_LIKE_MANAGE,
    //         title: PAGE_TAB.STYLEMATE_LIKE_MANAGE_PRODUCT
    //       }
    //     }
    //   ]
    // }
  ]
}
