const PAGE_TITLE = {
  LOGIN: '로그인',
  PASSWORD_RESET: '비밀번호 재설정',
  PASSWORD_CHANGE: '비밀번호 변경',
  INVITATION: '미디언스 어드민으로 초대합니다.',
  JOIN: '회원가입',
  JOIN_COMPUTED: '회원가입 완료',
  // 공통
  COMMON_OPERATOR: '운영자 관리',
  COMMON_OPERATOR_DETAIL: '운영자 상세',
  COMMON_ROLE: '역할 관리',
  COMMON_ROLE_FORM: '역할 등록',
  COMMON_NOTICE: '관리자 알림',
  // 서비스
  SERVICE: '서비스',
  SERVICE_LOGIN_MANAGE: '서비스 관리',
  SERVICE_LOGIN_MANAGE_LIST: '서비스 관리',
  SERVICE_LOGIN_MANAGE_FORM: '서비스 등록',
  SERVICE_MAINTENANCE_MANAGE: '서비스 점검 관리',
  // 비즈니스
  BUSINESS: '비즈니스',
  BUSINESS_USER: '비즈니스 회원 관리',
  BUSINESS_USER_FORM: '비즈니스 회원 등록',
  BUSINESS_USER_DETAIL: '비즈니스 회원 상세',
  BUSINESS_MANAGE: '비즈니스 관리',
  BUSINESS_MANAGE_DETAIL: '비즈니스 상세',
  BUSINESS_AGENCY: '에이전시 관리',
  BUSINESS_AGENCY_DETAIL: '에이전시 상세',
  BUSINESS_DATA_MANAGE: '데이터 관리',
  BUSINESS_COMPANY: '출처사 관리',
  BUSINESS_COMPANY_LIST: '출처사 관리',
  BUSINESS_COMPANY_FORM: '출처사 등록',
  BUSINESS_FORM: '비즈니스 등록',
  BUSINESS_FORM_DETAIL: '비즈니스 상세',

  BCA: 'MBCA',
  BCA_CAMPAIGN: '캠페인 관리',
  BCA_CAMPAIGN_READY_LIST: '대기',
  BCA_CAMPAIGN_DETAIL: '브랜드 콘텐츠 광고 캠페인 검수',
  BCA_CAMPAIGN_PROGRESS_LIST: '진행',
  BCA_CAMPAIGN_FINISH_LIST: '완료',
  BCA_CAMPAIGN_READY_DETAIL_MATERIAL: '소재 정보',
  BCA_CAMPAIGN_FINISH_DETAIL_MATERIAL: '소재 정보',
  BCA_MEDIA: '',
  BCA_MEDIA_FACEBOOK: '페북/인스타그램 관리',
  BCA_MEDIA_FACEBOOK_ACCOUNT_FORM: '페이스북 계정 등록',
  BCA_MEDIA_GOOGLE: '구글 관리',
  BCA_MEDIA_GOOGLE_ACCOUNT_FORM: '구글 계정 등록',
  BCA_MEDIA_MONITORING: '매체 모니터링',

  BCA_REPORT: '광고 리포트',
  BCA_REPORT_PERFORMANCE: '성과지표',
  BCA_REPORT_BUSINESS: '비즈니스 별 통합 리포트',
  BCA_REPORT_MEDIA: '매체 별 리포트',
  BCA_REPORT_MATERIAL: '소재 별 리포트',
  BCA_REPORT_MBP: '[MBP 상품] 별 리포트',
  BCA_REPORT_TRACKING: '매체 추적 리포트',
  BCA_REPORT_AD_PERFORMANCE_DATA: '광고 성과 데이터',

  // 캠페인
  CAMPAIGN: '캠페인',
  CAMPAIGN_WAITING: '대기 캠페인',
  CAMPAIGN_FORM: '캠페인 등록',
  CAMPAIGN_PROGRESS: '진행 캠페인',
  CAMPAIGN_END: '종료 캠페인',
  CAMPAIGN_MANAGE: '캠페인 관리',
  CAMPAIGN_SELF: '셀프캠페인',

  // 인플루언서
  INFLUENCES_MANAGE: '인플루언서 관리',
  INFLUENCES_SEARCH: '인플루언서 검색',
  INFLUENCES_FORM: '인플루언서 등록',
  INFLUENCES_DETAIL: '인플루언서 상세',
  INFLUENCES_CHANNEL: '채널 검색',
  INFLUENCES_TAG_MANAGE: '태그 관리',
  INFLUENCES_COST_SEARCH: '단가 검색',
  // 정산
  SETTLEMENT: '정산',
  SETTLEMENT_REGULAR: '정기 정산',
  SETTLEMENT_REGULAR_MANAGE: '정기 내역 관리',
  SETTLEMENT_IRREGULAR: '비정기 정산',
  SETTLEMENT_IRREGULAR_FORM: '비정기 정산 등록',
  SETTLEMENT_IRREGULAR_MANAGE: '비정기 내역 관리',
  // 통계
  STATISTICS: '통계',
  STATISTICS_DASHBOARD: '통계 대시보드',
  STATISTICS_REPORT: '통계 리포트',
  STATISTICS_REPORT_FORM: '신규 리포트 등록',
  // 운영
  OPERATION: '운영/CS',
  OPERATION_MESSAGE: '메시지 관리',
  OPERATION_ALIMTALK: '알림톡 관리',
  OPERATION_NOTICE: '공지사항 관리',
  OPERATION_NOTICE_FORM: '공지사항 등록',
  OPERATION_MEDIANCE_NOTICE: '와이드',
  OPERATION_STYLEMATE_NOTICE: '스타일메이트',
  OPERATION_MBP_NOTICE: '비즈센터',
  OPERATION_FAQ: 'FAQ 관리',
  OPERATION_FAQ_FORM: 'FAQ 등록',
  OPERATION_MEDIANCE_FAQ: '와이드',
  OPERATION_STYLEMATE_FAQ: '스타일메이트',
  OPERATION_MBP_FAQ: '비즈센터',
  OPERATION_RECOMMEND: '1:1 문의',
  OPERATION_RECOMMEND_ANSWER: '1:1 문의 답변',
  OPERATION_QNAS: '1:1 문의',
  OPERATION_QNAS_FORM: '1:1 등록',
  OPERATION_MEDIANCE_QNAS: '와이드',
  OPERATION_STYLEMATE_QNAS: '스타일메이트',
  OPERATION_MBP_QNAS: '비즈센터',
  OPERATION_CHANNEL_GUIDE: '채널연결 가이드 관리',
  OPERATION_TRACKING: '배송 관리',
  OPERATION_BANNER: '배너 관리',
  OPERATION_BANNER_ORDERING: '배너 순서변경',
  OPERATION_BANNER_FORM: '배너 등록',
  OPERATION_TERMS: '약관 관리',
  OPERATION_TERMS_SERVICE: '이용약관 관리',
  OPERATION_TERMS_SERVICE_FORM: '이용약관 등록',
  OPERATION_TERMS_POLICY: '개인정보처리방침 관리',
  OPERATION_TERMS_POLICY_FORM: '개인정보처리방침 등록',
  OPERATION_TERMS_MARKETING: '마케팅활용약관 관리',
  OPERATION_TERMS_MARKETING_FORM: '마케팅활용약관 등록',
  OPERATION_TERMS_THIRD_PARTY: '제3자 정보제공 관리',
  OPERATION_TERMS_THIRD_PARTY_FORM: '제3자 정보제공 등록',
  OPERATION_TERMS_GUIDELINE: '검수가이드라인 관리',
  OPERATION_TERMS_GUIDELINE_FORM: '검수가이드라인 등록',

  // 홈페이지
  HOMEPAGE: '홈페이지 관리',
  HOMEPAGE_IR: 'IR 관리',
  HOMEPAGE_IR_FORM: 'IR 등록',
  HOMEPAGE_RECOMMEND: '광고 문의',
  HOMEPAGE_ADVERTISE_RECOMMEND: '광고 문의',
  HOMEPAGE_ADVERTISE_RECOMMEND_ANSWER: '광고 문의 답변',
  HOMEPAGE_PRODUCTION_RECOMMEND: '제작 문의',
  HOMEPAGE_PRODUCTION_RECOMMEND_ANSWER: '제작 문의 답변',
  HOMEPAGE_AGENCY_RECOMMEND: '에이전시 문의',
  HOMEPAGE_AGENCY_RECOMMEND_ANSWER: '에이전시 문의 상세',

  // 쇼핑호스트
  LIVE_CAST: '라이브 커머스 관리',
  LIVE_CAST_SHOW_HOST: '쇼호스트 관리',
  LIVE_CAST_SHOW_HOST_FORM: '쇼호스트 등록',
  LIVE_CAST_SHOW_HOST_DETAIL: '쇼호스트 상세',
  LIVE_CAST_STUDIO: '스튜디오 관리',

  // 스타일메이트
  STYLEMATE: '스타일메이트',
  STYLEMATE_APPLY_MANAGE: '승인요청관리',
  STYLEMATE_PRODUCT_MANAGE: '제품 관리',
  STYLEMATE_PRODUCT_MANAGE_FORM: '제품 등록',
  STYLEMATE_PRODUCT_MANAGE_LIKE: '제품 관리 - 좋아요',
  STYLEMATE_BRAND_MANAGE: '브랜드 관리',
  STYLEMATE_BRAND_MANAGE_FORM: '브랜드 등록',
  STYLEMATE_BRAND_MANAGE_LIKE: '브랜드 관리 - 좋아요',
  STYLEMATE_FRONT_MANAGE: '프론트 관리',
  STYLEMATE_FRONT_MANAGE_BANNER: '배너관리',
  STYLEMATE_FRONT_MANAGE_BANNER_LIST: '배너 관리(홈)',
  STYLEMATE_FRONT_MANAGE_BANNER_FORM: '배너 등록(홈)',
  STYLEMATE_FRONT_MANAGE_BANNER_ORDERING: '배너 순서 관리(홈)',
  STYLEMATE_FRONT_MANAGE_BANNER_ITEM: '배너관리(아이템)',
  STYLEMATE_FRONT_MANAGE_BANNER_ITEM_FORM: '배너 등록(아이템)',
  STYLEMATE_FRONT_MANAGE_BANNER_ITEM_ORDERING: '배너 순서 관리(아이템)',
  STYLEMATE_FRONT_MANAGE_PRODUCT: '신규제품관리',
  STYLEMATE_FRONT_MANAGE_BRAND: '신규브랜드관리',
  STYLEMATE_FRONT_MANAGE_CONTENT: '콘텐츠 관리',
  STYLEMATE_LIKE_MANAGE: '좋아요 관리',
  STYLEMATE_LIKE_MANAGE_BRAND: '브랜드',
  STYLEMATE_LIKE_MANAGE_PRODUCT: '제품',
  STYLEMATE_CATEGORY_MANAGE: '카테고리관리',

  // 상품
  PRODUCT: '상품',
  PRODUCT_MANAGE: '상품 관리',
  PRODUCT_MANAGE_LIST: '상품 관리',
  PRODUCT_MANAGE_FORM: '상품 등록',
  PRODUCT_ORDER: '상품주문내역',
  PRODUCT_ORDER_LIST: '상품주문내역',
  PRODUCT_ORDER_MANAGE: '상품주문내역상세',
  PRODUCT_CREATOR: '크리에이터 1인당 반영',

  // 셀프캠페인 알림
  COMMON_SELF_CAMPAIGN: '셀프캠페인 알림'
}

export default PAGE_TITLE
