import axios from '@/plugins/axios'

const state = {}

const getters = {}

const actions = {
  async changeUserPassword ({ commit }, { id, data }) {
    const setting = {
      method: 'PATCH',
      url: `/users/${id}/password`,
      params: {
        ...data
      }
    }
    const result = await axios(setting)
    return result
  },
  async deleteUser ({ commit }, id) {
    const config = {
      method: 'DELETE',
      url: `/_p/administrators/${id}`
    }

    await axios(config)
  },
  // 회원 연락처 인증 토큰(문자발송)
  async setUserTelAuth ({ commit, rootGetters }, data) {
    const setting = {
      method: 'POST',
      url: '/tel-auth/',
      data: {
        ...data
      }
    }
    const result = await axios(setting)
    return result
  },
  // 회원 연락처 인증 코드 확인
  async getUserTelAuthConfirm ({ commit, rootGetters }, data) {
    const setting = {
      method: 'GET',
      url: '/tel-auth-confirm/',
      params: {
        ...data
      }
    }
    const result = await axios(setting)
    return result
  },
  async updateUserInfo ({ commit, rootGetters }, data) {
    const uid = rootGetters['auth/info'].id
    const setting = {
      method: 'PUT',
      url: `/_p/administrators/${uid}`,
      data: {
        name: rootGetters['auth/info'].name,
        status: rootGetters['auth/info'].status,
        ...data
      }
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
