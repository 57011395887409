import axios from '@/plugins/axios'
import {
  SET_BOOKINGS_LIST,
  SET_JOINS_LIST,
  SET_POST_LIST,
  SET_POST_DETAIL,
  SET_BOOKING_DETAIL,
  SET_MEMO_LIST
} from '@/store/mutation_types'

const state = {
  memoModel: [],
  bookingsModel: {},
  bookingMeta: null,
  joinsModel: {},
  joinsMeta: null,
  postModel: null,
  postsMeta: null,
  postDetail: null,
  bookingDetail: null
}

const getters = {
  memoListData: state => state.memoModel,
  bookingsList: (state) => {
    return state.bookingsModel
  },
  joinsList: (state) => {
    return state.joinsModel
  },
  postList: (state) => {
    return state.postModel
  },
  bookingMeta: state => state.bookingMeta,
  postMeta: state => state.postsMeta,
  postDetail: state => state.postDetail,
  bookingDetail: state => state.bookingDetail
}

const actions = {
  // 신청자 목록
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__bookings
  async getBookings ({ commit }, { id, filters }) {
    const config = {
      method: 'GET',
      url: `/_p/campaign-manages/${id}/bookings`,
      params: {
        perPage: 9999,
        page: 1,
        filters: { ...filters }
      }
    }

    await axios(config).then((response) => {
      commit(SET_BOOKINGS_LIST, response)
    })
  },

  // 참여자목록
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__joins
  async getJoins ({ commit }, { id, filters }) {
    const config = {
      method: 'GET',
      url: `/_p/campaign-manages/${id}/joins`,
      params: {
        perPage: 9999,
        page: 1,
        filters: { ...filters }
      }
    }

    await axios(config).then((response) => {
      commit(SET_JOINS_LIST, response)
    })
  },

  // 캠페인 신청자 목록 다운로드
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__booking_download
  async bookingDownload ({ commit }, { id, params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: `/_p/campaign-manages/${id}/booking-download`,
      params: {
        ...params
      }
    }
    const res = await axios(config)
    return res
  },

  // 캠페인 신청자 필터 목록 다운로드
  // https://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/post__p_campaign_manages__campaign__booking_filter_download
  async bookingFilterDownload ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      responseType: 'blob',
      url: `/_p/campaign-manages/${id}/booking-filter-download`,
      data: {
        ...data
      }
    }
    const res = await axios(config)
    return res
  },

  // 캠페인 참여자 목록 다운로드
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__booking_download
  async joinDownload ({ commit }, { id, params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: `/_p/campaign-manages/${id}/join-download`,
      params: {
        ...params
      }
    }
    const res = await axios(config)
    return res
  },

  // 캠페인 진행관리 목록 다운로드
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__booking_download
  async postDownload ({ commit }, { id, params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: `/_p/campaign-manages/${id}/post-download`,
      params: {
        ...params
      }
    }
    const res = await axios(config)
    return res
  },

  async postStatDownload ({ commit }, { id, params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: `/_p/campaign-manages/${id}/post-stat-download`,
      params: {
        ...params
      }
    }
    const res = await axios(config)
    return res
  },

  // 신청자 > 참여자 변경
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/patch__p_campaign_manages__campaign__booking_to_joins
  async bookingToJoins ({ commit }, { id, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaign-manages/${id}/booking-to-joins`,
      data
    }

    await axios(config)
  },
  // 신청자 > 제외자 변경
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/patch__p_campaign_manages__campaign__booking_to_joins
  async bookingToExcludes ({ commit }, { id, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaign-manages/${id}/booking-to-excludes`,
      data
    }

    await axios(config)
  },
  // 참여자 목록
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__joins
  async joinToBookings ({ commit }, { id, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaign-manages/${id}/join-to-bookings`,
      data
    }

    await axios(config)
  },

  // 송장번호 업로드
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/post__p_campaign_manages__campaign__join_delivery_upload
  async deliveryUpload ({ commit }, { id, file }) {
    const config = {
      method: 'POST',
      data: file,
      url: `/_p/campaign-manages/${id}/join-delivery-upload`
    }

    await axios(config)
  },

  // 참여자 송장번호 등록
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/post__p_campaign_manages__campaign__bookings__booking__delivery
  async createDelivery ({ commit }, { campaign, booking, params, data }) {
    const config = {
      method: 'POST',
      url: `/_p/campaign-manages/${campaign}/bookings/${booking}/delivery`,
      data
    }

    await axios(config)
  },
  // 참여자 일괄 등록
  // 주소
  async addAllJoinsUploads ({ commit }, { campaign, file }) {
    const config = {
      method: 'POST',
      url: `/_p/campaign-manages/${campaign}/upload-excel-joins`,
      data: file
    }

    await axios(config)
  },

  // 캠페인 세부진행상태 변경
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/patch__p_campaign_manages__campaign__process_detail_status
  async processDetailStatus ({ commit }, { id, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaign-manages/${id}/process-detail-status`,
      data
    }

    await axios(config)
  },

  async processStatusFinish ({ commit }, { id, isProcessCancel }) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaign-manages/${id}/process-status-finish`,
      data: {
        isProcessCancel
      }
    }

    await axios(config)
  },

  // 캠페인 진행관리목록
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__posts
  async getPostList ({ commit }, { id, filters }) {
    const config = {
      method: 'GET',
      url: `/_p/campaign-manages/${id}/posts`,
      params: {
        perPage: 9999,
        page: 1,
        filters: { ...filters }
      }
    }

    await axios(config).then((response) => {
      commit(SET_POST_LIST, response)
    })
  },

  // 캠페인 포스트 상세
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__posts__post_
  async getPostDetail ({ commit }, { campaign, post, params }) {
    const config = {
      method: 'GET',
      url: `/_p/campaign-manages/${campaign}/posts/${post}`,
      params: {
        perPage: 9999,
        page: 1,
        order: '',
        sort: '',
        ...params
      }
    }

    await axios(config).then((response) => {
      commit(SET_POST_DETAIL, response)
    })
  },

  // 지급포인트변경
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/put__p_campaign_manages__campaign__bookings__booking__confirm_point
  async setConfirmPoint ({ commit }, { campaign, booking, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/campaign-manages/${campaign}/bookings/${booking}/confirm-point`,
      data
    }

    await axios(config)
  },

  // 지급포인트 일괄 변경
  //
  async setConfirmSelectedPoint ({ commit }, { campaign, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaign-manages/${campaign}/bookings/confirm-point`,
      data
    }

    await axios(config)
  },

  //  참여자 포스트 상태 관리
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/put__p_campaign_manages__campaign__bookings__booking__post_status
  async setPostStatus ({ commit }, { campaign, booking, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/campaign-manages/${campaign}/bookings/${booking}/post-status`,
      data
    }

    const res = await axios(config)
    return res
  },

  // 캠페인 관리 부킹상세
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__bookings__booking_
  async getBookingDetail ({ commit }, { campaign, booking }) {
    const config = {
      method: 'GET',
      url: `/_p/campaign-manages/${campaign}/bookings/${booking}`
    }

    await axios(config).then((response) => {
      commit(SET_BOOKING_DETAIL, response)
    })
  },

  // 캠페인 채널 -> 참여자 생성
  // http://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/post__p_campaign_manages__campaign__channel_to_joins
  async setChannelToJoins ({ commit }, { campaign, channels }) {
    const config = {
      method: 'POST',
      url: `/_p/campaign-manages/${campaign}/channel-to-joins`,
      data: channels
    }

    const res = await axios(config)
    return res
  },

  // 참여자 포스트 히스토리 목록
  // https://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/get__p_campaign_manages__campaign__bookings__booking__post_histories
  async getPostHistory ({ commit }, { campaign, booking }) {
    const config = {
      method: 'GET',
      url: `/_p/campaign-manages/${campaign}/bookings/${booking}/post-histories`
    }

    const res = await axios(config)
    return res
  },

  async getInstagramMedia ({ commit }, { token, fbId, limit, after }) {
    const config = {
      method: 'GET',
      url: `https://graph.facebook.com/v10.0/${fbId}/media`,
      params: {
        access_token: token,
        limit,
        after
      }
    }
    const result = await axios(config)
    return result
  },

  async getInstagramMediaDetail ({ commit }, { token, mediaId }) {
    const config = {
      method: 'GET',
      url: `https://graph.facebook.com/v10.0/${mediaId}`,
      params: {
        fields: 'caption,comments_count,id,ig_id,is_comment_enabled,like_count,media_product_type,media_type,media_url,owner,permalink,shortcode,thumbnail_url,timestamp,username,video_title',
        access_token: token
      }
    }
    const result = await axios(config)
    return result
  },

  async getInstagramInfo ({ commit }, { token, fbId }) {
    const config = {
      method: 'GET',
      url: `https://graph.facebook.com/v10.0/${fbId}`,
      params: {
        access_token: token,
        fields: 'biography,id,ig_id,followers_count,follows_count,media_count,name,profile_picture_url,username,website'
      }
    }
    const result = await axios(config)
    return result
  },

  // 참여자 포스트 등록
  // https://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%EC%BA%A0%ED%8E%98%EC%9D%B8%20%EA%B4%80%EB%A6%AC/post__p_campaign_manages__campaign__bookings__booking__instagram_post
  async setJoinInstagramPost ({ commit }, { campaign, booking, data, isCreate }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaign-manages/${campaign}/bookings/${booking}/instagram-post`,
      data: {
        ...data
      }
    }

    const result = await axios(config)
    return result
  },

  async setJoinInstagramPostLink ({ commit }, { campaign, booking, data, isCreate }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaign-manages/${campaign}/bookings/${booking}/instagram-post-link`,
      data: {
        ...data
      }
    }

    const result = await axios(config)
    return result
  },

  async setJoinYoutubePostLink ({ commit }, { campaign, booking, data, isCreate }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaign-manages/${campaign}/bookings/${booking}/youtube-post-link`,
      data: {
        ...data
      }
    }

    const result = await axios(config)
    return result
  },

  async isDisplay ({ commit }, { campaign, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaign-manages/${campaign}/is-display`,
      data: {
        ...data
      }
    }

    const result = await axios(config)
    return result
  },

  async readyPostStatus ({ commit }, { campaign, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaign-manages/${campaign}/bookings/ready-post-status`,
      data: {
        ...data
      }
    }

    const result = await axios(config)
    return result
  },

  async isExclusive ({ commit }, { campaign }) {
    const config = {
      method: 'GET',
      url: `/_p/campaign-manages/${campaign}/bookings/is-exclusive`
    }

    const res = await axios(config)
    return res
  },

  // 포스트 평가
  // https://api.alloo.cc/docs/admin/index.html#/(%EA%B4%80%EB%A6%AC%EC%9E%90)%20%ED%8F%AC%EC%8A%A4%ED%8A%B8%20%EA%B4%80%EB%A6%AC/post__p_posts__post__review
  async postReview  ({ commit }, { postId, data }) {
    const config = {
      method: 'POST',
      url: `/_p/posts/${postId}/review`,
      data
    }

    await axios(config)
  },
  async postReviewEdit  ({ commit }, { postId, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/posts/${postId}/review`,
      data
    }

    await axios(config)
  },

  /* 메모 */
  async getMemoList ({ commit }, id) {
    const config = {
      method: 'GET',
      url: `/_p/campaign-manages/${id}/memos`,
      params: {
        perPage: 9999,
        page: 1
      }
    }
    await axios(config).then((response) => {
      commit(SET_MEMO_LIST, response)
    })
  },
  async saveMemoItem ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/campaign-manages/${id}/memos`,
      data
    }

    await axios(config)
  },
  async deleteMemoItem ({ commit }, { id, memoId }) {
    const config = {
      method: 'DELETE',
      url: `/_p/campaign-manages/${id}/memos/${memoId}`
    }

    await axios(config)
  },
  async getTrackingList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/campaign-manages/delivery',
      params: {
        filters: {},
        ...params
      }
    }

    const res = await axios(config)
    return res
  },
  async postContentsDownload ({ commit }, { campaignId, params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: `/_p/campaign-manages/${campaignId}/contents-download`,
      params: {
        ...params
      }
    }
    axios.defaults.timeout = 60 * 1000 * 10
    const res = await axios(config)
    axios.defaults.timeout = 60 * 1000
    return res
  }
}

const mutations = {
  [SET_MEMO_LIST]: (state, payload) => {
    state.memoModel = payload
  },
  [SET_BOOKINGS_LIST]: (state, payload) => {
    state.bookingsModel = payload
  },
  [SET_JOINS_LIST]: (state, payload) => {
    state.joinsModel = payload
  },
  [SET_POST_LIST]: (state, payload) => {
    state.postModel = payload
  },
  [SET_POST_DETAIL]: (state, payload) => {
    state.postDetail = payload
  },
  [SET_BOOKING_DETAIL]: (state, payload) => {
    state.bookingDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
