<template>
  <editor
    v-model="editorData"
    api-key="dxjv7rexabbqliwfac59lhn4gdawdip5lajsi5y4xkdtv93e"
    :init="ediorConfig"
    :disabled="props.disabled"
  >
  </editor>
</template>

<script setup>
import { computed, reactive } from 'vue'
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
import dayjs from 'dayjs'

const props = defineProps({
  modelValue: {
    type: String,
    require: true,
    default: ''
  },
  disabled: {
    type: Boolean,
    require: false,
    default: false
  },
  bodyFile: {
    type: Array,
    default: () => []
  },
  isMedia: {
    type: Boolean,
    default: true
  },
  height: {
    type: Number,
    default: null
  }
})

const emit = defineEmits([
  'update:modelValue',
  'update:bodyFiles',
  'change'
])

const editorData = computed({
  get: () => props.modelValue || props.value,
  set: (value) => {
    emit('update:modelValue', value)
    emit('change', value)
  }
})

const bodyFiles = computed({
  get: () => props.bodyFile,
  set: (value) => emit('update:bodyFiles', value)
})

const ediorConfig = reactive({
  height: props.height || (props.isMedia ? 500 : 240),
  menubar: false,
  plugins: [
    `advlist autolink lists link ${props.isMedia ? 'image imagetools' : ''} charmap`,
    'searchreplace visualblocks code fullscreen',
    `print preview anchor insertdatetime ${props.isMedia ? 'media' : ''}`,
    'paste code help wordcount table emoticons'
  ],
  toolbar: `formatselect | fontsizeselect | forecolor backcolor |
        bold italic link | charmap emoticons |
        alignleft aligncenter alignright |
        table image media`,

  language: 'ko_KR',
  fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
  contextmenu: 'link image table',
  icons: 'material',
  body_class: 'editor-contents',
  content_css: '/editor-contents.css',
  content_style: 'body { font-family:\'Noto Sans KR\', Arial,sans-serif; font-size:14px }',
  images_upload_handler: function (blobInfo, success, failure, progress) {
    let url = ''
    let key = ''

    const getPreSignedUrl = async (fileName, file) => {
      const fileNameToTimeStamp = fileName.replace(fileName.substr(0, fileName.lastIndexOf('.')), dayjs().valueOf())
      const config = {
        method: 'GET',
        url: `${import.meta.env.VITE_APP_API_URL}/uploads/pre-signed-url`,
        params: {
          fileName: fileNameToTimeStamp,
          contentType: file.type
        }
      }
      await axios(config).then(response => {
        url = response.data.url
        key = response.data.key
      })
    }

    const uploadSignedUrl = async ({ url, file }) => {
      const readFile = (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => { resolve(reader.result) })
          reader.readAsArrayBuffer(file)
        })
      }

      const data = await readFile(file)
      await fetch(url, { method: 'PUT', body: data })
    }

    getPreSignedUrl(blobInfo.blob().name, blobInfo.blob()).then(async () => {
      await uploadSignedUrl({
        url: url,
        file: blobInfo.blob()
      })
      bodyFiles.value.push(`${import.meta.env.VITE_APP_S3_URL}/${key}`)
      success(`${import.meta.env.VITE_APP_S3_URL}/${key}`)
    })
  }
})
</script>

<style lang="scss">
textarea {
  width: 100%;
  border: 1px solid $gray-light2;
}

.tox.tox-tinymce {
  margin: 0;
  border-color: $gray-light2;

  .tox-toolbar,
  .tox-toolbar__overflow,
  .tox-toolbar__primary {
    background: none;
    border-bottom: 1px solid $gray-light2;
  }

  .tox-statusbar {
    border-top-color: $gray-light2;
  }
}
</style>
