import axios from '@/plugins/axios'
import {
  SET_CAMPAIGN_LIST,
  SET_CAMPAIGN_DETAIL,
  SET_CAMPAIGN_DETAIL_LOAD,
  SET_CAMPAIGN_DETAIL_RESET,
  SET_CAMPAIGN_LIST_EXCLUSIVE,
  SET_CAMPAIGN_PROCESS,
  SET_CAMPAIGN_FINISH_LIST
} from '@/store/mutation_types'
import { objectToString } from '@/utils/object'
import { dateStandardFormatList } from '@/utils/date'

const state = {
  campaignModel: [],
  loadStatus: 0,
  isDetail: false,
  detailData: {},
  currentProcess: 'essential',
  exclusiveModel: {},
  finishListModel: {}
}

const getters = {
  listData: state => state.campaignModel,
  list: state => state.campaignModel.data,
  listCount: state => state.campaignModel.meta?.total,
  finishListData: state => state.finishListModel,
  paging: state => state.campaignModel.meta,
  exclusiveData: state => state.exclusiveModel,
  loadStatus: state => state.loadStatus,
  detailData: state => state.detailData,
  campaignUid: state => state.detailData?.uid,
  processChecked: state => {
    const loadCheck = (val) => {
      return state.loadStatus === 0 ? !!val : false
    }

    const isPropose = state.detailData?.bookingType === 'propose'
    const isStylemate = state.detailData?.serviceType === 'stylemate'

    const returnValue = {
      essential: {
        base: !!state.isDetail
      },
      basic: {
        base: loadCheck(state.detailData?.business),
        intro: loadCheck(state.detailData?.campaignIntro),
        schedule: loadCheck(state.detailData?.campaignSchedule),
        booking: loadCheck(state.detailData?.campaignBooking)
      },
      mission: {
        base: loadCheck(state.detailData?.campaignMission)
      },
      etc: {
        reward: loadCheck(state.detailData?.campaignReward),
        contract: loadCheck(state.detailData?.campaignContract)
      }
    }

    if (isPropose) delete returnValue.etc.contract
    if (isStylemate) delete returnValue.basic.intro

    return returnValue
  },
  isDetail: state => state.isDetail,
  isPropose: state => state.detailData?.bookingType === 'propose',
  isDelivery: state => state.detailData?.isDelivery,
  serviceType: state => state.detailData?.serviceType,
  currentProcess: state => state.detailData?.processStatus,
  channelType: state => state.detailData?.channelType,
  useConfirm: state => {
    return state.detailData?.campaignMission?.useConfirm
  },
  campaignBooking: state => state.detailData?.campaignBooking,
  bookingCount: state => state.detailData?.campaignBooking.bookingCount,
  processDetailStatus: state => state.detailData?.processDetailStatus,
  campaignCount: (state, getters, rootState, rootGetters) => {
    return {
      bookingCount: {
        total: state.detailData?.campaignBooking?.bookingCount,
        current: state.detailData?.campaignStat?.bookingCount - state.detailData?.campaignStat?.joinCount
      },
      joinCount: {
        current: state.detailData?.campaignStat?.joinCount
      },
      postCount: {
        current: state.detailData?.campaignStat?.joinCount
      }
    }
  },
  manageInfo: state => {
    return {
      makeSection: state.detailData?.makeSection,
      managerUser: state.detailData?.managerUser,
      selfCampaign: state.detailData?.selfCampaign,
      serviceType: state.detailData?.serviceType,
      channelType: state.detailData?.channelType,
      imageThumbnailPath: state.detailData?.imageThumbnailPath,
      name: state.detailData?.name,
      campaignSchedule: state.detailData?.campaignSchedule,
      bookingType: state.detailData?.bookingType,
      postType: state.detailData?.campaignMission?.postType,
      processStatus: state.detailData?.processStatus,
      processDetailStatus: state.detailData?.processDetailStatus,
      progressType: state.detailData?.progressType,
      isDelivery: state.detailData?.isDelivery,
      isDisplay: state.detailData?.isDisplay,
      isProcessCancel: state.detailData?.isProcessCancel,
      bookingInfo: {
        campaignBooking: state.detailData?.campaignBooking?.bookingCount,
        campaignStat: state.detailData?.campaignStat?.bookingCount,
        joinCount: state.detailData?.campaignStat?.joinCount
      },
      progressInfo: {
        postCount: state.detailData?.campaignStat?.postCount,
        likeCount: state.detailData?.campaignStat?.likeCount,
        commentCount: state.detailData?.campaignStat?.commentCount
      },
      business: state.detailData?.business,
      businessId: state.detailData?.businessId,
      brand: state.detailData?.brand?.[0],
      uid: state.detailData?.uid,
      id: state.detailData?.id,
      product: state.detailData?.product
    }
  },
  /* formData */
  essential: state => {
    return {
      makeSection: state.detailData?.makeSection,
      orderProductTitle: state.detailData?.selfCampaign?.businessOrderProduct.productTitle,
      managerUserId: state.detailData?.modifyManagerUserId ? [state.detailData?.managerUserId] : [],
      managerUser: state.detailData?.managerUser,
      processStatus: state.detailData?.processStatus,
      name: state.detailData?.name,
      bookingType: state.detailData?.bookingType,
      channelType: state.detailData?.channelType,
      isDelivery: state.detailData?.isDelivery,
      progressType: state.detailData?.progressType,
      currentStep: state.currentProcess,
      isGuestDisplay: state.detailData?.isGuestDisplay,
      serviceType: state.detailData?.serviceType,
      product: state.detailData?.product,
      productId: state.detailData?.productId,
      brandId: state.detailData?.brandId,
      businessId: state.detailData?.businessId,
      campaignOption: state.detailData?.campaignOption
    }
  },
  basicBase: state => {
    const data = state.detailData
    if (!data || !data?.type) {
      return {
        channelType: data.channelType,
        serviceType: data.serviceType,
        product: data.product
      }
    } else {
      return {
        makeSection: state.detailData?.makeSection,
        channelType: data.channelType,
        serviceType: data.serviceType,
        isDisplay: data.isDisplay,
        business: data.business,
        businessId: data.businessId,
        brand: objectToString(data.brand, 'tag'),
        type: objectToString(data.type, 'tag')[0],
        category: objectToString(data.category, 'tag'),
        guide: data.guide,
        guideAction: objectToString(data.guideAction, 'tag'),
        imageThumbnailPath: data.imageThumbnailPath,
        imageMainPath: data.imageMainPath,
        product: data.product
      }
    }
  },
  basicIntro: state => {
    const data = state.detailData
    if (!data || !data?.campaignIntro) {
      return {}
    } else {
      return {
        makeSection: state.detailData?.makeSection,
        introduction: data.campaignIntro.introduction,
        description: data.campaignIntro.description
      }
    }
  },
  basicSchedule: state => {
    const data = state.detailData
    if (!data || !data?.campaignSchedule) {
      return {
        serviceType: data.serviceType
      }
    } else {
      return {
        makeSection: state.detailData?.makeSection,
        serviceType: data.serviceType,
        ...data.campaignSchedule,
        customs: data.campaignSchedule.customs ? data.campaignSchedule.customs : []
      }
    }
  },
  basicBooking: state => {
    const data = state.detailData
    if (!data || !data?.campaignBooking) {
      return {
        serviceType: data.serviceType
      }
    } else {
      return {
        makeSection: state.detailData?.makeSection,
        serviceType: data.serviceType,
        ...data.campaignBooking,
        questions: !data.campaignBooking.questions ? [] : data.campaignBooking.questions
      }
    }
  },
  mission: state => {
    const data = state.detailData
    if (!data || !data?.campaignMission) {
      return {
        makeSection: state.detailData?.makeSection,
        channelType: state.detailData?.channelType,
        serviceType: data.serviceType
      }
    } else {
      return {
        makeSection: state.detailData?.makeSection,
        channelType: state.detailData?.channelType,
        serviceType: data.serviceType,
        ...data.campaignMission
      }
    }
  },
  reward: state => {
    const data = state.detailData
    if (!data || !data?.campaignReward) {
      return {
        makeSection: state.detailData?.makeSection
      }
    } else {
      return {
        makeSection: state.detailData?.makeSection,
        productRewardType: state.detailData?.selfCampaign?.businessOrderProduct.productProductRewardType,
        ...data.campaignReward
      }
    }
  },
  contract: state => {
    const data = state.detailData
    if (!data || !data?.campaignContract) {
      return {
        makeSection: state.detailData?.makeSection
      }
    } else {
      return {
        makeSection: state.detailData?.makeSection,
        ...data.campaignContract
      }
    }
  }
}

const actions = {
  setCurrentProcess ({ commit }, process) {
    commit(SET_CAMPAIGN_PROCESS, process)
  },
  setDetailDataLoad ({ commit }, data) {
    commit(SET_CAMPAIGN_DETAIL_LOAD, data)
  },
  setDetailDataReset ({ commit }) {
    commit(SET_CAMPAIGN_DETAIL_RESET)
  },
  async getList ({ commit, rootState }, params) {
    const config = {
      method: 'GET',
      url: '/_p/campaigns',
      params: {
        perPage: 10,
        page: 1,
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_CAMPAIGN_LIST, response)
    })
  },
  async getListExclusive ({ commit }, params) {
    const config = {
      method: 'GET',
      url: `/_p/campaigns/${params}/exclusives`
    }
    await axios(config).then((response) => {
      commit(SET_CAMPAIGN_LIST_EXCLUSIVE, response)
    })
  },
  async getCampaign ({ commit }, id) {
    const config = {
      method: 'GET',
      url: `/_p/campaigns/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_CAMPAIGN_DETAIL, response)
    })
  },
  // 등록
  changeEssentialServiceType ({ state, commit }, type) {
    state.detailData.serviceType = type
  },
  async saveEssential ({ commit }, { isCreate, id, data }) {
    const url = isCreate ? '' : `/${id}`
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaigns${url}`,
      data
    }
    await axios(config).then(res => {
      commit(SET_CAMPAIGN_DETAIL, res)
    })
  },
  async saveBasicBase ({ commit }, { isCreate, id, data }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaigns/${id}/basic`,
      data
    }
    await axios(config).then(res => {
      commit(SET_CAMPAIGN_DETAIL, res)
    })
  },
  async saveBasicIntro ({ commit }, { isCreate, id, data }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaigns/${id}/intro`,
      data
    }
    await axios(config).then(res => {
      commit(SET_CAMPAIGN_DETAIL, res)
    })
  },
  async saveBasicSchedule ({ commit }, { isCreate, id, data }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaigns/${id}/schedule`,
      data
    }
    await axios(config).then(res => {
      commit(SET_CAMPAIGN_DETAIL, res)
    })
  },
  async saveBasicBooking ({ commit }, { isCreate, id, data }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaigns/${id}/booking`,
      data
    }
    await axios(config).then(res => {
      commit(SET_CAMPAIGN_DETAIL, res)
    })
  },
  async saveMission ({ commit }, { isCreate, id, data }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaigns/${id}/mission`,
      data
    }
    await axios(config).then(res => {
      commit(SET_CAMPAIGN_DETAIL, res)
    })
  },
  async saveReward ({ commit }, { isCreate, id, data }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaigns/${id}/reward`,
      data
    }
    await axios(config).then(res => {
      commit(SET_CAMPAIGN_DETAIL, res)
    })
  },
  async saveContract ({ commit }, { isCreate, id, data }) {
    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: `/_p/campaigns/${id}/contract`,
      data
    }
    await axios(config).then(res => {
      commit(SET_CAMPAIGN_DETAIL, res)
    })
  },
  // 관리
  async changeToProgress ({ commit }, id) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaigns/${id}/process-status-progress`
    }
    const result = await axios(config)
    return result
  },
  async deleteCampaign ({ commit }, id) {
    const config = {
      method: 'DELETE',
      url: `/_p/campaigns/${id}`
    }
    const result = await axios(config)
    return result
  },
  async listDownload ({ commit }, params) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: '/_p/campaigns/download',
      params: {
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  async getFinishList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '_p/campaigns/finished',
      params: {
        perPage: 10,
        page: 1,
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_CAMPAIGN_FINISH_LIST, response)
    })
  },
  async finishReportDownload ({ commit }, { params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: '_p/campaign-manages/finish-report-download',
      params: {
        ...params
      }
    }
    axios.defaults.timeout = 60 * 1000 * 10
    const res = await axios(config)
    axios.defaults.timeout = 60 * 1000
    return res
  },

  /* manage user */
  async setManagerUser ({ commit }, { id, data }) {
    const config = {
      method: 'PATCH',
      url: `/_p/campaign-manages/${id}/manager-user`,
      data
    }

    await axios(config).then(res => {
      commit(SET_CAMPAIGN_DETAIL, res)
    })
  },

  async campaignContentsDownload ({ commit }, { params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: '/_p/campaigns/contents-download',
      params: {
        filters: {
          ...params
        }
      }
    }
    axios.defaults.timeout = 60 * 1000 * 10
    const res = await axios(config)
    axios.defaults.timeout = 60 * 1000
    return res
  }
}

const mutations = {
  [SET_CAMPAIGN_LIST]: (state, payload) => {
    payload.data = dateStandardFormatList(payload.data)
    state.campaignModel = payload
  },
  [SET_CAMPAIGN_LIST_EXCLUSIVE]: (state, payload) => {
    state.exclusiveModel = payload.map(item => item.campaign)
  },
  [SET_CAMPAIGN_DETAIL]: (state, payload) => {
    state.loadStatus = 0
    state.isDetail = true
    state.detailData = payload
  },
  [SET_CAMPAIGN_DETAIL_LOAD]: (state, payload) => {
    state.loadStatus = state.loadStatus + 1
    if (payload.serviceType === 'stylemate') {
      delete payload.business
      delete payload.businessId
      delete payload.product
      delete payload.productId
      delete payload.brand
      delete payload.brandId
    }
    state.detailData = Object.assign(state.detailData, payload)
  },
  [SET_CAMPAIGN_DETAIL_RESET]: (state) => {
    state.isDetail = false
    state.detailData = {}
  },
  [SET_CAMPAIGN_PROCESS]: (state, payload) => {
    state.currentProcess = payload
  },
  [SET_CAMPAIGN_FINISH_LIST]: (state, payload) => {
    state.finishListModel = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
