import axios from '@/plugins/axios'
import { SET_CHANNEL_LIST, SET_LOST_CONNECTION_LIST } from '@/store/mutation_types'

const state = {
  channelModel: {},
  lostConnectionModel: {}
}

const getters = {
  channelListData: state => state.channelModel,
  channelList: state => state.channelModel.data,
  channelPaging: state => state.channelModel.meta,
  lostConnectionData: state => state.lostConnectionModel
}

const actions = {
  async getChannelList ({ commit, dispatch }, params) {
    const config = {
      method: 'GET',
      url: '/_p/channels',
      params: {
        ...params
      }
    }
    const response = await axios(config)

    // for (const index in response.data) {
    //   const item = response.data[index]
    //   const channelStat = await dispatch('getChannelAnalysis', item.id)
    //   item.channelStat = channelStat
    // }
    commit(SET_CHANNEL_LIST, response)
  },
  async changeChannelCategory ({ commit }, { channel, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/channels/${channel}/category`,
      data: data
    }

    await axios(config)
  },
  async getChannelAnalysis ({ commit }, channelId) {
    const config = {
      method: 'GET',
      url: `/_p/channels/${channelId}/analysis`
    }
    try {
      const response = await axios(config)
      return response
    } catch (e) {
      console.log(e)
    }
  },
  async channelDownload ({ commit }, { params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: '/_p/channels/download',
      params: {
        perPage: 9999,
        ...params
      }
    }
    axios.defaults.timeout = 60 * 1000 * 10
    const res = await axios(config)
    axios.defaults.timeout = 60 * 1000
    return res
  },
  async getLostConnectionList ({ commit }, params) {
    // 연결 유실
    const config = {
      method: 'GET',
      url: '/_p/channels/lost-connection',
      params: {
        perPage: 10,
        page: 1,
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_LOST_CONNECTION_LIST, response)
    })
  }
}

const mutations = {
  [SET_CHANNEL_LIST]: (state, payload) => {
    state.channelModel = payload
  },
  [SET_LOST_CONNECTION_LIST]: (state, payload) => {
    state.lostConnectionModel = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
