import axios from '@/plugins/axios'
import { SET_PERSONALDASHBOARD_LIST } from '@/store/mutation_types'

const state = {
  dashboardModel: []
}

const getters = {

}

const actions = {
  // 캠페인 수
  async getCampaignList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/campaign-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  // 캠페인 지원자 수
  async getBookingList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/campaign-booking-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  // 인플루언서 수
  async getInfluenceList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/influence-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  // 인플루언서 채널
  async getInfluencerChannelList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/influence-channel-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  // 로그인 인플루언서 수
  async getLoginInfluence ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p​/statistics​/influence-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  // 채널 수
  async getChannelList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/channel-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  // 문의 수
  async getQnaList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/qna-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        answered: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  // 채널별 문의 수
  async getQnaTypeList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/qna-type-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  // 출금 금액
  async getBillingList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/billing-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  // 출금 신청자 수
  async getBillingInfluencerList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/billing-influence-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  async getDeletedUserList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/deleted-user-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  },
  async getExpiredPointStatList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/statistics/expired-point-stat',
      params: {
        startDate: '',
        endDate: '',
        statType: '',
        ...params
      }
    }
    const res = await axios(config)
    return res
  }
}

const mutations = {
  [SET_PERSONALDASHBOARD_LIST]: (state, payload) => {
    state.dashboardModel = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
