import { getBankLists } from '@/constants/codes'
import { getTagTypeList } from '@/constants/tabs'
import store from '@/store'

export const useRouterBefore = () => {
  const routerBeforeLoginActions = async (to) => {
    const channelType = store.getters['campaign/channelType']
    switch (to.name) {
      case 'BusinessManageFormBasic':
        await getBankLists()
        break
      case 'InfluencesTagManage':
        await getTagTypeList()
        break
      case 'CampaignProgressManageProgressManage':
        if (!channelType) {
          await store.dispatch('campaign/getCampaign', to.params.id)
        }
        break
    }

    if (to.name?.includes('InfluencesSearch')) {
      await getBankLists()
    }

    const isArrowAccessor = store.getters['auth/isArrowAccessor']
    const myInfoStatus = store.getters['auth/myInfoStatus']

    const { meta: { roleKeys } } = to.matched.find(({ meta }) => meta.roleKeys) || { meta: { roleKeys: '' } }
    const currentMenuRoleKeys = roleKeys
    if (Object.keys(isArrowAccessor).length === 0) {
      await store.dispatch('auth/isArrowAccessor')
    }
    if (!myInfoStatus) {
      await store.dispatch('auth/myInfo')
    }
    await store.dispatch('auth/currentRole', currentMenuRoleKeys)
  }

  return {
    routerBeforeLoginActions
  }
}
