const PAGE_TAB = {
  // 공통
  COMMON_OPERATOR_DETAIL_BASIC: '기본 정보',
  COMMON_OPERATOR_DETAIL_AUTHORITY: '권한 관리',
  COMMON_ROLE_FORM_BASIC: '기본 정보',
  COMMON_ROLE_FORM_AUTHORITY: '권한 관리',
  COMMON_ROLE_FORM_OPERATOR_MANAGE: '운영자 관리',
  COMMON_NOTICE: '트래킹 오류',
  // 비즈니스 관리
  BUSINESS_USER_DETAIL_BUSINESS: '비즈니스',
  BUSINESS_USER_DETAIL_INVITE: '초대 내역',
  BUSINESS_USER_DETAIL_HISTORY: '히스토리',
  BUSINESS_USER_DETAIL_QNA: '1:1 문의',
  BUSINESS_MANAGE_DETAIL_BASIC: '기본정보',
  BUSINESS_MANAGE_DETAIL_CAMPAIGN: '캠페인',
  BUSINESS_MANAGE_DETAIL_MEMBER: '멤버',
  BUSINESS_MANAGE_DETAIL_GOODS: '상품',
  BUSINESS_MANAGE_DETAIL_BRAND: '브랜드',
  BUSINESS_MANAGE_DETAIL_PRODUCT: '제품',
  BUSINESS_MANAGE_DETAIL_HISTORY: '히스토리',
  BUSINESS_AGENCY_DETAIL_BASIC: '기본정보',
  BUSINESS_AGENCY_DETAIL_BUSINESS: '비즈니스',
  BUSINESS_AGENCY_DETAIL_CAMPAIGN: '캠페인',
  BUSINESS_AGENCY_DETAIL_MEMBER: '멤버',
  BUSINESS_AGENCY_DETAIL_PRODUCT: '상품',
  BUSINESS_AGENCY_DETAIL_HISTORY: '히스토리',
  BUSINESS_FORM_BASIC: '기본정보',
  BUSINESS_FORM_CAMPAIGN: '캠페인',

  // BCA
  BCA_CAMPAIGN_WAITING: '검수',
  BCA_CAMPAIGN_PROGRESS: '진행',
  BCA_CAMPAIGN_COMPLETE: '종료',
  BCA_MEDIA_FACEBOOK_ACCOUNT: '계정관리',
  BCA_MEDIA_FACEBOOK_EXPOSE: '노출 페이지/계정',
  BCA_MEDIA_GOOGLE_ACCOUNT: '계정관리',
  BCA_MEDIA_GOOGLE_CATEGORY: '카테고리 관리',

  // 캠페인
  CAMPAIGN_FROM_CREATE: '생성',
  CAMPAIGN_FROM_BASIC: '기본정보',
  CAMPAIGN_FROM_MISSION: '미션',
  CAMPAIGN_FROM_REWARD: '리워드약관',
  CAMPAIGN_MANAGE_INFO: '캠페인 정보',
  CAMPAIGN_MANAGE_APPLICANT: '신청자 목록',
  CAMPAIGN_MANAGE_PARTICIPANT: '참여자 목록',
  CAMPAIGN_MANAGE_PROGRESS_MANAGE: '캠페인 진행 관리',
  CAMPAIGN_MANAGE_SURVEY: '설문조사',
  CAMPAIGN_MANAGE_POINT: '포인트 지급',
  CAMPAIGN_MANAGE_STATISTICS: '캠페인 통계',
  // 인플루언서
  INFLUENCES_DETAIL_CHANNEL: '채널 정보',
  INFLUENCES_DETAIL_CAMPAIGN: '캠페인 정보',
  INFLUENCES_DETAIL_POINT: '포인트/정산',
  INFLUENCES_DETAIL_INQUIRY: '1:1 문의',
  INFLUENCES_DETAIL_MEMBER: '회원 정보',
  INFLUENCES_DETAIL_POINT_BEFORE: '포인트 이전 내역',
  INFLUENCES_DETAIL_COST: '단가정보',
  INFLUENCES_DETAIL_REVIEW: '평가정보',
  // 운영 : 메시지
  OPERATION_MESSAGE_SEND: '메시지 작성',
  OPERATION_MESSAGE_SEND_LIST: '발송 대기 조회',
  OPERATION_MESSAGE_SEND_RESULT_LIST: '발송 결과 조회',
  OPERATION_MESSAGE_TEMPLATE: '템플릿 관리',
  // 운영 : 알림톡
  OPERATION_ALIMTALK_TEMPLATE: '템플릿 관리',
  OPERATION_ALIMTALK_SEND: '메세지 작성',
  OPERATION_ALIMTALK_SEND_LIST: '발송 대기 조회',
  OPERATION_ALIMTALK_SEND_RESULT_LIST: '발송 결과 조회',
  // 운영 : 채널연결 가이드
  OPERATION_CHANNEL_GUIDES_NEW_LIST: '신규',
  OPERATION_CHANNEL_GUIDES_EXIST_LIST: '기존',
  // 약관
  OPERATION_TERMS_SERVICE: '이용약관',
  OPERATION_TERMS_POLICY: '개인정보처리방침',
  OPERATION_TERMS_MARKETING: '마케팅활용약관',
  OPERATION_TERMS_THIRD_PARTY: '제3자 정보제공',
  OPERATION_TERMS_GUIDELINE: '검수가이드라인',
  // 쇼핑호스트
  LIVE_CAST_SHOW_HOST_INFO: '쇼호스트 정보',
  LIVE_CAST_SHOW_HOST_BROADCAST: '방송정보',
  // 스타일메이트
  STYLEMATE_FRONT_MANAGE_BANNER: '배너관리(홈)',
  STYLEMATE_FRONT_MANAGE_BANNER_ITEM: '배너관리(아이템)',
  STYLEMATE_FRONT_MANAGE_PRODUCT: '신규 제품 관리',
  STYLEMATE_FRONT_MANAGE_BRAND: '신규 브랜드 관리',
  STYLEMATE_FRONT_MANAGE_CONTENT: '콘텐츠 관리',
  STYLEMATE_LIKE_MANAGE_BRAND: '브랜드',
  STYLEMATE_LIKE_MANAGE_PRODUCT: '제품',
  // 상품
  PRODUCT_MANAGE_FORM_INFO: '상품정보',
  PRODUCT_MANAGE_FORM_CONTENT: '컨텐츠정보'
}

export default PAGE_TAB
