import axios from '@/plugins/axios'
import cryptoString from '@/utils/cryptoString'

export const SET_LOGIN_SCREENS_LIST = 'SET_LOGIN_SCREENS_LIST'
export const SET_LOGIN_SCREENS_DETAIL = 'SET_LOGIN_SCREENS_DETAIL'

const state = {
  loginScreenModel: {},
  loginScreenDetail: {}
}

const getters = {
  loginScreenListData: state => state.loginScreenModel,
  loginScreenDetail: state => state.loginScreenDetail
}

const actions = {
  async getLoginScreenList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/service-manages',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_LOGIN_SCREENS_LIST, response)
    })
  },
  async getLoginScreenItemDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/service-manages/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_LOGIN_SCREENS_DETAIL, response)
    })
  },
  async createLoginScreenItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/service-manages',
      data
    }
    const result = await axios(config)
    commit(SET_LOGIN_SCREENS_DETAIL, result)
    return result
  },
  async saveLoginScreenItem ({ commit }, { id, data }) {
    if (data?.bankAccount?.ownerNumber) {
      data.bankAccount.ownerNumber = cryptoString.encrypt(data.bankAccount.ownerNumber)
      delete data.bankAccount.personal
      delete data.bankAccount.corp
    }
    const setting = {
      method: 'PUT',
      url: `/_p/service-manages/${id}`,
      data
    }
    const result = await axios(setting)
    commit(SET_LOGIN_SCREENS_DETAIL, result)
    return result
  },
  async deleteLoginScreenItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/service-manages/${id}`
    }
    const result = await axios(setting)
    return result
  },
  async getMaintenance ({ commit }) {
    const config = {
      method: 'GET',
      url: '/_p/service-manages/maintenance'
    }
    return await axios(config)
  },
  async setMaintenance ({ commit }, { data }) {
    const config = {
      method: data.status === 'active' ? 'PUT' : 'PATCH',
      url: '/_p/service-manages/maintenance',
      data
    }
    return await axios(config)
  }
}

const mutations = {
  [SET_LOGIN_SCREENS_LIST]: (state, payload) => {
    state.loginScreenModel = payload
    state.loginScreenDetail = {}
  },
  [SET_LOGIN_SCREENS_DETAIL]: (state, payload) => {
    state.loginScreenDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
