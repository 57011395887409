<template>
  <div
    class="custom-input"
    :class="{
      'custom-input--count': props.showCount && props.options.maxlength
    }"
    :style="attrComputed.styleList"
  >
    <vxe-input
      v-model="formData.stateValue"
      v-bind="options"
      v-on="listeners"
    >
      <template
        v-if="props.showCount && props.options.maxlength"
        #suffix
      >
        ({{ formData.stateValue.length }}/{{ props.options.maxlength }})
      </template>
    </vxe-input>
    <span
      v-if="props.unit"
      class="custom-input__unit"
    >
      {{ props.unit }}
    </span>
  </div>
</template>

<script setup>
import { reactive, computed, watch, watchEffect, nextTick } from 'vue'
import { numberFormat, numberTel } from '@/utils/number.js'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null
  },
  formatType: {
    type: String,
    default: null
  },
  unit: {
    type: String,
    default: null
  },
  showCount: {
    type: Boolean,
    default: false
  },
  width: {
    type: [String, Number],
    default: '100%'
  },
  options: {
    type: Object,
    default: () => {}
  },
  isDescendant: {
    type: Boolean,
    default: false
  },
  params: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['enter', 'keyup', 'focus', 'blur', 'input', 'update:modelValue'])

const modelValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
const formData = reactive({
  changeChecked: false,
  stateValue: '',
  formatValue: ''
})
const listeners = computed(() => {
  return {
    focus: listenersActions.handleFocus,
    blur: listenersActions.handleBlur,
    input: listenersActions.handleInput,
    change: listenersActions.handleChange,
    keyup: listenersActions.handleKeyup
  }
})
const listenersActions = {
  formatTypeValue: (value) => {
    let updateValue = value

    const EngExp = /[A-Za-z]/gi
    const koreanExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/gi
    const numberExp = /[^0-9]/gi

    switch (props.formatType) {
      case 'kor':
        updateValue = String(updateValue).replace(EngExp, '')
        break
      case 'eng':
        updateValue = String(updateValue).replace(koreanExp, '')
        break
      case 'number':
        updateValue = String(updateValue).replace(numberExp, '')
        formData.formatValue = updateValue
        if (updateValue.length > 0) updateValue = numberFormat(updateValue)
        break
      case 'tel':
        updateValue = String(updateValue).replace(numberExp, '')
        formData.formatValue = updateValue
        if (updateValue.length > 0) updateValue = numberTel(updateValue)
        break
      case 'birth':
        updateValue = String(updateValue).replace(/[^0-9]/gi, '')
        updateValue = updateValue.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')
        break
      case 'bizNumber':
        updateValue = String(updateValue).replace(/[^0-9]/gi, '')
        updateValue = updateValue.replace(/([0-9]{3})([0-9]{2})([0-9]{0,5})/, '$1-$2-$3')
        break
      case 'rrn':
        updateValue = String(updateValue).replace(/[^0-9]/gi, '')
        updateValue = updateValue.replace(/([0-9]{6})([0-9]{0,7})/, '$1-$2')
        break
    }
    return updateValue.length ? updateValue.trim() : updateValue
  },
  handleFocus: (e) => {
    emit('focus', e)
  },
  handleBlur: (e) => {
    emit('blur', e)
  },
  handleInput: (e) => {
    const updateValue = listenersActions.formatTypeValue(e.value)
    nextTick(() => {
      formData.stateValue = updateValue
      emit('input', e)
    })
  },
  handleChange: (e) => {
    emit('input', e)
  },
  handleKeyup: (e) => {
    emit('keyup', e)
  },
  reset: () => {
    formData.stateValue = ''
    formData.formatValue = ''
  }
}
const attrComputed = reactive({
  styleList: computed(() => {
    return {
      width: typeof props.width === 'number' ? `${props.width}px` : props.width
    }
  })
})
watch(() => formData.stateValue, (val) => {
  let updateValue = val
  if (val.length > 0) {
    switch (props.formatType) {
      case 'number':
        updateValue = parseInt(formData.formatValue)
        break
      case 'tel':
        updateValue = formData.formatValue
        break
    }
  }
  modelValue.value = updateValue
})

const stopModelValueSave = watchEffect((onInvalidate) => {
  const cancel = () => {
    const val = modelValue.value
    if (val) {
      const changeValue = listenersActions.formatTypeValue(val)
      formData.formatValue = val || ''
      formData.stateValue = changeValue || ''
    } else {
      formData.formatValue = ''
      formData.stateValue = ''
    }
    formData.changeChecked = true
  }
  onInvalidate(() => cancel())
})
watch(() => modelValue.value, (val) => {
  if (val || val === 0) {
    stopModelValueSave()
    if (formData.changeChecked) {
      const changeValue = listenersActions.formatTypeValue(val)
      formData.formatValue = props.formatType === 'number' ? val : val || ''
      formData.stateValue = props.formatType === 'number' ? changeValue : changeValue || ''
    }
  } else if (val !== formData.stateValue) {
    formData.stateValue = ''
    formData.formatValue = ''
  }
}, { immediate: true })
</script>

<style lang="scss">
.custom-input {
  display: inline-flex;
  align-items: center;

  &--count {
    .vxe-input.is--suffix {
      .vxe-input--inner {
        padding-right: 4.5rem;
      }

      .vxe-input--suffix {
        right: 0.8em;
        justify-content: flex-end;
        width: auto;
        white-space: nowrap;
      }
    }
  }

  &__unit {
    margin: 0 0 0 5px;
    white-space: nowrap;
  }

  .vxe-input {
    flex: 1 0 0;
  }
}
</style>
