import store from '@/store'

export const CODE = {
  status: {
    active: '정상',
    inactive: '중지'
  },
  channelType: {
    instagram: '인스타그램',
    youtube: '유튜브'
    // facebook: '페이스북',
  },
  channelAddress: {
    instagram: 'https://www.instagram.com/',
    facebook: 'https://www.facebook.com/',
    youtube: 'https://www.youtube.com/channel/'
  },
  bookingType: {
    recruit: '모집형',
    propose: '제안형'
  },
  progressType: {
    single: '단일캠페인'
    // continuity: '연속캠페인'
  },
  blackList: {
    0: '미해당(-)',
    1: '주의',
    2: '경고',
    3: '위험',
    4: '블랙'
  },
  isDelivery: {
    true: '발송',
    false: '미발송'
  },
  isDisplay: {
    get: {
      true: '노출',
      false: '숨김'
    },
    set: {
      1: '노출',
      0: '숨김'
    }
  },
  fixed: {
    0: '미사용',
    1: '사용'
  },
  influenceType: {
    normal: '정상회원',
    dormancy: '휴면회원',
    secede: '탈퇴회원'
  },
  isNotify: {
    true: '사용',
    false: '미사용'
  },
  customScheduleType: {
    single: '단일',
    period: '기간'
  },
  processStatus: {
    ready: '대기',
    progress: '진행중',
    finish: '종료'
  },
  processDetailStatus: {
    ready: '대기',
    booking: '신청기간',
    announce: '인플루언서 발표',
    delivery: '배송시작',
    sample_post: '샘플포스트 등록기간',
    posting: '포스트등록기간',
    finish: '종료'
  },
  campaignType: {
    0: '제품/서비스리뷰',
    1: '이벤트참여/홍보',
    2: '방문인증/리뷰',
    3: '리그램/공유'
  },
  gender: {
    male: '남자',
    female: '여자'
  },
  marital: {
    married: '결혼',
    single: '미혼',
    divorce: '이혼'
  },
  pet: {
    1: '고양이',
    2: '강아지',
    4: '파충류',
    8: '곤충'
  },
  hasChildren: {
    false: '무자녀',
    true: '유자녀'
  },
  hasCar: {
    true: '보유',
    false: '미보유'
  },
  hasAddress: {
    true: '등록',
    false: '미등록'
  },
  questionType: {
    choice: '선택형',
    ranking: '순위형',
    simple: '단문입력형',
    long: '장문입력형'
  },
  isMultipleSelected: {
    false: '불가능',
    true: '가능'
  },
  postType: {
    video: '영상 올리기',
    image: '사진 올리기',
    text: '텍스트 올리기',
    // carousel: '텍스트 올리기',
    'video+image': '영상+사진 올리기'
  },
  postStatus: {
    ready: '대기',
    post_cancel: '포스트 취소',
    post_manuscript_request: '원고 요청',
    post_manuscript_confirm: '원고 컨펌 대기',
    post_manuscript_modify: '원고 수정',
    post_manuscript_modify_confirm: '원고 수정 완료',
    post_request: '포스트 요청',
    post_progress: '포스트 진행',
    post_modify_request: '포스트 수정 요청',
    post_modify_completed: '포스트 수정 완료',
    video_cancel: '영상 취소',
    video_temporary_request: '가영상 요청',
    video_temporary_confirm: '가영상 컨펌 대기',
    video_temporary_modify: '가영상 수정',
    video_temporary_modify_confirm: '가영상 수정 완료',
    video_request: '영상 요청',
    video_modify_request: '영상 수정 요청',
    video_modify_completed: '영상 수정 완료',
    video_progress: '영상 진행',
    tracking_error: '트래킹 오류',
    finish: '종료/지급완료'
  },
  useConfirm: {
    false: '미진행',
    true: '진행'
  },
  trendsCategory: {
    campaign_introduction: '캠페인소개',
    trends: '트렌드',
    in_company_news: '사내소식',
    latest_trend: '최신동향',
    education_materials: '교육자료'
  },
  country: {
    KOR: 'KOR',
    USA: 'USA',
    JPN: 'JPN',
    VET: 'VET'
  },
  isManaged: {
    true: '관리회원',
    false: '일반회원'
  },
  isChildren: {
    true: '자녀있음',
    false: '자녀없음'
  },
  isGuestDisplay: {
    true: '노출',
    false: '미노출'
  }
}

export const getBankLists = async () => {
  const resultMap = (obj) => {
    const lists = Object.keys(obj).map(function (key) {
      return { label: obj[key], value: key }
    }).sort((a, b) => {
      return a.label < b.label ? -1 : (a.label > b.label ? 1 : 0)
    })
    return lists
  }
  let bankList = store.getters['commons/bankList']
  if (!bankList) {
    await store.dispatch('commons/getBanks').then(() => {
      bankList = store.getters['commons/bankList']
      Object.assign(CODE_DATA.COMMON.banks, bankList)
      CODE_DATA.COMMON.banks.list = resultMap(bankList)
    })
  } else {
    Object.assign(CODE_DATA.COMMON.banks, bankList)
    CODE_DATA.COMMON.banks.list = resultMap(bankList)
  }
}

export const CODE_DATA = {
  COMMON: {
    status: {
      active: '정상',
      inactive: '중지',
      list: [
        { label: '정상', value: 'active' },
        { label: '중지', value: 'inactive' }
      ]
    },
    isDisplay: {
      1: '노출',
      0: '숨김',
      list: [
        { label: '노출', value: true },
        { label: '숨김', value: false }
      ]
    },
    isGuestDisplay: {
      1: '노출',
      0: '숨김',
      list: [
        { label: '노출', value: true },
        { label: '숨김', value: false }
      ]
    },
    ownerType: {
      personal: '개인',
      corp: '법인',
      list: [
        { label: '개인', value: 'personal' },
        { label: '법인', value: 'corp' }
      ]
    },
    banks: {
      list: []
    },
    mobileCarrier: {
      SKT: 'SKT',
      KT: 'KT',
      LGT: 'LGU+',
      MVNO: '알뜰폰',
      list: [
        { label: 'SKT', value: 'SKT' },
        { label: 'KT', value: 'KT' },
        { label: 'LGU+', value: 'LGT' },
        { label: '알뜰폰', value: 'MVNO' }
      ]
    },
    email: {
      direct: '직접입력',
      'naver.com': '네이버',
      'nate.com': '네이트',
      'gmail.com': '지메일',
      'hanmail.net': '한메일',
      list: [
        { label: '직접입력', value: 'direct' },
        { label: '네이버', value: 'naver.com' },
        { label: '네이트', value: 'nate.com' },
        { label: '지메일', value: 'gmail.com' },
        { label: '한메일', value: 'hanmail.net' }
      ]
    },
    addressLocale: {
      domestic: '국내',
      foreign: '해외',
      list: [
        { label: '국내', value: 'domestic' },
        { label: '해외', value: 'foreign' }
      ]
    },
    addressLocale2: {
      domestic: '국내',
      abroad: '국외',
      list: [
        { label: '국내', value: 'domestic' },
        { label: '국외', value: 'abroad' }
      ]
    },
    answerStatus: {
      ready: '대기',
      complete: '완료',
      list: [
        { label: '대기', value: 'ready' },
        { label: '완료', value: 'complete' }
      ]
    },
    answerReceiving: {
      0: '미수신',
      1: '수신',
      list: [
        { label: '수신', value: true },
        { label: '미수신', value: false }
      ]
    },
    serviceCategory: {
      mediance: '미디언스',
      bizcenter: '비즈센터',
      list: [
        { label: '미디언스', value: 'mediance' },
        { label: '비즈센터', value: 'bizcenter' }
      ]
    },
    existingSites: {
      all: '전체',
      mediance: '와이드',
      stylemate: '스타일메이트',
      list: [
        { label: '전체', value: null },
        { label: '와이드', value: 'mediance' },
        { label: '스타일메이트', value: 'stylemate' }
      ]
    },
    userStatus: {
      active: '정상',
      inactive: '탈퇴',
      list: [
        { label: '정상', value: 'active' },
        { label: '탈퇴', value: 'inactive' }
      ]
    },
    serviceType: {
      mediance: '미디언스',
      stylemate: '스타일메이트',
      bizcenter: '비즈센터',
      list: [
        { label: '미디언스', value: 'mediance' },
        { label: '스타일메이트', value: 'stylemate' },
        { label: '비즈센터', value: 'bizcenter' }

      ]
    },
    isAnswered: {
      1: '처리완료',
      0: '미처리',
      list: [
        { label: '처리완료', value: true },
        { label: '미처리', value: false }
      ]
    }
  },
  BUSINESSES: {
    type: {
      advertiser: '광고주',
      agency: '대행사',
      list: [
        { label: '광고주', value: 'advertiser' },
        { label: '대행사', value: 'agency' }
      ]
    },
    businessType: {
      corp: '사업자',
      personal: '개인',
      list: [
        { label: '사업자', value: 'corp' },
        { label: '개인', value: 'personal' }
      ]
    },
    roleType: {
      owner: '소유자',
      manager: '관리자',
      operator: '운영자',
      list: [
        { label: '소유자', value: 'owner' },
        { label: '관리자', value: 'manager' },
        { label: '운영자', value: 'operator' }
        // { label: '보고자', value: 'reporter' }
      ]
    },
    historyType: {
      member: '멤버',
      campaign: '캠페인',
      product: '상품',
      list: [
        { label: '멤버', value: 'member' },
        { label: '캠페인', value: 'campaign' },
        { label: '상품', value: 'product' }
      ]
    },
    agencyStatus: {
      active: '정상',
      inactive: '비활성',
      restriction: '제재',
      list: [
        { label: '정상', value: 'active' },
        { label: '비활성', value: 'inactive' },
        { label: '제재', value: 'restriction' }
      ]
    },
    status: {
      all: '전체',
      normal: '정상회원',
      secede: '탈퇴회원',
      list: [
        { label: '전체', value: 'all' },
        { label: '정상회원', value: 'normal' },
        { label: '탈퇴회원', value: 'secede' }
      ]
    },
    category: {
      all: '전체',
      member: '멤버',
      campaign: '캠페인',
      product: '상품',
      list: [
        { label: '전체', value: 'all' },
        { label: '멤버', value: 'member' },
        { label: '캠페인', value: 'campaign' },
        { label: '상품', value: 'product' }
      ]
    }
  },
  CAMPAIGN: {
    makeSection: {
      biz: 'MBP',
      ariel: 'Admin',
      list: [
        { label: 'MBP', value: 'biz' },
        { label: 'Admin', value: 'ariel' }
      ]
    },
    serviceType: {
      mediance: '미디언스',
      stylemate: '스타일메이트',
      list: [
        { label: '미디언스', value: 'mediance' },
        { label: '스타일메이트', value: 'stylemate' }
      ]
    },
    brand: {
      list: []
    },
    guideAction: {
      list: []
    },
    processStatus: {
      ready: '대기',
      progress: '진행중',
      finish: '종료',
      list: [
        { label: '대기', value: 'ready' },
        { label: '진행중', value: 'progress' },
        { label: '종료', value: 'finish' }
      ]
    },
    bookingType: {
      recruit: '모집형',
      propose: '제안형',
      list: [
        { label: '모집형', value: 'recruit' },
        { label: '제안형', value: 'propose' }
      ]
    },
    progressType: {
      single: '단일캠페인',
      // continuity: '연속캠페인',
      list: [
        { label: '단일캠페인', value: 'single' }
        // { label: '연속캠페인', value: 'continuity' }
      ]
    },
    isDelivery: {
      1: '발송',
      0: '미발송',
      list: [
        { label: '발송', value: true },
        { label: '미발송', value: false }
      ]
    },
    isNotify: {
      1: '사용',
      0: '미사용',
      list: [
        { label: '사용', value: true },
        { label: '미사용', value: false }
      ]
    },
    customScheduleType: {
      single: '단일',
      period: '기간',
      list: [
        { label: '단일', value: 'single' },
        { label: '기간', value: 'period' }
      ]
    },
    campaignType: {
      0: '제품/서비스리뷰',
      1: '이벤트참여/홍보',
      2: '방문인증/리뷰',
      3: '리그램/공유',
      list: [
        { label: '제품/서비스리뷰', value: 0 },
        { label: '이벤트참여/홍보', value: 1 },
        { label: '방문인증/리뷰', value: 2 },
        { label: '리그램/공유', value: 3 }
      ]
    },
    processDetailStatus: {
      ready: '대기',
      booking: '신청기간',
      announce: '인플루언서 발표',
      delivery: '배송시작',
      sample_post: '샘플포스트 등록기간',
      posting: '포스트등록기간',
      finish: '종료',
      list: [
        { label: '대기', value: 'ready' },
        { label: '신청기간', value: 'booking' },
        { label: '인플루언서 발표', value: 'announce' },
        { label: '배송시작', value: 'delivery' },
        { label: '샘플포스트 등록기간', value: 'sample_post' },
        { label: '포스트등록기간', value: 'posting' },
        { label: '종료', value: 'finish' }
      ]
    },
    processDetailSearchStatus: {
      ready: '대기',
      booking: '신청기간',
      announce: '인플루언서 발표',
      posting: '포스트등록기간',
      finish: '종료',
      list: [
        { label: '대기', value: 'ready' },
        { label: '신청기간', value: 'booking' },
        { label: '인플루언서 발표', value: 'announce' },
        { label: '포스트등록기간', value: 'posting' },
        { label: '종료', value: 'finish' }
      ]
    },
    questionType: {
      choice: '선택형',
      ranking: '순위형',
      simple: '단문입력형',
      long: '장문입력형',
      list: [
        { label: '선택형', value: 'choice' },
        { label: '순위형', value: 'ranking' },
        { label: '단문입력형', value: 'simple' },
        { label: '장문입력형', value: 'long' }
      ],
      selfList: [
        { label: '선택형', value: 'choice' },
        { label: '단문입력형', value: 'simple' }
      ]
    },
    isMultipleSelected: {
      0: '불가능',
      1: '가능',
      list: [
        { label: '불가능', value: false },
        { label: '가능', value: true }
      ]
    },
    postType: {
      video: '영상 올리기',
      image: '사진 올리기',
      text: '텍스트 올리기',
      'video+image': '영상+사진 올리기',
      list: [
        { label: '영상 올리기', value: 'video' },
        { label: '사진 올리기', value: 'image' },
        { label: '텍스트 올리기', value: 'text' },
        { label: '영상+사진 올리기', value: 'video+image' }
      ],
      productList: [
        { label: '영상 올리기', value: 'video' },
        { label: '사진 올리기', value: 'image' },
        { label: '영상+사진 올리기', value: 'video+image' }
      ]
    },
    postStatus: {
      ready: '대기',
      post_cancel: '포스트 취소',
      post_manuscript_request: '원고 요청',
      post_manuscript_confirm: '원고 컨펌 대기',
      post_manuscript_modify: '원고 수정',
      post_manuscript_modify_confirm: '원고 수정 완료',
      post_request: '포스트 요청',
      post_progress: '포스트 진행',
      post_modify_request: '포스트 수정 요청',
      post_modify_completed: '포스트 수정 완료',
      video_cancel: '영상 취소',
      video_temporary_request: '가영상 요청',
      video_temporary_confirm: '가영상 컨펌 대기',
      video_temporary_modify: '가영상 수정',
      video_temporary_modify_confirm: '가영상 수정 완료',
      video_request: '영상 요청',
      video_modify_request: '영상 수정 요청',
      video_modify_completed: '영상 수정 완료',
      video_progress: '영상 진행',
      tracking_error: '트래킹 오류',
      finish: '종료/지급완료',
      list: [
        { label: '대기', value: 'ready' },
        { label: '포스트 취소', value: 'post_cancel' },
        { label: '원고 요청', value: 'post_manuscript_request' },
        { label: '원고 컨펌 대기', value: 'post_manuscript_confirm' },
        { label: '원고 수정', value: 'post_manuscript_modify' },
        { label: '원고 수정 완료', value: 'post_manuscript_modify_confirm' },
        { label: '포스트 요청', value: 'post_request' },
        { label: '포스트 진행', value: 'post_progress' },
        { label: '포스트 수정 요청', value: 'post_modify_request' },
        { label: '포스트 수정 완료', value: 'post_modify_completed' },
        { label: '영상 취소', value: 'video_cancel' },
        { label: '가영상 요청', value: 'video_temporary_request' },
        { label: '가영상 컨펌 대기', value: 'video_temporary_confirm' },
        { label: '가영상 수정', value: 'video_temporary_modify' },
        { label: '가영상 수정 완료', value: 'video_temporary_modify_confirm' },
        { label: '영상 취소', value: 'video_request' },
        { label: '영상 수정 요청', value: 'video_modify_request' },
        { label: '영상 수정 완료', value: 'video_modify_completed' },
        { label: '영상 진행', value: 'video_progress' },
        { label: '트래킹 오류', value: 'tracking_error' },
        { label: '종료/지급완료', value: 'finish' }
      ]
    },
    useConfirm: {
      1: '진행',
      0: '미진행',
      list: [
        { label: '진행', value: true },
        { label: '미진행', value: false }
      ]
    },
    pointRewardType: {
      none: '없음',
      fixed: '고정포인트(공통-일괄지급)',
      flexed: '고정포인트(차등지급)',
      bidding: '입찰',
      list: [
        { label: '없음', value: 'none' },
        { label: '고정포인트(공통-일괄지급)', value: 'fixed' },
        { label: '고정포인트(차등지급)', value: 'flexed' },
        { label: '입찰', value: 'bidding' }
      ]
    },
    trendsCategory: {
      campaign_introduction: '캠페인소개',
      trends: '트렌드',
      in_company_news: '사내소식',
      latest_trend: '최신동향',
      education_materials: '교육자료',
      list: [
        { label: '캠페인소개', value: 'campaign_introduction' },
        { label: '트렌드', value: 'trends' },
        { label: '사내소식', value: 'in_company_news' },
        { label: '최신동향', value: 'latest_trend' },
        { label: '교육자료', value: 'education_materials' }
      ]
    },
    tagList: {
      channelCategory: '채널 카테고리',
      brand: '브랜드',
      guideAction: '가이드문구',
      youtubeProperty: '캠페인 유형(유튜브)',
      instagramProperty: '캠페인 유형(인스타/페북)',
      category: '카테고리',
      list: [
        { label: '채널 카테고리', value: 'channelCategory' },
        { label: '브랜드', value: 'brand' },
        { label: '가이드문구', value: 'guideAction' },
        { label: '캠페인 유형(유튜브)', value: 'youtubeProperty' },
        { label: '캠페인 유형(인스타/페북)', value: 'instagramProperty' },
        { label: '카테고리', value: 'category' }
      ]
    },
    sendState: {
      list: [
        { label: '원고 마감안내(D-2~3)', value: 'manuscriptEndDay' },
        { label: '원고 마감안내(D-Day)', value: 'manuscriptEndDay' },
        { label: '포스트 마감안내(D-2~3)', value: 'post' },
        { label: '포스트 마감안내(D-Day)', value: 'postEndDay' }
      ]
    },
    manageState: {
      list: [
        { label: '대기 > 원고 요청', value: 'post_manuscript_request' },
        { label: '대기 > 포스트 요청', value: 'post_request' },
        { label: '대기 > 가영상 요청', value: 'video_temporary_request' },
        { label: '대기 > 영상 요청', value: 'video_request' }
      ]
    },
    exclusive: {
      list: [
        { label: '중복미지원자', value: 'false' },
        { label: '중복지원자', value: 'true' }
      ]
    },
    isPosting: {
      list: [
        { label: '미업로드', value: false },
        { label: '업로드', value: true }
      ]
    },
    bookingStatus: {
      list: [
        { label: '신청', value: 'booking' },
        { label: '선정', value: 'join' }
      ]
    },
    resultPostStatus: {
      reject: '수정반려',
      modify: '포스트 수정요청',
      list: [
        { label: '수정반려', value: 'reject' },
        { label: '포스트 수정요청', value: 'modify' }
      ]
    },
    postRejectReason: {
      1: '캠페인 가이드에 포함되어 있지 않은 내용은 수정 요청이 불가합니다.',
      2: '이미지/영상의 밝기, 화질등 주관적인 기준에 의한 수정 요청은 불가합니다.',
      3: '요청 사항이 불분명하므로, 요청 사항은 상세하게 작성하여 재전달 부탁드립니다.',
      etc: '관리자 직접 입력',
      list: [
        { label: '캠페인 가이드에 포함되어 있지 않은 내용은 수정 요청이 불가합니다.', value: 1 },
        { label: '이미지/영상의 밝기, 화질등 주관적인 기준에 의한 수정 요청은 불가합니다.', value: 2 },
        { label: '요청 사항이 불분명하므로, 요청 사항은 상세하게 작성하여 재전달 부탁드립니다.', value: 3 },
        { label: '관리자 직접 입력', value: 'etc' }
      ]
    },
    postCancelReason: {
      1: '캠페인 일정 미준수',
      2: '참여자의 부주의로 인한 캠페인 상세 내용 착오',
      3: '참여자의 부주의로 인한 제품 분실/파손',
      4: '포스트 수정 사항 미반영',
      5: '캠페인 진행 의사 단순 변심',
      6: '관리자 직접 입력',
      list: [
        { value: 1, label: '캠페인 일정 미준수' },
        { value: 2, label: '참여자의 부주의로 인한 캠페인 상세 내용 착오' },
        { value: 3, label: '참여자의 부주의로 인한 제품 분실/파손' },
        { value: 4, label: '포스트 수정 사항 미반영' },
        { value: 5, label: '캠페인 진행 의사 단순 변심' },
        { value: 6, label: '관리자 직접 입력' }
      ]
    },
    deliveryStatus: {
      standby: '제품준비중',
      ready: '배송전',
      progress: '배송중',
      finished: '배송완료',
      list: [
        { label: '제품준비중', value: 'standby' },
        { label: '배송전', value: 'ready' },
        { label: '배송중', value: 'progress' },
        { label: '배송완료', value: 'finished' }
      ]
    }
  },
  INFLUENCER: {
    isManaged: {
      1: '관리회원',
      0: '일반회원',
      list: [
        { label: '관리회원', value: true },
        { label: '일반회원', value: false }
      ]
    },
    type: {
      normal: '정상회원',
      dormancy: '휴면회원',
      secede: '탈퇴회원',
      list: [
        { label: '정상회원', value: 'normal' },
        { label: '휴면회원', value: 'dormancy' },
        { label: '탈퇴회원', value: 'secede' }
      ]
    },
    blackList: {
      0: '미해당(-)',
      1: '주의',
      2: '경고',
      3: '위험',
      4: '블랙',
      list: [
        { label: '미해당(-)', value: 0 },
        { label: '주의', value: 1 },
        { label: '경고', value: 2 },
        { label: '위험', value: 3 },
        { label: '블랙', value: 4 }
      ]
    },
    gender: {
      male: '남자',
      female: '여자',
      list: [
        { label: '남자', value: 'male' },
        { label: '여자', value: 'female' }
      ]
    },
    marital: {
      married: '결혼',
      single: '미혼',
      divorce: '이혼',
      list: [
        { label: '결혼', value: 'married' },
        { label: '미혼', value: 'single' },
        { label: '이혼', value: 'divorce' }
      ]
    },
    pet: {
      1: '고양이',
      2: '강아지',
      4: '파충류',
      8: '곤충',
      list: [
        { label: '고양이', value: 1 },
        { label: '강아지', value: 2 },
        { label: '파충류', value: 4 },
        { label: '곤충', value: 8 }
      ]
    },
    hasPet: {
      0: '없음',
      1: '있음',
      list: [
        { label: '없음', value: false },
        { label: '있음', value: true }
      ]
    },
    isChildren: {
      1: '자녀있음',
      0: '자녀없음',
      list: [
        { label: '자녀있음', value: true },
        { label: '자녀없음', value: false }
      ]
    },
    hasChildren: {
      0: '무자녀',
      1: '유자녀',
      list: [
        { label: '무자녀', value: false },
        { label: '유자녀', value: true }
      ]
    },
    hasCar: {
      1: '보유',
      0: '미보유',
      list: [
        { label: '보유', value: true },
        { label: '미보유', value: false }
      ]
    },
    hasAddress: {
      1: '등록',
      0: '미등록',
      list: [
        { label: '등록', value: true },
        { label: '미등록', value: false }
      ]
    },
    country: {
      KOR: 'KOR',
      USA: 'USA',
      JPN: 'JPN',
      VET: 'VET',
      list: [
        { label: 'KOR', value: 'KOR' },
        { label: 'USA', value: 'USA' },
        { label: 'JPN', value: 'JPN' },
        { label: 'VET', value: 'VET' }
      ]
    },
    channel: {
      type: {
        instagram: '인스타그램',
        youtube: '유튜브',
        list: [
          { label: '인스타그램', value: 'instagram' },
          { label: '유튜브', value: 'youtube' }
        ]
      },
      url: {
        instagram: 'https://www.instagram.com/',
        youtube: 'https://www.youtube.com/channel/',
        list: [
          { label: 'instagram', value: 'https://www.instagram.com/' },
          { label: 'youtube', value: 'https://www.youtube.com/channel/' }
        ]
      }
    },
    isAgree: {
      agreeMarketing: '마케팅동의',
      agreeCampaign: '캠페인제안동의',
      agreeNewsletter: '뉴스레터수신동의',
      list: [
        { label: '마케팅동의', value: 'agreeMarketing' },
        { label: '캠페인제안동의', value: 'agreeCampaign' },
        { label: '뉴스레터수신동의', value: 'agreeNewsletter' }
      ]
    },
    periodType: {
      list: [
        { label: '가입일', value: 'influence.createdAt' },
        { label: '접속일', value: 'user.lastLoginedAt' }
      ]
    }
  },
  CHANNEL: {
    type: {
      instagram: '인스타그램',
      youtube: '유튜브',
      list: [
        { label: '인스타그램', value: 'instagram' },
        { label: '유튜브', value: 'youtube' }
      ]
    },
    url: {
      instagram: 'https://www.instagram.com/',
      youtube: 'https://www.youtube.com/channel/',
      list: [
        { label: 'instagram', value: 'https://www.instagram.com/' },
        { label: 'youtube', value: 'https://www.youtube.com/channel/' }
      ]
    },
    deviceType: {
      pc: 'pc',
      mobile: '모바일',
      list: [
        { label: 'pc', value: 'pc' },
        { label: '모바일', value: 'mobile' }
      ]
    },
    isConnected: {
      CONNECT_CHANNEL: '연결',
      DELETE_CHANNEL: '연결해제'
    },
    mediaType: {
      carousel: '캐러셀',
      image: '사진',
      video: '영상'
    },
    mediance: {
      bookingCount: '지원',
      joinCount: '선정',
      completionCount: '완료',
      campaignCancelCount: '취소'
    },
    campaignPost: {
      postCount: '진행포스트',
      postLikeCountAvg: '평균좋아요',
      postCommentCountAvg: '평균댓글',
      postEngagementRateAvg: 'EGR'
    }
  },
  SETTLEMENT: {
    billingProcessStatus: {
      ready: '대기',
      process: '확인완료',
      complete: '정산마감',
      list: [
        { label: '대기', value: 'ready' },
        { label: '확인완료', value: 'process' },
        { label: '정산마감', value: 'complete' }
      ]
    },
    billingConfirmStatus: {
      request: '대기',
      approval: '승인',
      refusal: '거부',
      list: [
        { label: '대기', value: 'request' },
        { label: '승인', value: 'approval' },
        { label: '거부', value: 'refusal' }
      ]
    },
    billingDepositStatus: {
      ready: '대기',
      notPossible: '입금 불가',
      complete: '입금 완료',
      list: [
        { label: '대기', value: 'ready' },
        { label: '입금 불가', value: 'notPossible' },
        { label: '입금 완료', value: 'complete' }
      ]
    },
    pointType: {
      campaign: '캠페인',
      managerAdjustment: '운영자 조정',
      auto: '일반정산',
      managed: '비정기정산',
      list: [
        { label: '캠페인', value: 'campaign' },
        { label: '운영자 조정', value: 'managerAdjustment' },
        { label: '일반정산', value: 'auto' },
        { label: '비정기정산', value: 'managed' }
      ]

    },
    pointCalculationType: {
      provision: '지급',
      subtraction: '차감',
      list: [
        { label: '지급', value: 'provision' },
        { label: '차감', value: 'subtraction' }
      ]

    }
  },
  OPERATION: {
    message: {
      bodyType: {
        general: '일반',
        replacement: '치환',
        list: [
          { label: '일반', value: 'general' },
          { label: '치환', value: 'replacement' }
        ]
      },
      receivingType: {
        general: {
          direct: '직접 입력',
          search: '인플루언서 검색으로 추가',
          csv: 'csv로 추가',
          list: [
            { label: '직접 입력', value: 'direct' },
            { label: '인플루언서 검색으로 추가', value: 'search' },
            { label: 'csv로 추가', value: 'csv' }
          ]
        },
        replacement: {
          csv: 'csv로 추가',
          list: [
            { label: 'csv로 추가', value: 'csv' }
          ]
        }
      },
      type: {
        sms: 'SMS',
        mms: 'MMS',
        lms: 'LMS',
        list: [
          { label: 'SMS', value: 'sms' },
          { label: 'MMS', value: 'mms' },
          { label: 'LMS', value: 'lms' }
        ]
      },
      sendGroupStatus: {
        ready: '대기',
        sending: '발송중',
        finish: '발송완료',
        cancel: '취소',
        list: [
          { label: '대기', value: 'ready' },
          { label: '발송중', value: 'sending' },
          { label: '발송완료', value: 'finish' },
          { label: '취소', value: 'cancel' }
        ]
      },
      sendStatus: {
        ready: '대기',
        sending: '발송중',
        cancel: '취소',
        fail: '실패',
        success: '성공',
        list: [
          { label: '대기', value: 'ready' },
          { label: '발송중', value: 'sending' },
          { label: '취소', value: 'cancel' },
          { label: '실패', value: 'fail' },
          { label: '성공', value: 'success' }
        ]
      },
      sendTimeType: {
        now: '바로발송',
        specify: '시간지정',
        list: [
          { label: '바로발송', value: 'now' },
          { label: '시간지정', value: 'specify' }
        ]
      },
      sendMinutesLater: {
        5: '5분뒤',
        10: '10분뒤',
        30: '30분뒤',
        60: '60분뒤',
        list: [
          { label: '5분뒤', value: 5 },
          { label: '10분뒤', value: 10 },
          { label: '30분뒤', value: 30 },
          { label: '60분뒤', value: 60 }
        ]
      }
    },
    noticeCategory: {
      notification: '알림',
      event: '이벤트',
      notMarked: '미표시',
      list: [
        { label: '알림', value: 'notification' },
        { label: '이벤트', value: 'event' },
        { label: '미표시', value: 'notMarked' }
      ]
    },
    topFixed: {
      0: '미사용',
      1: '사용',
      list: [
        { label: '미사용', value: 0 },
        { label: '사용', value: 1 }
      ]
    },
    faqCategory: {
      campaign: '캠페인 진행',
      channel: '채널연결',
      user: '회원정보',
      booking: '캠페인신청',
      point: '포인트',
      etc: '기타',
      list: [
        { label: '캠페인 진행', value: 'campaign', type: 'faq' },
        { label: '채널연결', value: 'channel', type: 'faq' },
        { label: '회원정보', value: 'user', type: 'faq' },
        { label: '캠페인신청', value: 'booking', type: 'faq' },
        { label: '포인트', value: 'point', type: 'faq' },
        { label: '기타', value: 'etc', type: 'faq' }
      ]
    },
    faqStylemateCategory: {
      sponsorship: '협찬진행',
      channel: '채널연결',
      user: '회원정보',
      etc: '기타',
      list: [
        { label: '협찬진행', value: 'sponsorship', sendValue: 'sponsorship', type: 'stylemateFaq' },
        { label: '채널연결', value: 'channel', sendValue: 'channel', type: 'stylemateFaq' },
        { label: '회원정보', value: 'user', sendValue: 'user', type: 'stylemateFaq' },
        { label: '기타', value: 'etc', sendValue: 'etc', type: 'stylemateFaq' }
      ]
    },
    faqMbpCategory: {
      campaign: '캠페인',
      creator: '크리에이터',
      business: '비즈니스',
      bizProduct: '비즈상품',
      brandContentsAd: '브랜드 콘텐츠 광고',
      etc: '기타',
      list: [
        { label: '캠페인 ', value: 'campaign', type: 'bizcenterFaq' },
        { label: '비즈니스', value: 'business', type: 'bizcenterFaq' },
        { label: '크리에이터', value: 'creator', type: 'bizcenterFaq' },
        { label: '비즈상품', value: 'bizProduct', type: 'bizcenterFaq' },
        { label: '브랜드 콘텐츠 광고', value: 'brandContentsAd', type: 'bizcenterFaq' },
        { label: '기타', value: 'etc', type: 'bizcenterFaq' }
      ]
    },
    qnaType: {
      instagramCampaign: '인스타그램 캠페인',
      youtubeCampaign: '유튜브 캠페인',
      facebookCampaign: '페이스북 캠페인',
      service: '서비스 이용',
      point: '포인트관련문의',
      etc: '기타 문의',
      list: [
        { label: '페이스북 캠페인', value: 'facebookCampaign' },
        { label: '인스타그램 캠페인', value: 'instagramCampaign' },
        { label: '유튜브 캠페인', value: 'youtubeCampaign' },
        { label: '서비스 이용', value: 'service' },
        { label: '포인트 관련문의', value: 'point' },
        { label: '기타 문의', value: 'etc' }
      ]
    },
    qnaTypeStylemate: {
      stylemateService: '서비스 이용',
      stylemateCampaign: '협찬',
      stylemateEtc: '기타',
      list: [
        { label: '서비스 이용', value: 'stylemateService' },
        { label: '협찬', value: 'stylemateCampaign' },
        { label: '기타', value: 'stylemateEtc' }
      ]
    },
    qnaTypeMbp: {
      campaign: '캠페인',
      business: '비즈니스',
      creator: '크리에이터',
      bizProduct: '비즈상품',
      brandContentsAd: '브랜드 콘텐츠 광고',
      etc: '기타',
      list: [
        { label: '캠페인', value: 'campaign' },
        { label: '비즈니스', value: 'business' },
        { label: '크리에이터', value: 'creator' },
        { label: '비즈상품', value: 'bizProduct' },
        { label: '브랜드 콘텐츠 광고', value: 'brandContentsAd' },
        { label: '기타', value: 'etc' }
      ]
    },
    qnaAnswerStatus: {
      ready: '대기',
      complete: '완료',
      list: [
        { label: '대기', value: 'ready' },
        { label: '완료', value: 'complete' }
      ]
    },
    bannerProcessStatus: {
      ready: '대기',
      process: '진행중',
      termination: '종료',
      list: [
        { label: '대기', value: 'ready' },
        { label: '진행중', value: 'process' },
        { label: '종료', value: 'termination' }
      ]
    },
    whatsNewCategory: {
      campaign_introduction: '캠페인소개',
      trends: '트렌드',
      in_company_news: '사내소식',
      latest_trend: '최신동향',
      education_materials: '교육자료',
      list: [
        { label: '캠페인소개', value: 'campaign_introduction' },
        { label: '트렌드', value: 'trends' },
        { label: '사내소식', value: 'in_company_news' },
        { label: '최신동향', value: 'latest_trend' },
        { label: '교육자료', value: 'education_materials' }
      ]
    },
    bannerArea: {
      home: '홈',
      item: '아이템',
      list: [
        { label: '홈', value: 'home' },
        { label: '아이템', value: 'item' }
      ]
    },
    alimtalk: {
      isSystem: {
        false: '수동',
        true: '자동',
        list: [
          { label: '자동', value: true },
          { label: '수동', value: false }
        ]
      },
      isUseCampaign: {
        true: '사용',
        false: '미사용',
        list: [
          { label: '사용', value: true },
          { label: '미사용', value: false }
        ]
      },
      isUseBusiness: {
        true: '사용',
        false: '미사용',
        list: [
          { label: '사용', value: true },
          { label: '미사용', value: false }
        ]
      },
      serviceType: {
        MBP: 'MBP',
        mediance: '미디언스',
        stylemate: '스타일메이트',
        list: [
          { label: '미디언스', value: 'mediance' },
          { label: '스타일메이트', value: 'stylemate' },
          { label: 'MBP', value: 'MBP' }
        ]
      },
      sendMinutesLater: {
        5: '5분뒤',
        10: '10분뒤',
        30: '30분뒤',
        60: '60분뒤',
        specifyTime: '예약시간',
        list: [
          { label: '5분뒤', value: 5 },
          { label: '10분뒤', value: 10 },
          { label: '30분뒤', value: 30 },
          { label: '60분뒤', value: 60 },
          { label: '예약시간', value: 'specifyTime' }
        ]
      },
      type: {
        sms: 'SMS',
        mms: 'MMS',
        lms: 'LMS',
        alimtalk: '알림톡',
        list: [
          { label: 'SMS', value: 'sms' },
          { label: 'MMS', value: 'mms' },
          { label: 'LMS', value: 'lms' },
          { label: '알림톡', value: 'alimtalk' }
        ]
      },
      buttonType: {
        WL: '웹링크',
        list: [
          { label: 'WL', value: '웹링크' }
        ]
      }
    },
    tracking: {
      serviceType: {
        mediance: '미디언스',
        stylemate: '스타일메이트',
        list: [
          { label: '미디언스', value: 'mediance' },
          { label: '스타일메이트', value: 'stylemate' }
        ]
      }
    }
  },
  HOMEPAGE: {
    adQnaChannelType: {
      instagram: '인스타그램',
      youtube: '유튜브',
      facebook: '페이스북',
      branded: '브랜디드',
      list: [
        { label: '인스타그램', value: 'instagram' },
        { label: '유튜브', value: 'youtube' },
        { label: '페이스북', value: 'facebook' },
        { label: '브랜디드', value: 'branded' }
      ]
    },
    serviceType: {
      mediance: '미디언스',
      youcast: '유캐스트',
      livecast: '라이브캐스트',
      brandcast: '브랜드캐스트',
      list: [
        { label: '미디언스', value: 'mediance' },
        { label: '유캐스트', value: 'youcast' },
        { label: '라이브캐스트', value: 'livecast' },
        { label: '브랜드캐스트', value: 'brandcast' }
      ]
    },
    vcastQnaType: {
      meet: '상담요청',
      cost: '견적요청',
      studio: '제작사추천',
      list: [
        { label: '상담요청', value: 'meet' },
        { label: '견적요청', value: 'cost' },
        { label: '제작사추천', value: 'studio' }
      ]
    },
    projectType: {
      digitalCf: '디지털CF',
      viralVideo: '바이럴영상',
      webDrama: '웹드라마',
      musicVideo: '뮤직비디오',
      bizAdVideo: '기업홍보 영상',
      vCommerce: 'V커머스 영상',
      fashionFilm: '패션필름',
      sketchVideo: '스케치영상',
      lecture: '강의/교육',
      interview: '인터뷰',
      motionGraphic: '모션그래픽',
      list: [
        { label: '디지털CF', value: 'digitalCf' },
        { label: '바이럴영상', value: 'viralVideo' },
        { label: '웹드라마', value: 'webDrama' },
        { label: '뮤직비디오', value: 'musicVideo' },
        { label: '기업홍보 영상', value: 'bizAdVideo' },
        { label: 'V커머스 영상', value: 'vCommerce' },
        { label: '패션필름', value: 'fashionFilm' },
        { label: '스케치영상', value: 'sketchVideo' },
        { label: '강의/교육', value: 'lecture' },
        { label: '인터뷰', value: 'interview' },
        { label: '모션그래픽', value: 'motionGraphic' }
      ]
    },
    agencyContactKey: {
      tel: '연락처',
      name: '담당자 성함',
      email: '이메일',
      position: '직책/직함'
    }
  },
  SHOW_HOST: {
    karmaType: {
      1: '아나운서',
      2: '쇼호스트',
      4: '인플루언서',
      8: '샐럽(연예인)',
      list: [
        { label: '아나운서', value: 1 },
        { label: '쇼호스트', value: 2 },
        { label: '인플루언서', value: 4 },
        { label: '샐럽(연예인)', value: 8 }
      ]
    },
    belongType: {
      none: '없음',
      freelancer: '프리랜서',
      belong: '전속',
      soleProprietor: '개인사업자',
      list: [
        { label: '없음', value: 'none' },
        { label: '프리랜서', value: 'freelancer' },
        { label: '전속', value: 'belong' },
        { label: '개인사업자', value: 'list' }
      ]
    },
    ageType: {
      twenties: '20대',
      thirties: '30대',
      forties: '40대',
      fifties: '50대',
      sixties: '50대 이상',
      list: [
        { label: '20대', value: 'twenties' },
        { label: '30대', value: 'thirties' },
        { label: '40대', value: 'forties' },
        { label: '50대', value: 'fifties' },
        { label: '50대 이상', value: 'sixties' }
      ]
    },
    costType: {
      '~30': '30만원 이하',
      '30~50': '30~50만원',
      '50~100': '50~100만원',
      '100~': '100만원 이상',
      list: [
        { label: '30만원 이하', value: '~30' },
        { label: '30~50만원', value: '30~50' },
        { label: '50~100만원', value: '50~100' },
        { label: '100만원 이상', value: '100~' }
      ]
    },
    registerType: {
      admin: '관리자',
      showhost: '쇼호스트',
      list: [
        { label: '관리자', value: 'admin' },
        { label: '쇼호스트', value: 'showhost' }
      ]
    },
    showHostChannelType: {
      instagram: '인스타그램',
      youtube: '유튜브',
      facebook: '페이스북',
      tiktok: '틱톡',
      blog: '블로그',
      list: [
        { label: '인스타그램', value: 'instagram' },
        { label: '유튜브', value: 'youtube' },
        { label: '페이스북', value: 'facebook' },
        { label: '틱톡', value: 'tiktok' },
        { label: '블로그', value: 'blog' }
      ]
    }
  },
  ADMINISTRATOR: {
    status: {
      active: '정상',
      inactive: '중지',
      list: [
        { label: '정상', value: 'active' },
        { label: '중지', value: 'inactive' }
      ]
    }
  },
  OPERATOR: {
    status: {
      all: '전체',
      active: '정상',
      inactive: '탈퇴',
      list: [
        { label: '전체', value: null },
        { label: '정상회원', value: 'active' },
        { label: '탈퇴회원', value: 'inactive' }
      ]
    }
  },
  STYLEMATE: {
    stylemateStatus: {
      all: '전체',
      approve: '승인',
      cancel: '승인취소',
      request: '요청',
      hold: '보류',
      list: [
        { label: '전체', value: null },
        { label: '승인', value: 'approve' },
        { label: '승인취소', value: 'cancel' },
        { label: '요청', value: 'request' },
        { label: '보류', value: 'hold' }
      ]
    }
  },
  PRODUCT_MANAGE: {
    saleStatus: {
      standby: '대기',
      progress: '판매중',
      stop: '판매중지',
      termination: '판매종료',
      list: [
        { label: '대기', value: 'standby' },
        { label: '판매중', value: 'progress' },
        { label: '판매중지', value: 'stop' },
        { label: '판매종료', value: 'termination' }
      ],
      standbyList: [
        { label: '대기', value: 'standby' },
        { label: '판매중', value: 'process' }
      ],
      processList: [
        { label: '판매중', value: 'process' },
        { label: '판매중지', value: 'stop' },
        { label: '판매종료', value: 'termination' }
      ],
      stopList: [
        { label: '판매중', value: 'process' },
        { label: '판매중지', value: 'stop' },
        { label: '판매종료', value: 'termination' }
      ]
    },
    platform: {
      mediance: '미디언스 와이드',
      list: [
        { label: '미디언스 와이드', value: 'mediance' }
      ]
    },
    channelType: {
      instagram: '인스타그램',
      list: [
        { label: '인스타그램', value: 'instagram' }
      ]
    },
    productRewardType: {
      none: '협찬미포함',
      product: '유형상품배송',
      intangible_product: '무형상품배송',
      direct: '참여자직접구매',
      list: [
        { label: '협찬미포함', value: 'none' },
        { label: '유형상품배송', value: 'product' },
        { label: '무형상품배송', value: 'intangible_product' },
        { label: '참여자직접구매', value: 'direct' }
      ]
    },
    isManuscriptPayment: {
      0: '미지급',
      1: '지급',
      list: [
        { label: '미지급', value: false },
        { label: '지급', value: true }
      ]
    },
    manuscriptPaymentType: {
      fixed: '고정',
      flexed: '고정포인트(차등))',
      list: [
        { label: '고정', value: 'fixed' },
        { label: '고정포인트(차등)', value: 'flexed' }
      ]
    },
    campaignValueType: {
      auto: '자동',
      manual: '수동',
      list: [
        { label: '자동', value: 'auto' },
        { label: '수동', value: 'manual' }
      ]
    },
    specificTargetSale: {
      0: '해당없음',
      agency: '에이전시',
      business: '광고주',
      specified: '특정비즈니스',
      list: [
        { label: '해당없음', value: 0 },
        { label: '에이전시', value: 'agency' },
        { label: '광고주', value: 'business' },
        { label: '특정비즈니스', value: 'specified' }
      ]
    },
    copyright: {
      isBrandOnline: '브랜드 공식 온라인 채널',
      isBrand: '브랜드 커머스 라이선스',
      isDistribution: '유통 커머스 라이선스',
      isAd: '광고 라이선스',
      isPortraitRights: '초상/성명권',
      isEditing: '편집 라이선스',
      isMediance: '미디언스 저작사용',
      list: [
        { label: '브랜드 공식 온라인 채널', value: 'isBrandOnline' },
        { label: '브랜드 커머스 라이선스', value: 'isBrand' },
        { label: '유통 커머스 라이선스', value: 'isDistribution' },
        { label: '광고 라이선스', value: 'isAd' },
        { label: '초상/성명권', value: 'isPortraitRights' },
        { label: '편집 라이선스', value: 'isEditing' },
        { label: '미디언스 저작사용', value: 'isMediance' }
      ]
    },
    searchDateType: {
      createdAt: '등록일',
      saleStartedAt: '판매시작일',
      saleFinishedAt: '판매종료일',
      updatedAt: '수정일',
      list: [
        { label: '등록일', value: 'createdAt' },
        { label: '판매시작일', value: 'saleStartedAt' },
        { label: '판매종료일', value: 'saleFinishedAt' },
        { label: '수정일', value: 'updatedAt' }
      ]
    },
    saleType: {
      exclusive: '전용 상품',
      package: '패키지 상품',
      list: [
        { label: '전용 상품', value: 'exclusive' },
        { label: '패키지 상품', value: 'package' }
      ]
    },
    saleProduct: {
      1: '셀프캠페인 상품',
      2: 'MBCA 상품',
      list: [
        { label: '셀프캠페인 상품', value: 1 },
        { label: 'MBCA 상품', value: 2 }
      ]
    }
  },
  PRODUCT_ORDER: {
    searchDateType: {
      createdAt: '주문일',
      approvaledAt: '구매확정일',
      cancelRequestAt: '취소신청일',
      canceledAt: '취소완료일',
      list: [
        { label: '주문일', value: 'createdAt' },
        { label: '구매확정일', value: 'approvaledAt' },
        { label: '취소신청일', value: 'cancelRequestAt' },
        { label: '취소완료일', value: 'canceledAt' }
      ],
      searchList: [
        { label: '주문일', value: 'businessOrder.createdAt' },
        { label: '구매확정일', value: 'campaign.createdAt' },
        // { label: '취소신청일', value: 'cancelRequestAt' },
        { label: '취소완료일', value: 'businessOrderProduct.canceledAt' }
      ]
    },
    orderStatus: {
      ready: '결제 대기',
      complete: '결제 완료',
      error: '결제 오류',
      close: '결제 창 닫음',
      autoCancel: '결제 자동취소', // 결제 오류 시 자동 취소
      cancel: '결제 취소', // 사용자 취소
      list: [
        { label: '결제 대기', value: 'ready' },
        { label: '결제 완료', value: 'complete' },
        { label: '결제 오류', value: 'error' },
        { label: '결제 창 닫음', value: 'close' },
        { label: '결제 자동취소', value: 'autoCancel' },
        { label: '결제 취소', value: 'cancel' }
      ]
    },
    paymentType: {
      card: '신용카드',
      // transfer: '계좌이체',
      list: [
        { label: '신용카드', value: 'card' }
        // { label: '계좌이체', value: 'transfer' }
      ]
    }
  },
  SELF_CAMPAIGN: {
    status: {
      standby: '대기',
      impossibility: '집행불가',
      examinationModifyRequest: '수정요청',
      examination: '검수중',
      complete: '검수완료',
      etc: '기타',
      list: [
        { label: '대기', value: 'standby' },
        { label: '검수중', value: 'examination' },
        { label: '집행불가', value: 'impossibility' },
        { label: '수정요청', value: 'examinationModifyRequest' },
        { label: '검수완료', value: 'complete' },
        { label: '기타', value: 'etc' }
      ],
      evaluationList: [
        { label: '검수완료', value: 'complete' },
        { label: '수정요청', value: 'examinationModifyRequest' },
        { label: '집행불가', value: 'impossibility' }
      ]
    },
    impossibilityType: {
      1: '캠페인 집행불가 업종',
      2: '심의기준 부적합 캠페인',
      4: '상품과 캠페인 내용 상이',
      8: '기타',
      list: [
        { label: '캠페인 집행불가 업종', value: 1 },
        { label: '심의기준 부적합 캠페인', value: 2 },
        { label: '상품과 캠페인 내용 상이', value: 4 },
        { label: '기타', value: 8 }
      ]
    }
  },
  SERVICE: {
    type: {
      mediance: '미디언스(labs 포함)',
      stylemate: '스타일메이트',
      bizcenter: '비즈센터',
      list: [
        { label: '미디언스(labs 포함)', value: 'mediance' },
        { label: '스타일메이트', value: 'stylemate' },
        { label: '비즈센터', value: 'bizcenter' }
      ]
    }
  },
  MBCA: {
    // abtest도 진행중으로 노출
    processDetailStatus: {
      standby: '임시저장',
      examination: '검수요청',
      examinationReject: '검수반려',
      examinationComplete: '진행예정',
      examinationCancel: '검수취소',
      abTest: '진행중',
      progress: '진행중',
      pause: '광고중단',
      finish: '종료',
      list: [
        { label: '임시저장', value: 'standby' },
        { label: '검수요청', value: 'examination' },
        { label: '검수반려', value: 'examinationReject' },
        { label: '진행예정', value: 'examinationComplete' },
        { label: '검수취소', value: 'examinationCancel' },
        { label: '진행중', value: 'abTest' },
        { label: '진행중', value: 'progress' },
        { label: '광고중단', value: 'pause' },
        { label: '종료', value: 'finish' }
      ]
    },
    mediaType: {
      facebook: '페이스북',
      instagram: '인스타그램',
      youtube: '유튜브',
      google: '구글',
      list: [
        { label: '페이스북', value: 'facebook' },
        { label: '인스타그램', value: 'instagram' },
        { label: '유튜브', value: 'youtube' },
        { label: '구글', value: 'google' }
      ]
    },
    purpose: {
      impression: '노출',
      conversion: '전환',
      list: [
        { label: '노출', value: 'impression' },
        { label: '전환', value: 'conversion' }
      ]
    },
    creativeStatus: {
      examination: '검수중',
      examinationComplete: '검수확인',
      examinationReject: '소재반려',
      pause: '소재광고중단',
      list: [
        { label: '검수중', value: 'examination' },
        { label: '검수확인', value: 'examinationComplete' },
        { label: '소재반려', value: 'examinationReject' },
        { label: '소재광고중단', value: 'pause' }
      ]
    },
    performance: {
      click: '클릭수',
      cpm: 'cpm',
      cpc: 'cpc',
      thruPlay: 'thruplay',
      cost: '사용 광고비',
      conversion_cost: '전환금액',
      'bca_ad_report.impression': '노출수',
      'bca_ad_report.view_count': '조회수',
      list: [
        { label: '노출수', value: 'impression' },
        { label: '조회수', value: 'viewCount' },
        { label: '클릭수', value: 'click' },
        { label: 'cpm', value: 'cpm' },
        { label: 'cpc', value: 'cpc' },
        { label: 'thruplay', value: 'thruPlay' },
        { label: '사용 광고비', value: 'cost' },
        { label: '전환금액', value: 'conversionCost' }
      ]
    },
    adType: {
      image: '이미지',
      video: '비디오',
      carousel: '캐러셀',
      list: [
        { label: '이미지', value: 'image' },
        { label: '비디오', value: 'video' },
        { label: '캐러셀', value: 'carousel' }
      ]
    },
    gender: {
      male: '남자',
      female: '여자',
      list: [
        { label: '남자', value: 1 },
        { label: '여자', value: 2 }
      ]
    },
    ageMin: {
      list: [
        { value: 13, label: '13세 이상' },
        { value: 18, label: '18세 이상' },
        { value: 25, label: '25세 이상' },
        { value: 35, label: '35세 이상' },
        { value: 45, label: '45세 이상' },
        { value: 55, label: '55세 이상' },
        { value: 65, label: '65세 이상' }
      ]
    },
    ageMax: {
      list: [
        { value: 17, label: '17세 이하' },
        { value: 24, label: '24세 이하' },
        { value: 34, label: '34세 이하' },
        { value: 44, label: '44세 이하' },
        { value: 54, label: '54세 이하' },
        { value: 64, label: '64세 이하' },
        { value: null, label: '100세 미만' }
      ]
    },
    requestStatus: {
      ready: '대기',
      progress: '진행중',
      fail: '실패',
      success: '성공',
      list: [
        { value: 'ready', label: '대기' },
        { value: 'progress', label: '진행중' },
        { value: 'fail', label: '실패' },
        { value: 'success', label: '성공' }
      ]
    }
  }
}
