import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import { PAGE, PAGE_TITLE } from '@@routes'
import Common from './Common'
import Service from './Service'
import Business from './Businesses'
import Campaign from './Campaign'
import Bca from './Bca'
import Influencer from './Influencer'
import Settlement from './Settlement'
import ProductManage from './ProductManage'
import Etc from './Etc'
// import Statistics from './Statistics'
import Operation from './Operation'
import Homepage from './Homepage'
import LiveCast from './LiveCast'
import Stylemate from './Stylemate'
import { setAuthInfoCookies, checkAuthCookies } from '@/plugins/authService'
import { useRouterBefore } from '@/composables/routerBefore'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "noMatch" */ '@page/Error.vue')
  },
  {
    path: '',
    name: PAGE.AUTH,
    redirect: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@page/Auth/Index.vue'),
    children: [
      {
        name: PAGE.LOGIN,
        path: '/auth',
        component: () => import(/* webpackChunkName: "Login" */ '@page/Auth/Login.vue'),
        meta: {
          title: PAGE_TITLE.LOGIN
        }
      },
      {
        name: PAGE.PASSWORD_RESET,
        path: '/passwordReset',
        component: () => import(/* webpackChunkName: "Login" */ '@page/Auth/PasswordReset.vue'),
        meta: {
          title: PAGE_TITLE.PASSWORD_RESET
        }
      },
      {
        name: PAGE.PASSWORD_CHANGE,
        path: '/passwordChange/:token',
        component: () => import(/* webpackChunkName: "Login" */ '@page/Auth/PasswordChange.vue'),
        meta: {
          title: PAGE_TITLE.PASSWORD_CHANGE
        }
      },
      {
        name: PAGE.INVITATION,
        path: '/invitation/:token',
        component: () => import(/* webpackChunkName: "Login" */ '@page/Auth/Invitation.vue'),
        meta: {
          title: PAGE_TITLE.INVITATION
        }
      },
      {
        name: PAGE.JOIN,
        path: '/join/:token',
        component: () => import(/* webpackChunkName: "Login" */ '@page/Auth/Join.vue'),
        meta: {
          title: PAGE_TITLE.JOIN
        }
      },
      {
        name: PAGE.JOIN_COMPUTED,
        path: '/joinComputed',
        component: () => import(/* webpackChunkName: "Login" */ '@page/Auth/JoinComputed.vue'),
        meta: {
          title: PAGE_TITLE.JOIN_COMPUTED
        }
      }
    ]
  },
  {
    path: '/',
    name: 'root',
    redirect: '/business',
    component: () => import(/* webpackChunkName: "root" */ '@page/WorkRoot.vue'),
    children: [
      Common,
      Service,
      Business,
      Campaign,
      Bca,
      Stylemate,
      Influencer,
      Settlement,
      // Statistics,
      Operation,
      Homepage,
      LiveCast,
      ProductManage,
      ...Etc
    ]
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    await setAuthInfoCookies(to.query)
    next({ path: to.path }, false)
  } else {
    if (checkAuthCookies()) {
      // 로그인 O
      const { routerBeforeLoginActions } = useRouterBefore()
      await routerBeforeLoginActions(to)
      await store.dispatch('auth/isLogined')
    } else {
      // 로그인 X
      await store.dispatch('auth/logout')
    }
    next()
  }
})

export default router
