import { PAGE, PAGE_TITLE, PAGE_TAB } from '@@routes'

export default {
  path: '/common',
  name: PAGE.COMMON,
  component: () => import(/* webpackChunkName: "operator" */'@page/Common/Index.vue'),
  meta: {
    serviceName: PAGE.COMMON,
    hide: true
  },
  children: [
    {
      path: 'operator',
      name: PAGE.COMMON_OPERATOR,
      redirect: '/common/operator/list',
      component: () => import(/* webpackChunkName: "operator" */ '@page/Common/OperatorManage/Index.vue'),
      meta: {
        title: PAGE_TITLE.COMMON_OPERATOR,
        menuName: PAGE.COMMON_OPERATOR,
        roleKeys: { menu: 'config', subMenu: 'operatorManager' }
      },
      children: [
        {
          path: 'list/:page?',
          name: PAGE.COMMON_OPERATOR_LIST,
          component: () => import(/* webpackChunkName: "operator" */ '@page/Common/OperatorManage/List.vue')
        },
        {
          path: 'detail',
          name: PAGE.COMMON_OPERATOR_DETAIL,
          redirect: '/common/operator/detail/basic',
          component: () => import(/* webpackChunkName: "operator" */ '@page/Common/OperatorManage/Detail.vue'),
          meta: {
            pageTitle: PAGE_TITLE.COMMON_OPERATOR_DETAIL,
            tabName: PAGE.COMMON_OPERATOR_DETAIL
          },
          children: [
            {
              path: 'basic/:id?',
              name: PAGE.COMMON_OPERATOR_DETAIL_BASIC,
              component: () => import(/* webpackChunkName: "operator" */ '@page/Common/OperatorManage/DetailBasic.vue'),
              meta: {
                title: PAGE_TAB.COMMON_OPERATOR_DETAIL_BASIC
              }
            },
            {
              path: 'authority/:id?',
              name: PAGE.COMMON_OPERATOR_DETAIL_AUTHORITY,
              component: () => import(/* webpackChunkName: "operator" */ '@page/Common/OperatorManage/DetailAuthority.vue'),
              meta: {
                title: PAGE_TAB.COMMON_OPERATOR_DETAIL_AUTHORITY
              }
            }
          ]
        }
      ]
    },
    {
      path: 'role',
      name: PAGE.COMMON_ROLE,
      redirect: '/common/role/list',
      component: () => import(/* webpackChunkName: "role" */ '@page/Common/Role/Index.vue'),
      meta: {
        title: PAGE_TITLE.COMMON_ROLE,
        menuName: PAGE.COMMON_ROLE,
        roleKeys: { menu: 'config', subMenu: 'roleManager' }
      },
      children: [
        {
          path: 'list/:page?',
          name: PAGE.COMMON_ROLE_LIST,
          component: () => import(/* webpackChunkName: "role" */ '@page/Common/Role/List.vue')
        },
        {
          path: 'form',
          name: PAGE.COMMON_ROLE_FORM,
          redirect: '/common/group/form/basic',
          component: () => import(/* webpackChunkName: "role" */ '@page/Common/Role/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.COMMON_ROLE_FORM,
            tabName: PAGE.COMMON_ROLE_FORM
          },
          children: [
            {
              path: 'basic/:id?',
              name: PAGE.COMMON_ROLE_FORM_BASIC,
              component: () => import(/* webpackChunkName: "role" */ '@page/Common/Role/FormBasic.vue'),
              meta: {
                title: PAGE_TAB.COMMON_ROLE_FORM_BASIC
              }
            },
            {
              path: 'authority/:id?',
              name: PAGE.COMMON_ROLE_FORM_AUTHORITY,
              component: () => import(/* webpackChunkName: "role" */ '@page/Common/Role/FormAuthority.vue'),
              meta: {
                title: PAGE_TAB.COMMON_ROLE_FORM_AUTHORITY,
                isParams: true
              }
            },
            {
              path: 'client/:id?',
              name: PAGE.COMMON_ROLE_FORM_OPERATOR_MANAGE,
              component: () => import(/* webpackChunkName: "role" */ '@page/Common/Role/FormOperationManage.vue'),
              meta: {
                title: PAGE_TAB.COMMON_ROLE_FORM_OPERATOR_MANAGE,
                isParams: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'notice',
      name: PAGE.COMMON_NOTICE,
      redirect: '/common/notice/list/error',
      component: () => import(/* webpackChunkName: "noticeAlarm" */ '@page/Common/Notice/Index.vue'),
      meta: {
        title: PAGE_TITLE.COMMON_NOTICE,
        menuName: PAGE.COMMON_NOTICE,
        hide: true,
        roleKeys: { menu: 'config', subMenu: 'roleManager' }
      },
      children: [
        {
          path: 'list/:type?',
          name: PAGE.COMMON_NOTICE_LIST,
          component: () => import(/* webpackChunkName: "noticeAlarm" */ '@page/Common/Notice/List.vue')
        }
      ]
    },
    {
      path: 'self',
      name: PAGE.COMMON_SELF_CAMPAIGN,
      redirect: '/common/self/list/campaign',
      component: () => import(/* webpackChunkName: "noticeAlarm" */ '@page/Common/SelfCampaign/Index.vue'),
      meta: {
        title: PAGE_TITLE.COMMON_SELF_CAMPAIGN,
        menuName: PAGE.COMMON_SELF_CAMPAIGN,
        hide: true,
        roleKeys: { menu: 'config', subMenu: 'roleManager' }
      },
      children: [
        {
          path: 'list/:type?',
          name: PAGE.COMMON_SELF_CAMPAIGN_LIST,
          component: () => import(/* webpackChunkName: "noticeAlarm" */ '@page/Common/SelfCampaign/List.vue')
        }
      ]
    }
  ]
}
