import { PAGE, PAGE_TITLE, PAGE_TAB } from '@@routes'

export default {
  path: '/liveCommerce',
  name: PAGE.LIVE_CAST,
  redirect: '/liveCommerce/showHost',
  component: () => import(/* webpackChunkName: "showHost" */ '@page/LiveCast/Index.vue'),
  meta: {
    title: PAGE_TITLE.LIVE_CAST,
    serviceName: PAGE.LIVE_CAST,
    useIcon: 'ri-gift-line'
  },
  children: [
    {
      path: 'showHost',
      name: PAGE.LIVE_CAST_SHOW_HOST,
      redirect: '/liveCommerce/showHost/list/1',
      component: () => import(/* webpackChunkName: "showHost" */ '@page/LiveCast/ShowHost/Index.vue'),
      meta: {
        title: PAGE_TITLE.LIVE_CAST_SHOW_HOST,
        menuName: PAGE.LIVE_CAST_SHOW_HOST,
        roleKeys: { menu: 'liveCommerce', subMenu: 'searchShowhost' }
      },
      children: [
        {
          path: 'list/:page?',
          name: PAGE.LIVE_CAST_SHOW_HOST_LIST,
          component: () => import(/* webpackChunkName: "showHost" */ '@page/LiveCast/ShowHost/List.vue'),
          meta: {
            title: PAGE_TITLE.LIVE_CAST_SHOW_HOST,
            menuName: PAGE.LIVE_CAST_SHOW_HOST_LIST
          }
        },
        {
          path: 'form/:id?',
          name: PAGE.LIVE_CAST_SHOW_HOST_FORM,
          component: () => import(/* webpackChunkName: "showHost" */ '@page/LiveCast/ShowHost/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.LIVE_CAST_SHOW_HOST_FORM,
            hide: true
          }
        },
        {
          path: 'detail/:id',
          name: PAGE.LIVE_CAST_SHOW_HOST_DETAIL,
          component: () => import(/* webpackChunkName: "showHost" */ '@page/LiveCast/ShowHost/Detail.vue'),
          meta: {
            pageTitle: PAGE_TITLE.LIVE_CAST_SHOW_HOST_DETAIL,
            tabName: PAGE.LIVE_CAST_SHOW_HOST_DETAIL,
            hide: true
          },
          children: [
            {
              path: 'info',
              name: PAGE.LIVE_CAST_SHOW_HOST_INFO,
              component: () => import(/* webpackChunkName: "showHost" */ '@page/LiveCast/ShowHost/DetailInfo.vue'),
              meta: {
                title: PAGE_TAB.LIVE_CAST_SHOW_HOST_INFO
              }
            },
            {
              path: 'broadcast',
              name: PAGE.LIVE_CAST_SHOW_HOST_BROADCAST,
              component: () => import(/* webpackChunkName: "showHost" */ '@page/LiveCast/ShowHost/DetailBroadcast.vue'),
              meta: {
                title: PAGE_TAB.LIVE_CAST_SHOW_HOST_BROADCAST
              }
            }
          ]
        }
      ]
    },
    {
      path: 'studio',
      name: PAGE.LIVE_CAST_STUDIO,
      component: () => import(/* webpackChunkName: "showHost" */ '@page/LiveCast/Studio/Index.vue'),
      meta: {
        title: PAGE_TITLE.LIVE_CAST_STUDIO,
        menuName: PAGE.LIVE_CAST_STUDIO,
        roleKeys: { menu: 'liveCommerce', subMenu: 'searchShowhost' }
      }
    }
  ]
}
