import { CODE_DATA } from '@/constants/codes'
import store from '@/store'
import { computed } from 'vue'

export const getTagTypeList = async () => {
  let tagTypeList = store.getters['tags/tagTypeList']
  if (tagTypeList.length === 0) {
    await store.dispatch('tags/getTagTypeList').then(() => {
      tagTypeList = store.getters['tags/tagTypeList']
      TABS.InfluencesTagManage.list = tagTypeList
    })
  } else {
    TABS.InfluencesTagManage.list = tagTypeList
  }
}

export const TABS = {
  CommonOperatorManageDetail: {
    type: 'router',
    queryHide: true
  },
  CommonRole: {
    type: 'router',
    remove: {
      CommonRoleFormAuthority: { value: ['', null, undefined], key: 'id' },
      CommonRoleFormClient: { value: ['', null, undefined], key: 'id' }
    },
    queryHide: true
  },
  CommonNoticeList: {
    type: 'params',
    key: 'type',
    defaultValue: 'error',
    list: [
      { label: '포스트 트레킹', value: 'error' },
      { label: '연결 유실', value: 'lostConnect' },
      { label: '인스타그램 좋아요/댓글 숨김', value: 'alarm' }
    ]
  },
  CommonSelfCampaignList: {
    type: 'params',
    key: 'type',
    defaultValue: 'campaign',
    list: [
      { label: '광고주캠페인문의', value: 'campaign' },
      { label: '포스트수정문의', value: 'post' },
      { label: '확인중', value: 'check' }
    ]
  },

  BusinessManageForm: {
    type: 'router',
    remove: {
      BusinessManageFormCampaign: { value: ['', null, undefined], key: 'id' }
    },
    count: {
      BusinessManageFormCampaign: computed(() => store.getters['businesses/campaignCount'])
    },
    queryHide: true
  },

  BusinessManageDetail: {
    type: 'router',
    remove: {
      // BusinessManageDetailCampaign: { value: ['', null, undefined], key: 'id' },
      BusinessManageDetailMember: { value: ['', null, undefined], key: 'id' },
      BusinessManageDetailCampaign: { value: ['', null, undefined], key: 'id' },
      BusinessManageDetailBrand: { value: ['', null, undefined], key: 'id' },
      BusinessManageDetailProduct: { value: ['', null, undefined], key: 'id' }
      // BusinessManageDetailHistory: { value: ['', null, undefined], key: 'id' }
    },
    count: {
      BusinessManageDetailMember: computed(() => store.getters['businesses/manageOperatorsCount'])
    },
    queryHide: true
  },

  BusinessAgencyDetail: {
    type: 'router',
    remove: {
      // BusinessAgencyDetailCampaign: { value: ['', null, undefined], key: 'id' },
      BusinessAgencyDetailBusiness: { value: ['', null, undefined], key: 'id' },
      BusinessAgencyDetailMember: { value: ['', null, undefined], key: 'id' }
      // BusinessAgencyDetailProduct: { value: ['', null, undefined], key: 'id' }
      // BusinessAgencyDetailHistory: { value: ['', null, undefined], key: 'id' }
    },
    count: {
      BusinessAgencyDetailBusiness: computed(() => store.getters['businesses/agencyBusinessesCount']),
      BusinessAgencyDetailMember: computed(() => store.getters['businesses/agencyOperatorsCount'])
    },
    queryHide: true
  },

  InfluencesSearchList: {
    type: 'params',
    key: 'type',
    defaultValue: 'normal',
    list: [
      { label: '정상회원', value: 'normal' },
      // { label: '휴면회원', value: 'dormancy' },
      { label: '탈퇴회원', value: 'secede' },
      { label: '단가회원', value: 'cost' }
    ]
  },
  InfluencesSearch: {
    type: 'router',
    remove: {
      InfluencesSearchDetailInquiry: { value: [true], key: 'isManaged' },
      InfluencesSearchDetailCost: { value: [false], key: 'isManaged' }
    },
    queryHide: true
  },
  InfluencesChannel: {
    type: 'params',
    key: 'type',
    defaultValue: 'instagram',
    list: CODE_DATA.CHANNEL.type.list
  },
  InfluencesTagManage: {
    type: 'params',
    key: 'type',
    defaultValue: 'channelCategory',
    keyOption: { valueKey: 'type', textKey: 'name' },
    list: []
  },
  OperationQnas: {
    type: 'router',
    queryHide: true
  },
  OperationFaq: {
    type: 'router',
    queryHide: true
  },
  OperationMedianceFaqList: {
    type: 'query',
    key: 'category',
    defaultValue: 'campaign',
    list: CODE_DATA.OPERATION.faqCategory.list,
    queryHide: true
  },
  OperationStylemateFaqList: {
    type: 'query',
    key: 'category',
    defaultValue: 'sponsorship',
    list: CODE_DATA.OPERATION.faqStylemateCategory.list,
    queryHide: true
  },
  OperationMbpFaqList: {
    type: 'query',
    key: 'category',
    defaultValue: 'campaign',
    list: CODE_DATA.OPERATION.faqMbpCategory.list,
    queryHide: true
  },
  OperationTerms: {
    type: 'router',
    queryHide: true
  },
  OperationMessage: {
    type: 'router',
    noneReplaceActiveTab: true,
    queryHide: true
  },
  OperationAlimtalk: {
    type: 'router',
    noneReplaceActiveTab: true,
    queryHide: true
  },
  OperationChannelGuide: {
    type: 'router',
    queryHide: true
  },

  LiveCastShowHost: {
    type: 'router',
    queryHide: true
  },

  CampaignProgress: {
    type: 'router',
    count: {
      bookings: 'CampaignProgressManageApplicant',
      joins: 'CampaignProgressManageParticipant',
      posts: 'CampaignProgressManageProgressManage'
    },
    queryHide: true
  },
  CampaignEnd: {
    type: 'router',
    count: {
      bookings: 'CampaignEndManageApplicant',
      joins: 'CampaignEndManageParticipant',
      posts: 'CampaignEndManageProgressManage'
    },
    queryHide: true
  },

  StylemateFrontManage: {
    type: 'router',
    queryHide: true
  },
  StylemateLikeManage: {
    type: 'router',
    queryHide: true
  },

  BcaCampaign: {
    type: 'router',
    queryHide: true
  },

  BcaReport: {
    type: 'router',
    queryHide: true
  },

  ProductManage: {
    type: 'router',
    remove: {
      ProductManageFormContent: { value: ['', null, undefined], key: 'id' }
    }
  }
}
