import axios from '@/plugins/axios'
import { SET_BANNER_LIST, SET_BANNER_DETAIL } from '@/store/mutation_types'
import { objectToString } from '@/utils/object'
import { dateStandardFormat } from '@/utils/date'

const state = {
  bannerModel: [],
  bannerDetail: []
}

const getters = {
  bannerListData: state => state.bannerModel,
  bannerDetail: state => state.bannerDetail
}

const actions = {
  async getBannerList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/banners',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_BANNER_LIST, response)
    })
  },
  async setBannerOrdering ({ commit }, { list, serviceType }) {
    const config = {
      method: 'PATCH',
      url: '/_p/banners/ordering',
      params: {
        order: 'ordering',
        ids: objectToString(list, 'id'),
        serviceType
      }
    }
    const result = await axios(config)
    return result
  },
  async getBannerItemDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/banners/${id}`
    }

    await axios(config).then(response => {
      commit(SET_BANNER_DETAIL, response)
    })
  },
  async createBannerItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/banners',
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async saveBannerItem ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/banners/${id}`,
      data: {
        ...data,
        startedAt: `${dateStandardFormat(data.startedAt)}:00`,
        finishedAt: `${dateStandardFormat(data.finishedAt)}:00`
      }
    }
    const result = await axios(setting)
    return result
  },
  async deleteBannerItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/banners/${id}`
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_BANNER_LIST]: (state, payload) => {
    state.bannerModel = payload
  },
  [SET_BANNER_DETAIL]: (state, payload) => {
    state.bannerDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
