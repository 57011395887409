import axios from '@/plugins/axios'
import { SET_QNA_LIST, SET_QNA_DETAIL } from '@/store/mutation_types'

const state = {
  qnaModel: {},
  qnaDetail: {}
}

const getters = {
  qnaListData: state => state.qnaModel,
  qnaDetail: state => state.qnaDetail,
  qnaList: state => state.qnaModel.data,
  qnaPaging: state => {
    return {
      total: state.qnaModel.meta.total,
      current: state.qnaModel.meta.current_page
    }
  }
}

const actions = {
  async getQnaList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/qnas',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_QNA_LIST, response)
    })
  },
  async getQnaItemDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/qnas/${id}`
    }

    await axios(config).then(response => {
      commit(SET_QNA_DETAIL, response)
    })
  },
  async saveQnaItemAnswer ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/qnas/${id}/answer`,
      data: {
        ...data
      }
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_QNA_LIST]: (state, payload) => {
    state.qnaModel = payload
  },
  [SET_QNA_DETAIL]: (state, payload) => {
    state.qnaDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
