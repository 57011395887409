<template>
  <!-- <img :src="fileData.image"> -->
  <div class="custom-uploader">
    <div class="custom-uploader__group">
      <div class="custom-uploader__button">
        <vxe-button
          block
          :content="props.text"
          icon="ri-upload-2-line"
          :disabled="props.disabled || (props.maxCount > 1 && fileData.completeFiles?.length >= props.maxCount)"
          :loading="fileData.loading"
        />
        <input
          type="file"
          :accept="props.accept"
          :multiple="props.maxCount > 1"
          :disabled="props.disabled || (props.maxCount > 1 && fileData.completeFiles?.length >= props.maxCount)"
          @change="fileActions.onFilePicked"
        >
      </div>
      <div
        v-if="props.content"
        class="custom-uploader__text"
      >
        {{ props.content }}
      </div>
      <div
        v-if="$slots['etc']"
        class="custom-uploader__etc"
      >
        <slot name="etc" />
      </div>
    </div>
    <template v-if="props.isThumbnail">
      <div
        v-if="props.type === 'file'"
      >
        <template v-if="fileData.files[0]">
          <div>
            <i class="ri-file-list-line" />
            {{ fileData.files[0]?.name }}
          </div>
        </template>
        <template v-else-if="fileData.completeFiles.length > 0">
          <div
            v-for="(item, index) in fileData.completeFiles"
            :key="index"
            class="custom-uploader__file"
          >
            <i class="ri-file-list-line" />
            <span>
              {{ item[props.fileNameKey] }}
            </span>
            <div
              v-if="props.maxCount > 1"
              class="uploader__file--feature"
            >
              <vxe-button
                size="mini"
                icon="ri-close-line"
                status="danger"
                :disabled="props.disabled"
                :style="{ width: '30px', marginLeft: '8px'}"
                @click="fileActions.deleteFile(index, item)"
              />
            </div>
          </div>
        </template>
      </div>
      <template v-else>
        <div
          v-if="fileData.completeFiles.length > 0 && !props.hideInfo"
          class="custom-uploader__list"
        >
          <div
            v-for="(image,index) in fileData.completeFiles"
            :key="index"
            class="custom-uploader__item"
            :class="{'custom-uploader__item--selected': image.isRepresentation}"
            @click="fileActions.selectFile(index, image)"
          >
            <span
              class="custom-uploader__thumbnail"
            >
              <img :src="image[props.imagePathKey] || image">
            </span>
            <div
              v-if="props.maxCount > 1"
              class="custom-uploader__feature"
            >
              <vxe-button
                size="mini"
                icon="ri-close-line"
                circle
                :disabled="props.disabled"
                @click="fileActions.deleteFile(index, image)"
              />
            </div>
          </div>
        </div>
      </template>
    </template>
    <div
      v-if="fileData.error"
      class="custom-uploader__error"
    >
      {{ fileData.error }}
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, nextTick } from 'vue'
import dayjs from 'dayjs'
import store from '@/store/index.js'
import cloneDeep from 'lodash/cloneDeep'
import imageCompress from '@/plugins/imageCompress.js'
const props = defineProps({
  modelValue: {
    type: [Array, String, Object],
    default: () => []
  },
  showList: {
    type: [Array, String],
    default: () => []
  },
  addList: {
    type: [Array, String],
    default: () => []
  },
  deleteList: {
    type: Array,
    default: () => []
  },
  maxCount: {
    type: Number,
    default: 1
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fileSize: {
    type: Number,
    default: null
  },
  content: {
    type: String,
    default: null
  },
  imagePathKey: {
    type: String,
    default: 'imagePath'
  },
  fileNameKey: {
    type: String,
    default: 'name'
  },
  accept: {
    type: String,
    default: '.gif, .jpg, .png, .svg'
  },
  type: {
    type: String,
    default: 'image'
  },
  isThumbnail: {
    type: Boolean,
    default: true
  },
  imageSize: {
    type: [Boolean, Object],
    default: false
  },
  text: {
    type: String,
    default: '업로드'
  },
  hideInfo: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'update:showList', 'update:addList', 'update:deleteList', 'change', 'selected'])

const fileData = reactive({
  imagesSize: [],
  files: [],
  presignedUrl: [],
  completeFiles: [],
  deleteFiles: [],
  temp: [],
  fileSignedUrl: [],
  state: false,
  images: null,
  error: null,
  addList: [],
  loading: false
})
const fileActions = {
  onFilePicked: async (e) => {
    if (props.type === 'image') {
      const format = props.accept.split(',') || ['.jpg', '.jpeg', '.png', '.svg']
      const target = e.target.files
      let checkPart = true
      let imageSizeCheck = true

      for (let i = 0; i < target.length; i++) {
        const lastName = target[i].name.substring(target[i].name.lastIndexOf('.'), target[i].name.length).toLocaleLowerCase()
        const check = format.find((item) => {
          return item.trim() === lastName.toLocaleLowerCase()
        })
        if (props.imageSize) {
          const dimensions = await fileActions.readImageFile(target[i])
          if (dimensions.width > props.imageSize.width || dimensions.height > props.imageSize.height) {
            imageSizeCheck = false
            break
          }
        }
        if (!check || (props.maxCount === 1 && props.fileSize && props.fileSize < target[i].size / 1024)) {
          checkPart = false
          break
        }
      }

      if (!checkPart || !imageSizeCheck) {
        fileData.error = !checkPart ? '파일 용량 또는 파일 형식을 확인해주세요.' : '이미지 사이즈를 확인해주세요.'

        if (props.maxCount === 1) {
          fileData.completeFiles = []
          emit('update:modelValue', '')
        }
      } else {
        fileData.error = null
        imageCompress(target, fileActions.onUpload)
      }
    } else {
      fileData.state = true
      fileData.files = e.target.files
      if (props.maxCount > 1) {
        fileActions.onUpload(e.target.files, 'files')
      }
      emit('change', e.target.files)
    }
  },
  onUpload: async (e, type) => {
    if (type !== 'files') {
      fileData.files = [
        ...e
      ]
    }
    for (let index = 0; index < fileData.files.length; index++) {
      await apiActions.getSignedUrl(fileData.files[index]).then(async response => {
        const config = {
          url: fileData.presignedUrl[index].url,
          file: fileData.files[index],
          name: fileData.files[index].name
        }
        await apiActions.uploadSignedUrl(config)
      })
    }
    if (props.maxCount > 1) {
      const merge = [...fileData.completeFiles, ...fileData.fileSignedUrl]
      fileData.completeFiles = fileData.completeFiles?.length > 0 ? merge : fileData.fileSignedUrl
      fileData.addList = props.addList?.length > 0 ? [...props.addList, ...fileData.fileSignedUrl] : fileData.fileSignedUrl
      emit('update:addList', fileData.addList)
      emit('update:showList', merge)
      emit('change')
    } else {
      fileData.completeFiles = fileData.fileSignedUrl
      fileData.state = true
      emit('update:addList', fileData.completeFiles[0][props.imagePathKey])
      emit('update:modelValue', fileData.completeFiles[0][props.imagePathKey])
      emit('change')
    }
    fileActions.reset()
  },
  selectFile: (index, row) => {
    if (!props.disabled && props.maxCount > 1) {
      fileData.completeFiles.map(item => {
        item.isRepresentation = false
      })
      row.isRepresentation = true
    }
    emit('selected')
  },
  deleteFile: (index, row) => {
    if (props.maxCount > 1) {
      if (row?.id) {
        fileData.deleteFiles.push(row.id)
        emit('update:deleteList', fileData.deleteFiles)
      }
    }
    // target[i].name.substring(target[i].name.lastIndexOf('.'), target[i].name.length).toLocaleLowerCase()
    const fileName = (item) => {
      if (item.profileImagePath) {
        item.profileImagePath.substring(item.profileImagePath.lastIndexOf('/') + 1)
      } else if (item.costFilePath) {
        item.costFilePath.substring(item.costFilePath.lastIndexOf('/') + 1)
      } else if (item.filePath) {
        item.filePath.substring(item.filePath.lastIndexOf('/') + 1)
      }
    }
    // const originIndex = fileData.completeFiles.findIndex(item => item.id === row.id)
    const addListIndex = fileData.addList.findIndex(item => fileName(item) === fileName(row))
    fileData.addList.splice(addListIndex, 1)
    fileData.completeFiles.splice(index, 1)
  },
  reset: () => {
    fileData.files = []
    fileData.presignedUrl = []
    fileData.fileSignedUrl = []
  },
  readImageFile: (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image()

      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img
        resolve({ width, height })
      }

      img.src = URL.createObjectURL(file)
    })
  }
}

const apiActions = {
  getSignedUrl: async (file) => {
    const fileNameToTimeStamp = file.name.replace(file.name.substr(0, file.name.lastIndexOf('.')), dayjs().valueOf())
    const config = {
      params: {
        fileName: fileNameToTimeStamp,
        contentType: file.type,
        name: file.name
      }
    }
    const res = await store.dispatch('uploads/getSignedUrl', config)
    res.name = file.name
    fileData.presignedUrl.push(res)
    fileData.temp = cloneDeep(fileData.presignedUrl)
  },
  uploadSignedUrl: async (config) => {
    fileData.loading = true
    await store.dispatch('uploads/uploadSignedUrl', config)
    const uploaded = fileData.temp.map(item => {
      const info = {}
      info.isRepresentation = false
      info[props.imagePathKey] = `${import.meta.env.VITE_APP_S3_URL}/${item.key}`
      info[props.fileNameKey] = item?.name
      return info
    })
    fileData.fileSignedUrl = uploaded
    fileData.loading = false
  }
}

watch(() => props.showList, (value) => {
  if (fileData.completeFiles.length === 0 && value && value.length > 0) {
    if (props.maxCount === 1) {
      fileData.state = false
      fileData.completeFiles = [value]
      nextTick(() => {
        emit('update:addList', null)
        emit('update:modelValue', null)
      })
    } else {
      fileData.completeFiles = value
    }
  } else if (fileData.state && value.length === 0) {
    fileData.completeFiles = []
    fileData.state = false
  }
}, { immediate: true })

watch(() => props.modelValue, (value) => {
  if (!value || value.length === 0) {
    fileActions.reset()
    fileData.state = false
  }
})
</script>

<style lang="scss" scoped>
.custom-uploader {
  &__group {
    display: flex;
    align-items: center;
  }

  &__button {
    position: relative;
    display: inline-flex;

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &__text {
    margin-left: 10px;
    font-size: 12px;
  }

  &__etc {
    margin-left: auto;
    font-size: 12px;
  }

  &__list {
    display: flex;
  }

  &__item {
    position: relative;
    display: flex;
    width: 100px;
    height: 100px;
    margin: 10px 20px 0 0;
    border: 1px solid #eee;

    &--selected {
      border: 2px solid $primary-color;
    }
  }

  &__thumbnail {
    display: block;
    width: 100%;
    height: 100%;
    background: $gray-light3;

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__feature {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  &__file {
    display: flex;
    align-items: center;
    margin-top: 10px;

    span {
      word-break: break-all;
    }

    i {
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
  }

  &__error {
    margin-top: 5px;
    font-size: 11px;
    color: $warning-color;
  }
}
</style>
