import axios from '@/plugins/axios'
import { SET_FAQ_LIST, SET_FAQ_DETAIL } from '@/store/mutation_types'
import { objectToString } from '@/utils/object'

const state = {
  faqModel: {},
  faqDetail: {}
}

const getters = {
  faqListData: state => state.faqModel,
  faqDetail: state => state.faqDetail
}

const actions = {
  async getFaqList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/faqs',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_FAQ_LIST, response)
    })
  },
  async setFaqOrdering ({ commit }, { category, list }) {
    const config = {
      method: 'PATCH',
      url: '/_p/faqs/ordering',
      data: {
        category: category,
        ids: objectToString(list, 'id')
      }
    }
    const result = await axios(config)
    return result
  },
  async getFaqItemDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/faqs/${id}`
    }
    await axios(config).then(response => {
      commit(SET_FAQ_DETAIL, response)
    })
  },
  async createFaqItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/faqs',
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async saveFaqItem ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/faqs/${id}`,
      data: {
        ...data
      }
    }
    const result = await axios(setting)
    return result
  },
  async deleteFaqItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/faqs/${id}`
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_FAQ_LIST]: (state, payload) => {
    state.faqModel = {}
    state.faqModel = payload
  },
  [SET_FAQ_DETAIL]: (state, payload) => {
    state.faqDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
