import { PAGE, PAGE_TITLE, PAGE_TAB } from '@@routes'

export default {
  path: '/bca',
  name: PAGE.BCA,
  redirect: '/bca/campaign',
  component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Index.vue'),
  meta: {
    title: PAGE_TITLE.BCA,
    serviceName: PAGE.BCA,
    useIcon: 'ri-advertisement-line',
    roleKeys: { menu: 'mbca', subMenu: 'campaignManage' }
  },
  children: [
    {
      path: 'campaign',
      name: PAGE.BCA_CAMPAIGN,
      redirect: '/bca/campaign/ready',
      component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/Index.vue'),
      meta: {
        title: PAGE_TITLE.BCA_CAMPAIGN,
        menuName: PAGE.BCA_CAMPAIGN,
        tabName: PAGE.BCA_CAMPAIGN
      },
      children: [
        {
          path: 'ready',
          name: PAGE.BCA_CAMPAIGN_READY,
          redirect: '/bca/campaign/ready/list',
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.BCA_CAMPAIGN_READY_LIST,
            type: 'ready'
          },
          children: [
            {
              path: 'list',
              name: PAGE.BCA_CAMPAIGN_READY_LIST,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/List.vue')
            },
            {
              path: 'detail/:id?',
              name: PAGE.BCA_CAMPAIGN_READY_DETAIL,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/Detail.vue'),
              meta: {
                pageTitle: PAGE_TITLE.BCA_CAMPAIGN_DETAIL
              }
            }
          ]
        },
        {
          path: 'progress',
          name: PAGE.BCA_CAMPAIGN_PROGRESS,
          redirect: '/bca/campaign/progress/list',
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.BCA_CAMPAIGN_PROGRESS_LIST,
            type: 'progress'
          },
          children: [
            {
              path: 'list',
              name: PAGE.BCA_CAMPAIGN_PROGRESS_LIST,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/List.vue')
            },
            {
              path: 'detail/:id?',
              name: PAGE.BCA_CAMPAIGN_PROGRESS_DETAIL,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/Detail.vue')
            }
          ]
        },
        {
          path: 'finish',
          name: PAGE.BCA_CAMPAIGN_FINISH,
          redirect: '/bca/campaign/finish/list',
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.BCA_CAMPAIGN_FINISH_LIST,
            type: 'finish'
          },
          children: [
            {
              path: 'list',
              name: PAGE.BCA_CAMPAIGN_FINISH_LIST,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/List.vue')
            },
            {
              path: 'detail/:id?',
              name: PAGE.BCA_CAMPAIGN_FINISH_DETAIL,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Campaign/Detail.vue')
            }
          ]
        }
      ]
    },
    {
      path: 'facebook',
      name: PAGE.BCA_MEDIA_FACEBOOK,
      redirect: '/bca/facebook/account',
      component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Facebook/Index.vue'),
      meta: {
        title: PAGE_TITLE.BCA_MEDIA_FACEBOOK,
        menuName: PAGE.BCA_MEDIA_FACEBOOK,
        tabName: PAGE.BCA_MEDIA_FACEBOOK
      },
      children: [
        {
          path: 'account',
          redirect: '/bca/facebook/account/list',
          name: PAGE.BCA_MEDIA_FACEBOOK_ACCOUNT,
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Facebook/TabIndex.vue'),
          meta: {
            title: PAGE_TAB.BCA_MEDIA_FACEBOOK_ACCOUNT
          },
          children: [
            {
              path: 'list',
              name: PAGE.BCA_MEDIA_FACEBOOK_ACCOUNT_LIST,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Account/List.vue'),
              props: { mediaType: 'facebook' }
            },
            {
              path: 'form/:id?',
              name: PAGE.BCA_MEDIA_FACEBOOK_ACCOUNT_FORM,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Account/Form.vue'),
              props: { mediaType: 'facebook' },
              meta: {
                pageTitle: PAGE_TITLE.BCA_MEDIA_FACEBOOK_ACCOUNT_FORM
              }
            }
          ]
        },
        {
          path: 'expose',
          redirect: '/bca/facebook/expose/list',
          name: PAGE.BCA_MEDIA_FACEBOOK_EXPOSE,
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Facebook/TabIndex.vue'),
          meta: {
            title: PAGE_TAB.BCA_MEDIA_FACEBOOK_EXPOSE
          },
          children: [
            {
              path: 'list',
              name: PAGE.BCA_MEDIA_FACEBOOK_EXPOSE_LIST,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Facebook/Expose/List.vue')
            }
          ]
        }
      ]
    },
    {
      path: 'google',
      name: PAGE.BCA_MEDIA_GOOGLE,
      redirect: '/bca/google/account',
      component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Youtube/Index.vue'),
      meta: {
        title: PAGE_TITLE.BCA_MEDIA_GOOGLE,
        menuName: PAGE.BCA_MEDIA_GOOGLE,
        tabName: PAGE.BCA_MEDIA_GOOGLE
      },
      children: [
        {
          path: 'account',
          redirect: '/bca/google/account/list',
          name: PAGE.BCA_MEDIA_GOOGLE_ACCOUNT,
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Youtube/TabIndex.vue'),
          meta: {
            title: PAGE_TAB.BCA_MEDIA_GOOGLE_ACCOUNT
          },
          children: [
            {
              path: 'list',
              name: PAGE.BCA_MEDIA_GOOGLE_ACCOUNT_LIST,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Account/List.vue'),
              props: { mediaType: 'google' }
            },
            {
              path: 'form/:id?',
              name: PAGE.BCA_MEDIA_GOOGLE_ACCOUNT_FORM,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Account/Form.vue'),
              props: { mediaType: 'google' },
              meta: {
                pageTitle: PAGE_TITLE.BCA_MEDIA_GOOGLE_ACCOUNT_FORM
              }
            }
          ]
        },
        {
          path: 'category',
          redirect: '/bca/google/category/list',
          name: PAGE.BCA_MEDIA_GOOGLE_CATEGORY,
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Youtube/TabIndex.vue'),
          meta: {
            title: PAGE_TAB.BCA_MEDIA_GOOGLE_CATEGORY
          },
          children: [
            {
              path: 'list',
              name: PAGE.BCA_MEDIA_GOOGLE_CATEGORY_LIST,
              component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Youtube/Category/List.vue')
            }
          ]
        }
      ]
    },
    {
      path: 'monitoring',
      name: PAGE.BCA_MEDIA_MONITORING,
      component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Media/Monitoring.vue'),
      meta: {
        title: PAGE_TITLE.BCA_MEDIA_MONITORING,
        menuName: PAGE.BCA_MEDIA_MONITORING
      }
    },
    {
      path: 'report',
      name: PAGE.BCA_REPORT,
      redirect: '/bca/report/performance',
      component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Report/Index.vue'),
      meta: {
        title: PAGE_TITLE.BCA_REPORT,
        menuName: PAGE.BCA_REPORT,
        tabName: PAGE.BCA_REPORT
      },
      children: [
        {
          path: 'performance',
          name: PAGE.BCA_REPORT_PERFORMANCE,
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Report/Performance.vue'),
          meta: {
            title: PAGE_TITLE.BCA_REPORT_PERFORMANCE,
            pageTitle: PAGE_TITLE.BCA_REPORT_PERFORMANCE
          }
        },
        {
          path: 'business',
          name: PAGE.BCA_REPORT_BUSINESS,
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Report/Business.vue'),
          meta: {
            title: PAGE_TITLE.BCA_REPORT_BUSINESS,
            pageTitle: PAGE_TITLE.BCA_REPORT_BUSINESS
          }
        },
        {
          path: 'media',
          name: PAGE.BCA_REPORT_MEDIA,
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Report/Media.vue'),
          meta: {
            title: PAGE_TITLE.BCA_REPORT_MEDIA,
            pageTitle: PAGE_TITLE.BCA_REPORT_MEDIA
          }
        },
        {
          path: 'material',
          name: PAGE.BCA_REPORT_MATERIAL,
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Report/Material.vue'),
          meta: {
            title: PAGE_TITLE.BCA_REPORT_MATERIAL,
            pageTitle: PAGE_TITLE.BCA_REPORT_MATERIAL
          }
        },
        {
          path: 'mbp',
          name: PAGE.BCA_REPORT_MBP,
          component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Report/Mbp.vue'),
          meta: {
            title: PAGE_TITLE.BCA_REPORT_MBP,
            pageTitle: PAGE_TITLE.BCA_REPORT_MBP
          }
        }
        // 현 버전에서 제외
        // {
        //   path: 'tracking',
        //   name: PAGE.BCA_REPORT_TRACKING,
        //   component: () => import(/* webpackChunkName: "bca" */'@page/Bca/Report/Tracking.vue'),
        //   meta: {
        //     title: PAGE_TITLE.BCA_REPORT_TRACKING,
        //     pageTitle: PAGE_TITLE.BCA_REPORT_TRACKING
        //   }
        // }
      ]
    },
    {
      path: 'adPerformanceData',
      name: PAGE.BCA_REPORT_AD_PERFORMANCE_DATA,
      component: () => import(/* webpackChunkName: "bca" */'@page/Bca/AdPerformanceData.vue'),
      meta: {
        title: PAGE_TITLE.BCA_REPORT_AD_PERFORMANCE_DATA,
        menuName: PAGE.BCA_REPORT_AD_PERFORMANCE_DATA,
        pageTitle: PAGE_TITLE.BCA_REPORT_AD_PERFORMANCE_DATA
      }
    }
  ]
}
