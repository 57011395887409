import axios from '@/plugins/axios'
import {
  SET_HOST_MEMO_LIST,
  SET_SHOW_HOST_LIST,
  SET_SHOW_HOST_DETAIL,
  SET_SHOW_HOST_BROADCAST_LIST,
  SET_SHOW_HOST_BROADCAST_DETAIL
} from '@/store/mutation_types'
import { objectToString } from '@/utils/object'
import { CODE_DATA } from '@/constants/codes'

const state = {
  memoModel: {},
  showHostModel: {},
  showHostDetail: {},
  broadcastModel: {},
  broadcastDetail: {}
}

const getters = {
  memoListData: state => state.memoModel,
  registerType: state => state.showHostDetail.registerType,
  showHostList: state => state.showHostModel,
  showHostPaging: state => state.showHostModel.meta,
  showHostDetail: state => {
    const data = state.showHostDetail
    const representImage = data?.showhostProfileImage?.filter((item) => item.isRepresentation)
    return {
      ...data,
      representImage: representImage ? representImage[0] : null,
      allBroadcastCount: data.otherBroadcastCount + data.livecastBroadcastCount
    }
  },
  showHostDetailForm: state => {
    const data = state.showHostDetail
    const email = data.email ? data.email.split('@') : ['', '']
    const background = CODE_DATA.SHOW_HOST.karmaType.list.filter((item) => item.label === data.background)
    const backgroundType = background?.[0]?.value || 0
    return {
      registerType: data.registerType,
      temp: {
        email: {
          localPart: email[0],
          domain: email[1],
          domainSelected: 'direct'
        },
        popupCategory: data.liveCommerceCategory,
        snsChannel: {
          channelType: null,
          channelUrl: null
        }
      },
      form: {
        name: data.name,
        email: data.email,
        tel: data.tel,
        liveCommerceCategory: data.liveCommerceCategory ? objectToString(data.liveCommerceCategory) : null,
        marital: data.marital,
        karmaType: data.karmaType,
        belongType: data.belongType,
        ageType: data.ageType,
        profileImagePath: data.showhostProfileImage?.[0]?.profileImagePath || null,
        showhostProfileImage: data.showhostProfileImage,
        addShowhostProfileImage: [],
        backgroundType: backgroundType,
        additionalInformation: {
          background: data.background,
          snsChannel: data.snsChannel,
          affiliatedCompany: data.affiliatedCompany,
          costType: data.costType,
          castingCost: data.castingCost
        },
        'additionalInformation.castingCost': data.castingCost
      }
    }
  },
  broadcast: state => state.broadcastModel,
  broadcastDetail: state => state.broadcastDetail
}

const actions = {
  async getMemoList ({ commit }, id) {
    const config = {
      method: 'GET',
      url: `/_p/showhosts/${id}/memos`,
      params: {
        perPage: 10,
        page: 1
      }
    }
    await axios(config).then((response) => {
      commit(SET_HOST_MEMO_LIST, response)
    })
  },
  async saveMemoItem ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/showhosts/${id}/memos`,
      data: data
    }

    await axios(config)
  },
  async deleteMemoItem ({ commit }, { id, memoId }) {
    const config = {
      method: 'DELETE',
      url: `/_p/showhosts/${id}/memos/${memoId}`
    }

    await axios(config)
  },
  async getShowHostList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/showhosts',
      params: {
        perPage: 10,
        page: 1,
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_SHOW_HOST_LIST, response)
    })
  },
  async createShowHost ({ commit }, data) {
    const config = {
      method: 'POST',
      url: '/_p/showhosts',
      data: data
    }
    const result = await axios(config)
    return result
  },
  async saveShowHost ({ commit }, { id, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/showhosts/${id}`,
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async getShowHost ({ commit }, id) {
    const config = {
      method: 'GET',
      url: `/_p/showhosts/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_SHOW_HOST_DETAIL, response)
    })
  },
  async getBroadcastList ({ commit }, { id, params }) {
    const config = {
      method: 'GET',
      url: `/_p/showhosts/${id}/broadcasts`,
      params: {
        perPage: 10,
        page: 1,
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_SHOW_HOST_BROADCAST_LIST, response)
    })
  },
  async createBroadcast ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/showhosts/${id}/broadcasts`,
      data: data
    }
    const result = await axios(config)
    return result
  },
  async saveBroadcast ({ commit }, { id, broadcastId, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/showhosts/${id}/broadcasts/${broadcastId}`,
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async deleteBroadcast ({ commit }, { id, broadcastId }) {
    const config = {
      method: 'DELETE',
      url: `/_p/showhosts/${id}/broadcasts/${broadcastId}`
    }

    await axios(config)
  },
  async getBroadcast ({ commit }, { id, broadcastId }) {
    const config = {
      method: 'GET',
      url: `/_p/showhosts/${id}/broadcasts/${broadcastId}`
    }
    await axios(config).then((response) => {
      commit(SET_SHOW_HOST_BROADCAST_DETAIL, response)
    })
  }
}

const mutations = {
  [SET_HOST_MEMO_LIST]: (state, payload) => {
    state.memoModel = payload
  },
  [SET_SHOW_HOST_LIST]: (state, payload) => {
    state.showHostModel = payload
  },
  [SET_SHOW_HOST_DETAIL]: (state, payload) => {
    state.showHostDetail = payload
  },
  [SET_SHOW_HOST_BROADCAST_LIST]: (state, payload) => {
    state.broadcastModel = payload
  },
  [SET_SHOW_HOST_BROADCAST_DETAIL]: (state, payload) => {
    state.broadcastDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
