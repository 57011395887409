import axios from '@/plugins/axios'
import {
  SET_BUSINESSES_MANAGE_LIST,
  SET_BUSINESSES_MANAGE_DETAIL,
  SET_BUSINESSES_MANAGE_OPERATOR,
  SET_BUSINESSES_MANAGE_BRAND,
  SET_CATEGORY_LIST,
  SET_BUSINESSES_AGENCY_LIST,
  SET_BUSINESSES_AGENCY_DETAIL,
  SET_BUSINESSES_AGENCY_BUSINESS,
  SET_BUSINESSES_AGENCY_OPERATOR,
  SET_BUSINESSES_COMPANY_LIST,
  SET_BUSINESSES_COMPANY_DETAIL
} from '@/store/mutation_types'
import cryptoString from '@/utils/cryptoString'

const state = {
  categories: {
    list: {}
  },
  manage: {
    model: {},
    detail: {},
    brand: {},
    operators: {},
    operatorCount: 0,
    campaignCount: 0
  },
  agency: {
    model: {},
    detail: {},
    businesses: {},
    operators: {},
    businessesCount: 0,
    operatorCount: 0
  },
  company: {
    list: {},
    detail: {}
  }
}

const getters = {
  manageListData: state => state.manage.model,
  manageDetail: state => {
    const { bankAccount } = state.manage.detail
    if (bankAccount?.ownerNumber) {
      bankAccount.ownerNumber = cryptoString.decrypt(bankAccount.ownerNumber)
    }
    return {
      ...state.manage.detail,
      bankAccount: bankAccount
    }
  },
  manageOperators: state => state.manage.operators,
  manageOperatorsCount: state => {
    return state.manage.operatorCount || 0
  },
  manageBrandList: state => state.manage.brand,
  manageCompanyList: state => state.company.list,
  manageCompanyDetail: state => state.company.detail,
  campaignCount: state => state.manage.detail.campaignCount || 0,
  categoryListData: state => state.categories.list,
  agencyListData: state => state.agency.model,
  agencyDetail: state => state.agency.detail,
  agencyOperators: state => state.agency.operators,
  agencyOperatorsCount: state => state.agency.operatorCount || 0,
  agencyBusinessesCount: state => state.agency.businessesCount || 0,
  agencyBusinesses: state => state.agency.businesses
}

const actions = {
  async getBusinessManageList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/businesses',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_MANAGE_LIST, response)
    })
  },
  async getBusinessManageDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/businesses/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_MANAGE_DETAIL, response)
    })
  },
  async getBusinessManageOperator ({ commit }, { id, data }) {
    const config = {
      method: 'GET',
      url: `/_p/businesses/${id}/operators`,
      params: {
        ...data
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_MANAGE_OPERATOR, response)
    })
  },
  async saveBusinessManageOperator ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/businesses/${id}/operators`,
      data: {
        ...data
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_MANAGE_OPERATOR, response)
    })
  },
  async setBusinessManageOperatorRole ({ commit }, { id, role, operatorId }) {
    const config = {
      method: 'PATCH',
      url: `/_p/businesses/${id}/operators/${operatorId}/role`,
      data: {
        role: role
      }
    }
    const result = await axios(config)
    return result
  },
  async setBusinessManageAgency ({ commit }, { id, agencyId }) {
    const config = {
      method: 'PATCH',
      url: `/_p/businesses/${id}/agency`,
      data: {
        agencyId: agencyId
      }
    }
    const result = await axios(config)
    return result
  },
  async deleteBusinessManageOperator ({ commit }, { id, operatorId }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/businesses/${id}/operators/${operatorId}`
    }
    const result = await axios(setting)
    return result
  },
  async getBusinessManageBrandList ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/businesses/${id}/brand`
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_MANAGE_BRAND, response)
    })
  },
  async setBusinessManageBrandConnect ({ commit }, { id, brandIds }) {
    const config = {
      method: 'POST',
      url: `/_p/businesses/${id}/connect-brand`,
      data: {
        ids: brandIds
      }
    }
    const result = await axios(config)
    return result
  },
  async setBusinessManageBrandDisconnect ({ commit }, { id, brandIds }) {
    const config = {
      method: 'PATCH',
      url: `/_p/businesses/${id}/disconnect-brand`,
      data: {
        ids: brandIds
      }
    }
    const result = await axios(config)
    return result
  },
  async createBusinessManageItem ({ commit }, { data }) {
    if (data.bankAccount?.ownerNumber) {
      data.bankAccount.ownerNumber = cryptoString.encrypt(data.bankAccount.ownerNumber)
      delete data.bankAccount.personal
      delete data.bankAccount.corp
    }
    const config = {
      method: 'POST',
      url: '/_p/businesses',
      data
    }
    const result = await axios(config)
    commit(SET_BUSINESSES_MANAGE_DETAIL, result)
    return result
  },
  async saveBusinessManageItem ({ commit }, { id, data }) {
    if (data?.bankAccount?.ownerNumber) {
      data.bankAccount.ownerNumber = cryptoString.encrypt(data.bankAccount.ownerNumber)
      delete data.bankAccount.personal
      delete data.bankAccount.corp
    }
    const setting = {
      method: 'PUT',
      url: `/_p/businesses/${id}`,
      data
    }
    const result = await axios(setting)
    commit(SET_BUSINESSES_MANAGE_DETAIL, result)
    return result
  },
  async deleteBusinessManageItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/businesses/${id}`
    }
    const result = await axios(setting)
    return result
  },

  async getCategoryList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/categories',
      params: {
        depth: params?.depth || 1,
        ...params,
        perPage: 99999
      }
    }
    const result = await axios(config)
    commit(SET_CATEGORY_LIST, result)
    return result
  },
  async createCategoryItem ({ commit }, { data }) {
    const setting = {
      method: 'POST',
      url: '/_p/categories',
      data
    }
    const result = await axios(setting)
    return result
  },
  async saveCategoryItem ({ commit }, { category, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/categories/${category}`,
      data
    }
    const result = await axios(setting)
    return result
  },
  async deleteCategoryItem ({ commit }, { category }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/categories/${category}`
    }
    const result = await axios(setting)
    return result
  },
  async getAgencyList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/agencies',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_AGENCY_LIST, response)
    })
  },
  async getAgencyDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/agencies/${id}`
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_AGENCY_DETAIL, response)
    })
  },
  async createAgencyItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/agencies',
      data
    }
    const result = await axios(config)
    commit(SET_BUSINESSES_AGENCY_DETAIL, result)
    return result
  },
  async saveAgencyItem ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/agencies/${id}`,
      data
    }
    const result = await axios(setting)
    commit(SET_BUSINESSES_AGENCY_DETAIL, result)
    return result
  },
  async deleteAgencyItem ({ commit }, { id }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/agencies/${id}`
    }
    const result = await axios(setting)
    return result
  },
  async setAgencyStatus ({ commit }, data) {
    const config = {
      method: 'PATCH',
      url: `/_p/agencies/${data.id}/status`,
      data: {
        status: data.status
      }
    }
    const result = await axios(config)
    return result
  },
  async getAgencyBusinessDetail ({ commit }, { id, filters }) {
    const config = {
      method: 'GET',
      url: `/_p/agencies/${id}/businesses`,
      params: {
        filters: {
          ...filters
        }
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_AGENCY_BUSINESS, response)
    })
  },
  async getAgencyOperator ({ commit }, { id, data }) {
    const config = {
      method: 'GET',
      url: `/_p/agencies/${id}/operators`,
      params: {
        ...data
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_AGENCY_OPERATOR, response)
    })
  },
  async saveAgencyOperator ({ commit }, { id, data }) {
    const config = {
      method: 'POST',
      url: `/_p/agencies/${id}/operators`,
      data: {
        ...data
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_AGENCY_OPERATOR, response)
    })
  },
  async setAgencyOperatorRole ({ commit }, { id, role, operatorId }) {
    const config = {
      method: 'PATCH',
      url: `/_p/agencies/${id}/operators/${operatorId}/role`,
      data: {
        role: role
      }
    }
    const result = await axios(config)
    return result
  },
  async deleteAgencyOperator ({ commit }, { id, operatorId }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/agencies/${id}/operators/${operatorId}`
    }
    const result = await axios(setting)
    return result
  },
  // 출처사
  async getBusinessCompanyList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/affiliated-companies',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_COMPANY_LIST, response)
    })
  },
  async getBusinessCompanyDetail ({ commit }, { affiliatedCompany }) {
    const config = {
      method: 'GET',
      url: `/_p/affiliated-companies/${affiliatedCompany}`
    }

    await axios(config).then(response => {
      commit(SET_BUSINESSES_COMPANY_DETAIL, response)
    })
  },
  async createBusinessCompanyItem ({ commit }, { data }) {
    const setting = {
      method: 'POST',
      url: '/_p/affiliated-companies',
      data
    }
    const result = await axios(setting)
    return result
  },
  async saveBusinessCompanyItem ({ commit }, { affiliatedCompany, data }) {
    const setting = {
      method: 'PUT',
      url: `/_p/affiliated-companies/${affiliatedCompany}`,
      data
    }
    const result = await axios(setting)
    return result
  },
  async deleteBusinessCompanyItem ({ commit }, { affiliatedCompany }) {
    const setting = {
      method: 'DELETE',
      url: `/_p/affiliated-companies/${affiliatedCompany}`
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_BUSINESSES_MANAGE_LIST]: (state, payload) => {
    state.manage.model = payload
    state.manage.detail = {}
  },
  [SET_BUSINESSES_MANAGE_DETAIL]: (state, payload) => {
    state.manage.detail = payload
    state.manage.operatorCount = payload.operatorCount
  },
  [SET_BUSINESSES_MANAGE_OPERATOR]: (state, payload) => {
    state.manage.operators = payload
  },
  [SET_BUSINESSES_MANAGE_BRAND]: (state, payload) => {
    state.manage.brand = payload
  },
  [SET_CATEGORY_LIST]: (state, payload) => {
    state.categories.list = payload
  },
  [SET_BUSINESSES_AGENCY_LIST]: (state, payload) => {
    state.agency.model = payload
  },
  [SET_BUSINESSES_AGENCY_DETAIL]: (state, payload) => {
    state.agency.detail = payload
    state.agency.operatorCount = payload.operatorCount
    state.agency.businessesCount = payload.businessesCount
  },
  [SET_BUSINESSES_AGENCY_BUSINESS]: (state, payload) => {
    state.agency.businesses = payload
    state.agency.businessesCount = payload?.meta?.total
  },
  [SET_BUSINESSES_AGENCY_OPERATOR]: (state, payload) => {
    state.agency.operators = payload
    state.agency.operatorCount = payload?.meta?.total
  },
  [SET_BUSINESSES_COMPANY_LIST]: (state, payload) => {
    state.company.list = payload
  },
  [SET_BUSINESSES_COMPANY_DETAIL]: (state, payload) => {
    state.company.detail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
