import axios from '@/plugins/axios'
import { SET_BANK_LIST, SET_DELIVERY_COMPANY_LIST } from '@/store/mutation_types'

const state = {
  bankList: null,
  deliveryCompany: []
}

const getters = {
  bankList: state => state.bankList,
  deliveryCompany: state => state.deliveryCompany
}

const actions = {
  async getBanks ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/commons/banks'
    }

    await axios(config).then((response) => {
      commit(SET_BANK_LIST, response)
    })
  },
  async getDeliveryCompany ({ commit }) {
    const config = {
      method: 'GET',
      url: '/commons/deliveries'
    }
    await axios(config).then((response) => {
      commit(SET_DELIVERY_COMPANY_LIST, response)
    })
  },
  async customError ({ commit }, data) {
    const config = {
      method: 'POST',
      url: '/commons/custom-error',
      data
    }

    await axios(config)
  },
  async getBizNumber ({ commit }, data) {
    const config = {
      method: 'GET',
      url: '/commons/bizno-check',
      params: data
    }
    const result = await axios(config)
    return result
  }
}

const mutations = {
  [SET_BANK_LIST]: (state, payload) => {
    state.bankList = payload
  },
  [SET_DELIVERY_COMPANY_LIST]: (state, payload) => {
    state.deliveryCompany = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
