import axios from '@/plugins/axios'
import { SET_USER_MENU } from '@/store/mutation_types'

const state = {
  userMenu: []
}

const getters = {
  userMenuAuth () {
    return state.userMenu
  }
}

const actions = {
  async getMenu ({ commit }) {
    const config = {
      method: 'GET',
      url: '/_p/menu'
    }
    await axios(config).then((response) => {
      commit(SET_USER_MENU, response.menu)
    })
  }
}

const mutations = {
  [SET_USER_MENU]: (state, payload) => {
    state.userMenu = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
