<template>
  <div
    class="category-selector"
  >
    <vxe-select
      v-model="categoryData.oneDepth.selectedItem"
      placeholder="카테고리"
      :disabled="props.disabled"
      @change="categoryActions.firstDepthChange"
    >
      <vxe-option
        v-for="item in categoryData.oneDepth.options"
        :key="item.id"
        :value="item.id"
        :label="item.name"
      >
        {{ item.name }}
      </vxe-option>
    </vxe-select>
    <vxe-select
      v-if="props.isTwoDepth"
      v-model="categoryData.twoDepth.selectedItem"
      placeholder="카테고리"
      :disabled="props.disabled"
      @change="categoryActions.secondDepthChange"
    >
      <vxe-option
        v-for="item in categoryData.twoDepth.options"
        :key="item.id"
        :value="item.id"
        :label="item.name"
      >
        {{ item.name }}
      </vxe-option>
    </vxe-select>
  </div>
</template>
<script setup>
import { computed, watch, reactive } from 'vue'
import { useGlobalConfig } from '@/composables/globalConfig.js'

const { store } = useGlobalConfig()

const props = defineProps({
  selectedId: {
    type: [Number],
    default: null
  },
  selectedItem: {
    type: Object,
    default: null
  },
  isTwoDepth: {
    type: Boolean,
    default: false
  },
  isSearch: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'business'
  }
})
const emit = defineEmits(['update:selectedId', 'update:twoDepthValue'])
const categoryData = reactive({
  oneDepth: {
    options: null,
    selectedItem: null
  },
  twoDepth: {
    options: null,
    selectedItem: null
  },
  selectedId: computed({
    get: () => props.selectedId,
    set: (value) => {
      emit('update:selectedId', value)
    }
  })
})

const categoryActions = {
  getCategory: async (params) => {
    const res = await store.dispatch('businesses/getCategoryList', { ...params, type: props.type })
    const allSet = { id: null, name: '전체' }
    if (params?.parentId) {
      categoryData.twoDepth.options = props.isSearch ? [allSet, ...res.data] : res.data
    } else {
      categoryData.oneDepth.options = props.isSearch ? [allSet, ...res.data] : res.data
    }
  },
  firstDepthChange: async (item) => {
    categoryData.selectedId = item.value
    categoryData.twoDepth.selectedItem = null
    if (item.value !== null) {
      const config = {
        parentId: item.value,
        depth: 2
      }
      categoryActions.getCategory(config)
    }
  },
  secondDepthChange: async (item) => {
    emit('update:twoDepthValue', item.value)
    if (item.value !== null) {
      categoryData.selectedId = item.value
    }
  }
}
categoryActions.getCategory()

watch(() => props.selectedId, (value) => {
  if (!value) {
    categoryData.oneDepth.selectedItem = null
    categoryData.twoDepth.selectedItem = null
    categoryData.twoDepth.options = null
  }
})
watch(
  () => props.selectedItem,
  async (value) => {
    if (value?.depth) {
      if (value?.depth === 2) {
        categoryData.oneDepth.selectedItem = value.parentId
        await categoryActions.getCategory({ parentId: value.parentId, depth: 2 })
        categoryData.twoDepth.selectedItem = value.id
        emit('update:twoDepthValue', value.id)
      } else {
        categoryData.oneDepth.selectedItem = value.id
        await categoryActions.getCategory({ parentId: value.id, depth: 2 })
      }
    }
  }, { immediate: true })
</script>

<style lang="scss">
.category-selector {
  .vxe-select {
    width: 200px !important;

    + .vxe-select {
      margin-left: 8px;
    }
  }
}
</style>
