import crypto from 'crypto-js'

const cryptoString = {
  encrypt: (value) => {
    return crypto.AES.encrypt(value, import.meta.env.VITE_APP_KEY).toString()
  },
  decrypt: (value) => {
    const bytes = crypto.AES.decrypt(value, import.meta.env.VITE_APP_KEY)
    return bytes.toString(crypto.enc.Utf8)
  }
}

export default cryptoString

// example
// import cryptoString from '@/utils/cryptoString'

// const text = '1017210'
// const ciphertext = cryptoString.encrypt(text)
// const originalText = cryptoString.decrypt(ciphertext)

// console.log(ciphertext, originalText)
