const fetchFromObject = (row, property) => {
  if (typeof row === 'undefined') return false
  const _index = property.indexOf('.')
  if (_index > -1) {
    return fetchFromObject(row?.[property.substring(0, _index)], property.substr(_index + 1))
  }
  return row?.[property]
}

export default fetchFromObject

// example
// const obj = {a: 1, b: 2, c: 3, user: { name: 'test'}}
// fetchFromObject(obj, 'user.name')
// 'test'
