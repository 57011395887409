<template>
  <div class="custom-button-group">
    <div
      v-if="$slots['left']"
      class="custom-button-group__left"
    >
      <slot name="left" />
    </div>
    <div class="custom-button-group__right">
      <slot name="before" />
      <slot />
      <template v-if="!$slots['default']">
        <vxe-button
          v-if="isCancel"
          status="info"
          :content="cancelButtonText || '취소'"
          @click="actions.cancel"
        />
        <vxe-button
          v-if="isDelete && !isCreate && props.role?.delete"
          status="danger"
          :content="deleteButtonText || '삭제'"
          @click="actions.delete"
        />
        <vxe-button
          v-if="(isSave && props.role?.modify) || !props.role"
          :status="props.saveStatus || 'primary'"
          :content="saveButtonText || (isCreate ? '저장' : '수정')"
          :loading="props.loading"
          @click="actions.save"
        />
      </template>
      <slot name="after" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomButtonGroup',
  props: {
    role: {
      type: Object,
      default: null
    },
    saveButtonText: {
      type: String,
      default: null
    },
    deleteButtonText: {
      type: String,
      default: null
    },
    cancelButtonText: {
      type: String,
      default: null
    },
    isCreate: {
      type: Boolean,
      default: true
    },
    isSave: {
      type: Boolean,
      default: true
    },
    isCancel: {
      type: Boolean,
      default: true
    },
    isDelete: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    saveStatus: {
      type: String,
      default: 'primary'
    }
  },
  emits: ['cancel', 'delete', 'save'],
  setup (props, { emit }) {
    const actions = {
      cancel: () => { emit('cancel') },
      delete: () => { emit('delete') },
      save: () => { emit('save') }
    }

    return {
      props,
      actions
    }
  }
}
</script>

<style lang="scss">
.custom-button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  a,
  button {
    min-width: 80px;
    padding-bottom: 1px;
  }

  &__left,
  &__right {
    display: flex;
    align-items: baseline;
  }

  &__left {
    margin-right: auto;
  }

  &__right {
    margin-left: auto;
  }

  .custom-form + & {
    margin-top: 20px;
  }
}
</style>
