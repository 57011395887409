import axios from 'axios'
import errorHandler from './errorHandler'
import { refresh, refreshErrorHandle } from '@/plugins/authService'
// import qs from 'qs'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const config = {
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 120 * 1000
  // paramsSerializer: {
  //   serialize: params => {
  //     return JSON.stringify(params) // 形式： ids=1&ids=2&ids=3
  //   }
  // }
  // withCredentials: true// Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(refresh, refreshErrorHandle)

// response interceptor
_axios.interceptors.response.use(
  function (response) {
    return response.data
  },
  async function (error) {
    errorHandler(error)
    return Promise.reject(error)
  }
)

export default _axios
