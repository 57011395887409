import axios from '@/plugins/axios'
import {
  SET_BUSINESSESUSER_LIST,
  SET_BUSINESSESUSER_DETAIL,
  SET_BUSINESSES_OPERATORS_LIST,
  SET_BUSINESSES_NOT_USER_LIST
} from '@/store/mutation_types'

const state = {
  businessesUser: {},
  businessesUserDetail: {},
  businessesOperators: {},
  businessesNotUser: {}
}
const getters = {
  businessesUserList: state => state.businessesUser,
  businessesUserDetail: state => state.businessesUserDetail,
  businessesOperatorsList: state => state.businessesOperators,
  businessesNotUserList: state => state.businessesNotUser
}
const actions = {
  async getBusinessesUserList ({ commit }, data) {
    const config = {
      method: 'GET',
      url: '_p/operators',
      params: {
        filters: {},
        ...data
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSESUSER_LIST, response)
    })
  },
  async getBusinessesUserDetail ({ commit }, { id, data }) {
    const config = {
      method: 'GET',
      url: `_p/operators/${id}`,
      params: {
        ...data
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSESUSER_DETAIL, response)
    })
  },
  async saveBusinessesUserDetail ({ commit }, { id, data }) {
    const setting = {
      method: 'PUT',
      url: `_p/operators/${id}`,
      data
    }
    const result = await axios(setting)
    return result
  },
  async getBusinessesList ({ commit }, { id, data }) {
    const config = {
      method: 'GET',
      url: `_p/operators/${id}/businesses`,
      params: {
        ...data
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_OPERATORS_LIST, response)
    })
  },
  async createBusinessesUserItem ({ commit }, { data }) {
    const config = {
      method: 'POST',
      url: '/_p/operators',
      params: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async getBusinessesNotUserList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '_p/operators/not-users',
      params: {
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_BUSINESSES_NOT_USER_LIST, response)
    })
  },
  async createBusinessesNotUser ({ commit }, data) {
    const config = {
      method: 'POST',
      url: '/_p/operators/user',
      data: {
        ...data
      }
    }
    const result = await axios(config)
    return result
  },
  async setOperatorLabsApprove ({ commit }, user) {
    const config = {
      method: 'PATCH',
      url: `/_p/operators/${user}/force-labs-search-approve`,
      data: {
        labsStatus: 'approve'
      }
    }
    const result = await axios(config)
    return result
  }
}

const mutations = {
  [SET_BUSINESSESUSER_LIST]: (state, payload) => {
    state.businessesUser = payload
  },
  [SET_BUSINESSESUSER_DETAIL]: (state, payload) => {
    state.businessesUserDetail = payload
  },
  [SET_BUSINESSES_OPERATORS_LIST]: (state, payload) => {
    state.businessesOperators = payload
  },
  [SET_BUSINESSES_NOT_USER_LIST]: (state, payload) => {
    state.businessesNotUser = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
