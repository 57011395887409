import axios from '@/plugins/axios'
import { dateStandardFormatList } from '@/utils/date'
import { SET_USER_LIST, SET_USER_NOT_ADMINISTRATOR_LIST } from '@/store/mutation_types'
// import qs from 'qs'

const state = {
  userModel: [],
  userNotAdministratorModel: {}
}

const getters = {
  userList: state => state.userModel,
  userNotAdministratorData: state => state.userNotAdministratorModel
}

const actions = {
  async getUsers ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/administrators',
      params: {
        perPage: 10,
        page: 1,
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_USER_LIST, response)
    })
  },
  async userDetail ({ commit }, id) {
    const config = {
      method: 'GET',
      url: `/_p/administrators/${id}`
    }

    const result = await axios(config)
    return result
  },
  async userDetailModify ({ commit }, { id, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/administrators/${id}`,
      params: data
    }

    await axios(config)
  },

  async otherUserDetailModify ({ commit }, { id, data }) {
    const config = {
      method: 'PUT',
      url: `/_p/administrators/${id}/modify`,
      params: data
    }

    await axios(config)
  },

  async userInvite ({ commit }, params) {
    const config = {
      method: 'POST',
      url: '/_p/administrators/invite',
      params: {
        ...params,
        siteUrl: `${import.meta.env.VITE_APP_URL}/invitation/`
      }
    }
    const result = await axios(config)
    return result
  },

  async administratorAdd ({ commit }, params) {
    const config = {
      method: 'POST',
      url: '/_p/administrators/user',
      params
    }
    const result = await axios(config)
    return result
  },

  async getUsersNotAdministrator ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/administrators/not-users',
      params: {
        perPage: 10,
        page: 1,
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_USER_NOT_ADMINISTRATOR_LIST, response)
    })
  },

  async checkToken ({ commit }, token) {
    const setting = {
      method: 'GET',
      url: `/_p/administrators/is-admin-check/${token}`
    }
    const data = await axios(setting)
    return data
  },
  async join ({ commit }, { params }) {
    const setting = {
      method: 'POST',
      url: '/_p/administrators',
      params
    }
    const data = await axios(setting)
    return data
  },
  async emailDoubleCheck ({ commit }, { email }) {
    const setting = {
      method: 'GET',
      url: '/email-double-check',
      params: {
        email: email
      }
    }

    const data = await axios(setting)
    return data
  }
}

const mutations = {
  [SET_USER_LIST]: (state, payload) => {
    payload.data = dateStandardFormatList(payload.data)
    state.userModel = payload
  },
  [SET_USER_NOT_ADMINISTRATOR_LIST]: (state, payload) => {
    state.userNotAdministratorModel = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
