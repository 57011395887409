import { PAGE, PAGE_TITLE, PAGE_TAB } from '@@routes'

const termsChildren = (path, title, type) => {
  return {
    path,
    name: PAGE[title],
    redirect: `/operation/terms/${path}/list`,
    component: () => import(/* webpackChunkName: "business" */ '@page/Operation/Terms/TabIndex.vue'),
    meta: {
      title: PAGE_TITLE[title],
      type
    },
    children: [
      {
        path: 'list',
        name: PAGE[`${title}_LIST`],
        component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Terms/List.vue')
      },
      {
        path: 'form/:id?',
        name: PAGE[`${title}_FORM`],
        component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Terms/Form.vue'),
        meta: {
          pageTitle: PAGE_TITLE[`${title}_FORM`]
        }
      }
    ]
  }
}

export default {
  path: '/operation',
  name: PAGE.OPERATION,
  redirect: '/operation/message',
  component: () => import(/* webpackChunkName: "operation" */'@page/Operation/Index.vue'),
  meta: {
    title: PAGE_TITLE.OPERATION,
    serviceName: PAGE.OPERATION,
    useIcon: 'ri-book-read-line'
  },
  children: [
    {
      path: 'message',
      name: PAGE.OPERATION_MESSAGE,
      redirect: '/operation/message/send',
      component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Message/Index.vue'),
      meta: {
        title: PAGE_TITLE.OPERATION_MESSAGE,
        menuName: PAGE.OPERATION_MESSAGE,
        tabName: PAGE.OPERATION_MESSAGE,
        roleKeys: { menu: 'manage', subMenu: 'messageManage' }
      },
      children: [
        {
          path: 'send',
          name: PAGE.OPERATION_MESSAGE_SEND,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Message/Send.vue'),
          meta: {
            title: PAGE_TAB.OPERATION_MESSAGE_SEND
          }
        },
        {
          path: 'list',
          name: PAGE.OPERATION_MESSAGE_SEND_LIST,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Message/SendList.vue'),
          meta: {
            title: PAGE_TAB.OPERATION_MESSAGE_SEND_LIST
          }
        },
        {
          path: 'result',
          name: PAGE.OPERATION_MESSAGE_SEND_RESULT_LIST,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Message/ResultList.vue'),
          meta: {
            title: PAGE_TAB.OPERATION_MESSAGE_SEND_RESULT_LIST
          }
        },
        {
          path: 'template',
          name: PAGE.OPERATION_MESSAGE_TEMPLATE,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Message/Templates.vue'),
          meta: {
            title: PAGE_TAB.OPERATION_MESSAGE_TEMPLATE
          }
        }
      ]
    },
    {
      path: 'alimtalk',
      name: PAGE.OPERATION_ALIMTALK,
      redirect: '/operation/alimtalk/send',
      component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Alimtalk/Index.vue'),
      meta: {
        title: PAGE_TITLE.OPERATION_ALIMTALK,
        menuName: PAGE.OPERATION_ALIMTALK,
        tabName: PAGE.OPERATION_ALIMTALK,
        roleKeys: { menu: 'manage', subMenu: 'messageManage' }
      },
      children: [
        {
          path: 'send',
          name: PAGE.OPERATION_ALIMTALK_SEND,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Alimtalk/Send.vue'),
          meta: {
            title: PAGE_TAB.OPERATION_ALIMTALK_SEND
          }
        },
        {
          path: 'list',
          name: PAGE.OPERATION_ALIMTALK_SEND_LIST,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Alimtalk/SendList.vue'),
          meta: {
            title: PAGE_TAB.OPERATION_ALIMTALK_SEND_LIST
          }
        },
        {
          path: 'result',
          name: PAGE.OPERATION_ALIMTALK_SEND_RESULT_LIST,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Alimtalk/ResultList.vue'),
          meta: {
            title: PAGE_TAB.OPERATION_ALIMTALK_SEND_RESULT_LIST
          }
        },
        {
          path: 'template',
          name: PAGE.OPERATION_ALIMTALK_TEMPLATE,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Alimtalk/Templates.vue'),
          meta: {
            title: PAGE_TAB.OPERATION_ALIMTALK_TEMPLATE
          }
        }
      ]
    },
    {
      path: 'tracking',
      name: PAGE.OPERATION_TRACKING,
      redirect: '/operation/tracking/list',
      component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Index.vue'),
      meta: {
        title: PAGE_TITLE.OPERATION_TRACKING,
        menuName: PAGE.OPERATION_TRACKING,
        roleKeys: { menu: 'campaign', subMenu: 'campaignManage' }
      },
      children: [
        {
          path: 'list',
          name: PAGE.OPERATION_TRACKING_LIST,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Tracking/List.vue')
        }
      ]
    },
    {
      path: 'notice',
      name: PAGE.OPERATION_NOTICE,
      redirect: '/operation/notice/mediance/list',
      component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/Index.vue'),
      meta: {
        title: PAGE_TITLE.OPERATION_NOTICE,
        menuName: PAGE.OPERATION_NOTICE,
        tabName: PAGE.OPERATION_NOTICE,
        roleKeys: { menu: 'manage', subMenu: 'noticeManage' }
      },
      children: [
        {
          path: 'mediance',
          name: PAGE.OPERATION_MEDIANCE_NOTICE,
          redirect: '/operation/notice/mediance/list',
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.OPERATION_MEDIANCE_NOTICE
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_MEDIANCE_NOTICE_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/Mediance/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_MEDIANCE_NOTICE_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/Mediance/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.OPERATION_MEDIANCE_NOTICE_FORM
              }
            }
          ]
        },
        {
          path: 'stylemate',
          name: PAGE.OPERATION_STYLEMATE_NOTICE,
          redirect: '/operation/notice/stylemate/list',
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.OPERATION_STYLEMATE_NOTICE
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_STYLEMATE_NOTICE_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/Stylemate/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_STYLEMATE_NOTICE_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/Stylemate/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.OPERATION_STYLEMATE_NOTICE_FORM
              }
            }
          ]
        },
        {
          path: 'mbp',
          name: PAGE.OPERATION_MBP_NOTICE,
          redirect: '/operation/notice/mbp/list',
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.OPERATION_MBP_NOTICE
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_MBP_NOTICE_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/MBP/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_MBP_NOTICE_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Notice/MBP/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.OPERATION_MBP_NOTICE_FORM
              }
            }
          ]
        }
      ]
    },
    {
      path: 'faq',
      name: PAGE.OPERATION_FAQ,
      redirect: '/operation/faq/mediance/list',
      component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/Index.vue'),
      meta: {
        title: PAGE_TITLE.OPERATION_FAQ,
        menuName: PAGE.OPERATION_FAQ,
        tabName: PAGE.OPERATION_FAQ,
        roleKeys: { menu: 'manage', subMenu: 'faqManage' }
      },
      children: [
        {
          path: 'mediance',
          name: PAGE.OPERATION_MEDIANCE_FAQ,
          redirect: '/operation/faq/mediance/list',
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.OPERATION_MEDIANCE_FAQ
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_MEDIANCE_FAQ_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/Mediance/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_MEDIANCE_FAQ_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/Mediance/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.OPERATION_MEDIANCE_FAQ_FORM
              }
            }
          ]
        },
        {
          path: 'stylemate',
          name: PAGE.OPERATION_STYLEMATE_FAQ,
          redirect: '/operation/faq/stylemate/list',
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.OPERATION_STYLEMATE_FAQ
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_STYLEMATE_FAQ_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/Stylemate/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_STYLEMATE_FAQ_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/Stylemate/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.OPERATION_STYLEMATE_FAQ_FORM
              }
            }
          ]
        },
        {
          path: 'mbp',
          name: PAGE.OPERATION_MBP_FAQ,
          redirect: '/operation/faq/mbp/list',
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.OPERATION_MBP_FAQ
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_MBP_FAQ_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/MBP/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_MBP_FAQ_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/MBP/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.OPERATION_MBP_FAQ_FORM
              }
            }
          ]
        }
      ]
      // children: [
      //   {
      //     path: 'list',
      //     name: PAGE.OPERATION_FAQ_LIST,
      //     component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/List.vue'),
      //     meta: {
      //       tabName: 'faqList'
      //     }
      //   },
      //   {
      //     path: 'form/:id?',
      //     name: PAGE.OPERATION_FAQ_FORM,
      //     component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Faq/Form.vue'),
      //     meta: {
      //       pageTitle: PAGE_TITLE.OPERATION_FAQ_FORM
      //     }
      //   }
      // ]
    },
    {
      path: 'qnas',
      name: PAGE.OPERATION_QNAS,
      redirect: '/operation/qnas/mediance/list',
      component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/Index.vue'),
      meta: {
        title: PAGE_TITLE.OPERATION_QNAS,
        menuName: PAGE.OPERATION_QNAS,
        tabName: PAGE.OPERATION_QNAS,
        roleKeys: { menu: 'manage', subMenu: 'qnaManage' }
      },
      children: [
        {
          path: 'mediance',
          name: PAGE.OPERATION_MEDIANCE_QNAS,
          redirect: '/operation/qnas/mediance/list',
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.OPERATION_MEDIANCE_QNAS
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_MEDIANCE_QNAS_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/Mediance/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_MEDIANCE_QNAS_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/Mediance/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.OPERATION_MEDIANCE_QNAS_FORM
              }
            }
          ]
        },
        {
          path: 'stylemate',
          name: PAGE.OPERATION_STYLEMATE_QNAS,
          redirect: '/operation/qnas/stylemate/list',
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.OPERATION_STYLEMATE_QNAS
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_STYLEMATE_QNAS_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/Stylemate/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_STYLEMATE_QNAS_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/Stylemate/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.OPERATION_STYLEMATE_QNAS_FORM
              }
            }
          ]
        },
        {
          path: 'mbp',
          name: PAGE.OPERATION_MBP_QNAS,
          redirect: '/operation/qnas/mbp/list',
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/TabIndex.vue'),
          meta: {
            title: PAGE_TITLE.OPERATION_MBP_QNAS
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_MBP_QNAS_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/MBP/List.vue')
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_MBP_QNAS_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Qnas/MBP/Form.vue'),
              meta: {
                pageTitle: PAGE_TITLE.OPERATION_MBP_QNAS_FORM
              }
            }
          ]
        }
      ]
    },
    {
      path: 'channelGuide',
      name: PAGE.OPERATION_CHANNEL_GUIDE,
      redirect: '/operation/channelGuide/new/list',
      component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/ChannelGuide/Index.vue'),
      meta: {
        title: PAGE_TITLE.OPERATION_CHANNEL_GUIDE,
        menuName: PAGE.OPERATION_CHANNEL_GUIDE,
        tabName: PAGE.OPERATION_CHANNEL_GUIDE,
        roleKeys: { menu: 'manage', subMenu: 'messageManage' }
      },
      children: [
        {
          path: 'new',
          name: PAGE.OPERATION_CHANNEL_GUIDES_NEW,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/ChannelGuide/RouteIndex.vue'),
          redirect: '/operation/channelGuide/new/list',
          meta: {
            title: PAGE_TAB.OPERATION_CHANNEL_GUIDES_NEW_LIST,
            tabName: PAGE.OPERATION_CHANNEL_GUIDES_NEW_LIST,
            menuName: PAGE.OPERATION_CHANNEL_GUIDES_NEW_LIST,
            roleKeys: { menu: 'manage', subMenu: 'messageManage' }
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_CHANNEL_GUIDES_NEW_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/ChannelGuide/NewList.vue'),
              meta: {
                title: PAGE_TAB.OPERATION_CHANNEL_GUIDES_NEW_LIST
              }
            },
            {
              path: 'form/:id?',
              name: PAGE.OPERATION_CHANNEL_GUIDES_NEW_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/ChannelGuide/NewForm.vue'),
              meta: {
                title: PAGE_TAB.OPERATION_CHANNEL_GUIDES_NEW_FORM
              }
            }
          ]
        },
        {
          path: 'exist',
          name: PAGE.OPERATION_CHANNEL_GUIDES_EXIST,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/ChannelGuide/RouteIndex.vue'),
          redirect: '/operation/channelGuide/exist/list',
          meta: {
            title: PAGE_TAB.OPERATION_CHANNEL_GUIDES_EXIST_LIST,
            menuName: PAGE.OPERATION_CHANNEL_GUIDES_EXIST_LIST,
            tabName: PAGE.OPERATION_CHANNEL_GUIDES_EXIST_LIST,
            roleKeys: { menu: 'manage', subMenu: 'messageManage' }
          },
          children: [
            {
              path: 'list',
              name: PAGE.OPERATION_CHANNEL_GUIDES_EXIST_LIST,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/ChannelGuide/ExistList.vue'),
              meta: {
                title: PAGE_TAB.OPERATION_CHANNEL_GUIDES_EXIST_LIST
              }
            },
            {
              path: 'form',
              name: PAGE.OPERATION_CHANNEL_GUIDES_EXIST_FORM,
              component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/ChannelGuide/ExistForm.vue'),
              meta: {
                title: PAGE_TAB.OPERATION_CHANNEL_GUIDES_EXIST_LIST
              }
            }
          ]
        }
      ]
    },
    {
      path: 'banners',
      name: PAGE.OPERATION_BANNER,
      redirect: '/operation/banners/list',
      component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Index.vue'),
      meta: {
        title: PAGE_TITLE.OPERATION_BANNER,
        menuName: PAGE.OPERATION_BANNER,
        roleKeys: { menu: 'manage', subMenu: 'bannerManage' }
      },
      children: [
        {
          path: 'list',
          name: PAGE.OPERATION_BANNER_LIST,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Banners/List.vue')
        },
        {
          path: 'ordering',
          name: PAGE.OPERATION_BANNER_ORDERING,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Banners/Ordering.vue'),
          meta: {
            pageTitle: PAGE_TITLE.OPERATION_BANNER_ORDERING
          }
        },
        {
          path: 'form/:id?',
          name: PAGE.OPERATION_BANNER_FORM,
          component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Banners/Form.vue'),
          meta: {
            pageTitle: PAGE_TITLE.OPERATION_BANNER_FORM
          }
        }
      ]
    },
    {
      path: 'terms',
      name: PAGE.OPERATION_TERMS,
      redirect: '/operation/terms/service/list',
      component: () => import(/* webpackChunkName: "operation" */ '@page/Operation/Terms/Index.vue'),
      meta: {
        title: PAGE_TITLE.OPERATION_TERMS,
        menuName: PAGE.OPERATION_TERMS,
        tabName: PAGE.OPERATION_TERMS,
        roleKeys: { menu: 'arrow', subMenu: 'accessor' }
      },
      children: [
        termsChildren('service', 'OPERATION_TERMS_SERVICE', 'terms'),
        termsChildren('policy', 'OPERATION_TERMS_POLICY', 'policies'),
        termsChildren('third_party', 'OPERATION_TERMS_THIRD_PARTY', 'third-partys'),
        termsChildren('marketing', 'OPERATION_TERMS_MARKETING', 'marketings'),
        termsChildren('exam-guides', 'OPERATION_TERMS_GUIDELINE', 'exam-guides')
      ]
    }
  ]
}
