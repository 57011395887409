import axios from '@/plugins/axios'
import { SET_AGENCY_QNA_LIST, SET_AGENCY_QNA_DETAIL } from '@/store/mutation_types'

const state = {
  agencyQnaModel: {},
  agencyQnaDetail: {}
}

const getters = {
  agencyQnaListData: state => state.agencyQnaModel,
  agencyQnaDetail: state => state.agencyQnaDetail
}

const actions = {
  async getAgencyQnaList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/agency-requests',
      params: {
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_AGENCY_QNA_LIST, response)
    })
  },
  async getAgencyQnaItemDetail ({ commit }, { id }) {
    const config = {
      method: 'GET',
      url: `/_p/agency-requests/${id}`
    }
    await axios(config).then(response => {
      commit(SET_AGENCY_QNA_DETAIL, response)
    })
  }
}

const mutations = {
  [SET_AGENCY_QNA_LIST]: (state, payload) => {
    state.agencyQnaModel = payload
  },
  [SET_AGENCY_QNA_DETAIL]: (state, payload) => {
    state.agencyQnaDetail = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
