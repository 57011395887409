import axios from '@/plugins/axios'
import { SET_COST_LIST } from '@/store/mutation_types'

const state = {
  costModel: {}
}

const getters = {
  costListData: state => state.costModel
}

const actions = {
  async getCostList ({ commit, dispatch }, params) {
    const config = {
      method: 'GET',
      url: '/_p/influence-costs',
      params: {
        ...params
      }
    }
    const response = await axios(config)
    commit(SET_COST_LIST, response)
  },
  async costDownload ({ commit }, { params }) {
    const config = {
      method: 'GET',
      responseType: 'blob',
      url: '/_p/influence-costs/download',
      params: {
        perPage: 999999,
        ...params
      }
    }
    axios.defaults.timeout = 60 * 1000 * 10
    const res = await axios(config)
    axios.defaults.timeout = 60 * 1000
    return res
  }
}

const mutations = {
  [SET_COST_LIST]: (state, payload) => {
    state.costModel = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
