<template>
  <div
    v-if="props.isAdd || (!props.isAdd && tagData.list.length > 0)"
    class="tag-list"
    :class="{'tag-list--tel': props.formatType === 'tel'}"
  >
    <span
      v-for="(tag, index) in tagData.list"
      :key="index"
      class="v-tag"
      :class="tagData.deleteIndex === index ? 'red' : ''"
    >
      {{ tagAction.tagFormat(tag) }}
      <slot
        name="etc"
        :tag="tag"
      />
      <span
        v-if="!props.disabled && props.isAdd || props.isRemove"
        class="v-tag__close"
        @click="tagAction.delete(tag, index)"
      >
        <i class="ri-close-line" />
      </span>
    </span>

    <div
      v-if="!props.disabled && props.isAdd && tagData.list.length < props.maxCount"
      class="tag-list__input-box"
    >
      <span
        v-if="!props.disabled"
        v-show="tagData.tooltipShow"
        class="tag-list__tooltip"
      >
        동일한 태그가 존재합니다.
      </span>

      <span class="tag-list__input-inner">
        <input
          ref="tagInput"
          v-model="tagData.inputValue"
          type="text"
          :class="{'active': tagData.inputVisible}"
          :maxlength="maxLength"
          @blur="tagAction.handleBlur"
          @input="tagAction.handleInput"
          @keyup.delete="tagAction.handleTagDelete"
          @keyup.enter="tagAction.handleTagAdd"
        />
      </span>
      <span
        class="v-tag"
        :class="{'v-tag--hide': tagData.inputVisible}"
        @click="tagAction.tagShowInput"
      >
        {{ props.label }}
        <i class="ri-add-line" />
      </span>
    </div>
  </div>
  <template v-if="tagData.list.length === 0 && props.disabled">
    -
  </template>
</template>

<script setup>
import { computed, watch, ref, reactive, nextTick } from 'vue'
import { objectToString } from '@/utils/object/index.js'
import { numberTel } from '@/utils/number.js'

const props = defineProps({
  modelValue: {
    type: [Array, String],
    default: () => []
  },
  etcInfo: {
    type: [Array],
    default: () => []
  },
  label: {
    type: String,
    default: 'New Tag'
  },
  maxCount: {
    type: Number,
    default: 99999
  },
  disabled: {
    type: Boolean,
    default: false
  },
  formatType: {
    type: String,
    default: null
  },
  maxLength: {
    type: Number,
    default: null
  },
  isAdd: {
    type: Boolean,
    default: true
  },
  isRemove: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['update:modelValue', 'change', 'remove'])

const tagList = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value.map(item => item.replace(/ /g, '')))
  }
})

const tagInput = ref(null)
const tagData = reactive({
  list: [],
  inputValue: '',
  inputVisible: false,
  deleteInfo: {},
  deleteIndex: null,
  tooltipShow: false
})
const tagAction = {
  delete: (removeTag, index) => {
    const tags = tagData.list.filter((tag, idx) => {
      return !(idx === index && tag === removeTag)
    })
    tagData.list = tags
    tagList.value = tags
    emit('remove', { removeValue: removeTag, index })
  },
  toggleTooltip: () => {
    tagData.tooltipShow = true
    setTimeout(() => {
      tagData.tooltipShow = false
    }, 700)
  },
  tagShowInput: () => {
    tagData.inputVisible = true
    nextTick(() => {
      tagInput.value.focus()
    })
  },
  tagFormat: (tag) => {
    return props.formatType === 'tel' ? numberTel(tag) : tag
  },
  handleInput: ($event) => {
    const inputValue = $event.target.value

    if (inputValue) {
      tagData.deleteIndex = null
    }
  },
  handleBlur: () => {
    tagData.inputVisible = false
    tagData.inputValue = ''
  },
  handleTagAdd: () => {
    const inputValue = tagData.inputValue
    let tags = tagData.list
    if (tags.includes(inputValue)) tagAction.toggleTooltip()
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }
    tagData.inputValue = ''
    tagData.list = tags
    tagList.value = tags
    emit('change')
  },
  handleTagDelete: () => {
    if (tagData.list.length > 0 && tagData.inputValue === '') {
      if (tagData.deleteIndex !== null) {
        tagData.list = tagData.list.slice(0, tagData.deleteIndex)
        tagData.deleteIndex = null
        emit('remove', tagData.deleteInfo)
      } else {
        tagData.deleteIndex = tagData.list.length - 1
        tagData.deleteInfo = { removeValue: tagData.list[tagData.deleteIndex], index: tagData.deleteIndex }
      }
    }
  }
}

watch(() => tagList.value, (value) => {
  if (value) {
    if (typeof value[0] === 'object') {
      tagData.list = objectToString(value, 'tel')
    } else {
      tagData.list = value
    }
  } else {
    tagData.list = []
  }
}, { immediate: true, deep: true })
</script>

<style lang="scss">
.tag-list {
  $root: &;

  margin: -2px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    padding: 1px 5px 0 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    &:focus {
      border-color: $primary-color;
      border-width: 1px;
      outline: none;
    }

    &.active {
      z-index: 1;
    }
  }

  &__input-box {
    position: relative;
    display: inline-block;
    margin: 2px;

    > .v-tag { margin: 0; }
  }

  &--tel {
    > .v-tag,
    #{$root}__input-box {
      width: 32.5%;
    }

    #{$root}__input-box {
      > .v-tag {
        width: 100%;
      }
    }
  }

  &__tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: 10;
    padding: 5px 10px;
    margin-bottom: 5px;
    color: $white;
    white-space: nowrap;
    background: rgba($black, 0.8);
    transform: translateX(-50%);
  }

  * + & {
    margin-top: 8px;
  }
}
</style>
