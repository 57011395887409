import axios from '@/plugins/axios'
import {
  SET_TAG_LIST,
  SET_TAG_LIST_ITEM,
  SET_TAG_TYPE_LIST
} from '@/store/mutation_types'

const state = {
  typeList: [],
  channelCategoryModel: {},
  brandModel: {},
  guideActionModel: {},
  categoryModel: {},
  instagramPropertyModel: {},
  youtubePropertyModel: {},
  adTypeModel: {},
  interestModel: {},
  liveCommerceCategoryModel: {},
  costTypeModel: {},
  productTagModel: {}
}

const getters = {
  tagTypeList: state => state.typeList,
  channelCategoryListData: state => state.channelCategoryModel,
  brandListData: state => state.brandModel,
  guideActionListData: state => state.guideActionModel,
  categoryListData: state => state.categoryModel,
  instagramPropertyListData: state => state.instagramPropertyModel,
  youtubePropertyListData: state => state.youtubePropertyModel,
  adTypeListData: state => state.adTypeModel,
  interestListData: state => state.interestModel,
  liveCommerceCategoryListData: state => state.liveCommerceCategoryModel,
  costTypeListData: state => state.costTypeModel,
  productTagListData: state => state.productTagModel,
  /* 이전 */
  tagList: state => (key) => state[`${key}Model`]
}

const actions = {
  async getTagTypeList ({ commit }) {
    const config = {
      method: 'GET',
      url: '/_p/tags/types'
    }
    await axios(config).then((response) => {
      commit(SET_TAG_TYPE_LIST, response)
    })
  },
  async getTagList ({ commit }, params) {
    const config = {
      method: 'GET',
      url: '/_p/tags',
      params: {
        perPage: 10,
        page: 1,
        filters: {},
        ...params
      }
    }
    await axios(config).then((response) => {
      commit(SET_TAG_LIST, { res: response, type: params.filters.type })
    })
  },
  async createTagItem ({ commit }, { params }) {
    const config = {
      method: 'POST',
      url: '/_p/tags',
      data: params
    }
    await axios(config).then((response) => {
      commit(SET_TAG_LIST_ITEM, { res: response, type: params.type })
    })
  },
  async saveTagItem ({ commit }, { id, params }) {
    const setting = {
      method: 'PUT',
      url: `/_p/tags/${id}`,
      params: {
        ...params
      }
    }
    const result = await axios(setting)
    return result
  }
}

const mutations = {
  [SET_TAG_TYPE_LIST]: (state, payload) => {
    state.typeList = payload
  },
  [SET_TAG_LIST]: (state, payload) => {
    state[`${payload.type}Model`] = payload.res
  },
  [SET_TAG_LIST_ITEM]: (state, payload) => {
    state[`${payload.type}Model`].data.push(payload.res)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
