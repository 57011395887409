<template>
  <div
    class="tag-selector"
    :class="{'tag-selector--disabled': props.disabled}"
  >
    <div
      v-if="props.isAdd && props.isChoice && props.choiceType !== 'popup'"
      class="tag-selector__add"
    >
      <vxe-input
        v-model="tagData.form.tag"
        class="input-show-count"
        maxlength="20"
        style="width: 100%;"
      >
        <template #suffix>
          {{ tagData.form.tag?.length || 0 }}/20
        </template>
      </vxe-input>
      <vxe-button
        content="추가"
        status="primary"
        @click="tagActions.createTag"
      />
    </div>
    <div class="tag-selector__list">
      <template v-if="!props.onlyButton">
        <template v-if="props.isChoice && props.choiceType !== 'popup'">
          <span
            v-for="(item, index) in tagData.list"
            :key="index"
            class="v-tag v-tag--mini purple"
            :class="{'active': tagData.selected.includes(item.tag)}"
            @click="tagActions.selected(item.tag)"
          >
            {{ item.tag }}
          </span>
        </template>
        <template v-else>
          <span
            v-for="(item, index) in tagData.selected"
            :key="index"
            class="v-tag v-tag--mini purple"
            @click="tagActions.open"
          >
            {{ item }}
          </span>
        </template>
      </template>
      <template v-if="props.onlyButton || (tagData.selected.length === 0 && props.choiceType === 'popup' && (props.isAdd || props.isChoice))">
        <button
          class="v-tag v-tag--mini purple active"
          @click="tagActions.open"
        >
          {{ props.addButtonText }}
        </button>
      </template>
    </div>
  </div>

  <custom-modal
    v-if="tagData.modal.show"
    v-model="tagData.modal.show"
    :title="tagData.modal.title"
    :width="520"
    @confirm="tagActions.handleOk"
  >
    <div
      v-if="props.isAdd"
      class="tag-selector__add"
    >
      <vxe-input
        v-model="tagData.form.tag"
        class="input-show-count"
        maxlength="20"
        style="width: 100%;"
      >
        <template #suffix>
          {{ tagData.form.tag?.length || 0 }}/20
        </template>
      </vxe-input>
      <vxe-button
        content="추가"
        status="primary"
        @click="tagActions.createTag"
      />
    </div>
    <div class="tag-selector__list">
      <span
        v-for="(item, index) in tagData.list"
        :key="index"
        class="v-tag v-tag--mini purple"
        :class="{'active': tagData.modal.selected.includes(item.tag)}"
        @click="tagActions.modalSelected(item.tag)"
      >
        {{ item.tag }}
      </span>
    </div>
    <div
      v-if="props.etc.length"
      class="tag-selector__etc"
    >
      {{ props.etcTitle }} :
      <span
        v-for="(item, index) in props.etc"
        :key="index"
        class="v-tag v-tag--mini purple active"
      >
        {{ item.tag }}
      </span>
    </div>
  </custom-modal>
</template>
<script setup>
import { computed, watch, reactive } from 'vue'
import { useGlobalConfig } from '@/composables/globalConfig.js'
import { objectToString } from '@/utils/object/index.js'
import cloneDeep from 'lodash/cloneDeep'

const { store } = useGlobalConfig()

const props = defineProps({
  modelValue: {
    type: [Array, String],
    default: () => []
  },
  type: {
    type: String,
    default: 'category'
  },
  isChoice: {
    type: Boolean,
    default: false
  },
  choiceType: {
    type: String,
    default: 'popup'
  },
  isAdd: {
    type: Boolean,
    default: false
  },
  onlyButton: {
    type: Boolean,
    default: false
  },
  addButtonText: {
    type: String,
    default: '카테고리추가 +'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  maxCount: {
    type: Number,
    default: null
  },
  isGuide: {
    type: Boolean,
    default: false
  },
  etc: {
    type: Array,
    default: () => []
  },
  etcTitle: {
    type: String,
    default: '기타 카테고리(수정불가)'
  }
})
const emit = defineEmits(['update:modelValue', 'save', 'change'])

const model = computed({
  get: () => {
    const list = props.modelValue
    if (typeof list === 'string') {
      return [list]
    } else if (list.length > 0 && typeof list[0] === 'object') {
      return objectToString(list)
    } else {
      return list
    }
  },
  set: (value) => {
    if (props.isChoice) {
      emit('update:modelValue', value)
      emit('change')
    } else {
      emit('change', value)
    }
  }
})

const tagActions = {
  open: () => {
    if (props.isAdd || props.onlyButton) {
      if (tagData.list.length === 0) apiActions.getList()
      tagData.modal.selected = cloneDeep(tagData.selected)
      tagData.modal.show = true
    }
  },
  selected: (tag) => {
    if (props.disabled) return false
    const checked = !tagData.selected.includes(tag)
    if (checked && (!props.maxCount || (props.maxCount && tagData.selected.length < props.maxCount))) {
      tagData.selected.push(tag)
    } else if (!checked) {
      tagData.selected = tagData.selected.filter(t => t !== tag)
    }
    model.value = tagData.selected
  },
  modalSelected: (tag) => {
    if (props.disabled) return false
    const checked = !tagData.modal.selected.includes(tag)
    if (checked && (!props.maxCount || (props.maxCount && tagData.modal.selected.length < props.maxCount))) {
      tagData.modal.selected.push(tag)
    } else if (!checked) {
      tagData.modal.selected = tagData.modal.selected.filter(t => t !== tag)
    }
  },
  handleOk: () => {
    tagData.selected = tagData.modal.selected
    model.value = tagData.selected
    tagData.modal.show = false
  },
  createTag: () => apiActions.createItem(tagData.form.tag)
}
const tagData = reactive({
  list: [],
  selected: [],
  form: {
    tag: null
  },
  modal: {
    show: false,
    title: '태그',
    selected: []
  }
})

const apiActions = {
  getList: async () => {
    const list = store.getters[`tags/${props.type}ListData`]
    if (!list.data) {
      const params = {
        perPage: 9999,
        filters: { type: props.type, status: 'active' }
      }
      await store.dispatch('tags/getTagList', params)
      const res = store.getters[`tags/${props.type}ListData`]
      tagData.list = res.data
    } else {
      tagData.list = list.data
    }
  },
  createItem: async (tag) => {
    const config = {
      params: {
        tag,
        type: props.type,
        status: 'active'
      }
    }
    await store.dispatch('tags/createTagItem', config)
    const res = store.getters[`tags/${props.type}ListData`]
    tagData.list = res.data
    tagData.form.tag = ''
  }
}

if (props.isChoice) apiActions.getList()

watch(model, (value) => {
  tagData.selected = value
}, { immediate: true })
</script>

<style lang="scss" scoped>
.tag-selector {
  position: relative;

  &__add {
    display: flex;

    & + * {
      margin-top: 12px;
    }
  }

  &__etc {
    margin-top: 20px;
  }

  &--disabled {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background: rgba($white, 0.5);
    }
  }
}
</style>
