const currencyKRW = (value) => {
  return '￦' + value
}

const currencyUSD = (value) => {
  return '$' + value
}

const numberFormat = (value) => {
  value += ''
  const list = value.split('.')
  const prefix = list[0].charAt(0) === '-' ? '-' : ''
  let num = prefix ? list[0].slice(1) : list[0]
  let result = ''
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
}

const numberPad = (num, width = 2) => {
  const number = num.toString()
  return number.length >= width ? number : new Array(width - number.length + 1).join('0') + number
}

const numberAbbr = (num, digits = 2, type = '') => {
  const units = type === 'ko' ? ['천', '백만'] : ['K', 'M']
  let decimal
  let result

  const size = num.toString().length

  for (let i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1)
    if (size >= 7) {
      if (num <= -decimal || num >= decimal) {
        result = +(num / decimal).toFixed(digits) + units[i]
        return result
      }
    }
  }
  return numberFormat(num)
}

const numberTel = (num) => {
  const replaceType = num.length <= 7 ? '$1-$2$3' : '$1-$2-$3'
  return num.replace(/(^02.{0}|^01.{1}|^[0-9]{3})([0-9]*)([0-9]{4})/, replaceType)
}

const numberExtract = (value) => {
  const regex = /[^0-9]/g
  return value.replace(regex, '')
}

const numberRrn = (value) => {
  const updateValue = String(value).replace(/[^0-9]/gi, '')
  const regex = /([0-9]{6})([0-9]{0,7})/
  return updateValue.replace(regex, '$1-$2')
}

const numberBizNumber = (value) => {
  const updateValue = String(value).replace(/[^0-9]/gi, '')
  const regex = /([0-9]{3})([0-9]{2})([0-9]{0,5})/
  return updateValue.replace(regex, '$1-$2-$3')
}

export {
  currencyKRW,
  currencyUSD,
  numberFormat,
  numberPad,
  numberAbbr,
  numberTel,
  numberExtract,
  numberRrn,
  numberBizNumber
}
